<template>
  <div></div>
</template>

<script>
export default {
  name: "MobileRedirect",
  APP_LINKS: {
    android: "weld-app://",
    ios: "weld-app://",
    other: "weld-app://",
  },
  created() {
    const platform = this.getMobileOperatingSystem();
    const appLink = this.$options.APP_LINKS[platform];

    if (!appLink) {
      return;
    }

    const appPath = this.$route.fullPath.substring(
      1
    );

    console.log(`deeplink: ${appLink}${appPath}`);
    location.replace(`${appLink}${appPath}`);
  },

  methods: {
    getMobileOperatingSystem() {
      const ua = navigator.userAgent;
      if (/android/i.test(ua)) {
        return "android";
      } else if (
        /iPad|iPhone|iPod/.test(ua) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
      ) {
        return "ios";
      }
      return "other";
    },
  },
};
</script>

<style scoped></style>
