import { RbacService } from "@/services/user/rbac.service";

export const useCanMiddleware =
  (component) =>
  ({ next }) => {
    if (!RbacService.userCan(component)) {
      return next({
        path: "/",
      });
    }
    return next();
  };
