import ClipboardService from "@/services/clipboard/clipboard.service";

export default {
  inject: ["showSuccessTip"],
  methods: {
    async copyLinkHandler(link) {
      const success = await ClipboardService.copyToClipboard(link);

      success &&
        this.showSuccessTip(
          this.$t("referral_partner.terms.personal_link.copied_to_clipboard")
        );
    },
  },
};
