<template>
  <div class="referral-indicators">
    <base-loader v-if="isDataLoading"></base-loader>
    <div class="referral-indicator">
      <div class="referral-indicator__tip">
        <v-popover placement="top" class="hint">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" fill="white" />
            <path
              d="M10.1724 6.25063C10.828 6.25063 11.3793 5.68712 11.3793 5.01404C11.3793 4.30965 10.8131 3.7931 10.1724 3.7931C9.53172 3.7931 8.96552 4.30965 8.96552 5.01404C8.96552 5.70278 9.51682 6.25063 10.1724 6.25063Z"
              fill="#7A939C"
            />
            <path
              d="M9.11452 14.8129C9.11452 15.2355 9.38272 15.5172 9.78502 15.5172H10.5747C10.977 15.5172 11.2452 15.2355 11.2452 14.8129V8.03508C11.2452 7.61245 10.977 7.33069 10.5747 7.33069H9.78502C9.38272 7.33069 9.11452 7.61245 9.11452 8.03508V14.8129Z"
              fill="#7A939C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.6207 10C18.6207 14.7611 14.7611 18.6207 10 18.6207C5.23892 18.6207 1.37931 14.7611 1.37931 10C1.37931 5.23892 5.23892 1.37931 10 1.37931C14.7611 1.37931 18.6207 5.23892 18.6207 10Z"
              fill="#7A939C"
            />
          </svg>
          <template slot="popover" class="hint">
            <div class="hint__body">
              <div class="hint__text">
                {{
                  "You can use this amount for usual transactions and it was added to your Metamask balance."
                }}
              </div>
            </div>
            <div class="hint__footer">
              <a v-close-popover class="hint__close">Close</a>
            </div>
          </template>
        </v-popover>
      </div>
      <div class="referral-indicator__header">
        <div class="referral-indicator__info">
          <div class="referral-indicator__info-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.24757 7.5H15.7876L18.9866 14.6656C20.3154 17.6421 18.1371 21 14.8774 21H8.90401C5.58703 21 3.40999 17.5335 4.85055 14.5457L8.24757 7.5ZM14.8146 9H9.1896L6.20171 15.1971C5.24133 17.189 6.69269 19.5 8.90401 19.5H14.8774C17.0506 19.5 18.5027 17.2614 17.6169 15.2771L14.8146 9Z"
                fill="#00B7BD"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.85451 3H15.1463C17.0447 3 18.3154 4.95298 17.5462 6.68858L17.2138 7.43858C16.7931 8.38791 15.8523 9 14.8139 9H9.18689C8.14852 9 7.20774 8.3879 6.78702 7.43858L6.45463 6.68858C5.68544 4.95298 6.9561 3 8.85451 3ZM8.85451 4.5C8.04091 4.5 7.49634 5.33699 7.82599 6.08082L8.15838 6.83082C8.33869 7.23767 8.74188 7.5 9.18689 7.5H14.8139C15.2589 7.5 15.6621 7.23767 15.8424 6.83082L16.1748 6.08082C16.5045 5.33699 15.9599 4.5 15.1463 4.5H8.85451Z"
                fill="#00B7BD"
              />
            </svg>
          </div>
          <div class="referral-indicator__info-column">
            <div class="referral-indicator__info-title">
              {{ $t("referrals.earned.title") }}
            </div>
            <div class="referral-indicator__info-sub-title">
              {{ $t("referrals.earned.sub-title") }}
            </div>
          </div>
        </div>
        <div class="referral-indicator__value">
          {{ indicators.earned / 100 }} USDT
        </div>
      </div>
      <div class="referral-indicator__hint">
        <div
          class="referral-indicator__hint-icon referral-indicator__hint-icon--fill"
        >
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.95833 7.95476C6.8169 9.09618 6.83474 10.9646 7.99818 12.1281L9.99103 14.1209C10.1985 14.3284 10.2579 14.6369 10.2045 14.9254C10.0984 15.4981 10.1533 16.1601 10.5553 16.5621C10.6831 16.6899 10.8884 16.6919 11.0139 16.5665L16.57 11.0103C16.6955 10.8849 16.6935 10.6795 16.5657 10.5517C16.1637 10.1497 15.5017 10.0948 14.929 10.2009C14.6404 10.2543 14.332 10.1949 14.1245 9.98746L12.1317 7.99461C10.9682 6.83117 9.09976 6.81333 7.95833 7.95476Z"
              fill="#00B7BD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.29123 12.8352C5.75006 11.294 5.70675 8.79227 7.25137 7.24765C8.796 5.70303 11.2977 5.74633 12.8389 7.2875L14.7656 9.21423C15.4537 9.09045 16.5267 9.09836 17.2729 9.84458C17.7785 10.3502 17.8059 11.1888 17.2773 11.7174L11.7211 17.2736C11.1925 17.8022 10.3539 17.7748 9.84831 17.2692C9.10208 16.523 9.09417 15.45 9.21796 14.7619L7.29123 12.8352ZM7.99833 12.1281C6.8349 10.9646 6.81705 9.09618 7.95848 7.95476C9.09991 6.81333 10.9684 6.83117 12.1318 7.99461L14.1247 9.98746C14.3321 10.1949 14.6406 10.2543 14.9291 10.2009C15.5019 10.0948 16.1639 10.1497 16.5658 10.5517C16.6937 10.6795 16.6956 10.8849 16.5702 11.0103L11.014 16.5665C10.8886 16.6919 10.6833 16.6899 10.5554 16.5621C10.1535 16.1601 10.0986 15.4981 10.2046 14.9254C10.2581 14.6369 10.1987 14.3284 9.99118 14.1209L7.99833 12.1281Z"
              fill="#00B9BF"
            />
            <path
              d="M12.0547 14.2247C12.0115 14.0389 12.1272 13.8532 12.3131 13.81L14.0752 13.4009C14.2611 13.3578 14.4468 13.4735 14.4899 13.6594L14.8641 15.271C15.0202 15.9435 14.6016 16.6152 13.9292 16.7713C13.2567 16.9274 12.585 16.5088 12.4289 15.8364L12.0547 14.2247Z"
              fill="#00B9BF"
            />
          </svg>
        </div>
        <div class="referral-indicator__hint-text">
          {{
            $t("referrals.earned.hint", {
              countUsers: indicators.countTransactions,
            })
          }}
        </div>
      </div>
    </div>
    <div class="referral-indicator">
      <div class="referral-indicator__tip">
        <v-popover placement="top" class="hint">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" fill="white" />
            <path
              d="M10.1724 6.25063C10.828 6.25063 11.3793 5.68712 11.3793 5.01404C11.3793 4.30965 10.8131 3.7931 10.1724 3.7931C9.53172 3.7931 8.96552 4.30965 8.96552 5.01404C8.96552 5.70278 9.51682 6.25063 10.1724 6.25063Z"
              fill="#7A939C"
            />
            <path
              d="M9.11452 14.8129C9.11452 15.2355 9.38272 15.5172 9.78502 15.5172H10.5747C10.977 15.5172 11.2452 15.2355 11.2452 14.8129V8.03508C11.2452 7.61245 10.977 7.33069 10.5747 7.33069H9.78502C9.38272 7.33069 9.11452 7.61245 9.11452 8.03508V14.8129Z"
              fill="#7A939C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.6207 10C18.6207 14.7611 14.7611 18.6207 10 18.6207C5.23892 18.6207 1.37931 14.7611 1.37931 10C1.37931 5.23892 5.23892 1.37931 10 1.37931C14.7611 1.37931 18.6207 5.23892 18.6207 10Z"
              fill="#7A939C"
            />
          </svg>
          <template slot="popover" class="hint">
            <div class="hint__body">
              <div class="hint__text">
                {{
                  "You can use this amount for usual transactions and it was added to your Metamask balance."
                }}
              </div>
            </div>
            <div class="hint__footer">
              <a v-close-popover class="hint__close">Close</a>
            </div>
          </template>
        </v-popover>
      </div>
      <div class="referral-indicator__header">
        <div class="referral-indicator__info">
          <div class="referral-indicator__info-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.24757 7.5H15.7876L18.9866 14.6656C20.3154 17.6421 18.1371 21 14.8774 21H8.90401C5.58703 21 3.40999 17.5335 4.85055 14.5457L8.24757 7.5ZM14.8146 9H9.1896L6.20171 15.1971C5.24133 17.189 6.69269 19.5 8.90401 19.5H14.8774C17.0506 19.5 18.5027 17.2614 17.6169 15.2771L14.8146 9Z"
                fill="#333F48"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.85451 3H15.1463C17.0447 3 18.3154 4.95298 17.5462 6.68858L17.2138 7.43858C16.7931 8.38791 15.8523 9 14.8139 9H9.18689C8.14852 9 7.20774 8.3879 6.78702 7.43858L6.45463 6.68858C5.68544 4.95298 6.9561 3 8.85451 3ZM8.85451 4.5C8.04091 4.5 7.49634 5.33699 7.82599 6.08082L8.15838 6.83082C8.33869 7.23767 8.74188 7.5 9.18689 7.5H14.8139C15.2589 7.5 15.6621 7.23767 15.8424 6.83082L16.1748 6.08082C16.5045 5.33699 15.9599 4.5 15.1463 4.5H8.85451Z"
                fill="#333F48"
              />
            </svg>
          </div>
          <div class="referral-indicator__info-column">
            <div class="referral-indicator__info-title">
              {{ $t("referrals.expected-earn.title") }}
            </div>
            <div class="referral-indicator__info-sub-title">
              {{ $t("referrals.expected-earn.sub-title") }}
            </div>
          </div>
        </div>
        <div class="referral-indicator__value">
          {{ indicators.expectedEarning / 100 }} USDT
        </div>
      </div>
      <div class="referral-indicator__hint">
        <div class="referral-indicator__hint-icon">
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.89506 5.89254C3.83556 7.95203 3.89331 11.2877 5.9482 13.3426L8.51717 15.9116C8.35213 16.829 8.36267 18.2596 9.35764 19.2546C10.0317 19.9287 11.1499 19.9653 11.8547 19.2605L14.3803 16.7348C15.0312 17.3857 16.0865 17.3857 16.7374 16.7348C17.3882 16.084 17.3882 15.0287 16.7374 14.3778L19.263 11.8522C19.9678 11.1474 19.9312 10.0292 19.2571 9.35512C18.2622 8.36015 16.8315 8.34961 15.9141 8.51465L13.3451 5.94567C11.2902 3.89078 7.95456 3.83304 5.89506 5.89254ZM6.83787 6.83535C5.31597 8.35725 5.33976 10.8485 6.89101 12.3998L9.54813 15.0569C9.82479 15.3336 9.90399 15.7448 9.83274 16.1295C9.69132 16.8932 9.76451 17.7759 10.3004 18.3118C10.4709 18.4823 10.7447 18.4849 10.9119 18.3176L18.3202 10.9094C18.4874 10.7422 18.4848 10.4684 18.3143 10.2979C17.7784 9.76199 16.8957 9.6888 16.1321 9.83022C15.7474 9.90146 15.3361 9.82227 15.0594 9.54561L12.4023 6.88848C10.8511 5.33723 8.35977 5.31344 6.83787 6.83535Z"
              fill="#00B7BD"
            />
          </svg>
        </div>
        <div class="referral-indicator__hint-text">
          {{
            $t("referrals.expected-earn.hint", {
              countUsers: indicators.countUsers - indicators.countTransactions,
            })
          }}
        </div>
      </div>
    </div>
    <div class="referral-indicator">
      <div class="referral-indicator__tip">
        <v-popover placement="top" class="hint">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" fill="white" />
            <path
              d="M10.1724 6.25063C10.828 6.25063 11.3793 5.68712 11.3793 5.01404C11.3793 4.30965 10.8131 3.7931 10.1724 3.7931C9.53172 3.7931 8.96552 4.30965 8.96552 5.01404C8.96552 5.70278 9.51682 6.25063 10.1724 6.25063Z"
              fill="#7A939C"
            />
            <path
              d="M9.11452 14.8129C9.11452 15.2355 9.38272 15.5172 9.78502 15.5172H10.5747C10.977 15.5172 11.2452 15.2355 11.2452 14.8129V8.03508C11.2452 7.61245 10.977 7.33069 10.5747 7.33069H9.78502C9.38272 7.33069 9.11452 7.61245 9.11452 8.03508V14.8129Z"
              fill="#7A939C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.6207 10C18.6207 14.7611 14.7611 18.6207 10 18.6207C5.23892 18.6207 1.37931 14.7611 1.37931 10C1.37931 5.23892 5.23892 1.37931 10 1.37931C14.7611 1.37931 18.6207 5.23892 18.6207 10Z"
              fill="#7A939C"
            />
          </svg>
          <template slot="popover" class="hint">
            <div class="hint__body">
              <div class="hint__text">
                {{
                  "You can use this amount for usual transactions and it was added to your Metamask balance."
                }}
              </div>
            </div>
            <div class="hint__footer">
              <a v-close-popover class="hint__close">Close</a>
            </div>
          </template>
        </v-popover>
      </div>
      <div class="referral-indicator__header">
        <div class="referral-indicator__info">
          <div class="referral-indicator__info-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 9.375C14.2782 9.375 16.125 7.52817 16.125 5.25C16.125 2.97183 14.2782 1.125 12 1.125C9.72183 1.125 7.875 2.97183 7.875 5.25C7.875 7.52817 9.72183 9.375 12 9.375ZM14.625 5.25C14.625 6.69975 13.4497 7.875 12 7.875C10.5503 7.875 9.375 6.69975 9.375 5.25C9.375 3.80025 10.5503 2.625 12 2.625C13.4497 2.625 14.625 3.80025 14.625 5.25Z"
                fill="#00B9BF"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.625 15.75C20.625 12.8505 18.2745 10.5 15.375 10.5H8.625C5.72551 10.5 3.375 12.8505 3.375 15.75V17.625C3.375 20.5245 5.72551 22.875 8.625 22.875H15.375C18.2745 22.875 20.625 20.5245 20.625 17.625V15.75ZM4.875 15.75C4.875 13.6789 6.55393 12 8.625 12H15.375C17.4461 12 19.125 13.6789 19.125 15.75V17.625C19.125 19.6961 17.4461 21.375 15.375 21.375H8.625C6.55393 21.375 4.875 19.6961 4.875 17.625V15.75Z"
                fill="#00B9BF"
              />
            </svg>
          </div>
          <div class="referral-indicator__info-column">
            <div class="referral-indicator__info-title">
              {{ $t("referrals.active-users.title") }}
            </div>
            <div class="referral-indicator__info-sub-title">
              {{ $t("referrals.active-users.sub-title") }}
            </div>
          </div>
        </div>
        <div class="referral-indicator__value">
          {{ indicators.countTransactions }} from {{ indicators.countUsers }}
        </div>
      </div>
      <div class="referral-indicator__hint">
        <div class="referral-indicator__hint-icon">
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.89506 5.89254C3.83556 7.95203 3.89331 11.2877 5.9482 13.3426L8.51717 15.9116C8.35213 16.829 8.36267 18.2596 9.35764 19.2546C10.0317 19.9287 11.1499 19.9653 11.8547 19.2605L14.3803 16.7348C15.0312 17.3857 16.0865 17.3857 16.7374 16.7348C17.3882 16.084 17.3882 15.0287 16.7374 14.3778L19.263 11.8522C19.9678 11.1474 19.9312 10.0292 19.2571 9.35512C18.2622 8.36015 16.8315 8.34961 15.9141 8.51465L13.3451 5.94567C11.2902 3.89078 7.95456 3.83304 5.89506 5.89254ZM6.83787 6.83535C5.31597 8.35725 5.33976 10.8485 6.89101 12.3998L9.54813 15.0569C9.82479 15.3336 9.90399 15.7448 9.83274 16.1295C9.69132 16.8932 9.76451 17.7759 10.3004 18.3118C10.4709 18.4823 10.7447 18.4849 10.9119 18.3176L18.3202 10.9094C18.4874 10.7422 18.4848 10.4684 18.3143 10.2979C17.7784 9.76199 16.8957 9.6888 16.1321 9.83022C15.7474 9.90146 15.3361 9.82227 15.0594 9.54561L12.4023 6.88848C10.8511 5.33723 8.35977 5.31344 6.83787 6.83535Z"
              fill="#00B7BD"
            />
          </svg>
        </div>
        <div class="referral-indicator__hint-text">
          {{ $t("referrals.active-users.hint") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/components/base/BaseLoader";
import { ReferralsApi } from "@/office/referrals-api";
export default {
  name: "ReferralIndicators",
  components: { BaseLoader },
  data() {
    return {
      indicators: {
        earned: 0,
        expectedEarning: 0,
        countUsers: 0,
        countTransactions: 0,
      },
      isDataLoading: true,
    };
  },
  created() {
    this.loadIndicators();
  },
  methods: {
    async loadIndicators() {
      try {
        let indicatorsResponse = await ReferralsApi.getIndicators();
        this.indicators.earned = indicatorsResponse.data.earned;
        this.indicators.expectedEarning =
          indicatorsResponse.data.expected_earning;
        this.indicators.countUsers =
          indicatorsResponse.data.cnt_made_transaction.cnt_users;
        this.indicators.countTransactions =
          indicatorsResponse.data.cnt_made_transaction.cnt_transactions;
      } catch (e) {
        console.error(e);
      }

      this.isDataLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.referral-indicators {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
}

.referral-indicator {
  width: calc(33.3333333% - 13.33333px);
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(183, 201, 211, 0.5);
  height: 183px;
  padding: 32px 12px;
  position: relative;

  &__tip {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;

    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__info {
    display: flex;
    align-items: flex-start;
    margin-right: 15px;

    &-icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 8px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      font-size: pxToRem(16);
      line-height: pxToRem(24);
      margin-bottom: 3px;
    }

    &-sub-title {
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $gray1;
    }
  }

  &__value {
    margin-left: auto;
    font-weight: 700;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
  }

  &__hint {
    display: flex;
    align-items: flex-start;

    &-icon {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      flex-shrink: 0;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $gray1;
    }
  }
}
</style>
