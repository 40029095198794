<template>
  <div
    class="otp-input"
    :class="{
      error: vErrors.length > 0 || isError,
      [`otp-input--small`]: mode === 'small',
    }"
  >
    <div class="otp-input__code">
      <div class="otp-input__code-label" v-if="!hiddenLabel">
        {{ label }}
      </div>
      <div class="otp-input__code-el">
        <my-otp-input
          ref="otpInput"
          :num-count="numCount"
          @input="handleOnChange"
          @enter="handleEnterKey"
        ></my-otp-input>
      </div>
    </div>
    <div
      class="otp-input__resend"
      :class="{ sent: isOtpResent }"
      v-if="canResend && !hideResend"
    >
      <div class="otp-input__resend-text" v-if="mode !== 'small'">
        {{ $t("havent_received") }}
      </div>
      <div
        class="otp-input__resend-button"
        @click="resendCode"
        v-if="!isOtpResent"
      >
        {{ $t("resend_code") }}
      </div>
      <div class="otp-input__resend-wait" v-else>
        {{ $t("can_resend_code_after", { timer: resendTimeoutText }) }}
      </div>
    </div>
  </div>
</template>

<script>
import MyOtpInput from "@/components/input/OtpInput";
import AuthApi from "@/office/auth-api";
import { OTP_TYPES } from "@/const/auth/otp-types";
import { OTP_RESEND_TIMEOUT } from "@/const/config";
export default {
  name: "WOtpInput",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    mode: {
      type: String,
      default: "base",
    },
    otpType: {
      type: String,
      default: OTP_TYPES.SMS,
    },
    username: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "Enter code",
    },
    hiddenLabel: {
      type: Boolean,
      default: false,
    },
    numCount: {
      type: Number,
      default: 4,
    },
    vErrors: {
      type: Array,
      default() {
        return [];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isForgot: {
      type: Boolean,
      default: false,
    },
    resendMethod: {
      type: Function,
      default: null,
    },
    hideResend: {
      type: Boolean,
      default: false,
    },
  },
  components: { MyOtpInput },
  data() {
    return {
      isOtpResent: true,
      baseResendTimeout: OTP_RESEND_TIMEOUT,
      resendTimeout: OTP_RESEND_TIMEOUT,
      resendOTPInterval: null,
    };
  },
  watch: {
    vErrors: function () {},
  },
  computed: {
    resendTimeoutText: function () {
      let minutes = Math.floor(this.resendTimeout / 60);
      let seconds = this.resendTimeout - minutes * 60;

      return `${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)}`;
    },
    canResend: function () {
      return this.otpType !== OTP_TYPES.GOOGLE_AUTH;
    },
  },
  created() {
    this.setTimer();
  },
  beforeDestroy() {
    if (this.resendOTPInterval) {
      clearInterval(this.resendOTPInterval);
    }
  },
  methods: {
    handleOnChange(value) {
      this.$emit("change", value);
    },
    handleOnComplete(value) {
      this.$emit("change", value);
    },
    handleEnterKey() {
      this.$emit("enter");
    },
    setTimer() {
      if (this.resendOTPInterval) {
        clearInterval(this.resendOTPInterval);
      }

      this.resendOTPInterval = setInterval(() => {
        if (this.resendTimeout <= 0) {
          clearInterval(this.resendOTPInterval);
          this.isOtpResent = false;
          this.resendTimeout = this.baseResendTimeout;
        } else {
          this.resendTimeout -= 1;
        }
      }, 1000);
    },
    clearInput() {
      this.$refs.otpInput.clearInput();
    },
    resendCode() {
      if (this.resendMethod) {
        this.resendMethod();
        this.isOtpResent = true;
        this.setTimer();
        return;
      }

      if (this.username) {
        this.isOtpResent = true;
        this.clearInput();
        AuthApi.resendOtp(this.username, this.otpType, this.isForgot)
          .then(() => {
            this.setTimer();
          })
          .catch(() => {
            this.isOtpResent = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/variables";
@import "~@/assets/scss/mixins";

.otp-input {
  &.error & {
    &__num {
      border-bottom-color: $mainHot;
    }
  }

  &--small {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &--small & {
    &__resend {
      margin-top: 20px;

      &-wait {
        margin-top: 0 !important;
      }
    }

    &__code {
      width: 100%;

      &-label + .otp-input__code-el {
        margin-left: auto;
      }
    }
  }

  &__code {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &-label {
      margin-right: 16px;
      color: $gray1;
    }

    &-el {
      flex-grow: 0;
      flex-shrink: 0;
      & > div > div {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__num {
    width: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid $gray3;
    padding: 0 0 4px;
    font-size: pxToRem(24);
    line-height: pxToRem(30);
    font-weight: 700;
    color: $mainBlack;
    text-align: center;
    font-family: "Quicksand", sans-serif;

    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  &__resend {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    &.sent {
      flex-direction: column;
    }

    &.sent & {
      &-wait {
        margin-top: 10px;
        font-size: pxToRem(14);
        color: $gray1;
      }
    }

    &-text {
      color: $gray1;
    }

    &-button {
      color: $mainCold;
      font-weight: 700;
      margin-left: 5px;
      cursor: pointer;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
</style>
