export const OTP_TYPES = {
  GOOGLE_AUTH: "google_auth",
  SMS: "sms",
  EMAIL: "email",
};

export const OTP_LENGTH = {
  [OTP_TYPES.GOOGLE_AUTH]: 6,
  [OTP_TYPES.SMS]: 4,
  [OTP_TYPES.EMAIL]: 4,
};

export const OTP_TITLE = {
  [OTP_TYPES.GOOGLE_AUTH]: "google authenticator",
  [OTP_TYPES.SMS]: "SMS",
  [OTP_TYPES.EMAIL]: "E‑mail",
};
