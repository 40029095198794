export const whitelistWallets = {
  nbu: {
    whitebit: {
      USDT: "TZEdPrFe6trvpB2AhR7s487zLF27gzEP2q",
      USDC: "TZEdPrFe6trvpB2AhR7s487zLF27gzEP2q",
      DAI: "0x69FA47f9C609b62DEaFDCbA3aD3902a3041D10Da",
    },
    huobi: {
      USDT: "TDjQZG77Phoe999KNoFD1ktdY7PRFkJDVx",
      USDC: "TDjQZG77Phoe999KNoFD1ktdY7PRFkJDVx",
    },
  },
  pkr: {
    whitebit: {
      USDT: "TUq8iWjh4w7X9oREf4gHCQeadM4xqkmWt3",
      USDC: "TWCQQDiYjczqqBdK3RYwDHQbukMj9bM81c",
      DAI: "0x844924Bd98aDA7c88cf55AbB8e8829C4267453B3",
    },
    huobi: {
      USDT: "TCbhfwmRg9eotGPZTNbzGqeWirTQVfuqnE",
      USDC: "TCbhfwmRg9eotGPZTNbzGqeWirTQVfuqnE",
      DAI: "0x0d36c2f341261cad692c2f96c8897b578c1baa9a",
    },
  }
};