import axiosInstance from "@/services/http/axios-instance";

class _OnboardingApi {
  initiateOnboardingProcess(cardProductId) {
    return axiosInstance.post("/onboarding/initiate", {
      card_type_id: cardProductId,
    });
  }

  getDiiaDeeplink() {
    return axiosInstance.get("/user/onboarding/diia/deeplink", {});
  }
}
export const OnboardingApi = new _OnboardingApi();
