<template>
  <div class="checkbox-wrapper">
    <label class="checkbox">
      <input type="checkbox" v-model="isChecked" />
      <span class="checkbox__mark"></span>
      <span class="checkbox__text" v-if="label" @click="emitClick">{{
        label
      }}</span>
    </label>
    <div class="input__hint" v-if="!!hint && hint.trim().length > 0">
      <v-popover placement="top" class="hint">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1724 6.25063C10.828 6.25063 11.3793 5.68712 11.3793 5.01404C11.3793 4.30965 10.8131 3.7931 10.1724 3.7931C9.53172 3.7931 8.96552 4.30965 8.96552 5.01404C8.96552 5.70278 9.51682 6.25063 10.1724 6.25063Z"
            fill="#7A939C"
          />
          <path
            d="M9.11452 14.8129C9.11452 15.2355 9.38272 15.5172 9.78502 15.5172H10.5747C10.977 15.5172 11.2452 15.2355 11.2452 14.8129V8.03508C11.2452 7.61245 10.977 7.33069 10.5747 7.33069H9.78502C9.38272 7.33069 9.11452 7.61245 9.11452 8.03508V14.8129Z"
            fill="#7A939C"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.6207 10C18.6207 14.7611 14.7611 18.6207 10 18.6207C5.23892 18.6207 1.37931 14.7611 1.37931 10C1.37931 5.23892 5.23892 1.37931 10 1.37931C14.7611 1.37931 18.6207 5.23892 18.6207 10Z"
            fill="#7A939C"
          />
        </svg>
        <template slot="popover" class="hint">
          <div class="hint__body">
            <div class="hint__text">
              {{ hint }}
            </div>
          </div>
          <div class="hint__footer">
            <a v-close-popover class="hint__close">{{ closeButton }}</a>
          </div>
        </template>
      </v-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxInput",
  model: {
    prop: "checked",
    event: "update",
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    closeButton: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isChecked: false,
      initialDataLoaded: false,
    };
  },
  created() {
    this.isChecked = this.checked;

    this.$nextTick(() => {
      this.initialDataLoaded = true;
    });
  },
  watch: {
    checked: function () {
      this.isChecked = this.checked;
    },
    isChecked: function () {
      if (this.initialDataLoaded) {
        this.$emit("update", this.isChecked);
      }
    },
  },
  computed: {
    switcherLabel: function () {
      if (Array.isArray(this.label)) {
        return this.isChecked ? this.label[0] : this.label[1];
      } else {
        return this.label;
      }
    },
  },
  methods: {
    emitClick() {
      this.$emit("click", this.isChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  .input-hint {
    margin-left: 20px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &-wrapper {
    display: flex;
    align-items: center;
  }

  input {
    display: none;
  }

  input:checked + &__mark {
    border-color: $mainCold;

    &:before {
      opacity: 1;
    }
  }

  &__mark {
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid $gray1;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 9px;
    transition: border-color 0.2s linear;
    flex-grow: 0;
    flex-shrink: 0;

    &:before {
      position: absolute;
      display: block;
      content: "";
      opacity: 0;
      width: 14px;
      height: 14px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: $mainCold;
      border-radius: 2px;
      transition: opacity 0.2s linear;
    }
  }

  &__text {
    font-size: pxToRem(14);
    line-height: pxToRem(24);
    color: $gray1;
  }
}
</style>
