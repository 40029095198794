<template>
  <div class="popup__close" @click="$emit('click')">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13040_27302)">
        <path
          d="M5 5L19 19"
          stroke="#7A939C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19 5L5 19"
          stroke="#7A939C"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13040_27302">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import responsiveMixin from "@/mixins/responsive-mixin";

export default {
  name: "PopupCloseButton",
  mixins: [responsiveMixin],
};
</script>

<style scoped></style>
