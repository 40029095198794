import { LOGIN_MODE_EMAIL, LOGIN_MODE_PHONE } from "@/const/login-modes";
import { GA_EVENTS } from "@/const/ga.events";

class GtagService {
  static SIGN_IN = "sign-in";
  static SIGN_UP = "sign-up";

  sendEvent(context, eventName, data = {}) {
    switch (eventName) {
      case GA_EVENTS.SIGN_IN_NO_2FA_START:
      case GA_EVENTS.SIGN_IN_2FA_START:
      case GA_EVENTS.SIGN_IN_NO_2FA:
      case GA_EVENTS.SIGN_IN_2FA:
        this._sendSignInEvent(context, eventName, data);
        break;
      case GA_EVENTS.SIGN_UP_START:
      case GA_EVENTS.SIGN_UP_OTP_START:
      case GA_EVENTS.SIGN_UP_SET_PASSWORD_START:
      case GA_EVENTS.SIGN_UP_OTP:
      case GA_EVENTS.SIGN_UP:
      case GA_EVENTS.SIGN_UP_SET_PASSWORD:
        this._sendSignUpEvent(context, eventName, data);
        break;
      default:
        this._sendEvent(context, eventName, data);
    }
  }

  _sendSignInEvent(context, eventName, data = {}) {
    switch (eventName) {
      case GA_EVENTS.SIGN_IN_NO_2FA_START:
        data = {
          otp: false,
          attempt: true,
        };
        break;
      case GA_EVENTS.SIGN_IN_2FA_START:
        data = {
          otp: true,
          attempt: true,
        };
        break;
      case GA_EVENTS.SIGN_IN_NO_2FA:
        data = {
          otp: false,
          success: true,
        };
        break;
      case GA_EVENTS.SIGN_IN_2FA:
        data = {
          otp: true,
          success: true,
        };
        break;
    }

    const loginMethodData = this._getLoginMethodData(context);
    data = { ...data, ...loginMethodData };
    this._sendEvent(context, GtagService.SIGN_IN, data);
  }

  _sendSignUpEvent(context, eventName, data = {}) {
    switch (eventName) {
      case GA_EVENTS.SIGN_UP_START:
        // all users will have this event
        break;
      case GA_EVENTS.SIGN_UP:
        data = {
          success: true,
        };
        break;
      case GA_EVENTS.SIGN_UP_OTP_START:
        data = {
          otp: true,
          attempt: true,
        };
        break;
      case GA_EVENTS.SIGN_UP_OTP:
        data = {
          otp: true,
          success: true,
        };
        break;
      case GA_EVENTS.SIGN_UP_SET_PASSWORD_START:
        data = {
          password: true,
          attempt: true,
        };
        break;
      case GA_EVENTS.SIGN_UP_SET_PASSWORD:
        data = {
          password: true,
          success: true,
        };
        break;
    }
    this._sendEvent(context, GtagService.SIGN_UP, data);
  }

  _getLoginMethodData(context) {
    const result = {};
    const { loginMode } = context;

    switch (loginMode) {
      case LOGIN_MODE_PHONE:
        result.phone = true;
        break;
      case LOGIN_MODE_EMAIL:
        result.email = true;
        break;
    }

    return result;
  }

  _sendEvent(context, eventName, data = {}) {
    const { $gtag } = context;
    try {
      if ($gtag) {
        $gtag.event(eventName, data);
      }
    } catch (e) {
      console.log("err:", e);
    }
  }
}

export const GtagServiceHelper = new GtagService();
