<template>
  <div class="wrapper">
    <main-layout-header :page-title="pageTitle"></main-layout-header>
    <div class="main-layout__body">
      <div class="page page--coming-soon">
        <div class="coming-soon">
          <div class="coming-soon__image">
            <img src="~@/assets/img/coming-soon/staking-coming-soon.png" />
          </div>
          <div
            class="coming-soon__title"
            v-html="$t('coming-soon.staking.title')"
          ></div>
          <div
            class="coming-soon__sub-title"
            v-html="$t('coming-soon.staking.sub-title')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLayoutHeader from "@/components/layouts/MainLayout/MainLayoutHeader";
export default {
  name: "StakingComingSoon",
  components: { MainLayoutHeader },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  computed: {
    pageTitle: function () {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
.coming-soon {
  margin-top: 130px;

  &__image {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-weight: 700;
    font-size: pxToRem(30);
    line-height: pxToRem(36);
    text-align: center;
    color: $mainBlack;
    margin-bottom: 16px;
    font-family: "Mic 32 New Rounded", sans-serif;
  }

  &__sub-title {
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    text-align: center;
    color: $gray1;
  }
}

@media screen and (max-width: 486px) {
  .page {
    &--coming-soon {
      padding: 0;
    }
  }

  .main-layout__header-wrapper {
    padding: 0;
  }

  .coming-soon {
    &__image {
      width: 140px;
      height: 140px;
      margin-bottom: 56px;
    }

    &__title {
      font-size: pxToRem(20);
      line-height: pxToRem(24);

      ::v-deep br {
        display: none;
      }
    }

    &__sub-title {
      font-size: pxToRem(16);
      line-height: pxToRem(24);
    }
  }
}
</style>
