<template>
  <div class="unex-onboarding">
    <div class="unex-onboarding__steps">
      <div
        class="unex-onboarding__step"
        :style="{
          width: `${100 / visibleSteps.length}%`,
        }"
        v-for="(step, index) in visibleSteps"
        :key="`step-${step.slug}`"
        :class="{
          active: currentStepIndex === index,
          finished: currentStepIndex > index,
        }"
      >
        <div class="unex-onboarding__step-body">
          <div class="unex-onboarding__step-mark-wrapper">
            <svg
              class="unex-onboarding__step-mark finished"
              v-if="currentStepIndex > index"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM6.95645 9.57306L8.92143 11.6739L12.7227 6.98158C12.94 6.71337 13.3335 6.67208 13.6018 6.88936C13.87 7.10664 13.9113 7.50021 13.694 7.76842L9.71452 12.6807C9.33113 13.1539 8.61796 13.1793 8.20191 12.7345L6.04355 10.4269C5.80776 10.1748 5.82097 9.77934 6.07306 9.54355C6.32515 9.30776 6.72066 9.32097 6.95645 9.57306Z"
                fill="#00B7BD"
                fill-opacity="0.9"
              />
            </svg>
            <div
              class="unex-onboarding__step-mark"
              :class="{
                active: currentStepIndex === index,
              }"
              v-else
            ></div>
          </div>
          <!--<div
            class="unex-onboarding__step-title"
            v-if="currentStepIndex !== index"
          >
            {{ $t(`unex.onboarding.steps.${step.slug}`) }}
          </div>-->
        </div>

        <div class="unex-onboarding__step-delimiter"></div>
      </div>
    </div>
    <div class="unex-onboarding__body">
      <base-loader v-if="isStepsAnalyzing"></base-loader>
      <router-view v-if="!isStepsAnalyzing"></router-view>
      <!--<div class="unex-onboarding__support-wrapper">
        <a
          :href="supportBotLink"
          target="_blank"
          class="unex-onboarding__support"
        >
          {{ $t("support") }}
        </a>
      </div>-->
    </div>
    <div class="unex-onboarding__footer" v-if="0">
      <no-bg-button :label="$t('back')"></no-bg-button>
    </div>
  </div>
</template>

<script>
import NoBgButton from "@/components/button/NoBgButton";
import BaseLoader from "@/components/base/BaseLoader";
import { mapGetters, mapState } from "vuex";
import { CardApi } from "@/office/card-api";
import responsiveMixin from "@/mixins/responsive-mixin";
import { SET_UNEX_ONBOARDING_ERROR } from "@/store/const/actions-types";
import { UnexOnboardingService } from "@/services/unex/unex-onboarding.service";
import { CardsProductService } from "@/services/cards/cards-product.service";
import { TasksService } from "@/services/tasks/tasks.service";
import PixelEventsService from "@/services/pixelEventsService";

// const cardProductID = 1;

export default {
  name: "UnexOnboardingBase",
  components: { BaseLoader, NoBgButton },
  mixins: [responsiveMixin],
  provide() {
    return {
      toNextStep: this.toNextStep,
      errorWSMessageHandler: this.errorWSMessageHandler,
      getCardProductId: this.getCardProductId,
      getCardProductSlug: this.getCardProductSlug,
      getCardProduct: this.getCardProduct,
    };
  },
  data() {
    return {
      supportBotLink: "https://t.me/WeldSupport_Bot",
      stepsRoutes: [
        {
          slug: "diia-children",
          path: "/unex/onboarding/birth-certificates",
          is_visible: true,
        },
        {
          slug: "business-rules-children",
          path: "/unex/onboarding/business-rules/children",
          is_visible: false,
        },
        {
          slug: "diia-main",
          path: "/unex/onboarding/personal-documents",
          is_visible: true,
        },
        {
          slug: "business-rules-main",
          path: "/unex/onboarding/business-rules/main",
          is_visible: false,
        },
        {
          slug: "bank-request",
          path: "/unex/onboarding/bank-request",
          is_visible: false,
        },
        {
          slug: "liveness",
          path: "/unex/onboarding/photo",
          is_visible: true,
        },
        {
          slug: "otp",
          path: "/unex/onboarding/otp",
          is_visible: true,
        },
        {
          slug: "questionnaire",
          path: "/unex/onboarding/questionnaire",
          is_visible: true,
        },
        {
          slug: "questionnaire-verify",
          path: "/unex/onboarding/questionnaire/verify",
          is_visible: false,
        },
        {
          slug: "pkr-agreement",
          path: "/unex/onboarding/pkr/agreement",
          is_visible: true,
        },
        {
          slug: "diia-sign",
          path: "/unex/onboarding/diia-sign",
          is_visible: true,
        },
        {
          slug: "card-creation",
          path: "/unex/onboarding/card-creation",
          is_visible: false,
        },
      ],
      steps: [],
      isStepsAnalyzing: true,
      currentStep: null,
      cardProduct: {
        id: null,
      },
      stepsChannels: {
        wssBusinessRulesChildren: null,
        wssBusinessRulesMain: null,
        wssBankRequest: null,
        wssQuestionnaireVerify: null,
        wssCardCreation: null,
      },
    };
  },
  async created() {
    await this.initWS();
    await this.loadCardTypeId();
    this.loadSteps();
  },
  beforeDestroy() {
    if (this.stepsChannels.wssBusinessRulesMain) {
      this.stepsChannels.wssBusinessRulesMain.unsubscribe();
    }

    if (this.stepsChannels.wssBusinessRulesChildren) {
      this.stepsChannels.wssBusinessRulesChildren.unsubscribe();
    }

    if (this.stepsChannels.wssBankRequest) {
      this.stepsChannels.wssBankRequest.unsubscribe();
    }

    if (this.stepsChannels.wssQuestionnaireVerify) {
      this.stepsChannels.wssQuestionnaireVerify.unsubscribe();
    }

    if (this.stepsChannels.wssCardCreation) {
      this.stepsChannels.wssCardCreation.unsubscribe();
    }
  },
  computed: {
    ...mapState(["wss"]),
    ...mapGetters(["userData"]),
    visibleSteps: function () {
      return this.steps.filter((_step) => _step.is_visible);
    },
    currentStepIndex: function () {
      return this.visibleSteps.findIndex(
        (_step) => _step.slug === this.currentStep.slug
      );
    },
  },
  watch: {
    $route: function () {
      this.verifyStep();
    },
  },
  methods: {
    async initWS() {
      this.stepsChannels.wssBusinessRulesChildren = this.wss.subscribe(
        `business-rules-children-${this.userData.hashed_id}`,
        async (msg) => {
          let isErrorHandled = await this.errorWSMessageHandler(msg);

          if (isErrorHandled) {
            return;
          }

          this.handleBusinessRulesChildrenMessage(msg);
        }
      );

      this.stepsChannels.wssBusinessRulesMain = this.wss.subscribe(
        `business-rules-main-${this.userData.hashed_id}`,
        async (msg) => {
          let isErrorHandled = await this.errorWSMessageHandler(msg);

          if (isErrorHandled) {
            return;
          }

          this.handleBusinessRulesMainMessage(msg);
        }
      );

      this.stepsChannels.wssBankRequest = this.wss.subscribe(
        `unex-bank-request-${this.userData.hashed_id}`,
        async (msg) => {
          let isErrorHandled = await this.errorWSMessageHandler(msg);

          if (isErrorHandled) {
            return;
          }

          this.handleBankRequestMessage(msg);
        }
      );

      this.stepsChannels.wssQuestionnaireVerify = this.wss.subscribe(
        `questionnaire-verify-${this.userData.hashed_id}`,
        async (msg) => {
          let isErrorHandled = await this.errorWSMessageHandler(msg);

          if (isErrorHandled) {
            return;
          }

          this.handleQuestionnaireVerifyMessage(msg);
        }
      );

      this.stepsChannels.wssCardCreation = this.wss.subscribe(
        `card-creation-${this.userData.hashed_id}`,
        async (msg) => {
          let isErrorHandled = await this.errorWSMessageHandler(msg);

          if (isErrorHandled) {
            return;
          }

          this.handleCardCreationMessage(msg);
        }
      );
    },
    toNextStep() {
      let nextStep = this.getNextStep();
      this.currentStep = nextStep;
      this.$router.push(this.getStepPath(nextStep));
    },
    async loadSteps() {
      if (!this.cardProduct.id) {
        return this.$router.push("/cards");
      }

      let stepsResponse = await CardApi.getCardProductSteps(
        this.cardProduct.id
      );
      this.steps = stepsResponse.data.steps.sort((a, b) =>
        a.order > b.order ? 1 : a.order < b.order ? -1 : 0
      );
      this.currentStep = this.getCurrentStepByLast(stepsResponse.data.onboard);

      if (!this.currentStep) {
        return this.$router.push("/cards");
      }

      let stepPath = this.getStepPath(this.currentStep);

      if (this.$route.path !== stepPath) {
        await this.$router.push(stepPath);
      }

      this.isStepsAnalyzing = false;
    },
    async verifyStep() {
      let stepPath = this.getStepPath(this.currentStep);

      if (this.$route.path !== stepPath) {
        await this.$router.push(stepPath);
      }
    },
    getCurrentStepByLast(lastStep) {
      if (!lastStep) {
        return this.steps.find((_step) => _step.order === 1);
      }

      if (lastStep.status === "pending") {
        return this.steps.find((_step) => _step.id === lastStep.step_id);
      }

      if (lastStep.status === "complete") {
        let lastStepObj = this.steps.find(
          (_step) => _step.id === lastStep.step_id
        );

        return this.steps.find(
          (_step) => _step.order === lastStepObj.order + 1
        );
      }

      return false;
    },
    getStepPath(step) {
      return (
        this.stepsRoutes.find((_stepRoute) => _stepRoute.slug === step.slug)
          .path || "/cards"
      );
    },
    getNextStep() {
      const nextStep = this.steps.find(
        (_step) => _step.order === this.currentStep.order + 1
      );

      if (nextStep.slug === "diia-vpo") {
        return this.steps.find((_step) => _step.order === nextStep.order + 1);
      }

      return nextStep;
    },
    handleBusinessRulesChildrenMessage(msg) {
      if (msg.data.success === true) {
        this.currentStep = this.steps.find(
          (_step) => _step.slug === "business-rules-children"
        );
        this.stepsChannels.wssBusinessRulesChildren.unsubscribe();
        this.toNextStep();
      }
    },
    handleBusinessRulesMainMessage(msg) {
      if (msg.data.success === true) {
        this.currentStep = this.steps.find(
          (_step) => _step.slug === "business-rules-main"
        );
        this.stepsChannels.wssBusinessRulesMain.unsubscribe();

        setTimeout(() => {
          this.toNextStep();
        }, 3000);
      }
    },
    handleBankRequestMessage(msg) {
      if (msg.data.success === true) {
        this.currentStep = this.steps.find(
          (_step) => _step.slug === "bank-request"
        );
        this.stepsChannels.wssBankRequest.unsubscribe();
        this.toNextStep();
      }
    },
    handleQuestionnaireVerifyMessage(msg) {
      if (msg.data.success === true) {
        console.log("success");
        this.currentStep = this.steps.find(
          (_step) => _step.slug === "questionnaire-verify"
        );
        this.stepsChannels.wssQuestionnaireVerify.unsubscribe();
        this.toNextStep();
      }
    },
    handleCardCreationMessage(msg) {
      if (msg.data.success === true) {
        this.analytics();
        PixelEventsService.openCard();

        this.currentStep = this.steps.find(
          (_step) => _step.slug === "card-creation"
        );
        this.stepsChannels.wssQuestionnaireVerify.unsubscribe();

        if (this.cardProduct.slug === "weld-card") {
          TasksService.completeTask(TasksService.TASK_ADD_WELD_CARD);
        }

        this.$router.push({
          path: "/cards",
          params: {
            cardOpened: true,
          },
        });
      }
    },
    async errorWSMessageHandler(msg) {
      if (msg.data.success === false) {
        this.$store.commit(
          SET_UNEX_ONBOARDING_ERROR,
          UnexOnboardingService.getStepErrorMessageKey(this.currentStep.slug)
        );
        await this.$router.push("/unex/onboarding/fail");
        return true;
      }

      return false;
    },
    async loadCardTypeId() {
      let cardProductSlug = localStorage.getItem(
        CardsProductService.getLocalStorageSlugKey()
      );

      if (!cardProductSlug) {
        return await this.$router.push("/cards");
      }

      try {
        let cardProductResponse = await CardApi.getCardProductBySlug(
          cardProductSlug
        );
        this.cardProduct = cardProductResponse.data;
      } catch (e) {
        console.error(e);
        return await this.$router.push("/cards");
      }
    },
    getCardProductId() {
      return this.cardProduct.id;
    },
    getCardProductSlug() {
      return this.cardProduct.slug;
    },
    getCardProduct() {
      return this.cardProduct;
    },
    analytics() {
      console.log("AddCard_Success");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "AddCard_Success" });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-onboarding {
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
  position: relative;

  &__title {
    font-family: "Mic 32 New Rounded", sans-serif;
    font-weight: 700;
    font-size: pxToRem(32);
    line-height: pxToRem(38);
    color: $mainBlack;
    margin-bottom: 16px;
    text-align: center;
  }

  &__sub-title {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: pxToRem(16);
    line-height: pxToRem(20);
    color: $gray1;
    text-align: center;
  }

  &__steps {
    width: 100%;
    max-width: 786px;
    margin: 0 auto 28px;
    display: flex;
    align-items: flex-start;
  }

  &__step {
    position: relative;
    padding: 0 25px;

    &-body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &:last-child & {
      &-delimiter {
        display: none;
      }
    }

    &-delimiter {
      position: absolute;
      width: calc(100% - 40px);
      height: 1px;
      background: $gray2;
      top: 14px;
      left: calc(50% + 20px);
    }

    &-mark {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid $gray2;

      display: flex;
      align-items: center;
      justify-content: center;

      &-wrapper {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
      }

      &.active {
        width: 24px;
        height: 24px;
        background: $mainCold;
        border-color: $mainCold;
      }

      &.finished {
        width: 20px;
        height: 20px;
        /*background: red;*/
        border: 0px;
      }
    }

    &.finished & {
      border: 2px solid $mainCold;

      &-delimiter {
        background: $mainCold;
      }
    }

    &-title {
      text-align: center;
      font-weight: 700;
      font-size: pxToRem(12);
      line-height: pxToRem(16);
      color: $mainBlack;
    }
  }

  &__support {
    color: $gray1;
    font-size: pxToRem(18);
    line-height: pxToRem(22);
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }

    &-wrapper {
      margin-top: 45px;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}

@media screen and (max-width: 786px) {
  .unex-onboarding {
    &__steps {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }

    &__step {
      padding: 0 10px;
    }

    &__body {
      padding: 0 20px;
    }
  }
}
</style>
