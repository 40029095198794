<template>
  <div class="wrapper">
    <main-layout-header
      :page-title="pageTitle"
      :actions="[
        { label: $t('add_wallet'), method: showAddExchangePopupAction },
      ]"
    ></main-layout-header>
    <story class="page__story" ref="storyBlock"></story>
    <div class="main-layout__body">
      <div class="page page--wallets">
        <base-loader v-if="isDataLoading"></base-loader>
        <!--        <div class="wallets__table" v-if="defaultWallet">-->
        <!--          <div class="wallets__table-header">-->
        <!--            <div class="wallets__table-col">{{ $t("exchange") }}</div>-->
        <!--            <div class="wallets__table-col">{{ $t("title") }}</div>-->
        <!--            <div class="wallets__table-col">{{ $t("currency") }}</div>-->
        <!--            <div class="wallets__table-col">{{ $t("balance") }}</div>-->
        <!--            <div class="wallets__table-col">{{ $t("settings") }}</div>-->
        <!--          </div>-->
        <!--          <div class="wallets__table-body">-->
        <!--            <div class="wallets__table-row wallet">-->
        <!--              <div class="wallets__table-col">-->
        <!--                <div class="wallets__table-col-title" v-if="isMobile">-->
        <!--                  {{ $t("exchange") }}-->
        <!--                </div>-->
        <!--                <div class="wallet__name">-->
        <!--                  <div-->
        <!--                    class="wallet__name-icon"-->
        <!--                    v-if="defaultWallet.svg"-->
        <!--                    v-html="defaultWallet.svg"-->
        <!--                  ></div>-->
        <!--                  <div class="wallet__name-icon" v-else>-->
        <!--                    <svg-->
        <!--                      data-v-53e2cc2e=""-->
        <!--                      width="64"-->
        <!--                      height="64"-->
        <!--                      viewBox="0 0 64 64"-->
        <!--                      fill="none"-->
        <!--                      xmlns="http://www.w3.org/2000/svg"-->
        <!--                    >-->
        <!--                      <circle-->
        <!--                        data-v-53e2cc2e=""-->
        <!--                        cx="32"-->
        <!--                        cy="32"-->
        <!--                        r="32"-->
        <!--                        fill="#00B7BD"-->
        <!--                      ></circle>-->
        <!--                      <path-->
        <!--                        data-v-53e2cc2e=""-->
        <!--                        d="M46.3167 23C42.3102 23 39.0798 25.5806 39.0022 30.4216H34.9084L35.3256 24.9888C35.3935 24.0575 34.8405 23.4463 33.9189 23.4463H32.0661C31.1348 23.4463 30.5527 23.9314 30.4848 24.853L29.7281 34.8843C29.5923 36.4947 28.9036 37.6299 27.5648 37.6299C26.2552 37.6299 25.5373 36.3881 25.5373 34.8456V26.153C25.5373 25.2216 24.9165 24.6105 23.9949 24.6105H22.3069C21.3756 24.6105 20.7645 25.2313 20.7645 26.153V34.8456C20.7645 36.3881 20.0466 37.6299 18.737 37.6299C17.3983 37.6299 16.7095 36.4947 16.6028 34.8843L15.8849 24.9888C15.817 24.0575 15.235 23.4463 14.3037 23.4463H12.4508C11.5195 23.4463 10.9375 24.0672 11.0054 24.9888L11.762 35.2239C12.0725 39.3083 14.7887 41.85 18.6303 41.85C20.6869 41.85 22.2002 40.9574 23.1315 39.4828C24.0628 40.9574 25.5664 41.85 27.6327 41.85C31.5131 41.85 34.2294 39.415 34.5398 35.2239L34.6465 33.8463H39.0022V34.0888C39.0022 39.1433 42.2326 41.85 47.0443 41.85C49.2755 41.85 50.5172 41.5396 51.4776 41.2679C52.341 40.9963 52.7872 40.2395 52.5447 39.2402L52.3022 38.2411C52.0984 37.3777 51.4097 37.009 50.5172 37.2418C49.8284 37.4164 48.5964 37.688 47.0443 37.688C44.9489 37.688 43.8818 36.5239 43.8818 34.0791V33.8366H52.1275C53.0588 33.8366 53.67 33.2157 53.67 32.2941V29.9269C53.67 25.8522 50.3717 23 46.3167 23ZM48.7516 30.4216H43.8721V29.9366C43.8721 28.3552 44.8713 27.1523 46.307 27.1523C47.8494 27.1523 48.7419 28.394 48.7419 29.9366V30.4216H48.7516Z"-->
        <!--                        fill="white"-->
        <!--                      ></path>-->
        <!--                    </svg>-->
        <!--                  </div>-->
        <!--                  <div class="wallet__name-text">-->
        <!--                    {{ defaultWallet.name || "" }}-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="wallets__table-col">-->
        <!--                <div class="wallets__table-col-title" v-if="isMobile">-->
        <!--                  {{ $t("Title") }}-->
        <!--                </div>-->
        <!--                <div class="wallet__name">-->
        <!--                  <div class="wallet__name-text">-->
        <!--                    {{ defaultWallet.title || "" }}-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="wallets__table-col">-->
        <!--                <div class="wallets__table-col-title" v-if="isMobile">-->
        <!--                  {{ $t("currency") }}-->
        <!--                </div>-->
        <!--                <div class="wallet__currency">-->
        <!--                  {{ defaultWallet.currency.toUpperCase() }}-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="wallets__table-col">-->
        <!--                <div class="wallets__table-col-title" v-if="isMobile">-->
        <!--                  {{ $t("balance") }}-->
        <!--                </div>-->
        <!--                <div class="wallet__balance">-->
        <!--                  {{ defaultWallet.balance || "0" }}-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="wallets__table-col">-->
        <!--                <div-->
        <!--                  class="wallet__set-active"-->
        <!--                  @click="setActive({ id: null })"-->
        <!--                >-->
        <!--                  {{ $t("set_unactive") }}-->
        <!--                </div>-->
        <!--                <div-->
        <!--                  class="wallet__delete"-->
        <!--                  @click="deleteWallet(defaultWallet)"-->
        <!--                >-->
        <!--                  <svg-->
        <!--                    data-v-88c7101e=""-->
        <!--                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                    height="427pt"-->
        <!--                    viewBox="-40 0 427 427.00131"-->
        <!--                    width="427pt"-->
        <!--                  >-->
        <!--                    <path-->
        <!--                      data-v-88c7101e=""-->
        <!--                      d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"-->
        <!--                    ></path>-->
        <!--                    <path-->
        <!--                      data-v-88c7101e=""-->
        <!--                      d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"-->
        <!--                    ></path>-->
        <!--                    <path-->
        <!--                      data-v-88c7101e=""-->
        <!--                      d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"-->
        <!--                    ></path>-->
        <!--                    <path-->
        <!--                      data-v-88c7101e=""-->
        <!--                      d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"-->
        <!--                    ></path>-->
        <!--                  </svg>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="wallets__groups-wrapper" v-if="walletsGroups.length > 0">
          <div
            class="wallets__group"
            v-for="(walletGroup, index) in walletsGroups"
            :key="`wallet-group-${index}`"
          >
            <div class="wallets__group-title">
              {{ walletGroup.group_name[selectedLocale] }}
            </div>
            <div
              class="wallets__table"
              v-if="walletGroup.wallets.crypto_wallets"
            >
              <div class="wallets__table-header">
                <div class="wallets__table-col">{{ $t("exchange") }}</div>
                <div class="wallets__table-col">{{ $t("title") }}</div>
                <div class="wallets__table-col">{{ $t("currency") }}</div>
                <div class="wallets__table-col">{{ $t("balance") }}</div>
                <div class="wallets__table-col">{{ $t("settings") }}</div>
              </div>
              <div
                class="wallets__table-body"
                v-if="walletGroup.wallets.crypto_wallets.length > 0"
              >
                <div
                  class="wallets__table-row wallet"
                  v-for="wallet in walletGroup.wallets.crypto_wallets"
                  :key="`wallet-${wallet.id}`"
                >
                  <div class="wallets__table-col">
                    <div class="wallets__table-col-title" v-if="isMobile">
                      {{ $t("exchange") }}
                    </div>
                    <div class="wallet__name">
                      <div
                        class="wallet__name-icon"
                        v-if="walletGroup.svg"
                        v-html="walletGroup.svg"
                      ></div>
                      <div class="wallet__name-icon" v-else>
                        <svg
                          data-v-53e2cc2e=""
                          width="64"
                          height="64"
                          viewBox="0 0 64 64"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            data-v-53e2cc2e=""
                            cx="32"
                            cy="32"
                            r="32"
                            fill="#00B7BD"
                          ></circle>
                          <path
                            data-v-53e2cc2e=""
                            d="M46.3167 23C42.3102 23 39.0798 25.5806 39.0022 30.4216H34.9084L35.3256 24.9888C35.3935 24.0575 34.8405 23.4463 33.9189 23.4463H32.0661C31.1348 23.4463 30.5527 23.9314 30.4848 24.853L29.7281 34.8843C29.5923 36.4947 28.9036 37.6299 27.5648 37.6299C26.2552 37.6299 25.5373 36.3881 25.5373 34.8456V26.153C25.5373 25.2216 24.9165 24.6105 23.9949 24.6105H22.3069C21.3756 24.6105 20.7645 25.2313 20.7645 26.153V34.8456C20.7645 36.3881 20.0466 37.6299 18.737 37.6299C17.3983 37.6299 16.7095 36.4947 16.6028 34.8843L15.8849 24.9888C15.817 24.0575 15.235 23.4463 14.3037 23.4463H12.4508C11.5195 23.4463 10.9375 24.0672 11.0054 24.9888L11.762 35.2239C12.0725 39.3083 14.7887 41.85 18.6303 41.85C20.6869 41.85 22.2002 40.9574 23.1315 39.4828C24.0628 40.9574 25.5664 41.85 27.6327 41.85C31.5131 41.85 34.2294 39.415 34.5398 35.2239L34.6465 33.8463H39.0022V34.0888C39.0022 39.1433 42.2326 41.85 47.0443 41.85C49.2755 41.85 50.5172 41.5396 51.4776 41.2679C52.341 40.9963 52.7872 40.2395 52.5447 39.2402L52.3022 38.2411C52.0984 37.3777 51.4097 37.009 50.5172 37.2418C49.8284 37.4164 48.5964 37.688 47.0443 37.688C44.9489 37.688 43.8818 36.5239 43.8818 34.0791V33.8366H52.1275C53.0588 33.8366 53.67 33.2157 53.67 32.2941V29.9269C53.67 25.8522 50.3717 23 46.3167 23ZM48.7516 30.4216H43.8721V29.9366C43.8721 28.3552 44.8713 27.1523 46.307 27.1523C47.8494 27.1523 48.7419 28.394 48.7419 29.9366V30.4216H48.7516Z"
                            fill="white"
                          ></path>
                        </svg>
                      </div>
                      <div class="wallet__name-text">
                        {{ walletGroup.group_name[selectedLocale] }}
                      </div>
                    </div>
                  </div>
                  <div class="wallets__table-col">
                    <div class="wallets__table-col-title" v-if="isMobile">
                      {{ $t("Title") }}
                    </div>
                    <div class="wallet__name">
                      <div class="wallet__name-text">
                        {{ wallet.name || "" }}
                      </div>
                    </div>
                  </div>
                  <div class="wallets__table-col">
                    <div class="wallets__table-col-title" v-if="isMobile">
                      {{ $t("currency") }}
                    </div>
                    <div class="wallet__currency">
                      {{ wallet.currency.toUpperCase() }}
                    </div>
                  </div>
                  <div class="wallets__table-col">
                    <div class="wallets__table-col-title" v-if="isMobile">
                      {{ $t("balance") }}
                    </div>
                    <div class="wallet__balance">
                      {{ wallet.balance || "0" }}
                    </div>
                  </div>
                  <div class="wallets__table-col">
                    <switcher
                      :checked="wallet.is_default_wallet"
                      @click="changeActiveWallet(wallet)"
                      :label="[$t('wallet.active'), $t('wallet.not-active')]"
                    ></switcher>

                    <div class="wallet__delete" @click="deleteWallet(wallet)">
                      <svg
                        data-v-88c7101e=""
                        xmlns="http://www.w3.org/2000/svg"
                        height="427pt"
                        viewBox="-40 0 427 427.00131"
                        width="427pt"
                      >
                        <path
                          data-v-88c7101e=""
                          d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"
                        ></path>
                        <path
                          data-v-88c7101e=""
                          d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"
                        ></path>
                        <path
                          data-v-88c7101e=""
                          d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"
                        ></path>
                        <path
                          data-v-88c7101e=""
                          d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wallets__table-body" v-else>
                <div class="wallets__table-empty">
                  {{ $t("no_added_wallets") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wallets__groups-wrapper" v-else>
          <div class="wallets__groups-empty">
            {{ $t("no_added_wallets") }}
          </div>
        </div>
      </div>
    </div>
    <add-exchange-popup
      v-if="showAddExchangePopup"
      @closed="showAddExchangePopup = false"
      @success="processSuccess"
    />
    <confirm-exchange-delete-popup
      v-if="isShowConfirmExchangeDelete"
      @confirmDelete="confirmWalletDelete"
      @closed="closeConfirmExchangeDeletePopup"
    ></confirm-exchange-delete-popup>
  </div>
</template>

<script>
// import Switcher from "@/components/input/Switcher";
import AddExchangePopup from "@/components/profile/Exchanges/Popup/AddExchangeModal/AddExchangePopup";
import { WalletApi } from "@/office/wallet-api";
import responsiveMixin from "@/mixins/responsive-mixin";
import { GtagServiceHelper } from "@/services/gtag/gtag.service";
import { GA_EVENTS } from "@/const/ga.events";
import MainLayoutHeader from "@/components/layouts/MainLayout/MainLayoutHeader";
import BaseLoader from "@/components/base/BaseLoader";
import { mapGetters, mapState } from "vuex";
import Story from "@/components/profile/Tasks/Story";
import { TasksService } from "@/services/tasks/tasks.service";
import Switcher from "@/components/input/Switcher";
import ConfirmExchangeDeletePopup from "@/components/profile/Exchanges/Popup/ConfirmExchangeDeletePopup";
import store from "@/store";
import { SET_SUPPORT_LINK } from "@/store/const/actions-types";
import PixelEventsService from "@/services/pixelEventsService";

export default {
  name: "Exchanges",
  components: {
    ConfirmExchangeDeletePopup,
    Switcher,
    Story,
    BaseLoader,
    MainLayoutHeader,
    AddExchangePopup,
  },
  inject: ["showSuccessTip", "showErrorTip"],
  mixins: [responsiveMixin],
  metaInfo() {
    return {
      title: this.$t("wallets"),
    };
  },
  data() {
    return {
      showAddExchangePopup: false,
      isDataLoading: false,
      wallets: [],
      notFilteredWalletsGroups: [],
      defaultWallet: null,
      walletForDelete: null,
      isShowConfirmExchangeDelete: false,
    };
  },
  computed: {
    pageTitle: function () {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
    ...mapState(["selectedLocale"]),
    ...mapGetters(["userData"]),
    walletsGroups: function () {
      return this.notFilteredWalletsGroups.filter((_wg) => {
        return (
          _wg.wallets.tail_wallets !== null ||
          _wg.wallets.crypto_wallets !== null
        );
      });
    },
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, "crypt_balance");
    this.loadWallets();

    if (this.$route.query.add_wallet === "true") {
      this.showAddExchangePopupAction();

      this.$router.replace({ query: {} });
    }
  },
  methods: {
    showAddExchangePopupAction() {
      store.dispatch(SET_SUPPORT_LINK, "crypto_onbord");
      this.showAddExchangePopup = true;
    },
    loadWallets() {
      this.isDataLoading = true;
      WalletApi.getWallets().then((r) => {
        this.notFilteredWalletsGroups = r.data.wallets;

        // let _wallets = r.data.wallets.filter((_w) => !_w.default_c_wallet_id);
        // this.wallets = _wallets;
        // this.defaultWallet =
        //   r.data.wallets.find((_w) => _w.default_c_wallet_id) || null;
        this.isDataLoading = false;
      });
    },
    async processSuccess(walletData) {
      try {
        console.log(walletData);
        this.analytics();
        PixelEventsService.connectExchange();
        const { exchangeId, data } = walletData;
        GtagServiceHelper.sendEvent(this, GA_EVENTS.ADD_EXCHANGE, {
          exchangeId,
          data: JSON.stringify(data),
        });
        await this.loadWallets();
        await TasksService.completeTask(TasksService.TASK_ADD_EXCHANGE_WALLET);
        this.$refs.storyBlock.updateStory();
        // this.showAddExchangePopup = false;
        // this.showSuccessTip(this.$t("tip.wallet_successfully_added"));
      } catch (error) {
        console.log(error);
        this.showErrorTip(this.$t("tip.wallet_add_error"));
      }
    },
    closeConfirmExchangeDeletePopup() {
      this.isShowConfirmExchangeDelete = false;
      this.walletForDelete = null;
    },
    confirmWalletDelete() {
      if (!this.walletForDelete) {
        return;
      }

      this.isShowConfirmExchangeDelete = false;
      this.isDataLoading = true;

      WalletApi.deleteWallet(this.walletForDelete.id).then(async () => {
        await this.loadWallets();
        this.walletForDelete = null;
        this.showSuccessTip(
          this.$t("tip.wallet_deleted", {
            walletTitle: this.walletForDelete.title,
            walletName: this.walletForDelete.name,
          })
        );
      });
    },
    deleteWallet(wallet) {
      this.isShowConfirmExchangeDelete = true;
      this.walletForDelete = wallet;
    },
    changeActiveWallet(wallet) {
      console.log("switcher clicked");
      let walletId = wallet.id;

      if (wallet.is_default_wallet) {
        walletId = null;
      }

      this.setActive({ id: walletId });
    },
    setActive(wallet) {
      console.log("set active");
      WalletApi.setDefaultWallet(wallet.id).then(async () => {
        this.notFilteredWalletsGroups.forEach((wG) => {
          if (wG.wallets.crypto_wallets) {
            wG.wallets.crypto_wallets.forEach((cW) => {
              cW.is_default_wallet = cW.id === wallet.id;
            });
          }
        });

        if (wallet.id !== null) {
          this.analytics("Exchange_connected");
        }

        this.notFilteredWalletsGroups = [...this.notFilteredWalletsGroups];
        // this.loadWallets();
      });
    },

    analytics(event = "Add_Wallet") {
      console.log(event);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: event });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
.page {
  &--wallets {
    padding-top: 4.5rem;
    padding-bottom: 6.25rem;
  }
}

.wallets {
  &__group {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      font-size: pxToRem(24);
      line-height: pxToRem(30);
      margin-bottom: 32px;
    }
  }

  &__table {
    background: #ffffff;
    box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
    border-radius: 24px;

    & + & {
      margin-top: 50px;
    }

    &-empty {
      padding: 38px 32px;
      text-align: center;
      color: $gray1;
    }

    &-header {
      display: flex;
      align-items: stretch;
    }

    &-header & {
      &-col {
        padding-top: 40px;
        padding-bottom: 36px;
        color: $gray1;
        font-weight: 500;
      }
    }

    &-row {
      display: flex;
      align-items: center;
      transition: background-color 0.2s linear;
      border-top: 1px solid #dde5ed;

      &:hover {
        background-color: $grayLight;
      }
    }

    &-col {
      width: 20%;
      display: flex;
      align-items: center;
      padding: 38px 32px;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  &__groups {
    &-empty {
      padding: 38px 32px;
      color: #7a939c;
      text-align: center;
    }
  }
}

.wallet {
  &__name {
    display: flex;
    align-items: center;

    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;

      img,
      svg,
      &::v-deep svg {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      font-weight: 700;
    }
  }

  &__currency {
    font-weight: 500;
  }

  &__balance {
    font-weight: 700;
  }

  &__set-active {
    margin-right: 10px;
  }

  &__delete {
    width: 24px;
    height: 24px;
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      svg {
        path {
          fill: $mainHot;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: fill 0.2s linear;
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .main-layout__header {
    &-wrapper {
      padding: 0;
    }
  }

  .page {
    &--wallets {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: calc(100% + 32px);
      margin-left: -16px;
      padding: 1.5rem 1rem 2.5rem;
    }
  }

  .wallets {
    &__table {
      background-color: transparent;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: calc(100% + 32px);
      margin-left: -16px;
      padding-left: 16px;
      padding-right: 16px;
      margin-top: -24px;
      padding-top: 24px;
      border-radius: 0;

      &-header {
        display: none;
      }

      &-body {
        flex-grow: 1;
        overflow: auto;
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-top: -16px;
        padding: 16px;
      }

      &-col {
        width: 100%;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray3;

        &:last-child {
          border-bottom: none;
        }

        &-title {
          color: $gray1;
          font-size: pxToRem(16);
          line-height: pxToRem(24);
        }
      }
    }
  }

  .wallet {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-top: none;
    margin-bottom: 12px;
    background: #ffffff;
    box-shadow: 0 0 30px rgba(183, 201, 211, 0.5);
    border-radius: 8px;

    &:hover {
      background-color: #ffffff;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/_mixins.scss";
@import "~@/assets/scss/_variables.scss";
.wallet {
  .switcher {
    margin-right: 10px;

    &__label {
      margin-right: 8px;
      white-space: nowrap;
    }
  }
}
.wallet {
  &__enabler {
    .switcher__label {
      font-size: pxToRem(16);
      font-weight: 500;
      margin-right: 40px;
    }
  }
}

@media screen and (max-width: 486px) {
  .page__story {
    margin-top: 32px;
  }
  .wallet {
    &__enabler {
      width: 100%;

      .switcher__label {
        color: $gray1;
        font-size: pxToRem(16);
        line-height: pxToRem(24);
      }
    }
  }
}
</style>
