<template>
  <div
    class="custom-select"
    :class="{
      opened: isOpened,
      error: vErrors.length > 0 || isError,
      nowrap,
      disabled,
      'custom-select--left-align': alignLeft,
      'custom-select--active': isActive,
    }"
    v-click-outside="hideValuesList"
  >
    <div class="custom-select__selected" @click="toggleList">
      <div
        class="custom-select__selected-empty"
        :class="{ 'to-top': !!selectedItem }"
        :title="emptyPlaceholder"
      >
        {{ emptyPlaceholder }}
      </div>
      <div class="custom-select__selected-item" v-if="!!selectedItem">
        <div
          class="custom-select__selected-item-icon"
          v-if="showIcon && selectedItem.icon"
          v-html="selectedItem.icon"
        ></div>
        <div
          class="custom-select__selected-item-icon"
          v-else-if="showIcon && selectedItem.image"
        >
          <img :src="selectedItem.image" :alt="selectedItem.title" />
        </div>
        <div class="custom-select__selected-item-label" v-if="showSelectedId">
          {{ selectedItem.additionalTitle || selectedItem.id }}
        </div>
        <div
          class="custom-select__selected-item-label"
          v-if="showSelectedTitle"
        >
          {{
            shouldTranslateTitle
              ? this.$t(selectedItem.title)
              : selectedItem.title
          }}
        </div>
      </div>
      <div class="custom-select__caret">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2929 6.79289C14.6834 6.40237 15.3166 6.40237 15.7071 6.79289C16.0976 7.18342 16.0976 7.81658 15.7071 8.20711L14.2929 6.79289ZM10 12.5L10.7071 13.2071L10 13.9142L9.29289 13.2071L10 12.5ZM4.29289 8.20711C3.90237 7.81658 3.90237 7.18342 4.29289 6.79289C4.68342 6.40237 5.31658 6.40237 5.70711 6.79289L4.29289 8.20711ZM15.7071 8.20711L10.7071 13.2071L9.29289 11.7929L14.2929 6.79289L15.7071 8.20711ZM9.29289 13.2071L4.29289 8.20711L5.70711 6.79289L10.7071 11.7929L9.29289 13.2071Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    <div class="custom-select__errors" v-if="vErrors.length > 0">
      <div class="custom-select__error">{{ vErrors[0].$message }}</div>
    </div>
    <div class="custom-select__block" ref="selectDropdown" v-if="isOpened">
      <div class="custom-select__block-backdrop" @click="hideValuesList"></div>
      <div class="custom-select__list">
        <div class="custom-select__title">{{ title }}</div>
        <div class="custom-select__search" v-if="showSearch">
          <div class="custom-select__search-icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.1449 14.6695C11.9037 15.5953 10.3642 16.1434 8.69672 16.1434C4.58401 16.1434 1.25 12.8094 1.25 8.69672C1.25 4.58401 4.58401 1.25 8.69672 1.25C12.8094 1.25 16.1434 4.58401 16.1434 8.69672C16.1434 10.3642 15.5953 11.9037 14.6695 13.1449L18.4342 16.9096C18.8553 17.3306 18.8553 18.0132 18.4342 18.4342C18.0132 18.8553 17.3306 18.8553 16.9096 18.4342L13.1449 14.6695ZM14.2818 8.69672C14.2818 11.7812 11.7812 14.2818 8.69672 14.2818C5.61219 14.2818 3.11168 11.7812 3.11168 8.69672C3.11168 5.61219 5.61219 3.11168 8.69672 3.11168C11.7812 3.11168 14.2818 5.61219 14.2818 8.69672Z"
                fill="#7A939C"
              />
            </svg>
          </div>
          <input
            type="text"
            class="custom-select__search-el"
            :placeholder="`${$t('search')}...`"
            ref="searchInput"
            v-model="searchQuery"
          />
        </div>
        <div class="custom-select__list-values">
          <div
            class="custom-select__search-helper"
            v-if="
              showSearch &&
              searchMethod &&
              (values.length === 0 || filteredItems.length === 0)
            "
          >
            {{
              isLoading
                ? $t("custom-select.loading")
                : searchQuery.length >= 3
                ? $t("custom-select.not_found")
                : $t("custom-select.type_3_symbols")
            }}
          </div>
          <div
            class="custom-select__option"
            :class="{ selected: selectedKey === _val.id }"
            v-for="_val in filteredItems"
            :key="`select-${id}-option-${_val.id}`"
            @click="selectOption(_val.id)"
          >
            <div
              class="custom-select__option-icon"
              v-if="showIcon && _val.icon"
              v-html="_val.icon"
            ></div>
            <div
              class="custom-select__option-icon"
              v-else-if="showIcon && _val.image"
            >
              <img :src="_val.image" :alt="_val.title" />
            </div>
            <div class="custom-select__option-key" v-if="showKeyInLabel">
              {{ _val.additionalTitle || _val.id }}
            </div>
            <div class="custom-select__option-title">
              {{ shouldTranslateTitle ? $t(_val.title) : _val.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    values: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      type: [String, Number],
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    showKeyInLabel: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    showSelectedId: {
      type: Boolean,
      default: false,
    },
    showSelectedTitle: {
      type: Boolean,
      default: false,
    },
    emptyPlaceholder: {
      type: String,
      default: "Select any option",
    },
    listPosition: {
      type: String,
      default: "bottom",
    },
    vErrors: {
      type: Array,
      default() {
        return [];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
    listBlockTitle: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
    searchMethod: {
      type: Function,
      default: null,
    },
    shouldTranslateTitle: {
      type: Boolean,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localListPosition: this.listPosition,
      id: null,
      selectedKey: null,
      isOpened: false,
      searchQuery: "",
      isLoading: false,
    };
  },
  created() {
    this.id = Math.random();
    this.selectedKey = this.value;
  },
  watch: {
    value() {
      this.selectedKey = this.value;
    },
    isOpened() {
      if (this.isOpened) {
        this.$nextTick(() => {
          if (this.showSearch) {
            this.$refs.searchInput.focus();
          }
          this.checkDropdownHeight();
        });
      } else {
        this.resetLocalListPosition();
      }
    },
    searchQuery: async function () {
      if (this.searchMethod) {
        this.isLoading = true;
        await this.searchMethod(this.searchQuery);
        this.isLoading = false;
      }
    },
  },
  computed: {
    selectedItem: function () {
      let selectedItem = this.values.find(
        (_val) => _val.id === this.selectedKey
      );

      if (!selectedItem) {
        return null;
      }

      return selectedItem;
    },
    filteredItems: function () {
      if (!this.showSearch) {
        return this.values;
      }

      if (this.searchQuery.trim().length === 0) {
        return this.values;
      }

      return this.values.filter((_val) =>
        _val.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    selectOption(_id) {
      this.selectedKey = _id;
      this.isOpened = false;
      this.$emit("change", this.selectedItem.id);
    },
    toggleList() {
      if (this.disabled) {
        return;
      }

      this.isOpened = !this.isOpened;
    },
    hideValuesList() {
      this.isOpened = false;
    },
    resetLocalListPosition() {
      this.localListPosition = this.listPosition;
    },
    checkDropdownHeight() {
      if (this.$refs.selectDropdown) {
        this.localListPosition =
          window.innerHeight -
            this.$refs.selectDropdown.getBoundingClientRect().y <
          this.$refs.selectDropdown.clientHeight
            ? "top"
            : this.listPosition;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.main-layout {
  &__inner {
    overflow: hidden;
  }
}

.custom-select {
  position: relative;

  &--active {
    .custom-select__selected {
      border-bottom-color: $mainCold;
    }
  }

  &--left-align {
    .custom-select__block {
      left: 0;
      right: unset;
    }
  }

  &__title {
    margin-left: 24px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(16);
    line-height: pxToRem(20);

    color: $mainBlack;
  }

  &.error & {
    &__selected {
      border-bottom-color: $mainHot;

      &-empty {
        color: $mainHot;
      }
    }
  }

  &.disabled & {
    &__selected {
      background: $grayLight;
    }
  }

  &__errors {
    position: absolute;
    bottom: -20px;
    margin-top: 4px;
  }

  &__error {
    font-size: pxToRem(12);
    line-height: pxToRem(18);
    color: $mainHot;
  }

  &.opened & {
    &__caret {
      transform: rotate(180deg);
    }
  }

  &__caret {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    transition: transform 0.2s linear;
    color: $gray1;
  }

  &.nowrap & {
    &__selected {
      &-item {
        &-label {
          white-space: nowrap;
        }
      }
    }

    &__option {
      &-title {
        white-space: nowrap;
      }
    }
  }

  &__selected {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    padding-right: 4px;
    border-bottom: 1px solid $gray3;
    cursor: pointer;

    &-empty {
      font-weight: 400;
      color: $gray1;
      margin-right: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      font-family: "Nunito", "Rubik", sans-serif;

      &.to-top {
        position: absolute;
        top: -24px;
        font-size: pxToRem(12);
      }
    }

    &-item {
      display: flex;
      align-items: center;

      &-icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        flex-shrink: 0;
        border-radius: 10px;
        overflow: hidden;

        svg,
        img {
          width: 100%;
          height: 100%;
        }
      }

      &-label {
        color: $mainBlack;
        font-family: "Nunito", "Rubik", sans-serif;
        font-weight: 400;
      }
    }
  }

  &__block {
    min-width: 100%;
    max-height: 290px;
    position: absolute;
    top: calc(100% + 7px);
    z-index: 99;
    right: 0;
    width: 307px;

    &--top {
      top: unset;
      bottom: calc(100% + 7px);
    }
  }

  &__list {
    min-width: 100%;
    max-height: 290px;
    overflow-y: hidden;
    padding: 28px 8px 8px 8px;
    background: #ffffff;
    box-shadow: 0px 0px 62px rgba(183, 201, 211, 0.5);
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &-values {
      flex-grow: 1;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
        background: inherit;
      }

      &::-webkit-scrollbar-track {
        background: none;
      }

      &::-webkit-scrollbar-track-piece {
        background: none;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray3;
        border-radius: 4px;
      }
    }
  }

  &__option {
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 0 1rem;
    margin-bottom: 8px;
    transition: background-color 0.2s linear;
    cursor: pointer;
    border-bottom: 1px solid $dividersGray;

    &.selected & {
      &-title {
        font-weight: 700;
      }
    }

    &:hover {
      background-color: #f4f8fc;
      border-radius: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 12px;
      flex-shrink: 0;
      border-radius: 10px;
      overflow: hidden;

      svg,
      img {
        width: 100%;
        height: 100%;
      }
    }

    &-key {
      color: $gray1;
      margin-right: 16px;
      font-family: "Nunito", "Rubik", sans-serif;
    }

    &-title {
      color: $mainBlack;
      font-family: "Nunito", "Rubik", sans-serif;
    }
  }

  &__search {
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    margin-bottom: 8px;
    margin-left: 12px;
    margin-right: 12px;

    &-helper {
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $gray1;
      padding: 10px;
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 16px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      transform: translateY(-50%);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-el {
      width: 100%;
      padding: 7px 16px 7px 38px;
      background: transparent;
      border: 1px solid $gray3;
      box-sizing: border-box;
      border-radius: 50px;
      outline: none;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      font-family: "Nunito", "Rubik", sans-serif;
      &:focus {
        border: 1px solid $mainCold;
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .custom-select {
    &__block {
      position: fixed;
      top: unset;
      bottom: 0;
      left: 0;
      height: 100%;
      max-height: unset;
      z-index: 99;
      display: flex;
      flex-direction: column;

      &-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(51, 63, 72, 0.2);
      }
    }

    &__title {
      margin-left: 24px;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 700;
      font-size: pxToRem(16);
      line-height: pxToRem(20);

      color: $mainBlack;
    }

    &__list {
      position: absolute;
      bottom: 0;
      max-height: calc(100% - 60px);
      min-height: 50%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &-values {
        &::-webkit-scrollbar {
          width: 4px;
          background: inherit;
        }

        &::-webkit-scrollbar-track {
          background: none;
        }

        &::-webkit-scrollbar-track-piece {
          background: none;
        }

        &::-webkit-scrollbar-thumb {
          background: $gray3;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
