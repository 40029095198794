import axiosInstance from "@/services/http/axios-instance";

class _EventsApi {
  fireEvent(eventName) {
    return axiosInstance.post("/event/fire", {
      event_name: eventName,
    });
  }
}

export const EventsApi = new _EventsApi();
