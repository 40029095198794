import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import { VueMaskDirective } from "v-mask";
import Vue2TouchEvents from "vue2-touch-events";
import OtpInput from "@bachdgvn/vue-otp-input";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import vClickOutside from "v-click-outside";
import VTooltip from "v-tooltip";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueYoutube from "vue-youtube";

// For more options see below

import moment from "moment";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/lang/i18n";

import { SET_WSS_CONNECTION } from "@/store/const/actions-types";
import { CentrifugoApi } from "@/office/centrifugo-api";
import Centrifuge from "centrifuge";
import { GOOGLE_ANALYTICS_ID, WS_URL } from "@/const/api";
import { collectInitialData, loadUserData } from "@/helpers/boot-helpers";

Vue.use(VueYoutube);
Vue.component("v-otp-input", OtpInput);

Vue.config.productionTip = false;

Vue.directive("mask", VueMaskDirective);
Vue.use(Vue2TouchEvents);
Vue.use(VueCompositionAPI);
Vue.use(VueMeta);
Vue.use(VTooltip);
Vue.use(
  VueGtag,
  {
    config: {
      appName: "Weld Web App",
      id: GOOGLE_ANALYTICS_ID,
      pageTrackerScreenviewEnabled: true,
    },
  },
  router
);
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_G_RECAPTCHA_KEY });

Vue.use(vClickOutside);

Vue.prototype.$moment = moment;

async function createApp() {
  await collectInitialData();
  await loadUserData();
  await initWssConnection();

  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

async function initWssConnection() {
  let tokenResponse = await CentrifugoApi.getAccessToken();

  let wss = new Centrifuge(WS_URL, {
    // eslint-disable-next-line no-unused-vars
    onRefresh: (ctx, cb) => {
      CentrifugoApi.getAccessToken().then((r) => {
        cb({
          status: 200,
          data: {
            token: r.data.token,
          },
        });
      });
    },
  });

  wss.setToken(tokenResponse.data.token);

  wss.on("connect", () => {
    console.log("connected to wss:// server");
  });

  wss.connect();

  await store.dispatch(SET_WSS_CONNECTION, wss);
}

createApp();
