<template>
  <div class="card-transactions">
    <div class="card-transactions__header">
      <div class="card-transactions__filter-period">
        <div class="card-transactions__filter-period-title">
          {{ $t("card-transactions.header.title") + "&nbsp;" }}
        </div>
        <div class="card-transactions__select-wrapper">
          <div class="accent">
            {{ $t("card-transactions.header.for") + "&nbsp;" }}
          </div>
          <custom-select
            class="card-transactions__filter-period-select"
            :show-selected-title="true"
            :title="$t('card-transactions.filter.date.title')"
            :empty-placeholder="''"
            :values="periodOptions"
            v-model="period"
            :should-translate-title="true"
          ></custom-select>
        </div>
      </div>
    </div>
    <div
      class="card-transactions__list"
      ref="cardTransactionsList"
      v-if="isTransactionsGroupsEmpty"
      @mousewheel="setScrollTransactions"
    >
      <card-transaction-placeholder
        v-for="index in 4"
        :key="`card-transaction-placeholder-${index}`"
      ></card-transaction-placeholder>
    </div>
    <div
      class="card-transactions__list"
      ref="cardTransactionsList"
      :class="{
        maxsize: scrollTransactions !== 0,
        'card-transactions__list--scrollable': isScrollable,
      }"
      @mousewheel="setScrollTransactions"
      v-else
    >
      <CardTransactionDate
        v-for="(transactions, index) in transactionsGroups"
        :key="`transaction-${index}`"
        :date="index"
        :transactions="transactions"
      ></CardTransactionDate>
    </div>
  </div>
</template>

<script>
import CardTransactionPlaceholder from "@/components/profile/Cards/Transactions/CardTransactionPlaceholder";
import CardTransactionDate from "@/components/profile/Cards/Transactions/CardTransactionDate";
import CustomSelect from "@/components/input/CustomSelect";
import moment from "moment";
import { CURRENCIES_LIST } from "@/const/currencies";

export default {
  name: "CardTransactions",
  components: { CustomSelect, CardTransactionPlaceholder, CardTransactionDate },
  //TODO: replace with object style props notation
  props: ["scrollTransactions", "transactionsGroups", "isScrollable"],
  provide() {
    return {
      getCurrencySymbol: this.getCurrencySymbol,
    };
  },
  data() {
    return {
      period: "week",
      periodOptions: [
        {
          id: "year",
          title: "year",
        },
        {
          id: "quarter",
          title: "quarter",
        },
        {
          id: "month",
          title: "month",
        },
        {
          id: "week",
          title: "week",
        },
        {
          id: "day",
          title: "day",
        },
      ],
    };
  },
  watch: {
    period() {
      this.filtersUpdated();
    },
  },
  computed: {
    isTransactionsGroupsEmpty() {
      return Array.isArray(this.transactionsGroups)
        ? this.transactionsGroups.length === 0
        : Object.keys(this.transactionsGroups).length === 0;
    },
  },
  methods: {
    setScrollTransactions(e) {
      console.dir();
      if (this.$refs.cardTransactionsList.scrollTop === 0 && e.deltaY < -20) {
        this.$emit(
          "scrollTransaction",
          this.$refs.cardTransactionsList.scrollTop
        );
      }
      // this.$emit("scrollTransaction", e.target.scrollTop);
    },
    getScrollTop() {
      return this.$refs.cardTransactionsList.scrollTop;
    },
    filtersUpdated() {
      let filters = {};

      let dateFrom = moment()
        .subtract(1, this.period)
        .format("YYYY-MM-DD HH:mm:ss");
      let dateTo = moment().format("YYYY-MM-DD HH:mm:ss");

      filters["dt_from"] = dateFrom;
      filters["dt_to"] = dateTo;

      this.$emit("filtersUpdated", filters);
    },
    getCurrencySymbol(currency) {
      if (!currency) {
        return "₴";
      }

      let _curr = CURRENCIES_LIST.find(
        (curr) => parseInt(curr.code) === parseInt(currency)
      );

      if (!_curr) {
        return "";
      }

      return _curr.symbol;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.card-transactions {
  background: #fff;
  /*box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);*/
  border-radius: 24px 24px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__select-wrapper {
    display: flex;
    align-items: center;
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 24px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__title {
    color: $gray1;
    font-weight: 700;
    font-size: pxToRem(24);
    line-height: pxToRem(30);
    //margin-bottom: 24px;
  }

  &__filter {
    &-date {
      font-weight: 500;
      font-size: pxToRem(20);
      line-height: pxToRem(24);
      color: $gray2;
    }
  }

  &__list {
    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 1;

    &--scrollable {
      overflow-y: auto;
    }

    &.maxsize {
      height: 575px;
    }

    &::-webkit-scrollbar {
      width: 0px;
      background: inherit;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-track-piece {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background: none;
      border-radius: 0px;
    }
  }

  .card-transaction {
    border-bottom: 1px solid $gray3;

    &:last-child {
      border-bottom: none;
    }
  }
}

@media screen and (max-width: 486px) {
  .card-transactions {
    padding-left: 0px;
  }
}
</style>
<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.card-transactions {
  &__filter {
    &-period {
      display: flex;
      align-items: center;

      .accent,
      &-title,
      .custom-select__selected-item-label {
        font-size: 1.5rem;
        line-height: normal;
        font-family: "Nunito", "Rubik", sans-serif;
        font-weight: 700;
      }

      .accent,
      .custom-select__selected-item-label {
        color: $mainCold;
      }

      .custom-select {
        &__selected {
          padding-bottom: 0;
          padding-right: 0;
          border-bottom: none;
          width: auto;

          &-item {
            &-label {
              text-transform: lowercase;
            }
          }
        }

        &__caret {
          margin-left: 10px;

          svg {
            path {
              fill: $mainCold;
            }
          }
        }

        &__block {
          width: 194px;
        }
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .card-transactions {
    &__header {
      padding: 40px 16px 16px;
    }

    &__filter-period {
      .accent,
      &-title,
      .custom-select__selected-item-label {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
