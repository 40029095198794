<template>
  <div class="unex-diia-sign__wrapper">
    <div class="unex-onboarding__title">
      {{ $t("unex.onboarding.diia.sign.step.title") }}
    </div>
    <div class="unex-onboarding__sub-title">
      {{ $t("unex.onboarding.diia.sign.step.sub_title") }}
    </div>
    <div class="unex-diia-sign">
      <base-loader v-if="isDataLoading"></base-loader>
      <div class="unex-diia-sign__popup-title">
        {{ $t("unex.onboarding.diia.sign.document_title") }}
      </div>

      <div class="unex-diia-sign__agreement" @click="loadAgreement">
        <div class="unex-diia-sign__agreement-title">
          {{ $t("unex.onboarding.diia.sign.agreement_title") }}
        </div>
        <!--<div class="unex-diia-sign__agreement-download">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 17L2.621 19.485C2.72915 19.9177 2.97882 20.3018 3.33033 20.5763C3.68184 20.8508 4.11501 20.9999 4.561 21H19.439C19.885 20.9999 20.3182 20.8508 20.6697 20.5763C21.0212 20.3018 21.2708 19.9177 21.379 19.485L22 17M12 15V3V15ZM12 15L8 11L12 15ZM12 15L16 11L12 15Z"
              stroke="#00B7BD"
              stroke-opacity="0.9"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>-->
      </div>
      <a
        href="https://unexbank.ua/storage/uploads/0752e5d6-ef58-4fa4-be6f-4951506df691/DKBO_actualny.pdf"
        target="_blank"
        class="unex-diia-sign__agreement"
      >
        <div class="unex-diia-sign__agreement-title">
          {{ $t("unex.onboarding.diia.sign.offer_title") }}
        </div>
      </a>
      <div
        class="unex-diia-sign__text"
        v-html="$t('unex.onboarding.diia.sign.text')"
      ></div>
      <template v-if="!isMobile">
        <a class="unex-diia-sign__qr" :href="diiaDeepLinkHref" target="_blank">
          <canvas ref="qrCanvas"></canvas>
        </a>
        <div
          class="unex-diia-sign__qr-update-countdown"
          v-if="!canUpdateDeeplink"
        >
          {{ timerString }}
        </div>
        <div class="unex-diia-sign__qr-update-countdown" v-else>
          {{ $t("unex.onboarding.diia.sign.hint") }}
        </div>
        <TransparentButton
          @click="loadDiiaDeeplink"
          :disabled="!canUpdateDeeplink"
          :label="$t('unex.onboarding.diia.update_deeplink')"
          class="unex-diia-sign__qr-update"
        ></TransparentButton>
      </template>
      <template v-else>
        <div class="unex-diia-sign__action">
          <PrimaryButton
            @click="openDiiaLinkHandler"
            :disabled="isButtonClicked && !canUpdateDeeplink"
            :label="
              isButtonClicked && !canUpdateDeeplink
                ? timerString
                : $t('unex.onboarding.diia.deeplink_button')
            "
          />
        </div>
      </template>

      <div class="unex-diia-sign__help-wrapper">
        <div
          class="unex-diia-sign__title"
          v-html="$t('unex.onboarding.diia.sign.dii_help.title')"
        ></div>
        <div
          class="unex-diia-sign__text"
          v-html="$t('unex.onboarding.diia.sign.dii_help.text')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { UnexOnboardingApi } from "@/office/unex-onboarding-api";
import { mapGetters, mapState } from "vuex";
import BaseLoader from "@/components/base/BaseLoader";
import FileSaver from "file-saver";

import responsiveMixin from "@/mixins/responsive-mixin";
//import PrimaryButton from "@/components/button/PrimaryButton";
import TransparentButton from "@/components/button/TransparentButton";
import store from "@/store";
import { SET_SUPPORT_LINK } from "@/store/const/actions-types";
import PrimaryButton from "@/components/button/PrimaryButton";

export default {
  name: "UnexOnboardingDiiaSign",
  //components: { PrimaryButton, BaseLoader },
  components: { PrimaryButton, TransparentButton, BaseLoader },
  mixins: [responsiveMixin],
  inject: [
    "toNextStep",
    "showErrorTip",
    "errorWSMessageHandler",
    "getCardProductId",
  ],

  data() {
    return {
      diiaDeepLink: null,
      wssDiiaChanelSubscription: null,
      isDataLoading: false,
      agreementBase64: null,
      canUpdateDeeplink: false,
      updateDeeplinkInterval: null,
      updateDeeplinkTimeout: 150,
      updateDeeplinkTimeoutTemporary: 150,
      isButtonClicked: false,
    };
  },
  watch: {
    diiaDeepLink() {
      this.drawQR(this.diiaDeepLink);
    },
    canUpdateDeeplink(value) {
      if (value && this.isMobile) {
        this.loadDiiaDeeplink();
      }
    },
  },
  async created() {
    store.dispatch(SET_SUPPORT_LINK, "nbu_doc_sign");
    this.initWS();
    this.isDataLoading = true;
    await this.loadPrintForm();
    await this.loadDiiaDeeplink();
  },
  beforeDestroy() {
    if (this.wssDiiaChanelSubscription) {
      this.wssDiiaChanelSubscription.unsubscribe();
    }
  },
  computed: {
    ...mapState(["wss"]),
    ...mapGetters(["userData"]),
    diiaDeepLinkHref: function () {
      return this.isMobile ? this.diiaDeepLink : null;
    },
    timerString: function () {
      let minutes = Math.floor(this.updateDeeplinkTimeoutTemporary / 60);
      let seconds = this.updateDeeplinkTimeoutTemporary - minutes * 60;

      return (
        this.padLeft(minutes, "0", 2) + ":" + this.padLeft(seconds, "0", 2)
      );
    },
  },
  methods: {
    async loadPrintForm() {
      try {
        let agreementResponse = await UnexOnboardingApi.getUserAgreement();
        this.agreementBase64 = agreementResponse.data.document;
      } catch (e) {
        this.showErrorTip(
          this.$t("unex.onboarding.diia.sign.error.cant_get_agreement")
        );
        console.log("agreementResponse error", e);
      }
    },
    async loadDiiaDeeplink() {
      this.isDataLoading = true;
      try {
        let deepLinkResponse = await UnexOnboardingApi.getDiiaSignDeeplinkUrl(
          this.getCardProductId()
        );
        this.diiaDeepLink = deepLinkResponse.data.originalDeeplink;
        this.startDeeplinkTimeout();
      } catch (e) {
        console.log(e);
        this.canUpdateDeeplink = true;
      } finally {
        this.isDataLoading = false;
      }
    },
    async initWS() {
      this.wssDiiaChanelSubscription = this.wss.subscribe(
        `diia-sign-${this.userData.hashed_id}`,
        async (msg) => {
          let isErrorHandled = await this.errorWSMessageHandler(msg);

          if (isErrorHandled) {
            return;
          }
          this.handleChannelMessage(msg);
        }
      );
    },
    handleChannelMessage(msg) {
      console.log("diia-sign-channel", msg);

      if (msg.data.success === true) {
        this.toNextStep();
      }
    },
    loadAgreement() {
      if (!this.agreementBase64) {
        this.showErrorTip(
          this.$t("unex.onboarding.diia.sign.error.empty_agreement")
        );
        return;
      }

      this.isDataLoading = true;
      UnexOnboardingApi.downloadUserAgreement()
        .then((r) => {
          FileSaver.saveAs(r.data, `FullAgreement.pdf`);
          this.isDataLoading = false;
        })
        .catch(() => {
          this.showErrorTip(
            this.$t("unex.onboarding.diia.sign.error.empty_agreement")
          );
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    drawQR(url) {
      console.log(url);
      QRCode.toCanvas(this.$refs.qrCanvas, url, {
        errorCorrectionLevel: "L",
        width: 278,
        height: 278,
        margin: 0,
        color: {
          dark: "#66798F",
        },
      });
    },
    startDeeplinkTimeout() {
      this.canUpdateDeeplink = false;
      this.startCountdown();

      setTimeout(() => {
        this.canUpdateDeeplink = true;
        this.isButtonClicked = false;
        this.clearCountdown();
      }, 150000);
    },
    startCountdown() {
      this.updateDeeplinkTimeoutTemporary = this.updateDeeplinkTimeout;
      this.updateDeeplinkInterval = setInterval(() => {
        this.updateDeeplinkTimeoutTemporary--;
      }, 1000);
    },
    clearCountdown() {
      if (this.updateDeeplinkInterval) {
        clearInterval(this.updateDeeplinkInterval);
      }
    },
    padLeft(string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    },
    openDiiaLinkHandler() {
      if (this.isDataLoading) {
        return;
      }

      this.isButtonClicked = true;

      this.diiaDeepLinkHref && window.open(this.diiaDeepLinkHref, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-diia-sign {
  position: relative;
  width: 100%;
  /*max-width: 638px;*/
  max-width: 530px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 62px rgba(183, 201, 211, 0.5);
  border-radius: 24px;
  padding: 48px;

  &__action {
    margin-top: 40px;
  }

  &__popup-title {
    font-weight: 700;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    color: $mainBlack;
    margin-bottom: 24px;
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: pxToRem(32);
    line-height: pxToRem(38);
    color: $gray1;
    margin-bottom: 25px;
  }

  &__text {
    width: 99%;
    font-weight: 500;
    font-size: pxToRem(16);
    line-height: pxToRem(20);
    color: $gray1;

    &::v-deep {
      ul {
        li {
          font-weight: 500;
          font-size: pxToRem(16);
          line-height: pxToRem(20);
          color: $gray1;
          margin-left: -13px;
        }
      }
      a {
        color: $mainCold;
      }
    }
  }

  &__agreement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 31px;

    &-title {
      font-weight: 500;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      color: $mainCold;
      text-decoration: underline;
      cursor: pointer;
    }

    &-download {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 25px;
      cursor: pointer;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.8;
      }

      svg,
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__qr {
    display: flex;
    align-items: center;
    justify-content: center;

    canvas {
      width: 278px;
      height: 278px;
    }

    &-helper {
      text-align: center;
      margin-bottom: 15px;
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $gray1;
    }

    &-update {
      margin-top: 45px;

      &-countdown {
        text-align: center;
        margin-top: 25px;
        color: $gray1;
      }
    }
  }

  &__help-wrapper {
    margin-top: 36px;

    div.unex-diia-sign__title {
      font-style: normal;
      font-weight: 700;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      display: flex;
      align-items: center;
      color: $gray1;
    }
  }
}

.unex-onboarding__sub-title {
  font-weight: 500;
  font-size: pxToRem(16);
  line-height: pxToRem(20);
  color: $gray1;
  margin-bottom: 25px;
}

@media screen and (max-width: 786px) {
  .unex-diia-sign {
    padding: 20px 0;
    box-shadow: none;
  }
}
</style>
