import axiosInstance from "@/services/http/axios-instance";
// import { ResponseMockerService } from "@/services/mocker/response-mocker.service";

class _WalletApi {
  getWallets() {
    return axiosInstance.get("/user/wallets");
  }

  createWallet(exchangeId, walletData) {
    return axiosInstance.post("/user/wallets/create", {
      exchange_id: exchangeId,
      exchange_data: walletData,
    });
  }

  deleteWallet(walletId) {
    return axiosInstance.post("/user/wallets/delete", {
      wallet_id: walletId,
    });
  }

  setDefaultWallet(walletId) {
    return axiosInstance.post("/user/wallets/default/set", {
      wallet_id: walletId,
    });
  }

  getDefaultWallet() {
    return axiosInstance.get("/user/wallets/default");
    // return ResponseMockerService.mock({
    //   default_wallet: null,
    // });
  }
}
export const WalletApi = new _WalletApi();
