/**
 * @param {{imagePath: string, fileName: string}} option
 * @returns {Promise<File | null>}
 */
export const imageToFile = async ({ imagePath, fileName }) => {
  try {
    const imageResponse = await fetch(imagePath);
    if (imageResponse.ok) {
      const blob = await imageResponse.blob();
      return new File([blob], fileName, {
        type: blob.type,
        lastModified: new Date().getTime(),
      });
    }
    console.error("response return code: " + imageResponse.status);
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
