import MobileDetect from "mobile-detect"

class _MobileDetectService {
  MobileDetect = null;

  constructor() {
    this.MobileDetect = new MobileDetect(window.navigator.userAgent);
  }

  isPhone() {
    return !!this.MobileDetect.phone();
  }
}

export const MobileDetectService = new _MobileDetectService();
