<template>
  <div class="settings-p-details p-details">
    <div class="p-details__short">
      <div class="p-details__avatar">
        <img :src="user.avatar" :alt="user.firstName" />
      </div>
      <div class="p-details__full-name">{{ user.name }}</div>
    </div>
    <div class="p-details__full">
      <div class="p-details__section">
        <div class="p-details__section-header">
          <div class="p-details__section-title">{{ $t("your_profile") }}</div>
          <div
            class="p-details__section-edit"
            @click="showEditPDataPopup = true"
          >
            {{ $t("edit") }}
          </div>
        </div>
        <div class="p-details__section-body">
          <div class="p-details__field" v-if="user.fullName">
            <text-input
              :title="$t('full_name')"
              :disabled="true"
              v-model="user.fullName"
            ></text-input>
          </div>
          <div class="p-details__field">
            <phone-input
              v-model="user.phone"
              :initial-original-phone="user.originalPhone"
              :initial-country="user.country"
              :initial-country-code="user.countryCode"
              :disabled="true"
            ></phone-input>
          </div>
          <div class="p-details__field">
            <email-input :disabled="true" v-model="user.email"></email-input>
          </div>
          <div class="p-details__field">
            <custom-select
              :disabled="true"
              v-model="user.country"
              :values="countries"
              :empty-placeholder="$t('country.your')"
              :show-icon="true"
              :show-selected-title="true"
              :show-search="true"
            ></custom-select>
          </div>
        </div>
      </div>
      <div class="p-details__section">
        <div class="p-details__section-header">
          <div class="p-details__section-title">{{ $t("your_password") }}</div>
          <div
            class="p-details__section-edit"
            @click="showEditPasswordPopup = true"
          >
            {{ $t("change") }}
          </div>
        </div>
      </div>
      <div class="p-details__section">
        <div class="p-details__section-header">
          <div class="p-details__section-title">{{ $t("tfa") }}</div>
        </div>
        <div class="p-details__section-body">
          <div class="p-details__section-body">
            <switcher
              class="tfa-item"
              v-for="(tfaItem, index) in tfaEditable"
              :key="`tfa-item-${tfaItem.id}`"
              :label="getTFATitle(tfaItem.name)"
              v-model="tfaEditable[index].active"
              @update="saveActiveTFA(tfaEditable[index])"
            ></switcher>
          </div>
        </div>
      </div>
    </div>
    <edit-personal-data-popup
      v-if="showEditPDataPopup"
      @closed="closePersonalDataPopup"
      @success="userUpdated"
      @loadAvatar="loadAvatar"
      :user="user"
      :newAvatar="newAvatar"
    ></edit-personal-data-popup>
    <edit-password-popup
      v-if="showEditPasswordPopup"
      @closed="closePasswordPopup"
      :user="user"
      @success="passwordUpdated"
    ></edit-password-popup>
    <google-auth-config-popup
      v-if="showGoogleAuthConfigPopup"
      @closed="closeGoogleAuthConfigPopup"
      @success="verifyGoogleAuthCode"
    ></google-auth-config-popup>
    <avatar-cropper-popup
      v-if="showAvatarCropperPopup"
      @closed="closeAvatarCropperPopup"
      @crop="cropAvatar"
      :avatar="newAvatar"
    ></avatar-cropper-popup>
  </div>
</template>

<script>
import TextInput from "@/components/input/TextInput";
import PhoneInput from "@/components/input/Phone/PhoneInput";
import EditPersonalDataPopup from "@/components/profile/Settings/Popup/EditPersonalDataPopup";
import EditPasswordPopup from "@/components/profile/Settings/Popup/EditPasswordPopup";
import { UserApi } from "@/office/user-api";
import Switcher from "@/components/input/Switcher";
import GoogleAuthConfigPopup from "@/components/profile/Settings/Popup/GoogleAuthConfigPopup";
import CustomSelect from "@/components/input/CustomSelect";
import { COUNTRIES } from "@/const/countries";
import { mapState } from "vuex";
import EmailInput from "@/components/input/EmailInput";
import AvatarCropperPopup from "@/components/profile/Settings/Popup/AvatarCropperPopup";
import DefaultAvatar from "@/assets/img/logo-circle-small.svg";

const GOOGLE_AUTH = "google_auth";

export default {
  name: "SettingsPersonalDetails",
  components: {
    EmailInput,
    CustomSelect,
    GoogleAuthConfigPopup,
    Switcher,
    EditPasswordPopup,
    EditPersonalDataPopup,
    PhoneInput,
    TextInput,
    AvatarCropperPopup,
  },
  inject: ["showSuccessTip", "showErrorTip"],

  metaInfo: {
    title: "Personal Settings",
  },
  data() {
    return {
      user: {
        fullName: "",
        avatar: DefaultAvatar,
        email: "",
        phoneCode: "+380",
        phoneNumber: "",
        countryCode: "",
        phone: "",
      },
      tfaAvailable: [],
      tfaActive: [],
      tfaEditable: [],
      showEditPDataPopup: false,
      showEditPasswordPopup: false,
      showGoogleAuthConfigPopup: false,
      showAvatarCropperPopup: false,
      newAvatar: null,
    };
  },
  async created() {
    const { data } = await UserApi.getProfileData();
    this.user.fullName = data.user.full_name || "";
    this.user.phoneNumber = data.user.cell_phone;
    this.user.phone = data.user.cell_phone;
    this.user.originalPhone = data.user.original_phone;
    this.user.country = data.user.country;
    this.user.email = data.user.email;
    this.user.is2f = data.user.is2f || true;
    this.user.countryCode = data.user.country_code || "";
    this.user.avatar = data.user.avatar_url || DefaultAvatar;
    await this.loadUserTFA();
  },
  computed: {
    countries: function () {
      return COUNTRIES.map((_country) => {
        return {
          id: _country.isoCode,
          title: _country.name,
          image: _country.flag,
        };
      });
    },
    ...mapState({
      userPhone: (state) => state.user.phone,
    }),
  },
  methods: {
    closePersonalDataPopup() {
      this.newAvatar = null;
      this.showEditPDataPopup = false;
    },
    closePasswordPopup() {
      this.showEditPasswordPopup = false;
    },
    closeAvatarCropperPopup() {
      this.showAvatarCropperPopup = false;
    },
    closeGoogleAuthConfigPopup() {
      this.$data.showGoogleAuthConfigPopup = false;
      this.disableTfa(GOOGLE_AUTH);
    },
    userUpdated(user) {
      this.showEditPDataPopup = false;
      this.user = { ...user };

      this.showSuccessTip(this.$t("tip.personal_data_updated"));
    },
    passwordUpdated() {
      this.showEditPasswordPopup = false;
      this.showSuccessTip(this.$t("tip.password_updated"));
    },
    async loadUserTFA() {
      try {
        const [{ data: availableTFAData }, { data: activeTFAData }] =
          await Promise.all([
            UserApi.getAvailableTFA(),
            UserApi.getActiveTFA(),
          ]);
        this.tfaAvailable = availableTFAData.available_tfa_types;
        this.tfaActive = activeTFAData.tfa_types;

        this.tfaAvailable.forEach((_item) => {
          this.tfaEditable.push({
            id: _item["2f_type_id"],
            name: _item.name,
            active:
              this.tfaActive.findIndex(
                (_activeItem) =>
                  _activeItem["2f_type_id"] === _item["2f_type_id"]
              ) > -1,
          });
        });
      } catch (error) {
        console.error(
          "Something went wrong when trying to load user tfa \n",
          error
        );
      }
    },
    saveActiveTFA(tfaToUpdate) {
      //TODO: check TFA update
      if (tfaToUpdate.name === GOOGLE_AUTH && tfaToUpdate.active) {
        this.showGoogleAuthConfigPopup = true;
        return;
      }

      let tfaActiveIDs = this.tfaEditable
        .filter((_item) => _item.active)
        .map((_item) => _item.id);

      UserApi.setActiveTFA(tfaActiveIDs); //Необходимо передавать весь список активных
    },
    getTFATitle(name) {
      // let _strings = {
      //   sms: "SMS",
      //   email: "Email",
      //   google_auth: "Google Authenticator",
      // };

      return this.$t(name || "") || "";
    },
    disableTfa(name) {
      this.tfaEditable
        .filter((_item) => _item.active)
        .map((_item) => {
          if (_item.name === name) {
            _item.active = false;
          }
        });
    },
    verifyGoogleAuthCode(code) {
      UserApi.verifyGoogleAuthCode(code, this.user.phone)
        .then((r) => {
          if (r.data.success) {
            this.showGoogleAuthConfigPopup = false;
            this.showSuccessTip(this.$t("verify-google-auth-code.success-tip"));

            let tfaActiveIDs = this.tfaEditable
              .filter((_item) => _item.active)
              .map((_item) => _item.id);

            UserApi.setActiveTFA(tfaActiveIDs);
          }
        })
        .catch(() => {
          this.showGoogleAuthConfigPopup = false;
          this.showErrorTip(this.$t("verify-google-auth-code.error-tip"));

          let tfaActiveIDs = this.tfaEditable
            .filter((_item) => {
              if (_item.name === GOOGLE_AUTH) {
                _item.active = false;
              }

              return _item.active;
            })
            .map((_item) => _item.id);

          UserApi.setActiveTFA(tfaActiveIDs);
        });
    },
    loadAvatar(avatar) {
      this.newAvatar = avatar;
      this.showAvatarCropperPopup = true;
    },
    cropAvatar(avatar) {
      this.newAvatar = avatar;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.p-details {
  display: flex;
  align-items: flex-start;

  &__short {
    /*width: 108px;*/
    margin-right: 64px;
    flex-shrink: 0;
  }

  &__avatar {
    width: 108px;
    height: 108px;
    margin-bottom: 8px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__full-name {
    font-weight: 700;
  }

  &__full {
    width: 410px;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 64px;
    border-left: 1px solid $grayLight;
  }

  &__section {
    margin-bottom: 76px;

    &-header {
      margin-bottom: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      font-size: pxToRem(24);
      line-height: pxToRem(30);
      font-weight: bold;
    }

    &-edit {
      color: $mainHot;
      font-weight: 700;
      cursor: pointer;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.6;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__field {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tfa-item {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 486px) {
  .p-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__short {
      margin-right: 0;
      margin-bottom: 32px;
    }

    &__full {
      width: 100%;
      padding-left: 0;
      border-left: none;
    }

    &__section {
      &-title {
        font-size: pxToRem(20);
        line-height: pxToRem(24);
      }
    }
  }
}
</style>
