export const SET_CURRENT_LOCALE = "SET_CURRENT_LOCALE";
export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_TOKENS = "UPDATE_TOKENS";
export const SET_USER_PHONE = "SET_USER_PHONE";
export const SET_USER_ORIGINAL_PHONE = "SET_USER_ORIGINAL_PHONE";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_PASSWORD = "SET_USER_PASSWORD";
export const SET_USER_COUNTRY_CODE = "SET_USER_COUNTRY_CODE";
export const SET_USER_COUNTRY = "SET_USER_COUNTRY_CODE";
export const SET_USER_AVATAR = "SET_USER_AVATAR";
export const SET_APPLICATION_STATE = "SET_APPLICATION_STATE";
export const SET_WSS_CONNECTION = "SET_WSS_CONNECTION";
export const SET_WEB3_INSTANCE = "SET_WEB3_INSTANCE";
export const SET_PARENT_REFERRAL_UUID = "SET_PARENT_REFERRAL_UUID";
export const SET_AVAILABLE_CRYPTO_ACCOUNT = "SET_AVAILABLE_CRYPTO_ACCOUNT";
export const SET_CURRENT_CRYPTO_CHAIN_ID = "SET_CURRENT_CRYPTO_CHAIN_ID ";
export const SET_AGREEMENT = "SET_AGREEMENT";
export const LOAD_AGREEMENT_DATA = "LOAD_AGREEMENT_DATA";
export const SET_ACTIVE_STORY = "SET_ACTIVE_STORY";
export const SET_ACTIVE_CARD_PRODUCT = "SET_ACTIVE_CARD_PRODUCT";
export const SET_UNEX_ONBOARDING_ERROR = "SET_UNEX_ONBOARDING_ERROR";
export const CLEAR_UNEX_ONBOARDING_ERROR = "CLEAR_UNEX_ONBOARDING_ERROR";
export const SET_SUPPORT_LINK = "SET_SUPPORT_LINK";
