<template>
  <div class="wrapper">
    <main-layout-header :page-title="pageTitle"></main-layout-header>
    <div class="main-layout__body">
      <div class="page page--coming-soon">
        <coming-soon></coming-soon>
      </div>
    </div>
  </div>
</template>

<script>
import MainLayoutHeader from "@/components/layouts/MainLayout/MainLayoutHeader";
import ComingSoon from "@/components/base/ComingSoon";
export default {
  name: "ComingSoonTitled",
  components: { ComingSoon, MainLayoutHeader },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  computed: {
    pageTitle: function () {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
  },
};
</script>
