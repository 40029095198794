<template>
  <div class="popup">
    <div
      class="popup__block popup__block--card-products"
      v-if="!showSubProductsScreen"
    >
      <div class="popup__header popup__header--wrap">
        <div class="popup__title">
          {{ $t("card.popup.product.title") }}
        </div>
        <popup-close-button @click="closePopup"></popup-close-button>
      </div>
      <div
        class="card-products"
        v-if="!activeCardProduct || ignoreActiveCardProduct"
      >
        <base-loader v-if="isDataLoading"></base-loader>
        <div
          class="card-products__empty"
          v-if="!isDataLoading && cardProducts.length === 0"
          v-html="$t('card.popup.product.empty')"
        ></div>
        <div class="card-products__list" v-else>
          <card-product-item
            :class="{
              active:
                selectedCardProduct &&
                selectedCardProduct.id === cardProduct.id,
            }"
            v-for="cardProduct in cardProducts"
            :card-product="cardProduct"
            :key="`card-product-${cardProduct.id}`"
            @click="setActiveCardProduct(cardProduct)"
          ></card-product-item>
        </div>
        <div class="card-products__actions">
          <no-bg-button
            class="card-products__action button--hide"
            :label="$t('cancel')"
            @click="closePopup"
          ></no-bg-button>
          <primary-button
            class="card-products__action"
            :label="$t('confirm')"
            :disabled="!selectedCardProduct"
            @click="goToAddCardProcess"
          ></primary-button>
        </div>
      </div>
      <div
        class="card-products-active"
        v-if="!isDataLoading && activeCardProduct && !ignoreActiveCardProduct"
      >
        <div class="card-products-active__hint">
          {{
            $t("card.popup.product.existed.hint", {
              cardProductName: activeCardProduct.name,
            })
          }}
        </div>
        <div class="card-products-active__actions">
          <primary-button
            class="card-products-active__action"
            @click="continueAddCardProcess"
            :label="$t('card.popup.product.continue')"
          ></primary-button>
          <no-bg-button
            class="card-products-active__action"
            @click="ignoreActiveCardProduct = true"
            :label="$t('card.popup.product.select-new-card')"
          ></no-bg-button>
        </div>
      </div>
    </div>
    <add-card-sub-products
      :card-products="selectedCardProduct.child_card_types"
      @goBack="showSubProductsScreen = false"
      @goNext="goToAddCardSubProductProcess"
      v-else
    ></add-card-sub-products>
  </div>
</template>

<script>
import BaseLoader from "@/components/base/BaseLoader";
import CardProductItem from "@/components/profile/Cards/Items/CardProductItem";
import NoBgButton from "@/components/button/NoBgButton";
import PrimaryButton from "@/components/button/PrimaryButton";
import { SET_ACTIVE_CARD_PRODUCT } from "@/store/const/actions-types";
import { CardsProductService } from "@/services/cards/cards-product.service";
import { OnboardingApi } from "@/office/onboarding-api";
import PopupCloseButton from "@/components/button/PopupCloseButton";
import AddCardSubProducts from "@/components/profile/Cards/Popup/AddCard/AddCardSubProducts";

export default {
  name: "CardProductsPopup",
  components: {
    AddCardSubProducts,
    PopupCloseButton,
    PrimaryButton,
    NoBgButton,
    CardProductItem,
    BaseLoader,
  },
  inject: ["showErrorTip"],
  props: {
    cardProducts: {
      type: Array,
    },
    isDataLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSubProductsScreen: false,
      selectedCardProduct: null,
      ignoreActiveCardProduct: false,
    };
  },
  created() {},
  computed: {
    activeCardProduct: function () {
      let _activeCardProduct = this.cardProducts.find(
        (_card) => _card.step !== null
      );

      if (!_activeCardProduct) {
        return false;
      }

      return _activeCardProduct;
    },
  },
  methods: {
    closePopup() {
      this.$emit("closed");
    },
    setActiveCardProduct(cardProduct) {
      this.selectedCardProduct = cardProduct;
    },
    async goToAddCardProcess() {
      this.analytics();

      if (
        this.selectedCardProduct.child_card_types &&
        this.selectedCardProduct.child_card_types.length > 0
      ) {
        this.showSubProductsScreen = true;
        return;
      } else {
        this.showSubProductsScreen = false;
      }

      try {
        await OnboardingApi.initiateOnboardingProcess(
          this.selectedCardProduct.id
        );
        localStorage.setItem(
          CardsProductService.getLocalStorageSlugKey(),
          this.selectedCardProduct.slug
        );
      } catch (e) {
        console.error(e);
        this.showErrorTip(this.$t("errors.default"));
        return false;
      }

      let redirectRoute = CardsProductService.getCardProductOnboardingRoute(
        this.selectedCardProduct.slug
      );

      if (!redirectRoute) {
        this.showErrorTip(
          this.$t("card.popup.product.error.onboarding_route_not_found")
        );
        return;
      }

      this.$store.commit(SET_ACTIVE_CARD_PRODUCT, this.selectedCardProduct);
      this.$router.push(redirectRoute);
    },
    async goToAddCardSubProductProcess(subProduct) {
      console.log(subProduct);
      try {
        await OnboardingApi.initiateOnboardingProcess(subProduct.id);
        localStorage.setItem(
          CardsProductService.getLocalStorageSlugKey(),
          subProduct.slug
        );
      } catch (e) {
        console.error(e);
        this.showErrorTip(this.$t("errors.default"));
        return false;
      }

      let redirectRoute = CardsProductService.getCardProductOnboardingRoute(
        subProduct.slug
      );

      if (!redirectRoute) {
        this.showErrorTip(
          this.$t("card.popup.product.error.onboarding_route_not_found")
        );
        return;
      }

      this.$store.commit(SET_ACTIVE_CARD_PRODUCT, subProduct);
      this.$router.push(redirectRoute);
    },
    async continueAddCardProcess() {
      this.selectedCardProduct = this.activeCardProduct;
      await this.goToAddCardProcess();
    },
    analytics() {
      console.log("AddCard_Start");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "AddCard_Start" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  &__block {
    &--card-products {
      width: 100%;
      max-width: 440px;
    }
  }
}
.card-products-popup {
  position: relative;
}

.card-products {
  margin-top: 48px;

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__empty {
    font-size: pxToRem(14);
    line-height: pxToRem(22);
    color: $gray1;
    text-align: center;
  }

  &__actions {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__action {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-products-active {
  margin-top: 36px;

  &__hint {
    background: $grayLight;
    border-radius: 8px;
    padding: 24px;
    color: $gray1;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    margin-bottom: 72px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__action {
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.card-product {
  width: calc(50% - 12px);
  margin-right: 24px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  &.active {
    filter: grayscale(0);
  }
}

.popup__header--wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 486px) {
  .popup__header--wrap {
    flex-direction: row-reverse;
  }
  .button--hide {
    display: none;
  }
}
</style>
