<template>
  <div class="radio-group">
    <div class="radio-group__header">
      <div class="radio-group__label">{{ label }}</div>
      <div class="radio-group__hint" v-if="hint">
        <v-popover placement="top" class="hint">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.1724 6.25063C10.828 6.25063 11.3793 5.68712 11.3793 5.01404C11.3793 4.30965 10.8131 3.7931 10.1724 3.7931C9.53172 3.7931 8.96552 4.30965 8.96552 5.01404C8.96552 5.70278 9.51682 6.25063 10.1724 6.25063Z"
              fill="#7A939C"
            />
            <path
              d="M9.11452 14.8129C9.11452 15.2355 9.38272 15.5172 9.78502 15.5172H10.5747C10.977 15.5172 11.2452 15.2355 11.2452 14.8129V8.03508C11.2452 7.61245 10.977 7.33069 10.5747 7.33069H9.78502C9.38272 7.33069 9.11452 7.61245 9.11452 8.03508V14.8129Z"
              fill="#7A939C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.6207 10C18.6207 14.7611 14.7611 18.6207 10 18.6207C5.23892 18.6207 1.37931 14.7611 1.37931 10C1.37931 5.23892 5.23892 1.37931 10 1.37931C14.7611 1.37931 18.6207 5.23892 18.6207 10Z"
              fill="#7A939C"
            />
          </svg>
          <template slot="popover" class="hint">
            <div class="hint__body">
              <div class="hint__text">
                {{ hint }}
              </div>
            </div>
            <div class="hint__footer">
              <a v-close-popover class="hint__close">Close</a>
            </div>
          </template>
        </v-popover>
      </div>
    </div>
    <div class="radio-group__body">
      <div
        class="radio-group__input"
        v-for="(option, index) in options"
        :key="option.text"
      >
        <input
          :id="name + index"
          :name="name"
          type="radio"
          :value="option.value"
          :checked="option.value === value"
          :class="inputClass"
          :disabled="disabled"
          :required="required"
          @change="updateValue(option.value)"
        />
        <label class="radio-group__input-label" :for="name + index">
          <span class="radio-group__input-label-mark"></span>
          <span class="radio-group__input-label-text">{{ option.text }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioGroup",
  model: {
    event: "change",
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: [Array],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: [String, Object],
      default: "",
    },
    hint: {
      type: String,
      default: null,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.radio-group {
  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  &__hint {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $mainBlack;
  }

  &__input {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      display: none;

      &:checked {
      }
    }

    &-label {
      display: flex;
      align-items: center;

      &-mark {
        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        border: 1px solid $gray1;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 8px;
        transition: border-color 0.2s linear;

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          content: "";
          display: block;
          background: $mainCold;
          opacity: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          transition: opacity 0.2s linear;
        }
      }

      &-text {
        font-weight: 500;
        font-size: pxToRem(16);
        line-height: pxToRem(24);
        color: $gray1;
      }
    }

    input:checked + .radio-group__input-label & {
      &-label-mark {
        border-color: $mainCold;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}
</style>
