<template>
  <div class="exchange-connection">
    <div class="exchange-connection__tabs tabs">
      <template v-for="(tabName, index) of tabs">
        <button
          @click="setActiveTab(tabName)"
          type="button"
          class="tabs-item"
          :class="{ 'tabs-item--active': tabName === currentTab }"
          :key="index"
        >
          <span class="tabs-item__text">
            {{ tabName }}
          </span>
        </button>
      </template>
    </div>
    <div class="exchange-connection__content">
      <template v-if="isVideoGuideTab && selectedExchangeData">
        <video-guide :url="currentVideoGuideUrl" />
      </template>
      <template v-if="activeStep === 3">
        <div class="api-keys">
          <div class="connect-exchange-list">
            <template v-for="(stepText, index) of stepsListForActiveStep">
              <div class="connect-exchange-item" :key="index">
                <div
                  class="connect-exchange-item--indexed"
                  :class="{ 'api-keys-item': index === indexWithInputData }"
                >
                  <div
                    v-if="!isVideoGuideTab"
                    class="connect-exchange-item__index"
                  >
                    {{ index + 1 }}
                  </div>
                  <div class="connect-exchange-item__text" v-html="stepText" />
                </div>
                <div
                  v-if="index === indexWithInputData"
                  class="api-keys-item__content"
                >
                  <div
                    class="add-exchange__field"
                    v-for="field in fieldsForCurrentStepFilter"
                    :key="`field-${field.name}`"
                  >
                    <component
                      :is="getFieldComponent(field.type)"
                      v-bind="getFieldProps(field)"
                      :title="exchange_stepper_field_name(field.name)"
                      :empty-placeholder="
                        exchange_stepper_field_name(field.name)
                      "
                      :v-errors="exchangeDataErrors[field.name].$errors"
                      v-model="exchangeData[field.name]"
                    ></component>
                  </div>
                </div>

                <div
                  v-if="index === indexWithCopyIp"
                  class="add-exchange__copy-field"
                >
                  <link-field @copyLink="copyIp" :link="whitelistApi" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-else-if="activeStep === 4">
        <div>
          <template
            v-if="
              selectedExchangeSlug === 'whitebit' ||
              selectedExchangeSlug === 'huobi' ||
              selectedExchangeSlug === 'exmo'
            "
          >
            <div class="connect-exchange-list">
              <template v-for="(stepText, index) of stepsListForActiveStep">
                <div class="connect-exchange-item" :key="index">
                  <div
                    class="connect-exchange-item--indexed"
                    :class="{
                      'api-keys-item':
                        index === 0 ||
                        (isVideoGuideTab ? index === 1 : index === 3),
                    }"
                  >
                    <div
                      v-if="!isVideoGuideTab"
                      class="connect-exchange-item__index"
                    >
                      {{ index + 1 }}
                    </div>
                    <div
                      class="connect-exchange-item__text"
                      v-html="stepText"
                    />
                  </div>
                  <div
                    v-if="index === 0"
                    class="api-keys-item__content api-keys-item__content--no-padding"
                  >
                    <div
                      class="add-exchange__field"
                      v-for="field in fieldsForCurrentStepFilter"
                      :key="`field-${field.name}`"
                    >
                      <component
                        :is="getFieldComponent(field.type)"
                        v-bind="getFieldProps(field)"
                        :title="exchange_stepper_field_name(field.name)"
                        :empty-placeholder="
                          exchange_stepper_field_name(field.name)
                        "
                        :v-errors="exchangeDataErrors[field.name].$errors"
                        v-model="exchangeData[field.name]"
                      />
                    </div>
                  </div>
                  <template
                    v-else-if="
                      index === indexWithCopyAddress &&
                      selectedCurrency &&
                      selectedCurrencyAddress
                    "
                  >
                    <div class="connect-exchange-tip-copy">
                      <div class="copy-wallet-title">
                        {{ selectedCurrency }} {{ networkForCurrency }}
                      </div>
                      <div class="copy-wallet-wrap">
                        <div class="copy-wallet-link">
                          {{ selectedCurrencyAddress }}
                        </div>
                        <div
                          class="copy-wallet-button"
                          @click="copyWallet(selectedCurrencyAddress)"
                        >
                          <v-popover placement="top" class="hint">
                            <span>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.89477 13.2C4.84832 13.2 4 12.1109 4 11.0876V2.85283C4 1.82954 4.84832 1 5.89477 1H12.1055C13.1519 1 14.1054 1.82954 14.1054 2.85283V11.0876C14.1054 12.1109 13.2571 13.2 12.2107 13.2H9H5.89477ZM5.10547 11.0876V3.16455C5.10547 2.59606 5.46161 2.15625 6.04297 2.15625H9.10547H11.9805C12.5618 2.15625 12.9961 2.59606 12.9961 3.16455V7V11.0876C12.9961 11.6561 12.5618 12.1177 11.9805 12.1177H6.04297C5.46161 12.1177 5.10547 11.6561 5.10547 11.0876Z"
                                  fill="#00B7BD"
                                />
                                <path
                                  d="M3.1406 12.8281C3.1406 13.4347 3.66963 14.2 4.29685 14.2H7.99997H11C11 14.2 11.3984 14.2 11.5 14.5V15C11.5 15 11.421 15.3521 11 15.3521H7.49997H4.06247C2.93347 15.3521 2.00001 14.2637 2.00001 13.1719V4C1.9995 4 2.02963 3.32227 2.45069 3.32227C2.59273 3.32227 2.66731 3.32228 2.70377 3.32229C2.70678 3.32225 2.70977 3.32226 2.71274 3.3223C2.71005 3.3223 2.70707 3.32229 2.70377 3.32229C2.70251 3.32231 2.70124 3.32233 2.69997 3.32236C2.69997 3.32236 2.76863 3.32233 2.71274 3.3223C2.70977 3.32226 2.70678 3.32225 2.70377 3.32229C2.70707 3.32229 2.71005 3.3223 2.71274 3.3223C3.0327 3.32674 3.14094 3.79172 3.1406 4V8.35205V12.8281Z"
                                  fill="#00B7BD"
                                />
                              </svg>
                            </span>
                            <template v-slot:popover class="hint">
                              <div class="hint__body">
                                <div class="hint__text">
                                  {{ $t("selected_exchange_id.walletKey") }}
                                </div>
                              </div>
                              <div class="hint__footer">
                                <a v-close-popover class="hint__close">{{
                                  $t("selected_exchange_id.close")
                                }}</a>
                              </div>
                            </template>
                          </v-popover>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
      <div v-if="isCheckboxSectionVisible" class="checkbox-wrapper">
        <CheckboxInputNew
          v-model="isChecked"
          :label="$t('connect_exchange.whitelist.address_added')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/input/TextInput";
import CustomSelect from "@/components/input/CustomSelect";
import PasswordInput from "@/components/input/PasswordInput";
import ResponsiveMixin from "@/mixins/responsive-mixin";
import VideoGuide from "@/components/profile/Exchanges/Popup/AddExchangeModal/VideoGuide";
import {
  whitelistWallets,
  whitelistApi,
  curencyNetworks,
  videoGuides,
} from "@/const/connectExchange";
import ClipboardService from "@/services/clipboard/clipboard.service";
import CheckboxInput from "@/components/input/CheckboxInput";
import CheckboxInputNew from "@/components/input/CheckboxInputNew";
import LinkField from "@/components/referral/terms/shareLink/LinkField";
import { MobileDetectService } from "@/services/mobile/mobile-detect";
import { mapGetters } from "vuex";

export default {
  name: "ExchangeConnection",
  mixins: [ResponsiveMixin],
  components: {
    CheckboxInputNew,
    CheckboxInput,
    VideoGuide,
    TextInput,
    CustomSelect,
    PasswordInput,
    LinkField,
  },
  model: {
    event: "update",
  },
  inject: ["showSuccessTip"],
  props: {
    activeStep: {
      type: Number,
      required: true,
    },
    selectedExchangeData: {
      type: Object,
      required: true,
    },
    selectedExchangeSlug: {
      type: String,
      required: true,
    },
    selectedDevice: {
      type: String,
      required: true,
    },
    fieldsForCurrentStep: {
      type: Array,
      default: () => [],
    },
    exchangeDataErrors: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isChecked: false,
      currentTab: null,
      hasMobileVersion: {
        whitebit: false,
        huobi: true,
      },
      mobileDetectService: MobileDetectService,
    };
  },
  computed: {
    ...mapGetters(["userData"]),

    tabs() {
      return this.isExmo && this.activeStep === 4
        ? this.$t("connect_exchange.tabs").slice(0, 1)
        : this.$t("connect_exchange.tabs");
    },
    exchangeData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update", value);
      },
    },
    selectedCurrency() {
      return this.exchangeData?.currency ?? null;
    },
    selectedCurrencyAddress() {
      if (this.selectedCurrency === null) return null;

      const flow = this.userData.roles.includes("pkr-user") ? "pkr" : "nbu";
      return whitelistWallets[flow][this.selectedExchangeSlug][
        this.selectedCurrency
      ];
    },
    selectedLocale() {
      return this.$i18n.locale;
    },
    isWhitebit() {
      return this.selectedExchangeSlug === "whitebit";
    },
    isHuobi() {
      return this.selectedExchangeSlug === "huobi";
    },
    isExmo() {
      return this.selectedExchangeSlug === "exmo";
    },
    currentTabIndex() {
      return this.tabs.indexOf(this.currentTab);
    },
    isVideoGuideTab() {
      return this.currentTabIndex === 1;
    },
    whitelistApi() {
      return whitelistApi[this.selectedExchangeSlug];
    },
    networkForCurrency() {
      return (
        curencyNetworks[this.selectedExchangeSlug][this.selectedCurrency] ?? ""
      );
    },
    isDifferentMobile() {
      return this.hasMobileVersion[this.selectedExchangeSlug];
    },
    fieldsForCurrentStepFilter() {
      if (this.isWhitebit || this.isHuobi || this.isExmo) {
        return this.activeStep === 3
          ? this.fieldsForCurrentStep
          : this.fieldsForCurrentStep.filter(
              (field) => field.type === "select"
            );
      }
      return this.fieldsForCurrentStep;
    },
    activeStepLocaleKey() {
      return this.activeStep === 3 ? "api_keys" : "whitelist";
    },
    stepsListForActiveStep() {
      if (
        this.selectedExchangeSlug === "whitebit" ||
        this.selectedExchangeSlug === "huobi" ||
        this.selectedExchangeSlug === "exmo"
      ) {
        if (this.activeStep === 3) {
          return this.$t(
            `connect_exchange.${this.activeStepLocaleKey}.steps${
              this.isVideoGuideTab ? ".video_guide" : ""
            }.${this.selectedExchangeSlug}${
              this.isDifferentMobile ? "." + this.selectedDevice : ""
            }`
          );
        }
        const addToWhitelistSteps = this.$t(
          `connect_exchange.${this.activeStepLocaleKey}.steps${
            this.isVideoGuideTab ? ".video_guide" : ""
          }.${this.selectedExchangeSlug}${
            this.isDifferentMobile ? "." + this.selectedDevice : ""
          }`
        );
        if (!this.selectedCurrency) {
          return [addToWhitelistSteps.at(0)];
        }
        return !this.isVideoGuideTab
          ? addToWhitelistSteps
          : [addToWhitelistSteps.at(0), addToWhitelistSteps.at(-1)];
      }
      return [];
    },
    isMobileDevice() {
      return this.selectedDevice === "mob";
    },
    indexWithInputData() {
      if (this.isWhitebit) {
        return this.isVideoGuideTab ? 0 : this.activeStep === 3 ? 2 : 1;
      }
      if (this.isHuobi) {
        if (this.isMobileDevice) {
          return this.isVideoGuideTab ? 1 : this.activeStep === 3 ? 6 : 1;
        }
        return this.isVideoGuideTab ? 1 : this.activeStep === 3 ? 4 : 1;
      }
      if (this.isExmo) {
        return this.isVideoGuideTab ? 0 : 2;
      }
      return 1;
    },
    indexWithCopyIp() {
      if (this.isHuobi) {
        if (this.isMobileDevice) {
          return this.isVideoGuideTab ? 0 : this.activeStep === 3 ? 4 : 1;
        }
        return this.isVideoGuideTab ? 0 : this.activeStep === 3 ? 2 : 1;
      }
      if (this.isExmo) {
        return this.isVideoGuideTab ? 1 : 4;
      }
      return null;
    },
    indexWithCopyAddress() {
      if (this.isWhitebit) {
        return this.isVideoGuideTab ? 1 : 3;
      }
      if (this.isHuobi) {
        if (this.isMobileDevice) {
          return this.isVideoGuideTab ? 1 : 4;
        }
        return this.isVideoGuideTab ? 1 : 2;
      }
      return null;
    },
    currentVideoGuideUrl() {
      const videoGuidesByExchange =
        videoGuides[this.selectedExchangeSlug] || {};
      if (this.isHuobi) {
        return (
          videoGuidesByExchange[this.selectedDevice][this.activeStepLocaleKey][
            this.$i18n.locale
          ] || ""
        );
      }
      return (
        videoGuides[this.selectedExchangeSlug][this.activeStepLocaleKey][
          this.$i18n.locale
        ] || ""
      );
    },
    isCheckboxSectionVisible() {
      if (!this.isExmo) {
        return this.activeStep === 4 && this.selectedCurrency;
      }
      return this.activeStep === 3;
    },
  },
  methods: {
    setActiveTab(tabName) {
      if (!this.tabs.includes(tabName) || this.currentTab === tabName) return;
      this.currentTab = tabName;
    },
    getFieldComponent(type) {
      switch (type) {
        case "text":
          return "TextInput";
        case "select":
          return "CustomSelect";
        case "password":
          return "PasswordInput";
        default:
          return "TextInput";
      }
    },
    getFieldProps(field) {
      if (field.type === "select") {
        return {
          emptyPlaceholder: field.title,
          values: field.values,
          showIcon: false,
          showSelectedTitle: true,
          showSearch: true,
        };
      }
    },
    exchange_stepper_field_name(data) {
      switch (data.toLowerCase()) {
        case "access_key":
          return this.$t(
            `exchange_stepper_fild_name.accessKey.${this.selectedExchangeSlug}`
          );
        case "secret_key":
          return this.$t(
            `exchange_stepper_fild_name.secretKey.${this.selectedExchangeSlug}`
          );
        case "title":
        case "заголовок":
          return this.$t("exchange_stepper_fild_name.title");
        case "currency":
        case "Currency":
        case "Валюта":
        case "валюта":
          return this.$t("exchange_stepper_fild_name.currency");
        default:
          return data;
      }
    },
    copyWallet(address) {
      ClipboardService.copyToClipboard(address);
    },
    async copyIp(ip) {
      (await ClipboardService.copyToClipboard(ip)) &&
        this.showSuccessTip(this.$t("selected_exchange_id.ip"));
    },
  },
  mounted() {
    this.currentTab = this.tabs[0];
  },
  watch: {
    isChecked(value) {
      this.exchangeData = {
        ...this.exchangeData,
        isAddressCopied: value,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.exchange-connection {
  max-width: 100%;

  &__tabs {
    margin-bottom: 1.5rem;
  }

  &__content {
    padding-bottom: 0.5rem;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
  width: fit-content;

  &-item {
    height: 2rem;
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      background-color: transparent;
      border-radius: 0.125rem;
      height: 0.125rem;
      width: 100%;
    }

    &--active & {
      &__text {
        font-weight: 600;
        color: $mainBlack;
      }
    }

    &--active::before {
      background-color: $mainCold;
    }

    &:not(&--active) {
      cursor: pointer;
    }

    &__text {
      font-family: "Rubik", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $gray1;
    }
  }
}

.api-keys {
  &-item {
    align-items: center;

    &__content {
      margin-top: 1.5rem;
      padding-bottom: 2.25rem;
      display: grid;
      grid-auto-flow: row;
      row-gap: 2.5rem;

      &--no-padding {
        padding-bottom: 0;
      }
    }
  }
}

.video-guide {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  iframe {
    width: 100%;
    border-radius: 1.1875rem;
  }
}

.add-exchange {
  &__field {
    &::v-deep {
      .custom-select .custom-select__selected-empty.to-top {
        display: none;
      }
    }
  }

  &__copy-field {
    padding: 6px 10px 6px 24px;
    height: 32px;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 10px;

    .link-field {
      background: #ffffff;
    }
  }
}

.checkbox-wrapper {
  margin-top: 26px;
}

@media screen and (max-width: 486px) {
  .connect-exchange-item {
    margin-bottom: 10px;
  }

  .connect-exchange-list {
    display: block;
  }
}
</style>
