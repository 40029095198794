<template>
  <div class="unex-questionnaire__wrapper">
    <div class="unex-questionnaire">
      <base-loader v-if="isDataLoading"></base-loader>
      <text-input
        class="unex-questionnaire__code-word"
        v-model="questionnaireFields.codeWord"
        :title="$t('unex.onboarding.questionnaire.code_word.title')"
        :hint="$t('unex.onboarding.questionnaire.code_word.hint')"
        :closeButton="$t('unex.onboarding.questionnaire.code_word.close')"
        :v-errors="v$.questionnaireFields.codeWord.$errors"
      ></text-input>
      <div class="unex-questionnaire__radio-groups">
        <radio-group
          class="unex-questionnaire__radio"
          :label="$t('unex.onboarding.questionnaire.is_pep')"
          name="questionnaire-pep"
          v-model="questionnaireFields.is_pep"
          :options="[
            {
              text: $t('yes'),
              value: true,
            },
            {
              text: $t('no'),
              value: false,
            },
          ]"
        ></radio-group>
        <radio-group
          class="unex-questionnaire__radio"
          :label="$t('unex.onboarding.questionnaire.is_fatca')"
          name="questionnaire-fatca"
          v-model="questionnaireFields.is_fatca"
          :options="[
            {
              text: $t('yes'),
              value: true,
            },
            {
              text: $t('no'),
              value: false,
            },
          ]"
        ></radio-group>
<!--        <radio-group-->
<!--          class="unex-questionnaire__radio"-->
<!--          :label="$t('unex.onboarding.questionnaire.is_fop')"-->
<!--          name="questionnaire-fop"-->
<!--          v-model="questionnaireFields.is_fop"-->
<!--          :options="[-->
<!--            {-->
<!--              text: $t('yes'),-->
<!--              value: true,-->
<!--            },-->
<!--            {-->
<!--              text: $t('no'),-->
<!--              value: false,-->
<!--            },-->
<!--          ]"-->
<!--        ></radio-group>-->
<!--        <radio-group-->
<!--          class="unex-questionnaire__radio"-->
<!--          :label="$t('unex.onboarding.questionnaire.equal_address')"-->
<!--          name="questionnaire-equal_address"-->
<!--          v-model="questionnaireFields.equal_address"-->
<!--          :options="[-->
<!--            {-->
<!--              text: $t('yes'),-->
<!--              value: true,-->
<!--            },-->
<!--            {-->
<!--              text: $t('no'),-->
<!--              value: false,-->
<!--            },-->
<!--          ]"-->
<!--        ></radio-group>-->
      </div>
      <div
        class="unex-questionnaire__additional-address"
        v-if="!questionnaireFields.equal_address"
      >
        <div class="unex-questionnaire__additional-address-line">
          <custom-select
            class="unex-questionnaire__additional-address-line-small"
            v-model="questionnaireFields.adr_city_kind_id"
            :values="cityKindOptions"
            :show-selected-title="true"
            :show-search="true"
            :empty-placeholder="$t('unex.onboarding.questionnaire.city_kind')"
            :v-errors="v$.questionnaireFields.adr_city_kind_id.$errors"
          ></custom-select>
          <custom-select
            class="unex-questionnaire__additional-address-line-big"
            v-model="questionnaireFields.adr_city_key"
            :values="cityOptions"
            :show-selected-title="true"
            :show-search="true"
            :empty-placeholder="$t('unex.onboarding.questionnaire.city')"
            :disabled="!questionnaireFields.adr_city_kind_id"
            :search-method="searchCity"
          ></custom-select>
        </div>
        <div class="unex-questionnaire__additional-address-line">
          <custom-select
            class="unex-questionnaire__additional-address-line-half"
            :empty-placeholder="$t('unex.onboarding.questionnaire.post_index')"
            v-model="questionnaireFields.adr_post_index_key"
            :v-errors="v$.questionnaireFields.adr_post_index.$errors"
            :show-selected-title="true"
            :show-search="true"
            :disabled="!questionnaireFields.adr_city_key"
            :values="postCodesOptions"
          ></custom-select>
        </div>
        <div class="unex-questionnaire__additional-address-line">
          <custom-select
            class="unex-questionnaire__additional-address-line-small"
            v-model="questionnaireFields.adr_street_kind_id"
            :values="streetKindOptions"
            :show-selected-title="true"
            :show-search="true"
            :empty-placeholder="$t('unex.onboarding.questionnaire.street_kind')"
            :disabled="!questionnaireFields.adr_post_index"
            :v-errors="v$.questionnaireFields.adr_street_kind_id.$errors"
          ></custom-select>
          <custom-select
            class="unex-questionnaire__additional-address-line-big"
            v-model="questionnaireFields.adr_street_id"
            :show-selected-title="true"
            :show-search="true"
            :values="streetOptions"
            :empty-placeholder="$t('unex.onboarding.questionnaire.street')"
            :disabled="!questionnaireFields.adr_street_kind_id"
            :v-errors="v$.questionnaireFields.adr_street_id.$errors"
            :search-method="searchStreet"
          ></custom-select>
        </div>
        <div class="unex-questionnaire__additional-address-line">
          <text-input
            class="unex-questionnaire__additional-address-line-half"
            v-model="questionnaireFields.adr_building"
            :title="$t('unex.onboarding.questionnaire.building')"
            :v-errors="v$.questionnaireFields.adr_building.$errors"
          ></text-input>
          <text-input
            class="unex-questionnaire__additional-address-line-half"
            v-model="questionnaireFields.adr_korp"
            :title="$t('unex.onboarding.questionnaire.korp')"
            :v-errors="v$.questionnaireFields.adr_korp.$errors"
          ></text-input>
        </div>
        <div class="unex-questionnaire__additional-address-line">
          <text-input
            class="unex-questionnaire__additional-address-line-half"
            v-model="questionnaireFields.adr_flat"
            :title="$t('unex.onboarding.questionnaire.flat')"
            :v-errors="v$.questionnaireFields.adr_flat.$errors"
          ></text-input>
        </div>
      </div>
      <div class="unex-questionnaire__job-info">
        <div class="unex-questionnaire__job-field">
          <custom-select
            class="unex-questionnaire__job-field-full"
            :empty-placeholder="
              $t('unex.onboarding.questionnaire.employment_type')
            "
            v-model="questionnaireFields.employment_type"
            :show-selected-title="true"
            :show-search="true"
            :values="employmentTypeOptions"
            :v-errors="v$.questionnaireFields.employment_type.$errors"
          ></custom-select>
        </div>
        <div class="unex-questionnaire__job-field">
          <custom-select
            class="unex-questionnaire__job-field-full"
            :empty-placeholder="
              $t('unex.onboarding.questionnaire.activity_type')
            "
            v-model="questionnaireFields.activity_type_id"
            :show-selected-title="true"
            :show-search="true"
            :values="activityTypeOptions"
            :v-errors="v$.questionnaireFields.activity_type_id.$errors"
          ></custom-select>
        </div>
        <div class="unex-questionnaire__job-field">
          <text-input
            class="unex-questionnaire__job-field-full"
            :mask="numberMask"
            :title="$t('unex.onboarding.questionnaire.monthly_income')"
            v-model="questionnaireFields.monthly_income"
            :v-errors="v$.questionnaireFields.monthly_income.$errors"
          ></text-input>
        </div>
        <!--<div class="unex-questionnaire__job-field">
          <text-input
            class="unex-questionnaire__job-field-full"
            :mask="numberMask"
            :title="$t('unex.onboarding.questionnaire.un_monthly_income')"
            v-model="questionnaireFields.un_monthly_income"
            :v-errors="v$.questionnaireFields.un_monthly_income.$errors"
          ></text-input>
        </div>-->
        <div class="unex-questionnaire__job-field">
          <custom-select
            class="unex-questionnaire__job-field-full"
            :empty-placeholder="
              $t('unex.onboarding.questionnaire.income_source')
            "
            v-model="questionnaireFields.income_src_id"
            :values="incomeSrcOptions"
            :show-selected-title="true"
            :show-search="true"
            :v-errors="v$.questionnaireFields.income_src_id.$errors"
          ></custom-select>
        </div>
        <div class="unex-questionnaire__job-field">
          <text-input
            class="unex-questionnaire__job-field-full"
            :title="$t('unex.onboarding.questionnaire.job_place')"
            v-model="questionnaireFields.job"
            :v-errors="v$.questionnaireFields.job.$errors"
          ></text-input>
        </div>
      </div>
      <div class="unex-questionnaire__agreement-field">
        <checkbox-input
          :label="$t('unex.onboarding.questionnaire.personal_data_agreement')"
          v-model="questionnaireFields.zgoda"
          :v-errors="v$.questionnaireFields.zgoda.$errors"
        ></checkbox-input>
      </div>
      <primary-button
        :disabled="!questionnaireFields.zgoda"
        :label="$t('continue')"
        @click="saveQuestionnaire"
      ></primary-button>
    </div>
  </div>
</template>

<script>
import responsiveMixin from "@/mixins/responsive-mixin";
import RadioGroup from "@/components/input/RadioGroup";
import CustomSelect from "@/components/input/CustomSelect";
import TextInput from "@/components/input/TextInput";
import { UnexOnboardingApi } from "@/office/unex-onboarding-api";
import BaseLoader from "@/components/base/BaseLoader";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import PrimaryButton from "@/components/button/PrimaryButton";
import CheckboxInput from "@/components/input/CheckboxInput";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { mapGetters, mapState } from "vuex";

const numberMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  decimalLimit: 18,
});

export default {
  name: "Questionnaire",
  components: {
    CheckboxInput,
    PrimaryButton,
    BaseLoader,
    TextInput,
    CustomSelect,
    RadioGroup,
  },
  mixins: [responsiveMixin],
  inject: ["toNextStep", "errorWSMessageHandler", "getCardProductId"],
  data() {
    return {
      isDataLoading: false,
      numberMask,
      questionnaireFields: {
        codeWord: "",
        is_pep: false,
        is_fatca: false,
        is_fop: false,
        equal_address: true,
        adr_city_kind_id: null,
        adr_city_key: null,
        adr_city_id: null,
        adr_city_name: "",
        adr_street_kind_id: null,
        adr_street_kind_name: "",
        adr_street_id: null,
        adr_street_name: "",
        adr_building: "",
        adr_korp: "",
        adr_flat: "",
        adr_post_index: "",
        adr_post_index_key: null,
        employment_type: null,
        monthly_income: "",
        monthly_income_curr_id: 3386,
        un_monthly_income: "0",
        un_monthly_income_curr_id: 3386,
        income_src_id: null,
        zgoda: false,
        job: "",
        activity_type_id: null,
        activity_type_name: "",
      },
      incomeSrcDataset: [],
      currencyDataset: [],
      cityKindDataset: [],
      employmentTypeDataset: [],
      streetKindDataset: [],
      streetDataset: [],
      cityDataset: [],
      activityTypeDataset: [],
      postCodesDataset: [],
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    let baseValidations = {
      questionnaireFields: {
        codeWord: {
          required,
        },
        zgoda: {
          required,
        },
        employment_type: {
          required,
        },
        monthly_income: {
          required: helpers.withMessage(
            this.$t("registration_wm.required"),
            required
          ),
        },
        monthly_income_curr_id: {
          required,
        },
        un_monthly_income: {
          required,
        },
        un_monthly_income_curr_id: {
          required,
        },
        income_src_id: {
          required,
        },
        job: {
          required,
        },
        activity_type_id: {
          required,
        },
      },
    };
    if (!this.questionnaireFields.equal_address) {
      baseValidations.questionnaireFields = {
        ...baseValidations.questionnaireFields,
        adr_city_kind_id: {
          required,
        },
        adr_city_id: {
          required,
        },
        adr_city_name: {
          required,
        },
        adr_street_kind_id: {
          required,
        },
        adr_street_kind_name: {
          required,
        },
        adr_street_id: {
          required,
        },
        adr_street_name: {
          required,
        },
        adr_building: {
          required,
        },
        adr_korp: {
          required,
        },
        adr_flat: {
          required,
        },
        adr_post_index: {
          required,
        },
      };
    }

    return { ...baseValidations };
  },
  created() {
    this.loadDicts();
    this.initWS();
  },
  computed: {
    ...mapState(["wss"]),
    ...mapGetters(["userData"]),
    incomeSrcOptions: function () {
      return this.incomeSrcDataset.map((incomeSrc) => ({
        id: incomeSrc.id,
        title: incomeSrc.name,
      }));
    },
    currencyOptions: function () {
      return this.currencyDataset.map((currency) => ({
        id: currency.id,
        title: currency.name,
      }));
    },
    employmentTypeOptions: function () {
      return this.employmentTypeDataset.map((employmentType) => ({
        id: employmentType.id,
        title: employmentType.name,
      }));
    },
    cityKindOptions: function () {
      return this.cityKindDataset.map((cityKind) => ({
        id: cityKind.id,
        title: cityKind.name[0].toUpperCase() + cityKind.name.slice(1),
      }));
    },
    streetKindOptions: function () {
      return this.streetKindDataset.map((streetKind) => ({
        id: streetKind.id,
        title: streetKind.name[0].toUpperCase() + streetKind.name.slice(1),
      }));
    },
    cityOptions: function () {
      return this.cityDataset.map((city) => ({
        id: city.key,
        title: `${city.name}${city.district ? ` (${city.district})` : ""}`,
      }));
    },
    streetOptions: function () {
      return this.streetDataset.map((street) => ({
        id: street.id,
        title: street.street,
      }));
    },
    activityTypeOptions: function () {
      return this.activityTypeDataset.map((aType) => ({
        id: aType.id,
        title: aType.name,
      }));
    },
    postCodesOptions: function () {
      return this.postCodesDataset.map((pCode) => ({
        id: pCode.id,
        title: pCode.post_index,
      }));
    },
  },
  watch: {
    "questionnaireFields.adr_street_kind_id": function () {
      if (!this.questionnaireFields.adr_street_kind_id) {
        this.questionnaireFields.adr_street_kind_name = "";
        return;
      }

      this.questionnaireFields.adr_street_kind_name =
        this.streetKindDataset.find(
          (sK) => sK.id === this.questionnaireFields.adr_street_kind_id
        ).name;
    },
    "questionnaireFields.activity_type_id": function () {
      if (!this.questionnaireFields.activity_type_id) {
        this.questionnaireFields.activity_type_name = "";
        return;
      }

      this.questionnaireFields.activity_type_name =
          this.activityTypeDataset.find(
              (at) =>
                  parseInt(at.id) ===
                  parseInt(this.questionnaireFields.activity_type_id)
          ).name;
    },
    "questionnaireFields.adr_street_id": function () {
      if (!this.questionnaireFields.adr_street_id) {
        this.questionnaireFields.adr_street_name = "";
        return;
      }

      this.questionnaireFields.adr_street_name = this.streetDataset.find(
        (sK) => sK.id === this.questionnaireFields.adr_street_id
      ).street;
    },
    "questionnaireFields.adr_city_key": function () {
      if (!this.questionnaireFields.adr_city_key) {
        this.questionnaireFields.adr_city_name = "";
        return;
      }

      let cityObject = this.cityDataset.find(
        (sK) => sK.key === this.questionnaireFields.adr_city_key
      );

      this.questionnaireFields.adr_city_name = cityObject.name;
      this.postCodesDataset = cityObject.post_index;
    },
    "questionnaireFields.adr_post_index_key": function () {
      if (!this.questionnaireFields.adr_post_index_key) {
        this.questionnaireFields.adr_city_id = null;
        return;
      }

      let postCodeObject = this.postCodesDataset.find(
        (pC) => pC.id === this.questionnaireFields.adr_post_index_key
      );

      this.questionnaireFields.adr_city_id = postCodeObject.unex_city_id;
      this.questionnaireFields.adr_post_index = postCodeObject.post_index;
    },
  },
  methods: {
    async initWS() {
      this.wssQuestionnaireChannel = this.wss.subscribe(
        `questionnaire-${this.userData.hashed_id}`,
        async (msg) => {
          let isErrorHandled = await this.errorWSMessageHandler(msg);

          if (isErrorHandled) {
            return;
          }

          console.log("questionnaire channel", msg);
          this.handleChannelMessage(msg);
        }
      );
    },
    handleChannelMessage(msg) {
      if (msg.data.success === true) {
        this.toNextStep();
      }
    },
    async loadDicts() {
      this.isDataLoading = true;
      try {
        let dictsResponse = await UnexOnboardingApi.getQuestionnaireDicts();

        this.incomeSrcDataset =
          dictsResponse.data.CFVW_FM_CUST_INCOME_SRC_TYPE || [];
        this.currencyDataset = dictsResponse.data.CFVW_CURRENCY || [];
        this.cityKindDataset = dictsResponse.data.CFVW_CITY_KIND || [];
        this.employmentTypeDataset = dictsResponse.data.sr_UN_CREDIT_WORK || [];
        this.streetKindDataset = dictsResponse.data.CFVW_STREET_KIND || [];
        this.activityTypeDataset = dictsResponse.data.CFVW_BRANCHES || [];
        console.info(dictsResponse.data);
      } catch (e) {
        console.log("get questionnaire dicts error", e);
      } finally {
        this.isDataLoading = false;
      }
    },
    async saveQuestionnaire() {
      let _validationResult = await this.v$.$validate();

      if (!_validationResult) {
        return false;
      }

      try {
        this.isDataLoading = true;

        this.questionnaireFields.activity_type_id = parseInt(
            this.questionnaireFields.activity_type_id
        );

        await UnexOnboardingApi.sendQuestionnaire(
          this.questionnaireFields,
          this.getCardProductId()
        );
      } catch (e) {
        this.isDataLoading = false;
        console.log("send questionnaire error", e);
      }
    },
    async searchCity(name) {
      if (name.length >= 3) {
        try {
          let cityResponse = await UnexOnboardingApi.findInCityDict(
            this.questionnaireFields.adr_city_kind_id,
            name
          );
          this.cityDataset = cityResponse.data;
        } catch (e) {
          console.log("error getting cities", e);
        }
      }
    },
    async searchStreet(name) {
      if (name.length >= 3) {
        try {
          let streetResponse = await UnexOnboardingApi.findInStreetDict(
            this.questionnaireFields.adr_city_kind_id,
            this.questionnaireFields.adr_city_name,
            this.questionnaireFields.adr_street_kind_id,
            name
          );

          console.log("streetResponse", streetResponse.data);
          this.streetDataset = streetResponse.data;
        } catch (e) {
          console.log("error getting cities", e);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-questionnaire {
  position: relative;
  width: 100%;
  max-width: 530px;
  background: #ffffff;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  border-radius: 24px;
  padding: 48px;
}

@media screen and (max-width: 786px) {
  .unex-questionnaire {
    box-shadow: unset;
    padding: 20px 0;
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-questionnaire {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  &__hints {
    background: $grayLight;
    border-radius: 8px;
    padding: 24px;
  }

  &__hint {
    display: flex;
    align-items: flex-start;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;

    b {
      color: $mainBlack;
      font-weight: 700;
    }

    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $gray1;
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  &__code-word {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  &__radio {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__radio-groups {
    margin-bottom: 54px;
  }

  &__additional-address {
    margin-bottom: 80px;

    &-line {
      display: flex;
      align-items: flex-end;
      margin-bottom: 54px;

      &:last-child {
        margin-bottom: 0;
      }

      &-small {
        width: 136px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 16px;
      }

      &-big {
        flex-grow: 1;
        flex-shrink: 1;
      }

      &-half {
        width: calc(50% - 8px);

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  &__job {
    &-info {
      margin-bottom: 84px;
    }

    &-field {
      margin-bottom: 67px;

      &:last-child {
        margin-bottom: 0;
      }

      &-line {
        margin-bottom: 67px;
        display: flex;
        align-items: flex-end;

        &-small {
          width: 112px;
          margin-left: 16px;
          flex-shrink: 0;
          flex-grow: 0;
        }

        &-big {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
    }
  }

  &__agreement-field {
    margin-bottom: 25px;
  }
}
</style>
