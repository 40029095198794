<template>
  <div class="card-transaction">
    <div class="card-transaction-date">{{ viewDate(date) }}</div>
    <div
      class="card-transaction__item"
      v-for="item in transactions"
      :key="`transaction-${item.id}`"
    >
      <CardTransactionItem
        v-if="item.unex_status !== 'D'"
        :item="item"
      ></CardTransactionItem>
      <CardTransactionItemUnsuccessfully
        :item="item"
        v-else-if="item.unex_status === 'D'"
      ></CardTransactionItemUnsuccessfully>
    </div>
  </div>
</template>

<script>
import CardTransactionItem from "@/components/profile/Cards/Transactions/CardTransactionItem";
import CardTransactionItemUnsuccessfully from "@/components/profile/Cards/Transactions/CardTransactionItemUnsuccessfully";

export default {
  name: "CardTransactionDate",
  components: { CardTransactionItemUnsuccessfully, CardTransactionItem },
  props: {
    transactions: {
      type: Array,
      default() {
        return [];
      },
    },
    date: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    viewDate(date) {
      const tmp = new Date(date);
      let str = `${this.isToday(tmp)} ${tmp.getDate()} ${this.strMonth(
        tmp.getMonth()
      )}`;
      return str;
    },

    strMonth(date) {
      switch (date) {
        case 0:
          return this.$t("card-transactions.month[0]");
        case 1:
          return this.$t("card-transactions.month[1]");
        case 2:
          return this.$t("card-transactions.month[2]");
        case 3:
          return this.$t("card-transactions.month[3]");
        case 4:
          return this.$t("card-transactions.month[4]");
        case 5:
          return this.$t("card-transactions.month[5]");
        case 6:
          return this.$t("card-transactions.month[6]");
        case 7:
          return this.$t("card-transactions.month[7]");
        case 8:
          return this.$t("card-transactions.month[8]");
        case 9:
          return this.$t("card-transactions.month[9]");
        case 10:
          return this.$t("card-transactions.month[10]");
        case 11:
          return this.$t("card-transactions.month[11]");
        default:
          return "";
      }
    },

    isToday(date) {
      const today = new Date();
      if (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
      )
        return `${this.$t("card-transactions.filter.date.temporary")}, `;
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.card-transaction {
  background: #ffffff;

  &-date {
    padding: 10px 32px;
    margin-bottom: 24px;

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #7a939c;
  }
}

@media screen and (max-width: 486px) {
  .card-transaction {
    &-date {
      font-size: pxToRem(16);
      line-height: pxToRem(24);
    }
  }
}
</style>
