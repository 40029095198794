export const GA_EVENTS = {
  SIGN_IN_NO_2FA_START: "signInNo2FaStart",
  SIGN_IN_2FA_START: "signIn2FaStart",
  SIGN_IN_NO_2FA: "signInNo2Fa",
  SIGN_IN_2FA: "signInn2Fa",
  SIGN_UP_START: "signUpStart",
  SIGN_UP: "signUp",
  SIGN_UP_OTP_START: "signUpOtpStart",
  SIGN_UP_OTP: "signUpOtp",
  SIGN_UP_SET_PASSWORD_START: "signUpSetPasswordStart",
  SIGN_UP_SET_PASSWORD: "signUpSetPassword",
  ADD_EXCHANGE: "add-exchange",
};
