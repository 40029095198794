<template>
  <div class="story" v-if="story && story.tasks">
    <base-loader v-if="isDataLoading"></base-loader>
    <div class="story__tasks">
      <div
        class="story-task"
        :class="{ 'story-task--done': task.is_done }"
        v-for="task in story.tasks"
        :key="`task-${task.id}`"
        @click="handleTaskAction(task)"
      >
        <div
          class="story-task__icon"
          v-if="!task.is_done"
          v-html="task.svg"
        ></div>
        <div class="story-task__icon" v-else>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.9987 3.33203C10.7987 3.33203 3.33203 10.7987 3.33203 19.9987C3.33203 29.1987 10.7987 36.6654 19.9987 36.6654C29.1987 36.6654 36.6654 29.1987 36.6654 19.9987C36.6654 10.7987 29.1987 3.33203 19.9987 3.33203Z"
              fill="white"
            />
            <path
              d="M19.9987 2.83203C10.5226 2.83203 2.83203 10.5226 2.83203 19.9987H3.83203C3.83203 11.0748 11.0748 3.83203 19.9987 3.83203V2.83203ZM2.83203 19.9987C2.83203 29.4748 10.5226 37.1654 19.9987 37.1654V36.1654C11.0748 36.1654 3.83203 28.9226 3.83203 19.9987H2.83203ZM19.9987 37.1654C29.4748 37.1654 37.1654 29.4748 37.1654 19.9987H36.1654C36.1654 28.9226 28.9226 36.1654 19.9987 36.1654V37.1654ZM37.1654 19.9987C37.1654 10.5226 29.4748 2.83203 19.9987 2.83203V3.83203C28.9226 3.83203 36.1654 11.0748 36.1654 19.9987H37.1654Z"
              fill="#DDE5ED"
            />
            <path
              d="M12.9141 19.9979L17.6307 24.7146L27.0807 15.2812"
              stroke="#00B7BD"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="story-task__title">
          {{ task.title ? task.title[selectedLocale] : "" }}
        </div>
        <div class="story-task__description">
          {{ task.description ? task.description[selectedLocale] : "" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TasksApi } from "@/office/tasks-api";
import { mapState } from "vuex";
import BaseLoader from "@/components/base/BaseLoader";
import { RbacService } from "@/services/user/rbac.service";

export default {
  name: "Story",
  components: { BaseLoader },
  data() {
    return {
      isDataLoading: false,
      story: null,
    };
  },
  created() {
    this.loadStory();
  },
  computed: {
    ...mapState(["selectedLocale"]),
  },
  methods: {
    loadStory() {
      this.isDataLoading = true;
      TasksApi.getActiveStory().then((r) => {
        this.story = r.data.story;
        this.isDataLoading = false;
      });
    },
    async handleTaskAction(task) {
      if (task.is_done) {
        return;
      }

      if (
        task.slug === "add_first_exchange" &&
        RbacService.userCan("wallets")
      ) {
        await this.$router.push({
          path: "/wallets",
          query: {
            add_wallet: true,
          },
        });

        return;
      }

      if (task.slug === "staking") {
        await this.$router.push({
          path: "/token/staking",
          query: {
            start_staking: true,
          },
        });
      }

      if (task.slug === "add_weld_card") {
        await this.$router.push({
          path: "/cards",
          query: {
            start_add_card: true,
          },
        });
      }
    },
    updateStory() {
      this.isDataLoading = true;
      TasksApi.getStoryById(this.story.id).then((r) => {
        this.story = r.data.story;
        this.isDataLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.story {
  position: relative;
  padding: 32px 32px 0;

  &__tasks {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }

  &-task {
    position: relative;
    width: calc(33.33333% - 15px);
    padding: 36px;
    border: 1px solid rgba($mainCold, 0.9);
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--done {
      border-color: $gray3;
      cursor: default;
    }

    &--done & {
      &__icon {
      }

      &__title {
        color: $gray1;
      }
    }

    &__icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      border-radius: 50%;

      svg,
      img {
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      font-weight: 700;
      font-size: pxToRem(20);
      line-height: pxToRem(24);
      color: $mainCold;
      text-align: center;
    }

    &__description {
      font-weight: 500;
      font-size: pxToRem(16);
      line-height: pxToRem(24);
      color: $gray1;
      margin-top: 4px;
      display: none;
    }
  }
}

@media screen and (max-width: 486px) {
  .story {
    padding: 0 0 0 20px;

    &__tasks {
      flex-direction: column;
    }

    &-task {
      width: 100%;
      padding: 24px 24px 24px 56px;

      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &__icon {
        left: 0;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
}
</style>
