<template>
  <div
    class="share-modal popup"
    :class="{ 'share-modal--full-height': fullHeight }"
  >
    <div class="share-modal__container popup__block">
      <div class="share-modal__header modal-header">
        <div class="modal-header__title popup__title">
          <span>{{ modalTitle }}</span>
        </div>
        <popup-close-button @click="closeModal" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import PopupCloseButton from "@/components/button/PopupCloseButton";

export default {
  name: "ShareModal",
  components: {
    PopupCloseButton,
  },

  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
