<template>
  <div class="add-exchange__stepper">
    <modal-stepper-item
      :steps-count="stepsCount"
      :active-step="activeStep"
      :step-index="stepIndex"
      v-for="stepIndex in stepsCount"
      :key="`step-${stepIndex}`"
    />
  </div>
</template>

<script>
import ModalStepperItem from "@/components/profile/Exchanges/Popup/AddExchangeModal/Stepper/ModalStepperItem";
export default {
  name: "ModalStepper",
  components: { ModalStepperItem },
  props: {
    stepsCount: {
      type: Number,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

.add-exchange__stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    background-color: $dividersGray;
    height: 0.125rem;
    z-index: -1;
  }
}

@media screen and (max-width: 486px) {
  .add-exchange__stepper {
    margin-left: -0.25rem;
  }
}
</style>
