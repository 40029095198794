<template>
  <div class="popup">
    <div class="popup__block">
      <div class="p-details">
        <div class="p-details__full">
          <div class="p-details__section">
            <div class="p-details__section-header">
              <div class="popup__title">{{ $t("crop_avatar") }}</div>
              <popup-close-button @click="closePopup"></popup-close-button>
            </div>
            <div class="p-details__section-body">
              <div class="p-details__field">
                <Cropper
                  ref="cropper"
                  class="upload-example-cropper"
                  :src="avatar"
                  :stencil-component="$options.components.AvatarCropper"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup__actions">
        <no-bg-button
          class="popup__action"
          :label="$t('cancel')"
          @click="closePopup"
        ></no-bg-button>
        <primary-button
          class="popup__action"
          :label="$t('save')"
          @click="cropImage"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import NoBgButton from "@/components/button/NoBgButton";
import PrimaryButton from "@/components/button/PrimaryButton";
import PopupCloseButton from "@/components/button/PopupCloseButton";
import useVuelidate from "@vuelidate/core";
import { Cropper } from "vue-advanced-cropper";
import AvatarCropper from "@/components/base/AvatarCropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "EditPasswordPopup",
  components: {
    PopupCloseButton,
    PrimaryButton,
    NoBgButton,
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    AvatarCropper,
  },
  props: ["avatar", "coordinates", "transitions", "stencilCoordinates"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {};
  },

  created() {},
  watch: {},
  computed: {
    style() {
      const { height, width, left, top } = this.stencilCoordinates;
      const style = {
        width: `${width}px`,
        height: `${height}px`,
        transform: `translate(${left}px, ${top}px)`,
      };
      if (this.transitions && this.transitions.enabled) {
        style.transition = `${this.transitions.time}ms ${this.transitions.timingFunction}`;
      }
      return style;
    },
  },
  methods: {
    closePopup() {
      this.$emit("closed");
    },

    cropImage() {
      const result = this.$refs.cropper.getResult();
      this.$emit("crop", result.canvas.toDataURL("image/jpeg"));
      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(51, 63, 72, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  &__close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s linear;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .p-details__full {
    padding-bottom: 20px;
    padding-left: 0;
    border-left: 0;
  }

  .upload-example-cropper {
    border: solid 1px #eee;
    min-height: 300px;
    width: 100%;
  }
}
</style>
