<template>
  <component :is="layout">
    <router-view />
    <div class="success-tip" v-if="isShowSuccessTip">
      <div class="success-tip__icon">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.75 12C2.75 6.61479 7.11479 2.25 12.5 2.25C17.8852 2.25 22.25 6.61479 22.25 12C22.25 17.3852 17.8852 21.75 12.5 21.75C7.11479 21.75 2.75 17.3852 2.75 12ZM17.0303 10.5303C17.3232 10.2374 17.3232 9.76256 17.0303 9.46967C16.7374 9.17678 16.2626 9.17678 15.9697 9.46967L11.5 13.9393L9.03033 11.4697C8.73744 11.1768 8.26256 11.1768 7.96967 11.4697C7.67678 11.7626 7.67678 12.2374 7.96967 12.5303L10.9697 15.5303C11.2626 15.8232 11.7374 15.8232 12.0303 15.5303L17.0303 10.5303Z"
            fill="#00B7BD"
          />
        </svg>
      </div>
      <div class="success-tip__text">{{ tipText }}</div>
    </div>
    <div class="error-tip" v-if="isShowErrorTip">
      <div class="error-tip__icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_10216_160134)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75C6.61479 21.75 2.25 17.3852 2.25 12ZM15.3603 9.70037C15.6531 9.40748 15.6531 8.93261 15.3603 8.63971C15.0674 8.34682 14.5925 8.34682 14.2996 8.63971L11.9999 10.9394L9.70025 8.63971C9.40736 8.34682 8.93249 8.34682 8.63959 8.63971C8.3467 8.93261 8.3467 9.40748 8.63959 9.70037L10.9393 12L8.63959 14.2997C8.3467 14.5926 8.3467 15.0675 8.63959 15.3604C8.93249 15.6533 9.40736 15.6533 9.70025 15.3604L11.9999 13.0607L14.2996 15.3604C14.5925 15.6533 15.0674 15.6533 15.3603 15.3604C15.6531 15.0675 15.6531 14.5926 15.3603 14.2997L13.0606 12L15.3603 9.70037Z"
              fill="#EB6FBD"
            />
          </g>
          <defs>
            <clipPath id="clip0_10216_160134">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="error-tip__text">{{ tipText }}</div>
    </div>

    <!--    <support-button />-->
  </component>
</template>

<script>
import AuthLayout from "@/views/layouts/AuthLayout";
import MainLayout from "@/views/layouts/MainLayout";
import OnboardingLayout from "@/views/layouts/OnboardingLayout";
import MobileAppLaunchLayout from "@/views/layouts/MobileAppLaunchLayout.vue";
import responsiveMixin from "@/mixins/responsive-mixin";
// import SupportButton from "@/components/base/SupportButton";
import { mapGetters } from "vuex";
import PixelEventsService from "@/services/pixelEventsService";

export default {
  name: "App",
  components: {
    AuthLayout,
    MainLayout,
    OnboardingLayout,
    MobileAppLaunchLayout,
    // SupportButton
  },
  mixins: [responsiveMixin],
  provide() {
    return {
      showSuccessTip: this.showSuccessTip,
      showErrorTip: this.showErrorTip,
    };
  },
  metaInfo: {
    title: "Home",
    titleTemplate: "%s | Weld.Money",
  },
  computed: {
    ...mapGetters(["isUserLoggedIn"]),

    layout() {
      console.log("layout", this.$route.meta.layout);
      return this.$route.meta.layout || "main-layout";
    },
  },
  data() {
    return {
      tipText: "",
      isShowSuccessTip: false,
      isShowErrorTip: false,
    };
  },
  methods: {
    showSuccessTip(tipText, tipDuration = 3000) {
      this.tipText = tipText;
      this.isShowSuccessTip = true;

      setTimeout(() => {
        this.closeSuccessTip();
      }, tipDuration);
    },
    closeSuccessTip() {
      this.isShowSuccessTip = false;
      this.tipText = "";
    },
    showErrorTip(tipText, tipDuration = 3000) {
      this.tipText = tipText;
      this.isShowErrorTip = true;

      setTimeout(() => {
        this.closeErrorTip();
      }, tipDuration);
    },
    closeErrorTip() {
      this.isShowErrorTip = false;
      this.tipText = "";
    },
  },
  watch: {
    isUserLoggedIn: {
      immediate: true,
      handler(value) {
        value && PixelEventsService.saveUserData();
      },
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/base";

#app {
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>
