var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onboarding"},[_c('div',{staticClass:"onboarding__header"},[_c('onboarding-stepper',{attrs:{"active-step":2}}),_c('div',{staticClass:"onboarding__step-title"},[_vm._v("Enter an actual address")])],1),_c('div',{staticClass:"onboarding__address",style:({
      //0px 0px 62px rgba(183, 201, 211, 0.5);
      boxShadow:
        _vm.scrollTop > 10
          ? 'inset 0 62px 62px -62px rgba(183, 201, 211, 0.5)'
          : 'none',
    }),on:{"scroll":function($event){return _vm.handleScroll($event)}}},[_c('div',{staticClass:"onboarding__address-fields"},[_c('div',{staticClass:"onboarding__address-field"},[_c('text-input',{attrs:{"title":"Country"}})],1),_c('div',{staticClass:"onboarding__address-field"},[_c('text-input',{attrs:{"title":"ZIP Code"}})],1),_c('div',{staticClass:"onboarding__address-field"},[_c('text-input',{attrs:{"title":"Region"}})],1),_c('div',{staticClass:"onboarding__address-field"},[_c('text-input',{attrs:{"title":"District"}})],1),_c('div',{staticClass:"onboarding__address-field"},[_c('text-input',{attrs:{"title":"Settlement"}})],1),_c('div',{staticClass:"onboarding__address-field"},[_c('text-input',{attrs:{"title":"Street"}})],1),_c('div',{staticClass:"onboarding__address-field onboarding__address-field--row"},[_c('text-input',{attrs:{"title":"Building"}}),_c('text-input',{attrs:{"title":"Houseroom"}})],1)]),_c('div',{staticClass:"onboarding__address-actions"},[(!_vm.isMobile)?_c('no-bg-button',{staticClass:"onboarding__address-action",attrs:{"label":"Back","color":"dark"},on:{"click":_vm.toPrevStep}}):_c('floating-back-button',{on:{"click":_vm.toPrevStep}}),_c('primary-button',{staticClass:"onboarding__address-action onboarding__address-action--next",attrs:{"label":"Next step"},on:{"click":_vm.toNextStep}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }