<template>
  <div class="login-form__wrapper">
    <div class="login-form">
      <template v-if="!isReferralRegistration">
        <div class="login-form__heading">{{ $t("registration") }}</div>
        <div class="login-form__title">{{ $t("welcome_to_weld") }}</div>
      </template>
      <template v-else>
        <div class="login-form__referral-title">
          {{ $t("referral_registration.invite.title") }}
        </div>
      </template>
      <div
        class="login-form__sub-title"
        :class="{ 'login-form__sub-title--referral': isReferralRegistration }"
      >
        {{ loginFormSubtitle }}
      </div>
      <referral-registration-details v-if="isReferralRegistration" />
      <div class="login-form__field">
        <phone-input
          v-model="phone"
          :v-errors="v$.phone.$errors"
          initial-country="UA"
          initial-country-code="+380"
          @canFocus="focusPhoneInput"
          @enter="handleRegistration"
          @countryCodeUpdated="setCountryCode"
          @countryUpdated="setCountry"
          @originalPhoneUpdate="setOriginalPhone"
        ></phone-input>
      </div>
      <div class="login-form__field">
        <email-input
          v-model="email"
          :v-errors="v$.email.$errors"
          @enter="handleRegistration"
          @tab="openCountryList"
        ></email-input>
      </div>
      <div class="login-form__field">
        <custom-select
          v-model="selectedCountry"
          :values="countries"
          :empty-placeholder="$t('country.select')"
          :show-icon="true"
          :show-selected-title="true"
          :show-search="true"
          :v-errors="v$.selectedCountryCode.$errors"
          :title="$t('country_select')"
          ref="countryList"
        ></custom-select>
      </div>
      <div class="terms-agreed">
        {{ $t("by_clicking_continue_you_agree") }}
        <div
          class="terms-agreed__info"
          @click="showTermsAndConditionsPopup = true"
        >
          {{ $t("terms_and_cond_sign_up") }}
        </div>
      </div>
      <div class="login-form__button">
        <primary-button
          :disabled="isContinueButtonDisabled"
          :label="loginBtnLabel"
          @click="handleRegistration"
        ></primary-button>
      </div>
      <div class="login-form__sign-up">
        <div class="login-form__sign-up-text">
          {{ $t("already_have_account") }}
        </div>
        <router-link :to="loginLink" class="login-form__sign-up-button">
          {{ $t("sign_in") }}
        </router-link>
      </div>
    </div>
    <terms-popup
      v-if="showTermsAndConditionsPopup"
      :should-agreed="true"
      @closed="showTermsAndConditionsPopup = false"
      @agreed="storeUserAgreed"
    ></terms-popup>
  </div>
</template>

<script>
import PhoneInput from "@/components/input/Phone/PhoneInput";
import PrimaryButton from "@/components/button/PrimaryButton";
import ReferralRegistrationDetails from "@/components/sign-up/ReferralRegistrationDetails";
import AuthApi from "@/office/auth-api";
import {
  SET_AGREEMENT,
  SET_PARENT_REFERRAL_UUID,
  SET_SUPPORT_LINK,
  SET_USER_COUNTRY,
  SET_USER_COUNTRY_CODE,
  SET_USER_EMAIL,
  SET_USER_ORIGINAL_PHONE,
  SET_USER_PASSWORD,
  SET_USER_PHONE,
} from "@/store/const/actions-types";

import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  requiredIf,
  maxLength,
  email,
} from "@vuelidate/validators";
import EmailInput from "@/components/input/EmailInput";
import { GA_EVENTS } from "@/const/ga.events";
import { GtagServiceHelper } from "@/services/gtag/gtag.service";
import CustomSelect from "@/components/input/CustomSelect";
import { COUNTRIES } from "@/const/countries";
import { mapGetters } from "vuex";
import { QueryParserService } from "@/services/http/query-parser.service";
import { withI18nMessage } from "@/utils";
import store from "@/store";

export default {
  name: "Registration",
  components: {
    ReferralRegistrationDetails,
    TermsPopup: () => import("@/components/sign-up/Popup/TermsPopup"),
    CustomSelect,
    EmailInput,
    PrimaryButton,
    PhoneInput,
  },
  inject: ["showErrorTip"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  metaInfo() {
    return {
      title: this.$t("registration"),
    };
  },
  data() {
    return {
      phone: "",
      email: "",
      selectedCountryCode: null,
      selectedCountry: null,
      originalPhone: "",
      showTermsAndConditionsPopup: false,
      currentTermsAndCondVersion: "1.0",
      agreementParagraphs: [],
      utm: {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_term: null,
        utm_content: null,
      },
    };
  },
  validations() {
    return {
      phone: {
        required: withI18nMessage(required, {
          messagePath: () => "registration_wm.required",
        }),
        minLength: withI18nMessage(minLength(15), {
          messagePath: () => "registration_wm.phoneMin",
        }),
        maxLength: withI18nMessage(maxLength(20), {
          messagePath: () => "registration_wm.phoneMax",
        }),
      },
      email: {
        required: withI18nMessage(required, {
          messagePath: () => "registration_wm.required",
        }),
        email: withI18nMessage(email, {
          messagePath: () => "registration_wm.email",
        }),
      },
      otpCode: {
        requiredIf: requiredIf(this.isCodeSent),
        minValue: minLength(4),
        maxLength: maxLength(6),
      },
      selectedCountryCode: {
        required: withI18nMessage(required, {
          messagePath: () => "registration_wm.required",
        }),
      },
      agreementParagraphs: {},
    };
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, "reg_form");

    if (this.$route.query.r) {
      this.$store.dispatch(SET_PARENT_REFERRAL_UUID, this.$route.query.r);
    }

    this.utm = { ...QueryParserService.getUTMTagsFromQuery(this.$route.query) };

    console.log("this.utm", this.utm);
  },
  computed: {
    ...mapGetters(["parentReferralUUID"]),
    loginBtnLabel() {
      return this.isCodeSent ? this.$t("sign_up") : this.$t("continue");
    },
    countries: function () {
      return COUNTRIES.map((_country) => {
        return {
          id: _country.isoCode,
          title: _country.nameLocale[this.$i18n.locale] || _country.name,
          image: _country.flag,
        };
      });
    },
    isReferralRegistration() {
      return !!this.parentReferralUUID;
    },

    loginFormSubtitle() {
      return this.isReferralRegistration
        ? this.$t("referral_registration.invite.description")
        : this.$t("enter_your_phone_email");
    },
    isContinueButtonDisabled() {
      return !this.phone || !this.email || !this.selectedCountryCode;
    },
    loginLink() {
      let _utmFiltered = Object.fromEntries(
        Object.entries(this.utm).filter(([, v]) => v != null)
      );
      if (Object.keys(_utmFiltered).length > 0) {
        let _queryParams = new URLSearchParams(_utmFiltered);
        return "/login?" + _queryParams.toString();
      } else {
        return "/login";
      }
    },
  },
  methods: {
    openCountryList() {
      this.$refs.countryList.openList();
    },
    focusPhoneInput(_phoneInputInstance) {
      _phoneInputInstance.focus();
    },
    setCountryCode(countryCode) {
      this.selectedCountryCode = countryCode;
    },
    setCountry(country) {
      this.selectedCountry = country;
    },
    setOriginalPhone(phone) {
      this.originalPhone = phone;
    },
    async handleRegistration() {
      const result = await this.v$.$validate();

      if (!result) {
        return;
      }

      if (this.agreementParagraphs.length === 0) {
        this.showTermsAndConditionsPopup = true;
        // this.showErrorTip(this.$t("tip.should_accept_terms"));
        return;
      }

      this.analytics();
      this.registerUser();
    },
    storeUserAgreed(agreedData) {
      this.agreementParagraphs = agreedData.paragraphs;
      this.$store.dispatch(SET_AGREEMENT, agreedData);
    },
    handleCheckCountreTelephone() {
      if (this.phone.indexOf("+380") !== -1) {
        this.showErrorTip(this.$t("registration_wm.errorTip"));
      } else {
        this.showErrorTip(this.$t("registration_wm.errorTip_notUA"));
      }
    },
    registerUser() {
      GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_UP_START);
      AuthApi.register(
        this.phone,
        this.originalPhone,
        this.email,
        this.password,
        this.selectedCountryCode,
        this.selectedCountry,
        this.currentTermsAndCondVersion,
        this.parentReferralUUID,
        this.utm
      )
        .then(async () => {
          GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_UP);
          await this.$store.dispatch(SET_USER_PHONE, this.phone);
          await this.$store.dispatch(
            SET_USER_ORIGINAL_PHONE,
            this.originalPhone
          );
          await this.$store.dispatch(SET_USER_EMAIL, this.email);
          await this.$store.dispatch(SET_USER_PASSWORD, this.password);
          await this.$store.dispatch(
            SET_USER_COUNTRY_CODE,
            this.selectedCountryCode
          );
          await this.$store.dispatch(SET_USER_COUNTRY, this.selectedCountry);

          this.$router.push("/sign-up/otp");
        })
        .catch((err) => {
          //this.showErrorTip(`${this.phone} ${this.$t("registration_wm.errorTip")}`);
          this.handleCheckCountreTelephone();
          console.log(err);
        });
    },
    analytics() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "Step2_TelMail" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.password-info {
  margin-top: -28px;

  &__title {
    font-size: pxToRem(12);
    line-height: pxToRem(18);
    color: $mainBlack;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__item {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active & {
      &-icon {
        svg {
          path {
            fill: $mainCold;
          }
        }
      }

      &-text {
        color: $mainCold;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;

      svg,
      img {
        width: 100%;
        height: 100%;
      }

      svg {
        path {
          fill: $gray2;
        }
      }
    }

    &-text {
      font-size: pxToRem(12);
      line-height: pxToRem(18);
      color: $gray2;
    }
  }
}
.terms-agreed,
.terms-agreed__info {
  font-family: "Nunito", "Rubik", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}

.terms-agreed {
  margin-top: 40px;
  color: $gray1;
  text-align: center;
  font-weight: 400;

  &__info {
    display: inline;
    color: $mainCold;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 486px) {
  .login-form {
    width: 100%;
    padding: 0;
    box-shadow: none;

    &-wrapper {
      width: 100%;
    }
  }
}
</style>
