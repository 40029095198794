import axiosInstance from "@/services/http/axios-instance";
//import { ResponseMockerService } from "@/services/mocker/response-mocker.service";

class _CardApi {
  getCardList() {
    return axiosInstance.get("/user/cards");
    // return ResponseMockerService.mock({
    //   cards: [
    //     {
    //       id: 73,
    //       pan: "5375524700000032",
    //       iban: "UA853225390000026205113144004",
    //       exp_m: "05",
    //       exp_y: "27",
    //       title: null,
    //       status: "pending",
    //       balance: 0,
    //       user_id: 1685,
    //       currency: "UAH",
    //       card_type: {
    //         id: 6,
    //         svg: "weld-card.png",
    //         name: "WELD MONEY CARD",
    //         slug: "weld-card",
    //         background_image: "weld-card.svg",
    //       },
    //       custom_logo: null,
    //       credit_limit: null,
    //       unex_balance: 100,
    //     },
    //   ],
    // });
  }

  createCard(cardPan, cardTitle) {
    return axiosInstance.post("/user/cards/create", {
      pan: cardPan,
      title: cardTitle,
    });
  }

  topUp(cardId, exchangeId, amount, currency) {
    return axiosInstance.post("/user/cards/top-up", {
      card_id: cardId,
      exchange_id: exchangeId,
      sum: amount,
      currency,
    });
  }

  getCardProducts() {
    return axiosInstance.get("/cards/products");
    // return ResponseMockerService.mock({ products: [] });
  }

  getCardProductBySlug(slug) {
    return axiosInstance.get(`/cards/products/${slug}`);
  }

  getCardProductSteps(cardProductId) {
    return axiosInstance.post("/cards/products/steps", {
      card_type_id: cardProductId,
    });
  }

  getCardProductsRates() {
    return axiosInstance.get("/cards/products/rates", {});
  }

  sendCvvOtp(card_uuid) {
    return axiosInstance.post("/cards/cvv/otp", {
      card_uuid,
    });
  }

  getCvv(verificationId, otp) {
    return axiosInstance.post("/cards/cvv/otp/verify", {
      verification_id: verificationId,
      otp,
    });
  }

  getCardTransactions(card_uuid, filters) {
    return axiosInstance.post("/cards/transactions", {
      card_uuid,
      filters,
    });
  }
}
export const CardApi = new _CardApi();
