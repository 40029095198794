class _QueryParserService {
  getUTMTagsFromQuery(queryObject) {
    let _utm = {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null,
      utm_content: null,
    };

    console.log("queryObject", queryObject);

    if (queryObject.utm_source) {
      _utm.utm_source = queryObject.utm_source;
    }

    if (queryObject.utm_medium) {
      _utm.utm_medium = queryObject.utm_medium;
    }

    if (queryObject.utm_campaign) {
      _utm.utm_campaign = queryObject.utm_campaign;
    }

    if (queryObject.utm_term) {
      _utm.utm_term = queryObject.utm_term;
    }

    if (queryObject.utm_content) {
      _utm.utm_content = queryObject.utm_content;
    }

    return _utm;
  }
}

export const QueryParserService = new _QueryParserService();
