import VueI18n from "vue-i18n";
import Vue from "vue";
import ru from "@/lang/ru.json";
import en from "@/lang/en.json";
import uk from "@/lang/uk.json";
const selectedLocale = localStorage.getItem("selectedLocale");

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: selectedLocale || "uk",
  messages: {
    ru,
    uk,
    en,
  },
});

export default i18n;
