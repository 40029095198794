import axiosInstance from "@/services/http/axios-instance";

class TermsApi {
  getActiveTerm() {
    return axiosInstance.get("/terms");
  }

  saveAgreement(termId, paragraphs) {
    return axiosInstance.post("/terms/save", {
      term_id: termId,
      paragraphs,
    });
  }
}

export const TermsApiHelper = new TermsApi();
