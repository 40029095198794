<template>
  <div
    class="input"
    :class="{
      'with-hint': !!hint && hint.trim().length > 0,
      'with-copy': canCopy,
      'with-icon': icon,
      error: vErrors.length > 0 || isError,
    }"
  >
    <div class="input__title" :class="{ 'to-top': isLabelToTop }">
      {{ title }}
    </div>
    <input
      :type="type"
      class="input__el"
      :title="title"
      :disabled="disabled"
      v-model="text"
      @input="processInput"
      @focus="processFocus"
      @blur="processBlur"
      @keypress.enter="$emit('enter')"
      v-if="!mask"
      ref="inputEl"
    />
    <input
      :type="type"
      class="input__el"
      :title="title"
      :disabled="disabled"
      v-model="text"
      @input="processInput"
      @focus="processFocus"
      @blur="processBlur"
      @keypress.enter="$emit('enter')"
      v-mask="mask"
      ref="inputEl"
      @animationstart="checkAnimation"
      v-else
    />
    <div class="input__errors" v-if="vErrors.length > 0">
      <div class="input__error">{{ vErrors[0].$message }}</div>
    </div>
    <div class="input__hint" v-if="!!hint && hint.trim().length > 0">
      <v-popover placement="top" class="hint">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1724 6.25063C10.828 6.25063 11.3793 5.68712 11.3793 5.01404C11.3793 4.30965 10.8131 3.7931 10.1724 3.7931C9.53172 3.7931 8.96552 4.30965 8.96552 5.01404C8.96552 5.70278 9.51682 6.25063 10.1724 6.25063Z"
            fill="#7A939C"
          />
          <path
            d="M9.11452 14.8129C9.11452 15.2355 9.38272 15.5172 9.78502 15.5172H10.5747C10.977 15.5172 11.2452 15.2355 11.2452 14.8129V8.03508C11.2452 7.61245 10.977 7.33069 10.5747 7.33069H9.78502C9.38272 7.33069 9.11452 7.61245 9.11452 8.03508V14.8129Z"
            fill="#7A939C"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.6207 10C18.6207 14.7611 14.7611 18.6207 10 18.6207C5.23892 18.6207 1.37931 14.7611 1.37931 10C1.37931 5.23892 5.23892 1.37931 10 1.37931C14.7611 1.37931 18.6207 5.23892 18.6207 10Z"
            fill="#7A939C"
          />
        </svg>
        <template slot="popover" class="hint">
          <div class="hint__body">
            <div class="hint__text">
              {{ hint }}
            </div>
          </div>
          <div class="hint__footer">
            <a v-close-popover class="hint__close">{{ closeButton }}</a>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="input__copy" v-if="canCopy" @click="copyToClipboard">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.89477 13.2C4.84832 13.2 4 12.1109 4 11.0876V2.85283C4 1.82954 4.84832 1 5.89477 1H12.1055C13.1519 1 14.1054 1.82954 14.1054 2.85283V11.0876C14.1054 12.1109 13.2571 13.2 12.2107 13.2H9H5.89477ZM5.10547 11.0876V3.16455C5.10547 2.59606 5.46161 2.15625 6.04297 2.15625H9.10547H11.9805C12.5618 2.15625 12.9961 2.59606 12.9961 3.16455V7V11.0876C12.9961 11.6561 12.5618 12.1177 11.9805 12.1177H6.04297C5.46161 12.1177 5.10547 11.6561 5.10547 11.0876Z"
          fill="#333F48"
        />
        <path
          d="M3.1406 12.8281C3.1406 13.4347 3.66963 14.2 4.29685 14.2H7.99997H11C11 14.2 11.3984 14.2 11.5 14.5V15C11.5 15 11.421 15.3521 11 15.3521H7.49997H4.06247C2.93347 15.3521 2.00001 14.2637 2.00001 13.1719V4C1.9995 4 2.02963 3.32227 2.45069 3.32227C2.59273 3.32227 2.66731 3.32228 2.70377 3.32229C2.70678 3.32225 2.70977 3.32226 2.71274 3.3223C2.71005 3.3223 2.70707 3.32229 2.70377 3.32229C2.70251 3.32231 2.70124 3.32233 2.69997 3.32236C2.69997 3.32236 2.76863 3.32233 2.71274 3.3223C2.70977 3.32226 2.70678 3.32225 2.70377 3.32229C2.70707 3.32229 2.71005 3.3223 2.71274 3.3223C3.0327 3.32674 3.14094 3.79172 3.1406 4V8.35205V12.8281Z"
          fill="#333F48"
        />
      </svg>
    </div>
    <div class="input__icon" v-if="icon" v-html="icon"></div>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
    value: [String, Number],
    hint: {
      type: String,
      default: "",
    },
    closeButton: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vErrors: {
      type: Array,
      default() {
        return [];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [String, Object, Function],
      default: null,
    },
    canCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: "",
      isFocused: false,
    };
  },
  created() {
    this.text = this.value === 0 ? this.value : this.value || "";
  },
  mounted() {
    this.$emit("canFocus", this);
  },
  computed: {
    isLabelToTop: function () {
      return (this.text ? this.text.length > 0 : false) || this.isFocused;
    },
  },
  watch: {
    value: function () {
      this.text = this.value;
    },
  },
  methods: {
    checkAnimation(e) {
      if (e.animationName === "onAutoFillStart") {
        this.text = this.text + " ";
        this.autofilled = true;
      } else if (e.animationName === "onAutoFillCancel") {
        this.text = this.text + " ";
        this.autofilled = false;
      }
    },

    processFocus() {
      this.$emit("textInputFocus");
      this.isFocused = true;
    },
    processBlur() {
      this.$emit("textInputBlur");
      this.isFocused = false;
    },
    processInput() {
      this.$emit("update", this.text);
    },
    copyToClipboard() {
      if (!navigator.clipboard) {
        return;
      }

      navigator.clipboard.writeText(this.text).then(
        () => {
          this.$emit("copied");
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    focus() {
      this.$refs.inputEl.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.input {
  &.with-hint & {
    &__el {
      padding-right: 25px;
    }
  }
  &.with-copy & {
    &__el {
      padding-right: 25px;
    }
  }
  &.with-icon & {
    &__el {
      padding-right: 25px;
    }
  }

  &__hint {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    right: 0;
    cursor: pointer;
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 0.7;
    }

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    right: 0;

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__copy {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    right: 0;
    cursor: pointer;
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 0.7;
    }

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<style>
:-webkit-autofill {
  animation-name: onAutoFillStart;
}
:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
@keyframes onAutoFillStart {
  from {
  }
  to {
  }
}
@keyframes onAutoFillCancel {
  from {
  }
  to {
  }
}
</style>
