<template>
  <div class="login-form__wrapper">
    <div class="login-form login-form--password">
      <div class="login-form__title">{{ $t("set_password") }}</div>
      <div class="login-form__field">
        <password-input
          :title="$t('set_password_input')"
          v-model="password"
          :v-errors="v$.password.$errors"
        ></password-input>
      </div>
      <div class="login-form__field">
        <password-input
          :title="$t('confirm_password')"
          v-model="confirmPassword"
          :v-errors="v$.confirmPassword.$errors"
          @enter="handleButton"
        ></password-input>
      </div>
      <div class="password-info">
        <div class="password-info__title">{{ $t("password_must_be") }}:</div>
        <div class="password-info__list">
          <div
            class="password-info__item"
            :class="{
              active:
                v$.checkPassword.required.$invalid === false &&
                v$.checkPassword.minLength.$invalid === false,
            }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("at_least_chars", { numChars: 8 }) }}
            </div>
          </div>
          <div
            class="password-info__item"
            :class="{
              active: v$.checkPassword.existLowerCase.$invalid === false,
            }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("contain_lower") }}
            </div>
          </div>
          <div
            class="password-info__item"
            :class="{
              active: v$.checkPassword.existUpperCase.$invalid === false,
            }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("contain_upper") }}
            </div>
          </div>
          <div
            class="password-info__item"
            :class="{ active: v$.checkPassword.existDigit.$invalid === false }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("contain_digit") }}
            </div>
          </div>
          <div
            class="password-info__item"
            :class="{ active: v$.checkPassword.existSymbol.$invalid === false }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("contain_symbol") }}
            </div>
          </div>
        </div>
      </div>
      <div class="login-form__button">
        <primary-button
          :disabled="isContinueButtonDisabled"
          :label="loginBtnLabel"
          @click="handleButton"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordInput from "@/components/input/PasswordInput";
import PrimaryButton from "@/components/button/PrimaryButton";
import { UserApi } from "@/office/user-api";

import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  requiredIf,
  maxLength,
  sameAs,
  helpers,
} from "@vuelidate/validators";
import { mapGetters, mapState } from "vuex";
import {
  LOAD_AGREEMENT_DATA,
  LOAD_USER_DATA,
  SET_PARENT_REFERRAL_UUID,
  SET_SUPPORT_LINK,
} from "@/store/const/actions-types";
import { GA_EVENTS } from "@/const/ga.events";
import { GtagServiceHelper } from "@/services/gtag/gtag.service";
import { TermsApiHelper } from "@/office/terms-api";
import { TasksApi } from "@/office/tasks-api";
import { TasksService } from "@/services/tasks/tasks.service";
import { EventsService } from "@/services/events/events.service";
import store from "@/store";
import PixelEventsService from "@/services/pixelEventsService";

const existUpperCase = (value) => /[A-Z]/.test(value);
const existLowerCase = (value) => /[a-z]/.test(value);
const existDigit = (value) => /[0-9]/.test(value);
const existSymbol = (value) => /[!@#$%^&*)(+=._-]/.test(value);

export default {
  name: "Registration",
  components: { PrimaryButton, PasswordInput },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  metaInfo: {
    title: "Registration",
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      otpCode: "",
      checkPassword: "",
      isInvite: false,
    };
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage(
          this.$t("registration_wm.required"),
          required
        ),
        existDigit,
        existLowerCase,
        existSymbol,
        existUpperCase,
        minLength: minLength(8),
      },
      checkPassword: {
        required: helpers.withMessage(
          this.$t("registration_wm.required"),
          required
        ),
        existDigit,
        existLowerCase,
        existSymbol,
        existUpperCase,
        minLength: minLength(8),
      },
      confirmPassword: {
        required: helpers.withMessage(
          this.$t("registration_wm.required"),
          required
        ),
        //sameAs: sameAs(this.password),
        sameAs: helpers.withMessage(
          this.$t("password_sameas"),
          sameAs(this.password)
        ),
      },
      otpCode: {
        requiredIf: requiredIf(this.isCodeSent),
        minValue: minLength(4),
        maxLength: maxLength(6),
      },
    };
  },
  computed: {
    ...mapGetters(["parentReferralUUID"]),
    ...mapState({
      countryCode: (state) => state.user.countryCode,
      agreement: (state) => state.agreement,
    }),
    loginBtnLabel() {
      return this.isCodeSent ? this.$t("sign_up") : this.$t("continue");
    },
    isContinueButtonDisabled() {
      return !this.password || !this.confirmPassword;
    },
  },
  watch: {
    password: function () {
      this.checkPassword = this.password;
      this.v$.checkPassword.$touch();
    },
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, "set_pass");

    if (this.$route.query.invite && parseInt(this.$route.query.invite) === 1) {
      this.isInvite = true;
      this.$router.replace({ query: {} });
    }
  },
  methods: {
    async handleButton() {
      const result = await this.v$.$validate();

      if (!result) {
        return;
      }

      GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_UP_SET_PASSWORD_START);
      this.setPassword().then(async () => {
        this.analytics();
        GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_UP_SET_PASSWORD);
        await this.setParentReferralUUID();
        await this.setCountryCode();
        await this.saveUserAgreement();
        await this.completeRegistrationTask();

        await EventsService.fireEvent(EventsService.EVENT_REGISTRATION);
        await this.$store.dispatch(SET_PARENT_REFERRAL_UUID, null);

        PixelEventsService.completeRegistration();

        await this.$store.dispatch(LOAD_USER_DATA);

        if (this.isInvite) {
          this.$router.push({
            path: "/cards",
            query: {
              invite: 1,
            },
          });
        } else {
          // this.$router.push({
          //   path: "/cards",
          // });
          this.$router.push("/welcome");
        }
      });
    },
    async setParentReferralUUID() {
      if (
        this.parentReferralUUID &&
        this.parentReferralUUID.trim().length > 0 &&
        this.parentReferralUUID !== "null"
      ) {
        await UserApi.updateProfileData({
          parentReferralUUID: this.parentReferralUUID,
        });
      }
    },
    setPassword() {
      return UserApi.setPassword(this.password, this.confirmPassword);
    },
    analytics() {
      console.log("Step5_Pass");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "Step5_Pass" });
    },
    async setCountryCode() {
      if (this.countryCode) {
        await UserApi.updateProfileData({
          countryCode: this.countryCode,
        });
      }
    },
    async saveUserAgreement() {
      await this.$store.dispatch(LOAD_AGREEMENT_DATA);
      await TermsApiHelper.saveAgreement(
        this.agreement.termId,
        this.agreement.paragraphs
      );
    },
    async completeRegistrationTask() {
      await TasksApi.markTaskComplete(
        TasksService.TASK_TYPE,
        TasksService.TASK_REGISTRATION
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.login-form {
  &--password {
    .login-form__title {
      margin-bottom: 2.5rem;
    }
  }
}
.password-info {
  margin-top: -28px;

  &__title {
    font-size: pxToRem(12);
    line-height: pxToRem(18);
    color: $mainBlack;
    font-weight: 700;
    margin-bottom: 8px;
  }
  &__title,
  &__item-text {
    font-family: "Nunito", "Rubik", sans-serif;
  }

  &__item {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active & {
      &-icon {
        svg {
          path {
            fill: $mainCold;
          }
        }
      }

      &-text {
        color: $mainCold;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;

      svg,
      img {
        width: 100%;
        height: 100%;
      }

      svg {
        path {
          fill: $gray1;
        }
      }
    }

    &-text {
      font-weight: 400;
      font-size: pxToRem(14);
      line-height: pxToRem(20);
      color: $gray1;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.terms-agreed {
  margin-top: 40px;
  color: $gray1;
  text-align: center;

  a {
    color: $mainCold;
    text-decoration: none;
    font-weight: 700;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 486px) {
  .login-form {
    width: 100%;
    padding: 0;
    box-shadow: none;

    &__wrapper {
      width: 100%;
    }
  }
}
</style>
