<template>
  <div class="unex-sum-sub__wrapper">
    <div class="unex-onboarding__title">
      {{ $t("unex.onboarding.sum_sub.step.title") }}
    </div>
    <div class="unex-onboarding__sub-title">
      {{ $t("unex.onboarding.sum_sub.step.sub_title") }}
    </div>
    <div class="unex-sum-sub">
      <base-loader v-if="isDataLoading"></base-loader>
      <div class="unex-sum-sub__selfie">
        <div
          class="unex-sum-sub__selfie-area"
          id="sumsub-websdk-container"
        ></div>
      </div>
    </div>
    <onboarding-loader
      v-if="applicantAccepted"
      :lottie-json="waitingLottie"
      :title="$t('unex.onboarding.sum_sub.accepted.title')"
      :sub-title="$t('unex.onboarding.sum_sub.accepted.sub_title')"
    ></onboarding-loader>
  </div>
</template>

<script>
import BaseLoader from "@/components/base/BaseLoader";

import responsiveMixin from "@/mixins/responsive-mixin";
import snsWebSdk from "@sumsub/websdk";
import { SumsubApi } from "@/office/sumsub-api";
import { mapGetters, mapState } from "vuex";
import OnboardingLoader from "@/components/profile/Onboarding/OnboardingLoader";
import waitingLottie from "@/assets/lottie/waiting-loader.json";
import store from "@/store";
import {SET_SUPPORT_LINK} from "@/store/const/actions-types";

export default {
  name: "UnexOnboardingDiiaMain",
  components: { OnboardingLoader, BaseLoader },
  mixins: [responsiveMixin],
  inject: ["toNextStep", "errorWSMessageHandler", "getCardProductId"],
  data() {
    return {
      applicantAccepted: false,
      isDataLoading: false,
      wssSumSubChanelSubscription: null,
      waitingLottie: waitingLottie,
    };
  },
  mounted() {
    this.launchWebSdk();
    this.analytics("Diya_success");
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, 'video_veref');
    this.initWS();
  },
  beforeDestroy() {
    if (this.wssSumSubChanelSubscription) {
      this.wssSumSubChanelSubscription.unsubscribe();
    }
  },
  computed: {
    ...mapState(["wss", "selectedLocale"]),
    ...mapGetters(["userData"]),
  },
  methods: {
    async initWS() {
      this.wssSumSubChanelSubscription = this.wss.subscribe(
        `unex-sum-sub-${this.userData.hashed_id}`,
        async (msg) => {
          let isErrorHandled = await this.errorWSMessageHandler(msg);

          if (isErrorHandled) {
            return;
          }

          if (msg.data.success === true) {
            this.handleChannelMessage(msg);
          }
        }
      );
    },
    handleChannelMessage() {
      this.analytics("Video_verif_success");
      this.toNextStep();
    },
    async launchWebSdk() {
      let accessToken = await this.getNewAccessToken();
      let snsWebSdkInstance = snsWebSdk
        .init(accessToken.data.token, () => this.getNewAccessToken())
        .withConf({
          lang: this.selectedLocale,
          uiConf: {},
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        .on("idCheck.stepCompleted", (payload) => {
          console.log("stepCompleted", payload);
        })
        .on("idCheck.onError", (error) => {
          console.log("onError", error);
        })
        .on("idCheck.applicantStatus", (payload) => {
          console.log("idCheck.applicantStatus", payload);

          if (payload.reviewStatus === "completed") {
            this.applicantAccepted = true;
          }
        })
        .onMessage((type, payload) => {
          console.log("on message", type, payload);
        })
        .build();
      snsWebSdkInstance.launch("#sumsub-websdk-container");
    },

    async getNewAccessToken() {
      this.isDataLoading = true;
      const response = await SumsubApi.getAccessToken(this.getCardProductId());
      this.isDataLoading = false;
      return Promise.resolve(response);
    },

    analytics(event) {
      console.log(event);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: event });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-sum-sub {
  position: relative;
  width: 100%;
  max-width: 530px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  border-radius: 24px;
  padding: 48px 48px 24px;

  &__title {
    font-weight: 700;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    color: $mainBlack;
    margin-bottom: 12px;
    text-align: center;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    text-align: center;
    color: $gray1;
  }

  &__qr {
    display: flex;
    align-items: center;
    justify-content: center;

    canvas {
      width: 278px;
      height: 278px;
    }
  }
}

@media screen and (max-width: 786px) {
  .unex-sum-sub {
    box-shadow: unset;
    padding: 20px 0;
  }
}
</style>
