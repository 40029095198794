export const COUNTRIES = [
  {
    name: "Ukraine",
    dialCode: "+380",
    isoCode: "UA",
    flag: `${process.env.BASE_URL}img/flags/png/ua.png`,
    nameLocale: {
      en: "Ukraine",
      uk: "Україна",
    },
  },
  {
    name: "Albania",
    dialCode: "+355",
    isoCode: "AL",
    flag: `${process.env.BASE_URL}img/flags/png/al.png`,
    nameLocale: {
      en: "Albania",
      uk: "Албанія",
    },
  },
  {
    name: "Andorra",
    dialCode: "+376",
    isoCode: "AD",
    flag: `${process.env.BASE_URL}img/flags/png/ad.png`,
    nameLocale: {
      en: "Andorra",
      uk: "Андорра",
    },
  },
  {
    name: "Armenia",
    dialCode: "+374",
    isoCode: "AM",
    flag: `${process.env.BASE_URL}img/flags/png/am.png`,
    nameLocale: {
      en: "Armenia",
      uk: "Вірменія",
    },
  },
  {
    name: "Austria",
    dialCode: "+43",
    isoCode: "AT",
    flag: `${process.env.BASE_URL}img/flags/png/at.png`,
    nameLocale: {
      en: "Austria",
      uk: "Австрія",
    },
  },
  {
    name: "Azerbaijan",
    dialCode: "+994",
    isoCode: "AZ",
    flag: `${process.env.BASE_URL}img/flags/png/az.png`,
    nameLocale: {
      en: "Azerbaijan",
      uk: "Азербайджан",
    },
  },
  {
    name: "Belgium",
    dialCode: "+32",
    isoCode: "BE",
    flag: `${process.env.BASE_URL}img/flags/png/be.png`,
    nameLocale: {
      en: "Belgium",
      uk: "Бельгія",
    },
  },
  {
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    isoCode: "BA",
    flag: `${process.env.BASE_URL}img/flags/png/ba.png`,
    nameLocale: {
      en: "Bosnia and Herzegovina",
      uk: "Боснія і Герцеговина",
    },
  },
  {
    name: "Bulgaria",
    dialCode: "+359",
    isoCode: "BG",
    flag: `${process.env.BASE_URL}img/flags/png/bg.png`,
    nameLocale: {
      en: "Bulgaria",
      uk: "Болгарія",
    },
  },
  {
    name: "Canada",
    dialCode: "+1",
    isoCode: "CA",
    flag: `${process.env.BASE_URL}img/flags/png/ca.png`,
    nameLocale: {
      en: "Canada",
      uk: "Канада",
    },
  },
  {
    name: "Croatia",
    dialCode: "+385",
    isoCode: "HR",
    flag: `${process.env.BASE_URL}img/flags/png/hr.png`,
    nameLocale: {
      en: "Croatia",
      uk: "Хорватія",
    },
  },
  {
    name: "Cyprus",
    dialCode: "+357",
    isoCode: "CY",
    flag: `${process.env.BASE_URL}img/flags/png/cy.png`,
    nameLocale: {
      en: "Cyprus",
      uk: "Кіпр",
    },
  },
  {
    name: "Czech Republic",
    dialCode: "+420",
    isoCode: "CZ",
    flag: `${process.env.BASE_URL}img/flags/png/cz.png`,
    nameLocale: {
      en: "Czech Republic",
      uk: "Чехія",
    },
  },
  {
    name: "Denmark",
    dialCode: "+45",
    isoCode: "DK",
    flag: `${process.env.BASE_URL}img/flags/png/dk.png`,
    nameLocale: {
      en: "Denmark",
      uk: "Данія",
    },
  },
  {
    name: "Estonia",
    dialCode: "+372",
    isoCode: "EE",
    flag: `${process.env.BASE_URL}img/flags/png/ee.png`,
    nameLocale: {
      en: "Estonia",
      uk: "Естонія",
    },
  },
  {
    name: "Finland",
    dialCode: "+358",
    isoCode: "FI",
    flag: `${process.env.BASE_URL}img/flags/png/fi.png`,
    nameLocale: {
      en: "Finland",
      uk: "Фінляндія",
    },
  },
  {
    name: "France",
    dialCode: "+33",
    isoCode: "FR",
    flag: `${process.env.BASE_URL}img/flags/png/fr.png`,
    nameLocale: {
      en: "France",
      uk: "Франція",
    },
  },
  {
    name: "Georgia",
    dialCode: "+995",
    isoCode: "GE",
    flag: `${process.env.BASE_URL}img/flags/png/ge.png`,
    nameLocale: {
      en: "Georgia",
      uk: "Грузія",
    },
  },
  {
    name: "Germany",
    dialCode: "+49",
    isoCode: "DE",
    flag: `${process.env.BASE_URL}img/flags/png/de.png`,
    nameLocale: {
      en: "Germany",
      uk: "Німеччина",
    },
  },
  {
    name: "Greece",
    dialCode: "+30",
    isoCode: "GR",
    flag: `${process.env.BASE_URL}img/flags/png/gr.png`,
    nameLocale: {
      en: "Greece",
      uk: "Греція",
    },
  },
  {
    name: "Vatican",
    dialCode: "+379",
    isoCode: "VA",
    flag: `${process.env.BASE_URL}img/flags/png/va.png`,
    nameLocale: {
      en: "Vatican",
      uk: "Ватикан",
    },
  },
  {
    name: "Hungary",
    dialCode: "+36",
    isoCode: "HU",
    flag: `${process.env.BASE_URL}img/flags/png/hu.png`,
    nameLocale: {
      en: "Hungary",
      uk: "Угорщина",
    },
  },
  {
    name: "Iceland",
    dialCode: "+354",
    isoCode: "IS",
    flag: `${process.env.BASE_URL}img/flags/png/is.png`,
    nameLocale: {
      en: "Iceland",
      uk: "Ісландія",
    },
  },
  {
    name: "Ireland",
    dialCode: "+353",
    isoCode: "IE",
    flag: `${process.env.BASE_URL}img/flags/png/ie.png`,
    nameLocale: {
      en: "Ireland",
      uk: "Ірландія",
    },
  },
  {
    name: "Italy",
    dialCode: "+39",
    isoCode: "IT",
    flag: `${process.env.BASE_URL}img/flags/png/it.png`,
    nameLocale: {
      en: "Italy",
      uk: "Італія",
    },
  },
  {
    name: "Kazakhstan",
    dialCode: "+77",
    isoCode: "KZ",
    flag: `${process.env.BASE_URL}img/flags/png/kz.png`,
    nameLocale: {
      en: "Kazakhstan",
      uk: "Казахстан",
    },
  },
  {
    name: "Kosovo",
    dialCode: "+383",
    isoCode: "XK",
    flag: `${process.env.BASE_URL}img/flags/png/xk.png`,
    nameLocale: {
      en: "Kosovo",
      uk: "Косово",
    },
  },
  {
    name: "Kyrgyzstan",
    dialCode: "+996",
    isoCode: "KG",
    flag: `${process.env.BASE_URL}img/flags/png/kg.png`,
    nameLocale: {
      en: "Kyrgyzstan",
      uk: "Киргизстан",
    },
  },
  {
    name: "Latvia",
    dialCode: "+371",
    isoCode: "LV",
    flag: `${process.env.BASE_URL}img/flags/png/lv.png`,
    nameLocale: {
      en: "Latvia",
      uk: "Латвія",
    },
  },
  {
    name: "Liechtenstein",
    dialCode: "+423",
    isoCode: "LI",
    flag: `${process.env.BASE_URL}img/flags/png/li.png`,
    nameLocale: {
      en: "Liechtenstein",
      uk: "Ліхтенштейн",
    },
  },
  {
    name: "Lithuania",
    dialCode: "+370",
    isoCode: "LT",
    flag: `${process.env.BASE_URL}img/flags/png/lt.png`,
    nameLocale: {
      en: "Lithuania",
      uk: "Литва",
    },
  },
  {
    name: "Luxembourg",
    dialCode: "+352",
    isoCode: "LU",
    flag: `${process.env.BASE_URL}img/flags/png/lu.png`,
    nameLocale: {
      en: "Luxembourg",
      uk: "Люксембург",
    },
  },
  {
    name: "Malta",
    dialCode: "+356",
    isoCode: "MT",
    flag: `${process.env.BASE_URL}img/flags/png/mt.png`,
    nameLocale: {
      en: "Malta",
      uk: "Мальта",
    },
  },
  {
    name: "Moldova",
    dialCode: "+373",
    isoCode: "MD",
    flag: `${process.env.BASE_URL}img/flags/png/md.png`,
    nameLocale: {
      en: "Moldova",
      uk: "Молдова",
    },
  },
  {
    name: "Monaco",
    dialCode: "+377",
    isoCode: "MC",
    flag: `${process.env.BASE_URL}img/flags/png/mc.png`,
    nameLocale: {
      en: "Monaco",
      uk: "Монако",
    },
  },
  {
    name: "Montenegro",
    dialCode: "+382",
    isoCode: "ME",
    flag: `${process.env.BASE_URL}img/flags/png/me.png`,
    nameLocale: {
      en: "Montenegro",
      uk: "Чорногорія",
    },
  },
  {
    name: "Netherlands",
    dialCode: "+31",
    isoCode: "NL",
    flag: `${process.env.BASE_URL}img/flags/png/nl.png`,
    nameLocale: {
      en: "Netherlands",
      uk: "Нідерланди",
    },
  },
  {
    name: "North Macedonia",
    dialCode: "+389",
    isoCode: "MK",
    flag: `${process.env.BASE_URL}img/flags/png/mk.png`,
    nameLocale: {
      en: "North Macedonia",
      uk: "Північна Македонія",
    },
  },
  {
    name: "Norway",
    dialCode: "+47",
    isoCode: "NO",
    flag: `${process.env.BASE_URL}img/flags/png/no.png`,
    nameLocale: {
      en: "Norway",
      uk: "Норвегія",
    },
  },
  {
    name: "Poland",
    dialCode: "+48",
    isoCode: "PL",
    flag: `${process.env.BASE_URL}img/flags/png/pl.png`,
    nameLocale: {
      en: "Poland",
      uk: "Польща",
    },
  },
  {
    name: "Portugal",
    dialCode: "+351",
    isoCode: "PT",
    flag: `${process.env.BASE_URL}img/flags/png/pt.png`,
    nameLocale: {
      en: "Portugal",
      uk: "Португалія",
    },
  },
  {
    name: "Romania",
    dialCode: "+40",
    isoCode: "RO",
    flag: `${process.env.BASE_URL}img/flags/png/ro.png`,
    nameLocale: {
      en: "Romania",
      uk: "Румунія",
    },
  },
  {
    name: "San Marino",
    dialCode: "+378",
    isoCode: "SM",
    flag: `${process.env.BASE_URL}img/flags/png/sm.png`,
    nameLocale: {
      en: "San Marino",
      uk: "Сан-Марино",
    },
  },
  {
    name: "Serbia",
    dialCode: "+381",
    isoCode: "RS",
    flag: `${process.env.BASE_URL}img/flags/png/rs.png`,
    nameLocale: {
      en: "Serbia",
      uk: "Сербія",
    },
  },
  {
    name: "Slovakia",
    dialCode: "+421",
    isoCode: "SK",
    flag: `${process.env.BASE_URL}img/flags/png/sk.png`,
    nameLocale: {
      en: "Slovakia",
      uk: "Словаччина",
    },
  },
  {
    name: "Slovenia",
    dialCode: "+386",
    isoCode: "SI",
    flag: `${process.env.BASE_URL}img/flags/png/si.png`,
    nameLocale: {
      en: "Slovenia",
      uk: "Словенія",
    },
  },
  {
    name: "Spain",
    dialCode: "+34",
    isoCode: "ES",
    flag: `${process.env.BASE_URL}img/flags/png/es.png`,
    nameLocale: {
      en: "Spain",
      uk: "Іспанія",
    },
  },
  {
    name: "Sweden",
    dialCode: "+46",
    isoCode: "SE",
    flag: `${process.env.BASE_URL}img/flags/png/se.png`,
    nameLocale: {
      en: "Sweden",
      uk: "Швеція",
    },
  },
  {
    name: "Switzerland",
    dialCode: "+41",
    isoCode: "CH",
    flag: `${process.env.BASE_URL}img/flags/png/ch.png`,
    nameLocale: {
      en: "Switzerland",
      uk: "Швейцарія",
    },
  },
  {
    name: "Tajikistan",
    dialCode: "+992",
    isoCode: "TJ",
    flag: `${process.env.BASE_URL}img/flags/png/tj.png`,
    nameLocale: {
      en: "Tajikistan",
      uk: "Таджикистан",
    },
  },
  {
    name: "Turkey",
    dialCode: "+90",
    isoCode: "TR",
    flag: `${process.env.BASE_URL}img/flags/png/tr.png`,
    nameLocale: {
      en: "Turkey",
      uk: "Туреччина",
    },
  },
  {
    name: "Turkmenistan",
    dialCode: "+993",
    isoCode: "TM",
    flag: `${process.env.BASE_URL}img/flags/png/tm.png`,
    nameLocale: {
      en: "Turkmenistan",
      uk: "Туркменістан",
    },
  },
  {
    name: "United Kingdom",
    dialCode: "+44",
    isoCode: "GB",
    flag: `${process.env.BASE_URL}img/flags/png/gb.png`,
    nameLocale: {
      en: "United Kingdom",
      uk: "Велика Британія",
    },
  },
  {
    name: "USA",
    dialCode: "+1",
    isoCode: "US",
    flag: `${process.env.BASE_URL}img/flags/png/us.png`,
    nameLocale: {
      en: "USA",
      uk: "США",
    },
  },
  {
    name: "Uzbekistan",
    dialCode: "+998",
    isoCode: "UZ",
    flag: `${process.env.BASE_URL}img/flags/png/uz.png`,
    nameLocale: {
      en: "Uzbekistan",
      uk: "Узбекистан",
    },
  },
];
