<template>
  <div class="popup">
    <div class="popup__block popup__block--card-invite">
      <div class="popup__header">
        <div class="popup__title">
          {{ $t("card.popup.invite.title") }}
        </div>
        <popup-close-button @click="closePopup"></popup-close-button>
      </div>
      <div class="card-invite">
        <div class="card-invite__hints">
          <div class="card-invite__hint">
            <span v-html="$t('card.popup.invite.hint')"></span>
          </div>
        </div>
        <div class="card-invite__actions">
          <primary-button
            class="card-invite__action"
            :label="$t('card.popup.invite.button')"
            @click="closePopup"
          ></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
import PopupCloseButton from "@/components/button/PopupCloseButton";

export default {
  name: "InvitePopup",
  components: { PopupCloseButton, PrimaryButton },
  inject: ["showErrorTip"],
  data() {
    return {
      cardProducts: [],
      selectedCardProduct: null,
    };
  },
  created() {},
  methods: {
    closePopup() {
      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  &__block {
    &--card-invite {
      width: 100%;
      max-width: 440px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-right: 30px;
  }
}
.card-invite-popup {
  position: relative;
}

.card-invite {
  margin-top: 36px;

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__actions {
    margin-top: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__action {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__hints {
    background: $grayLight;
    border-radius: 8px;
    padding: 24px;
  }

  &__hint {
    display: flex;
    align-items: flex-start;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    b {
      color: $mainBlack;
      font-weight: 700;
    }

    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $gray1;
      margin-right: 10px;
      margin-top: 10px;
      flex-shrink: 0;
    }
  }
}
</style>
