<template>
  <div class="onboarding-loader">
    <img src="~@/assets/img/bell.svg" alt="" />
    <div
      class="onboarding-loader__title"
      v-html="$t(unexOnboardingError.title)"
      v-if="unexOnboardingError.title"
    ></div>
    <div
      class="onboarding-loader__sub-title"
      v-html="$t(unexOnboardingError.subTitle)"
      v-if="unexOnboardingError.subTitle"
    ></div>

    <!--<a
      :href="supportBotLink"
      target="_blank"
      class="onboarding-loader__support-link"
    >
      {{ $t("unex.onboarding.error.buttons.support") }}
    </a>-->
    <div class="block__links">
      <a
        :href="supportBotLink"
        target="_blank"
        class="onboarding-loader__feedback-link"
      >
        {{ $t("unex.onboarding.error.buttons.support") }}
      </a>
      <router-link to="/cards" class="onboarding-loader__cards-link">
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.549298 7.73538C-0.183098 8.42453 -0.1831 9.57547 0.549298 10.2646L8.19485 17.4588C8.62084 17.8596 9.29649 17.8448 9.70396 17.4258C10.1114 17.0067 10.0964 16.3421 9.67042 15.9412L2.29367 9L9.67042 2.05878C10.0964 1.65794 10.1114 0.993277 9.70395 0.574217C9.29649 0.155158 8.62083 0.140388 8.19485 0.541227L0.549298 7.73538Z"
            fill="#00B7BD"
          />
        </svg>

        {{ $t("unex.onboarding.error.buttons.to-card-page") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { CLEAR_UNEX_ONBOARDING_ERROR } from "@/store/const/actions-types";
import { UnexOnboardingService } from "@/services/unex/unex-onboarding.service";
import { CardsProductService } from "@/services/cards/cards-product.service";
import { CardApi } from "@/office/card-api";

export default {
  data() {
    return {
      supportBotLink: "https://t.me/WeldSupport_Bot",
      cardProduct: {
        slug: "",
      },
    };
  },
  name: "OnboardingFailLoader",
  computed: {
    ...mapState(["unexOnboardingError", "selectedLocale"]),
    ...mapGetters(["userData"]),
    feedbackFormLink: function () {
      return UnexOnboardingService.getFeedbackLink(
        this.cardProduct,
        this.userData.cell_phone,
        this.userData.full_name,
        this.selectedLocale
      );
    },
  },
  beforeDestroy() {
    this.$store.commit(CLEAR_UNEX_ONBOARDING_ERROR);
  },
  methods: {
    async loadCardTypeId() {
      let cardProductSlug = localStorage.getItem(
        CardsProductService.getLocalStorageSlugKey()
      );

      try {
        let cardProductResponse = await CardApi.getCardProductBySlug(
          cardProductSlug
        );
        this.cardProduct = cardProductResponse.data;
      } catch (e) {
        console.error(e);
        return await this.$router.push("/cards");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_mixins.scss";
@import "~@/assets/scss/_variables.scss";

.onboarding-loader {
  background-color: #ffffff;

  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 16px;

  &__icon {
    height: 178px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;

    svg {
      max-height: 100%;
    }
  }

  &__title {
    max-width: 654px;
    font-weight: bold;
    font-size: pxToRem(30);
    line-height: pxToRem(36);
    text-align: center;
    color: $mainBlack;
    margin-bottom: 16px;
  }

  &__sub-title {
    max-width: 654px;
    font-weight: normal;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;
    text-align: center;
    margin-bottom: 60px;
  }

  &__support-link {
    font-weight: bold;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    color: #ffffff;
    text-decoration: underline;
    transition: opacity 0.2s linear;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }

  &__feedback-link {
    /*font-weight: bold;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    color: #ffffff;
    text-decoration: underline;
    transition: opacity 0.2s linear;
    cursor: pointer;
    margin-top: 32px;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 36px;
    gap: 10px;

    width: 294px;
    height: 44px;

    background: $mainCold;
    border-radius: 24px;
    text-decoration: none;

    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(16);
    line-height: pxToRem(20);
    text-align: center;
    color: #fff;

    margin-right: 16px;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }

  &__cards-link {
    /*font-weight: bold;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    color: #ffffff;
    text-decoration: underline;
    transition: opacity 0.2s linear;
    cursor: pointer;
    margin-top: 35px;*/
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 36px;
    gap: 8px;

    width: 262px;
    height: 44px;

    background: #ffffff;

    border: 1px solid $mainCold;
    border-radius: 24px;
    text-decoration: none;

    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(16);
    line-height: pxToRem(20);
    text-align: center;
    color: $mainCold;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }

  .block__links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 786px) {
    .block__links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .onboarding-loader__cards-link,
    .onboarding-loader__feedback-link {
      width: 100%;
    }
    .onboarding-loader__feedback-link {
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }
}
</style>
