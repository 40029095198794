export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isMobile: function () {
      return this.windowWidth <= 486;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
