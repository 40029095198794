<template>
  <div class="wrapper">
    <main-layout-header :page-title="pageTitle"> </main-layout-header>
    <story class="page__story"></story>
    <div class="main-layout__body">
      <div class="page page--settings">
        <div class="settings__tabs">
          <div
            class="settings__tabs-item"
            :class="{ active: currentTabComponent === tab.component }"
            v-for="tab in tabs"
            :key="`settings-tab-${tab.component}`"
            @click="currentTabComponent = tab.component"
          >
            {{ $t(tab.title) }}
          </div>
        </div>
        <div class="settings__content">
          <component :is="currentTabComponent"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsPersonalDetails from "@/components/profile/Settings/SettingsPersonalDetails";
import SettingsNotifications from "@/components/profile/Settings/SettingsNotifications";
import MainLayoutHeader from "@/components/layouts/MainLayout/MainLayoutHeader";
import Story from "@/components/profile/Tasks/Story";
import { mapGetters } from "vuex";
import SettingsDocuments from "@/components/profile/Settings/SettingsDocuments";
import SettingsReferrals from "@/components/profile/Settings/SettingsReferrals";
import store from "@/store";
import {SET_SUPPORT_LINK} from "@/store/const/actions-types";

export default {
  name: "Settings",
  components: {
    Story,
    MainLayoutHeader,
    SettingsNotifications,
    SettingsPersonalDetails,
    SettingsDocuments,
    SettingsReferrals,
  },
  data() {
    return {
      tabs: [
        {
          title: "personal_details",
          component: "SettingsPersonalDetails",
        },
        // { title: this.$t("notifications"), component: "SettingsNotifications" },
        {
          title: "settings-tabs.documents.title",
          component: "SettingsDocuments",
        },
        // {
        //   title: this.$t("settings-tabs.referrals.title"),
        //   component: "SettingsReferrals",
        // },
      ],
      currentTabComponent: "SettingsPersonalDetails",
    };
  },
  computed: {
    pageTitle: function () {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
    ...mapGetters(["userData"]),
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, 'profile');
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main-layout__body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.page {
  &--settings {
    padding-top: 56px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 100%;
  }
}

.settings {
  &__tabs {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0px;
    padding: 0 32px;

    &-item {
      position: relative;
      padding: 0 16px 8px;
      cursor: pointer;
      color: $gray2;
      transition: opacity 0.2s linear, color 0.2s linear;

      &:not(.active):hover {
        opacity: 0.6;
      }

      &.active {
        color: $mainBlack;
        cursor: default;
        font-weight: 700;

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: $mainCold;
        border-radius: 2px;
        opacity: 0;
        transition: opacity 0.2s linear;
      }
    }
  }

  &__content {
    padding: 75px 32px 32px;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: auto;
  }
}

@media screen and (max-width: 486px) {
  .page {
    &--settings {
      padding-top: 24px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }

  .settings {
    &__tabs {
      margin-bottom: 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding-bottom: 20px;
      padding-top: 32px;
    }
  }
}

@media screen and (max-width: 486px) {
  .page__story{
      margin-top: 32px;
  }
  .main-layout__body {
    padding-left: 0;
    padding-right: 0;
  }
  .page {
    &--settings {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .settings {
    &__tabs {
      padding-left: 0;
      padding-right: 0;
    }

    &__content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
