<template>
  <div class="card-item-add">
    <div class="card-item-add__header">
      <div class="card-item-add__header-first-line">
        <div class="card-item-add__dash"></div>
      </div>
      <div class="card-item-add__header-second-line">
        <div class="card-item-add__dash"></div>
      </div>
    </div>
    <div class="card-item-add__body">
      <primary-button
        class="card-item-add__button"
        @click="emitClick"
        :label="$t('card.add-new')"
      ></primary-button>
    </div>
    <div class="card-item-add__footer">
      <div class="card-item-add__vendor">
        <svg
          width="48"
          height="32"
          viewBox="0 0 48 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="32" cy="16" r="16" fill="#F4F8FC" />
          <circle cx="16" cy="16" r="16" fill="#DDE5ED" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
export default {
  name: "CardItemAdd",
  components: { PrimaryButton },
  methods: {
    emitClick() {
      this.$emit("addCard");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.card-item-add {
  width: 100%;
  max-width: 340px;
  height: 213px;

  background: rgba(0, 183, 189, 0.9);
  box-shadow: 0 0 16px rgba(183, 201, 211, 0.5);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__dash {
    height: 16px;
    border-radius: 8px;
  }

  &__header-first-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__header-second-line & {
    &__dash {
      width: 138px;
      background: $grayLight;
    }
  }

  &__header-first-line & {
    &__dash {
      background: $gray3;
      width: 85px;

      &--small {
        width: 75px;
      }
    }
  }

  &__button {
    background: #ffffff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
    color: $mainCold;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__footer & {
    &__dash {
      width: 117px;
      background: $gray3;

      &--small {
        width: 52px;
        background: $grayLight;
      }
    }
  }

  &__vendor {
    margin-left: auto;
  }
}
</style>
