<template>
  <div class="settings-documents">
    <div class="settings-documents__empty" v-if="documentsGroups.length === 0">
      <div class="settings-documents__empty-icon">
        <img src="~@/assets/img/documents/documents-empty.svg" alt="" />
      </div>
      <div class="settings-documents__empty-title">
        {{ $t("settings-tabs.documents.empty") }}
      </div>
    </div>
    <div class="settings-documents__block" v-else>
      <div class="settings-documents__header">
        <div class="settings-documents__title">
          {{ $t("settings-tabs.documents.list-title") }}
        </div>
      </div>
      <div class="settings-documents__list">
        <div
          v-for="(document, index) in formattedDocuments"
          :key="`document-${index}`"
        >
          <div v-if="document.link" class="settings-documents__item">
            <div class="settings-documents__item-title">
              {{ document.title }}
            </div>
            <a
              @click="download(document.link)"
              target="_blank"
              class="settings-documents__item-link"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 17.0002L2.621 19.4852C2.72915 19.9179 2.97882 20.302 3.33033 20.5765C3.68184 20.851 4.11501 21.0002 4.561 21.0002H19.439C19.885 21.0002 20.3182 20.851 20.6697 20.5765C21.0212 20.302 21.2708 19.9179 21.379 19.4852L22 17.0002M12 15.0002V3.00024V15.0002ZM12 15.0002L8 11.0002L12 15.0002ZM12 15.0002L16 11.0002L12 15.0002Z"
                  stroke="#00B7BD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserApi } from "@/office/user-api";
import { mapState } from "vuex";

export default {
  name: "SettingsDocuments",
  metaInfo: {
    title: "Settings documents",
  },
  inject: ["showErrorTip"],
  data() {
    return {
      documentsGroups: [],
      isDataLoading: false,
    };
  },
  created() {
    this.loadDocuments();
  },
  computed: {
    ...mapState(["selectedLocale"]),

    formattedDocuments: function () {
      let _documents = [];

      this.documentsGroups.forEach((dG) => {
        _documents.push({
          title: `${this.$t("settings-tabs.documents.public-agreement")} ${
            dG.name
          }`,
          link: dG.public_agreement_url[this.selectedLocale],
        });
        _documents.push({
          title: `${this.$t("settings-tabs.documents.tariff")} ${dG.name}`,
          link: dG.tarif_url,
        });

        dG.card_agreement_links.forEach((agreement) => {
          _documents.push({
            title: `${this.$t("settings-tabs.documents.card-agreement")} ${
              dG.name
            }`,
            link: agreement.unex_agreement_link,
          });
        });
      });

      return _documents;
    },
  },
  methods: {
    async loadDocuments() {
      this.isDataLoading = true;
      try {
        let documentsResponse = await UserApi.getDocuments();
        this.documentsGroups = documentsResponse.data.documents_groups;
      } catch (e) {
        this.showErrorTip(this.$t("errors.default"));
        console.error(e);
      } finally {
        this.isDataLoading = false;
      }
    },

    download(url) {
      window.open(url , '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.settings-documents {
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 290px;
      max-height: 290px;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-title {
      font-weight: 700;
      font-size: pxToRem(24);
      line-height: pxToRem(30);
      text-align: center;
    }
  }

  &__block {
    background: #ffffff;
    box-shadow: 0px 0px 62px rgba(183, 201, 211, 0.5);
    border-radius: 24px;
  }

  &__header {
    padding: 45px 32px 21px;
  }

  &__title {
    font-weight: 700;
    font-size: pxToRem(24);
    line-height: pxToRem(30);
  }

  &__list {
    padding-bottom: 24px;
  }

  &__item {
    padding: 1.5rem 2rem;
    display: grid;
    grid-auto-flow: column;
    column-gap: 1.875rem;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $gray3;
    cursor: default;

    &-title {
      font-size: pxToRem(16);
      line-height: pxToRem(24);
      text-decoration-line: underline;
      color: $gray1;
    }

    &-link {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
