import axiosInstance from "@/services/http/axios-instance";

class CryptoApi {
  static STATUSES = {
    PENDING: "pending",
    SUCCESS: "complete",
    ERROR: "error",
  };

  static TRANSACTION_TYPES = {
    BUY: "buy",
    STAKING: "staking",
  };

  static TRANSACTION_TYPES_SITER = {
    BUY: "staking_buy_transaction",
    STAKING: "staking_transaction",
  };

  saveBuyTransaction(
    txHash,
    account,
    amountWeld,
    amountUsdt,
    amountCoin,
    coin,
    rate
  ) {
    return axiosInstance.post("/crypto/buy/save", {
      tx_hash: txHash,
      account,
      amount_weld: amountWeld,
      amount_usdt: amountUsdt,
      amount_coin: amountCoin,
      coin,
      rate,
      status: CryptoApi.STATUSES.PENDING,
    });
  }

  updateBuyTransaction(txHash, status) {
    return axiosInstance.post("/crypto/buy/update", {
      tx_hash: txHash,
      type: CryptoApi.TRANSACTION_TYPES.BUY,
      status,
    });
  }

  saveStakingTransaction(txHash, account, amountWeld, amountUsdt, rate) {
    return axiosInstance.post("/crypto/staking/save", {
      tx_hash: txHash,
      account,
      amount_weld: amountWeld,
      amount_usdt: amountUsdt,
      rate,
      status: CryptoApi.STATUSES.PENDING,
    });
  }

  updateStakingTransaction(txHash, status) {
    return axiosInstance.post("/crypto/staking/update", {
      tx_hash: txHash,
      type: CryptoApi.TRANSACTION_TYPES.STAKING,
      status,
    });
  }

  getWalletsWithStaking() {
    return axiosInstance.get("/crypto/staking/wallets");
  }

  getAccountTransactions(address) {
    return axiosInstance.get("/crypto/account/transactions", {
      params: {
        address,
      },
    });
  }
}

export const CryptoApiHelper = new CryptoApi();
export const CRYPTO_API_STATUSES = CryptoApi.STATUSES;
export const CRYPTO_API_TRANSACTION_TYPES = CryptoApi.TRANSACTION_TYPES_SITER;
