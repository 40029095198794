<template>
  <div class="phone-input__wrapper">
    <div class="phone-input" :class="{ error: vErrors.length > 0 }">
      <div class="phone-input__code">
        <custom-select
          :show-icon="true"
          :show-key-in-label="true"
          :show-selected-id="true"
          :show-search="true"
          align-left
          :values="phoneCodes"
          :empty-placeholder="$t('country_code')"
          v-model="country"
          :is-error="vErrors.length > 0"
          :disabled="disabled"
          :title="$t('unex.onboarding.otp.select.title')"
          :is-active="isInputFocus"
        ></custom-select>
      </div>

      <div class="phone-input__number">
        <text-input
          ref="phoneNumberInput"
          type="tel"
          v-model="phoneNumber"
          :is-error="vErrors.length > 0"
          :mask="'##-###-##-##'"
          :disabled="disabled"
          :title="$t('phone')"
          @enter="$emit('enter')"
          @canFocus="sendCanFocus"
          @textInputFocus="textInputFocusHandler"
          @textInputBlur="textInputBlurHandler"
        ></text-input>
      </div>
    </div>
    <div class="input__errors" v-if="vErrors.length > 0">
      <div class="input__error">{{ vErrors[0].$message }}</div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/input/CustomSelect";
import TextInput from "@/components/input/TextInput";
import { COUNTRIES } from "@/const/countries";

export default {
  name: "PhoneInput",
  components: { TextInput, CustomSelect },
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    initialOriginalPhone: {
      type: Number,
      default: null,
    },
    initialCountry: {
      type: String,
      default: null,
    },
    initialCountryCode: {
      type: [String, Number],
      default: null,
    },
    vErrors: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.phoneNumber = this.phoneNumber + " ";
    });
  },
  created() {
    if (this.initialOriginalPhone) {
      this.phoneNumber = this.initialOriginalPhone;
    }

    if (this.initialCountryCode) {
      this.countryCode = this.initialCountryCode;
    }

    if (this.initialCountry) {
      this.country = this.initialCountry;
    }
  },
  data() {
    return {
      country: "",
      countryCode: "",
      phoneNumber: "",
      isInputFocus: false,
    };
  },
  watch: {
    phoneNumber: function () {
      this.emitPhoneUpdate();
    },
    country: function () {
      let country = COUNTRIES.find(
        (_country) => _country.isoCode === this.country
      );
      this.countryCode = country.dialCode || "";
      this.emitCountryUpdate(this.country);
    },
    countryCode: function () {
      this.emitPhoneUpdate();
    },
    initialOriginalPhone: function () {
      if (this.initialOriginalPhone) {
        this.phoneNumber = this.initialOriginalPhone;
      }
    },
    initialCountryCode: function () {
      if (this.initialCountryCode) {
        this.countryCode = this.initialCountryCode;
      }
    },
    initialCountry: function () {
      if (this.initialCountry) {
        this.country = this.initialCountry;
      }
    },
  },
  methods: {
    textInputFocusHandler() {
      this.isInputFocus = true;
    },
    textInputBlurHandler() {
      this.isInputFocus = false;
    },
    sendCanFocus(_inst) {
      this.$emit("canFocus", _inst);
    },
    emitPhoneUpdate() {
      this.$emit(
        "update",
        (this.countryCode.id || this.countryCode) + this.phoneNumber
      );
      this.$emit("originalPhoneUpdate", this.phoneNumber);
    },
    emitCountryUpdate(country) {
      this.$emit("countryUpdated", country);
      this.$emit("countryCodeUpdated", this.countryCode);
    },
    focus() {
      this.$refs.phonNumberInput?.focus();
    },
  },
  computed: {
    phoneCodes: function () {
      return COUNTRIES.map((_country) => {
        return {
          id: _country.isoCode,
          additionalTitle: _country.dialCode,
          title: _country.name,
          image: _country.flag,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-input {
  display: flex;
  align-items: center;

  &__wrapper {
    position: relative;
  }

  &__code {
    width: 106px;
    margin-right: 24px;
  }

  &__number {
    flex-grow: 1;
  }
}
</style>
