<template>
  <div class="unex-pkr__wrapper">
    <onboarding-loader
      v-if="isAgreementGenerating"
      :lottie-json="waitingLoader"
      :title="$t('unex.onboarding.pkr.loader.title')"
      :sub-title="$t('unex.onboarding.pkr.loader.sub_title')"
    ></onboarding-loader>
    <div class="unex-onboarding__title">
      {{ $t("unex.onboarding.pkr.step.title") }}
    </div>
    <div class="unex-onboarding__sub-title">
      {{ $t("unex.onboarding.pkr.step.sub_title") }}
    </div>
    <div class="unex-pkr">
      <base-loader v-if="isDataLoading"></base-loader>
      <div class="unex-pkr__agreement" @click="loadAgreement">
        <div class="unex-pkr__agreement-title">
          {{ $t("unex.onboarding.pkr.agreement_title") }}
        </div>
      </div>
      <div class="unex-pkr__hint">
        {{ $t("unex.onboarding.pkr.hint", { phone: userData.cell_phone }) }}
      </div>
      <w-otp-input
        class="unex-otp__code-el unex-pkr__otp"
        hidden-label
        v-model="otpCode"
        :v-errors="v$.otpCode.$errors"
        :resend-method="sendOtp"
      ></w-otp-input>
      <primary-button
        :label="$t('unex.onboarding.pkr.button.check_otp')"
        @click="checkOtp"
      ></primary-button>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/components/base/BaseLoader";

import responsiveMixin from "@/mixins/responsive-mixin";
import WOtpInput from "@/components/input/WOtpInput";
import PrimaryButton from "@/components/button/PrimaryButton";
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength, required } from "@vuelidate/validators";
import { UnexOnboardingApi } from "@/office/unex-onboarding-api";
// import FileSaver from "file-saver";
import OnboardingLoader from "@/components/profile/Onboarding/OnboardingLoader";
import waitingLoader from "@/assets/lottie/waiting-loader";
import { mapGetters } from "vuex";
import store from "@/store";
import {SET_SUPPORT_LINK} from "@/store/const/actions-types";

export default {
  name: "UnexOnboardingDiiaMain",
  components: { OnboardingLoader, BaseLoader, PrimaryButton, WOtpInput },
  mixins: [responsiveMixin],
  inject: ["toNextStep", "getCardProductId"],
  data() {
    return {
      isDataLoading: false,
      isOtpCodeSent: false,
      otpCode: null,
      operationId: null,
      waitingLoader,
      isAgreementGenerating: false,
      agreementBlob: null,
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      otpCode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(6),
      },
    };
  },
  async created() {
    // await this.generateAgreement();
    store.dispatch(SET_SUPPORT_LINK, 'pkr_doc_sign');
    await this.sendOtp();
  },
  computed: {
    ...mapGetters(["userData"]),
  },
  methods: {
    handleCodeSent(phone, operationId) {
      this.otpPhone = phone;
      this.operationId = operationId;
      this.isOtpCodeSent = true;
    },
    handleOtpChecked() {
      this.toNextStep();
    },
    async generateAgreement() {
      this.isAgreementGenerating = true;

      try {
        await UnexOnboardingApi.generatePKRAgreement(this.getCardProductId());
      } catch (e) {
        console.log("generate agreement error", e);
      } finally {
        this.isAgreementGenerating = false;
      }
    },
    async checkOtp() {
      let validationResult = await this.v$.$validate();

      if (!validationResult) {
        return false;
      }

      this.isDataLoading = true;

      try {
        await UnexOnboardingApi.checkPKROtp(
          this.otpCode,
          this.getCardProductId()
        );

        this.handleOtpChecked();
      } catch (e) {
        this.showErrorTip(this.$t("unex.onboarding.otp.errors.otp-invalid"));
        console.log("opt check error", e);
      } finally {
        this.isDataLoading = false;
      }
    },
    async sendOtp() {
      try {
        this.isDataLoading = true;
        let otpResponse = await UnexOnboardingApi.sendPRKOtp(
          this.getCardProductId()
        );

        this.agreementBlob = otpResponse.data;
        console.log("otpResponse", otpResponse);
      } catch (e) {
        console.log("otp send error", e);
      } finally {
        this.isDataLoading = false;
      }
    },
    loadAgreement() {
      if (!this.agreementBlob) {
        return;
      }
      this.isDataLoading = true;

      const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${this.agreementBlob}`;
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      downloadLink.href = linkSource;
      downloadLink.target = "_self";
      downloadLink.download = "PKRAgreement.docx";
      downloadLink.click();

      this.isDataLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-pkr {
  position: relative;
  width: 100%;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  border-radius: 24px;
  padding: 48px;
}

@media screen and (max-width: 786px) {
  .unex-pkr {
    box-shadow: unset;
    padding: 20px 0;
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-pkr {
  &__hints {
    background: $grayLight;
    border-radius: 8px;
    padding: 24px;
  }

  &__agreement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 31px;

    &-title {
      font-weight: 500;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      color: $mainCold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__otp {
    margin-bottom: 28px;
  }

  &__hint {
    display: flex;
    align-items: flex-start;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
    }

    b {
      color: $mainBlack;
      font-weight: 700;
    }
  }
}
</style>
