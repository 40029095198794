<template>
  <div class="settings-notifications">
    <coming-soon></coming-soon>
  </div>
</template>

<script>
import ComingSoon from "@/components/base/ComingSoon";
export default {
  name: "SettingsNotifications",
  components: { ComingSoon },
  metaInfo: {
    title: "Settings notifications",
  },
};
</script>

<style lang="scss" scoped></style>
