<template>
  <div class="unex-diia__wrapper">
    <div class="unex-diia" :class="{ 'unex-diia--mobile': isMobile }">
      <base-loader v-if="isDataLoading"></base-loader>
      <div class="unex-diia__title">
        {{ $t(stepTitle) }}
      </div>
      <div class="unex-diia__text" v-html="diiaSteps"></div>
      <template v-if="!isMobile">
        <a class="unex-diia__qr" :href="diiaDeepLinkHref" target="_blank">
          <canvas ref="qrCanvas"></canvas>
        </a>
        <div class="unex-diia__qr-update-countdown" v-if="!canUpdateDeeplink">
          {{ timerString }}
        </div>
        <div class="unex-diia__qr-update-countdown" v-else>
          {{ $t("unex.onboarding.diia.main.hint") }}
        </div>
        <TransparentButton
          class="unex-diia__qr-update"
          @click="loadDiiaDeeplink"
          :label="$t('unex.onboarding.diia.update_deeplink')"
          :disabled="!canUpdateDeeplink"
        ></TransparentButton>
      </template>
      <template v-else>
        <div class="unex-diia__action">
          <PrimaryButton
            @click="openDiiaLinkHandler"
            :disabled="isButtonClicked && !canUpdateDeeplink"
            :label="
              isButtonClicked && !canUpdateDeeplink
                ? timerString
                : $t('unex.onboarding.diia.deeplink_button')
            "
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { UnexOnboardingApi } from "@/office/unex-onboarding-api";
import { mapGetters, mapState } from "vuex";
import BaseLoader from "@/components/base/BaseLoader";

import responsiveMixin from "@/mixins/responsive-mixin";
import TransparentButton from "@/components/button/TransparentButton";
import PrimaryButton from "@/components/button/PrimaryButton";

import store from "@/store";
import { SET_SUPPORT_LINK } from "@/store/const/actions-types";

export default {
  name: "UnexOnboardingDiiaMain",
  components: { PrimaryButton, TransparentButton, BaseLoader },
  mixins: [responsiveMixin],
  inject: ["toNextStep", "errorWSMessageHandler", "getCardProductId"],
  data() {
    return {
      diiaDeepLink: null,
      wssDiiaChanelSubscription: null,
      isDataLoading: false,
      canUpdateDeeplink: false,
      updateDeeplinkInterval: null,
      updateDeeplinkTimeout: 150,
      updateDeeplinkTimeoutTemporary: 150,
      isButtonClicked: false,
    };
  },
  watch: {
    diiaDeepLink() {
      this.drawQR(this.diiaDeepLink);
    },
    canUpdateDeeplink(value) {
      if (value && this.isMobile) {
        this.loadDiiaDeeplink();
      }
    },
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, "diia_veref");
    this.initWS();
    this.loadDiiaDeeplink();
  },
  beforeDestroy() {
    if (this.wssDiiaChanelSubscription) {
      this.wssDiiaChanelSubscription.unsubscribe();
    }
  },
  computed: {
    ...mapState(["wss"]),
    ...mapGetters(["userData"]),
    diiaDeepLinkHref: function () {
      return this.isMobile ? this.diiaDeepLink : null;
    },
    timerString: function () {
      let minutes = Math.floor(this.updateDeeplinkTimeoutTemporary / 60);
      let seconds = this.updateDeeplinkTimeoutTemporary - minutes * 60;

      return (
        this.padLeft(minutes, "0", 2) + ":" + this.padLeft(seconds, "0", 2)
      );
    },
    diiaSteps() {
      return this.isMobile
        ? this.$t("unex.onboarding.diia.main.text_mobile")
        : this.$t("unex.onboarding.diia.main.text");
    },
    stepTitle() {
      return this.isMobile
        ? "unex.onboarding.diia.main.step.title_mobile"
        : "unex.onboarding.diia.main.step.title";
    },
  },
  methods: {
    async openDiiaLinkHandler() {
      if (this.isDataLoading) {
        return;
      }

      this.isButtonClicked = true;
      this.diiaDeepLinkHref && window.open(this.diiaDeepLinkHref, "_blank");
    },
    async loadDiiaDeeplink() {
      this.isDataLoading = true;
      try {
        let deepLinkResponse = await UnexOnboardingApi.getDiiaMainDeeplinkUrl(
          this.getCardProductId()
        );
        this.diiaDeepLink = deepLinkResponse.data.deeplink;
        this.startDeeplinkTimeout();
      } catch (e) {
        this.canUpdateDeeplink = true;
      } finally {
        this.isDataLoading = false;
      }
    },
    async initWS() {
      this.wssDiiaChanelSubscription = this.wss.subscribe(
        `diia-main-${this.userData.hashed_id}`,
        async (msg) => {
          let isErrorHandled = await this.errorWSMessageHandler(msg);

          if (isErrorHandled) {
            return;
          }

          this.handleChannelMessage(msg);
        }
      );
    },
    handleChannelMessage(msg) {
      console.log("diia-main-channel", msg);

      if (msg.data.success === true) {
        this.toNextStep();
      }
    },
    drawQR(url) {
      console.log(url);
      QRCode.toCanvas(this.$refs.qrCanvas, url, {
        errorCorrectionLevel: "L",
        width: 278,
        height: 278,
        margin: 0,
        color: {
          dark: "#66798F",
        },
      });
    },
    startDeeplinkTimeout() {
      this.canUpdateDeeplink = false;
      this.startCountdown();

      setTimeout(() => {
        this.canUpdateDeeplink = true;
        this.isButtonClicked = false;
        this.clearCountdown();
      }, 150000);
    },
    startCountdown() {
      this.updateDeeplinkTimeoutTemporary = this.updateDeeplinkTimeout;
      this.updateDeeplinkInterval = setInterval(() => {
        this.updateDeeplinkTimeoutTemporary--;
      }, 1000);
    },
    clearCountdown() {
      if (this.updateDeeplinkInterval) {
        clearInterval(this.updateDeeplinkInterval);
      }
    },
    padLeft(string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-diia {
  position: relative;
  width: 100%;
  max-width: 530px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 62px rgba(183, 201, 211, 0.5);
  border-radius: 24px;
  padding: 48px;

  &__title {
    font-weight: 700;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    color: $mainBlack;
    margin-bottom: 24px;
    text-align: center;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin-top: 12px;
    font-weight: 500;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    /*text-align: center;*/
    color: $gray1;
    width: 100%;
    margin-bottom: 34px;

    &::v-deep {
      font-weight: 400;

      & ul {
        li {
          margin-left: -13px;
        }

        a {
          text-decoration: none;
          color: $mainCold;
        }
      }
    }
  }

  &__qr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 41px;

    &-helper {
      text-align: center;
      margin-bottom: 15px;
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $gray1;
    }

    canvas {
      width: 278px;
      height: 278px;
    }

    &-update {
      margin-top: 24px;

      &-countdown {
        text-align: center;
        margin-top: 25px;
        color: $gray1;
        margin-bottom: 36px;
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .unex-diia {
    &__action {
      padding: 0 1.25rem;
      width: 100%;
      position: fixed;
      bottom: calc(5.25rem + 1.25rem);
      left: 0;
    }
  }
}

@media screen and (max-width: 786px) {
  .unex-diia {
    padding: 20px 0;
    box-shadow: none;
  }
}
</style>
