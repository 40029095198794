<template>
  <div class="button-bordered" :class="{ disabled }" @click="processClick">
    <slot name="leading-icon" />
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "BorderedButton",
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    processClick() {
      if (this.disabled) {
        return;
      }

      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.button-bordered {
  width: 100%;
  padding: 12px 36px;
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: $mainCold;
  cursor: pointer;
  transition: opacity 0.2s linear;
  border: 1px solid $mainCold;

  &:hover {
    opacity: 0.6;
  }

  &.disabled {
    background-color: #fff;
    color: $gray1;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
