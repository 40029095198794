<template>
  <div class="block-tooltip">
    <div class="block-tooltip__content">
      <slot />
    </div>
    <div class="block-tooltip__action">
      <button @click="closeTooltip" type="button">
        {{ $t("popup-close") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TooltipContentWithButton",
  props: {
    isTooltipOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeTooltip() {
      this.$emit("closeTooltip");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.block-tooltip {
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;

  &__content span,
  &__action button {
    font-family: "Nunito", "Rubik", sans-serif;
  }

  &__content span {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $gray1;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      cursor: pointer;
      background: transparent;
      border: none;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1rem;
      color: $mainCold;
    }
  }
}
</style>
