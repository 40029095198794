<template>
  <div class="email-input__wrapper">
    <div class="email-input" :class="{ error: vErrors.length > 0 }">
      <text-input
        class="email-input__el"
        ref="emailInput"
        type="email"
        :title="$t('email')"
        v-model="email"
        :is-error="vErrors.length > 0"
        :mask="emailMask"
        :disabled="disabled"
        @canFocus="sendCanFocus"
        @enter="$emit('enter')"
      ></text-input>
    </div>
    <div class="input__errors" v-if="vErrors.length > 0">
      <div class="input__error">{{ vErrors[0].$message }}</div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/input/TextInput";

const _emailMask = (value) => {
  let shouldAppendAt = false;

  if (value.indexOf("@") > -1) {
    shouldAppendAt = true;
  }
  let values = value.split("@");

  if (values.length > 2) {
    values = [values[0], values[1]];
  }

  if (values[0].trim().length === 0) {
    return [""];
  }

  values[0] = values[0].replace(/[^a-zA-Z0-9!#$%&'*+\-/=?^_.`{|}~"]/, "");

  if (values[1]) {
    if (values[1].trim().length === 0) {
      return [`${values[0]}${shouldAppendAt ? "@" : ""}`];
    }

    let _value = values[1];

    _value =
      _value[0].replace(/[^a-zA-Z0-9]/, "") +
      _value.substr(1, value.length - 1);
    _value = _value.replace(/[^a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.]/, "");

    values[1] = _value;
  } else {
    return [`${values[0]}${shouldAppendAt ? "@" : ""}`];
  }

  return [values.join("@")];
};

export default {
  name: "EmailInput",
  components: { TextInput },
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    vErrors: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      email: "",
      emailMask: _emailMask,
    };
  },
  created() {
    if (this.value) {
      this.email = this.value;
    }
  },
  watch: {
    value: function () {
      this.email = this.value;
    },
    email: function () {
      this.emitEmailUpdate();
    },
  },

  methods: {
    sendCanFocus(_inst) {
      this.$emit("canFocus", _inst);
    },
    emitEmailUpdate() {
      if (!this.disabled) {
        this.$emit("update", this.email);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-input {
  display: flex;
  align-items: center;

  &__wrapper {
    position: relative;
  }

  &__el {
    flex-grow: 1;
  }
}
</style>
