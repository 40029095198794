<template>
  <div class="referral-hint referral-connect-metamask">
    <base-loader v-if="isDataLoading"></base-loader>
    <div class="referral-hint__body">
      <div class="referral-hint__title">
        {{ $t("referrals.hints.connect-metamask.title") }}
      </div>
      <div class="referral-hint__text">
        {{ $t("referrals.hints.connect-metamask.text") }}
      </div>
      <div class="referral-hint__actions">
        <primary-button
          :label="$t('referrals.hints.connect-metamask.action')"
          @click="connectAccount"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
import { mapGetters, mapState } from "vuex";
import { Web3Service } from "@/services/crypto/web3/web3-service";
import BaseLoader from "@/components/base/BaseLoader";
import { ReferralsApi } from "@/office/referrals-api";
export default {
  name: "ReferralConnectMetamask",
  components: { BaseLoader, PrimaryButton },
  data() {
    return {
      isDataLoading: false,
    };
  },
  computed: {
    ...mapGetters(["web3Instance"]),
    ...mapState({
      accounts: (state) => state.crypto.accounts,
      chainId: (state) => state.crypto.currentChainId,
    }),
  },
  methods: {
    async connectAccount() {
      this.isDataLoading = true;
      if (!this.web3Instance) {
        await new Web3Service().loadWeb3ToStore();
      }

      if (!this.web3Instance) {
        this.web3Exists = false;
      }

      await new Web3Service()
        .requestAccounts()
        .catch((err) => console.log(err));

      if (this.accounts.length > 0) {
        await ReferralsApi.saveMetamaskAcc(this.accounts[0]);
      }

      this.$emit("metamaskSaved");
    },
  },
};
</script>

<style scoped></style>
