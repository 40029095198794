<template>
  <custom-select
    class="rates-select"
    v-model="selectedRate"
    :values="ratesOptions"
    :show-selected-id="true"
    :show-selected-title="true"
    :show-key-in-label="true"
    :empty-placeholder="''"
    :title="$t('rates-indicator.select.title')"
  ></custom-select>
</template>

<script>
import CustomSelect from "@/components/input/CustomSelect";
import { RatesApi } from "@/office/rates-api";
export default {
  name: "RatesIndicator",
  components: { CustomSelect },
  data() {
    return {
      rates: [],
      selectedRate: null,
    };
  },
  async created() {
    let ratesResponse = await RatesApi.getRates();

    this.rates = ratesResponse.data;

    if (this.rates) {
      this.selectedRate = `${this.rates[0].currency} / ${this.rates[0].base_currency}`;
    }
  },
  computed: {
    ratesOptions: function () {
      return this.rates.map((rate) => ({
        id: `${rate.currency} / ${rate.base_currency}`,
        title: rate.rate,
      }));
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";

.custom-select {
  &.rates-select & {
    &__block {
      width: 210px;
    }

    &__caret {
      svg {
        path {
          fill: $gray1;
        }
      }
    }

    &__title {
      &:empty {
        display: none;
      }
    }

    &__selected {
      padding: 12px;
      border: 1px solid #dde5ed;
      border-radius: 24px;

      &-item {
        &-label {
          margin-right: 12px;
          font-weight: 700;
          color: $gray1;

          &:last-child {
            margin-right: 13px;
          }
        }

        &-icon {
          border-radius: 50%;

          img {
            border-radius: 50%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .custom-select {
    &.rates-select & {
      &__caret {
        transform: rotate(-90deg);
      }
    }
  }
}
</style>
