export const authMiddleware = ({ to, next, store }) => {
  if (!store.getters.isUserLoggedIn) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  }

  return next();
};
