import axios from "axios";
import router from "@/router";
import { API_URL } from "@/const/api";
import store from "@/store";
import { LOGOUT_USER, UPDATE_TOKENS } from "@/store/const/actions-types";
import AuthApi from "@/office/auth-api";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (
      error.response.status === 403 &&
      error.config.url !== "/user/refresh.token"
    ) {
      return AuthApi.refreshToken(store.state.user.refreshToken)
        .then(async (r) => {
          const refreshToken = r.data.refresh_token;
          const accessToken = r.data.access_token;
          console.log(UPDATE_TOKENS);
          await store.dispatch(UPDATE_TOKENS, {
            accessToken: accessToken,
            refreshToken: refreshToken,
          });
          error.config.headers["Authorization"] = `Bearer ${accessToken}`;
          return axios.request(error.config);
        })
        .catch(async (err) => {
          console.dir(err);
          // await store.dispatch(CLEAR_USER_DATA);

          if (router.currentRoute.meta.requireAuth === true) {
              await router.push({ path: "/login" });
          }

          return Promise.reject(error.response);
        });
    } else if (
      error.response.status === 403 &&
      error.config.url === "/user/login.otp"
    ) {
      return Promise.reject(error.response);
    } else if (error.config.url === "/user/refresh.token") {
      store.dispatch(LOGOUT_USER).then(() => {
          if (router.currentRoute.meta.requireAuth === true) {
              router.push("/login");
          }
        // if (!["Login", "Registration"].includes(router.currentRoute.name)) {
        //   router.push({
        //     path: "/login",
        //     query: {
        //       last_refresh_time: new Date().to,
        //     },
        //   });
        // }
      });
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error.response);
    }
  }
);

export default axiosInstance;
