<template>
  <div
    class="card-item"
    :class="{
      'card-item--pending': isStatusPending,
      'card-item--blocked': isStatusBlocked,
    }"
    :style="{
      'background-image': `url(/img/cards/${cardBackgroundImage})`,
    }"
    @click="selectedCard"
  >
    <div v-if="isStatusBlocked" class="status-description">
      <div class="status-description-content">
        <div class="status-description-content__icon">
          <img src="@/assets/img/card/cardStatus.svg" alt="card status" />
        </div>
        <div class="status-description-content__text">
          {{ $t("card.item.status.pending-fop.title") }}
        </div>
      </div>
      <div class="status-description-tooltip">
        <popover-with-close-button
          :tooltip-content="$t('card.item.status.pending-fop.hint')"
        />
      </div>
    </div>
    <div
      class="card-item__status card-item__status--pending-fop"
      v-if="isStatusPending && card.is_fop && card.is_fop === true"
    >
      <div class="card-item__status-icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99992 0.5C3.86457 0.5 0.5 3.86457 0.5 7.99992C0.5 12.1353 3.86457 15.4998 7.99992 15.4998C12.1353 15.4998 15.4998 12.1353 15.4998 7.99992C15.5041 7.69525 15.1762 7.36427 14.8199 7.35067C14.3748 7.33368 13.9996 7.64085 13.9996 8.07626C13.9996 10.9596 11.373 14.0594 7.99992 14C4.68672 13.9417 2.00885 11.3136 2 7.99992C1.99113 4.67734 4.6869 2.06527 7.99992 2C10.0922 1.95878 11.7049 2.6593 12.8633 4.24996H11.6445C11.57 4.24891 11.496 4.26268 11.4268 4.29047C11.3577 4.31827 11.2947 4.35953 11.2416 4.41187C11.1885 4.4642 11.1464 4.52657 11.1176 4.59534C11.0889 4.66411 11.074 4.73791 11.074 4.81245C11.074 4.887 11.0889 4.9608 11.1176 5.02957C11.1464 5.09834 11.1885 5.1607 11.2416 5.21304C11.2947 5.26538 11.3577 5.30664 11.4268 5.33444C11.496 5.36223 11.57 5.376 11.6445 5.37495H14.2695C14.4187 5.37493 14.8546 5.31566 14.9601 5.21018C15.0656 5.10469 15.1248 4.96163 15.1248 4.81245V2.03468C15.1259 1.9594 15.1119 1.88466 15.0835 1.8149C15.0552 1.74514 15.0132 1.68178 14.9599 1.62858C14.9066 1.57537 14.8432 1.5334 14.7734 1.50515C14.7036 1.4769 14.6288 1.46296 14.5536 1.46413C14.4045 1.46646 14.2625 1.52786 14.1586 1.63483C14.0548 1.74181 13.9977 1.88562 13.9999 2.03468V3.35156C12.6307 1.52812 10.4508 0.5 7.99992 0.5ZM7.69987 3.84591C7.35302 3.8497 6.99812 4.07327 6.99812 4.43746V8.93741C6.99818 9.50074 7.25055 9.74597 7.81242 9.74603H10.8124C11.1953 9.74603 11.3829 9.34375 11.3829 9.04688C11.3829 8.61719 11.1203 8.37056 10.8124 8.37492H8.37492V4.43746C8.37938 4.12618 8.05132 3.84208 7.69987 3.84591Z"
            fill="#EB6FBD"
          />
        </svg>
      </div>
      <div class="card-item__status-text">
        {{ $t("card.item.status.pending-fop.title") }}
      </div>
      <div class="card-item__status-hint">
        <v-popover placement="top" class="hint">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" fill="white" />
            <path
              d="M10.1724 6.25063C10.828 6.25063 11.3793 5.68712 11.3793 5.01404C11.3793 4.30965 10.8131 3.7931 10.1724 3.7931C9.53172 3.7931 8.96552 4.30965 8.96552 5.01404C8.96552 5.70278 9.51682 6.25063 10.1724 6.25063Z"
              fill="#7A939C"
            />
            <path
              d="M9.11452 14.8129C9.11452 15.2355 9.38272 15.5172 9.78502 15.5172H10.5747C10.977 15.5172 11.2452 15.2355 11.2452 14.8129V8.03508C11.2452 7.61245 10.977 7.33069 10.5747 7.33069H9.78502C9.38272 7.33069 9.11452 7.61245 9.11452 8.03508V14.8129Z"
              fill="#7A939C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.6207 10C18.6207 14.7611 14.7611 18.6207 10 18.6207C5.23892 18.6207 1.37931 14.7611 1.37931 10C1.37931 5.23892 5.23892 1.37931 10 1.37931C14.7611 1.37931 18.6207 5.23892 18.6207 10Z"
              fill="#7A939C"
            />
          </svg>
          <template slot="popover" class="hint">
            <div class="hint__body">
              <div class="hint__text">
                {{ $t("card.item.status.pending-fop.hint") }}
              </div>
            </div>
            <div class="hint__footer">
              <a v-close-popover class="hint__close">{{
                $t("card.item.status.pending-fop.close")
              }}</a>
            </div>
          </template>
        </v-popover>
      </div>
    </div>
    <div
      class="card-item__status card-item__status--pending"
      v-else-if="isStatusPending"
    >
      <div class="card-item__status-icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99992 0.5C3.86457 0.5 0.5 3.86457 0.5 7.99992C0.5 12.1353 3.86457 15.4998 7.99992 15.4998C12.1353 15.4998 15.4998 12.1353 15.4998 7.99992C15.5041 7.69525 15.1762 7.36427 14.8199 7.35067C14.3748 7.33368 13.9996 7.64085 13.9996 8.07626C13.9996 10.9596 11.373 14.0594 7.99992 14C4.68672 13.9417 2.00885 11.3136 2 7.99992C1.99113 4.67734 4.6869 2.06527 7.99992 2C10.0922 1.95878 11.7049 2.6593 12.8633 4.24996H11.6445C11.57 4.24891 11.496 4.26268 11.4268 4.29047C11.3577 4.31827 11.2947 4.35953 11.2416 4.41187C11.1885 4.4642 11.1464 4.52657 11.1176 4.59534C11.0889 4.66411 11.074 4.73791 11.074 4.81245C11.074 4.887 11.0889 4.9608 11.1176 5.02957C11.1464 5.09834 11.1885 5.1607 11.2416 5.21304C11.2947 5.26538 11.3577 5.30664 11.4268 5.33444C11.496 5.36223 11.57 5.376 11.6445 5.37495H14.2695C14.4187 5.37493 14.8546 5.31566 14.9601 5.21018C15.0656 5.10469 15.1248 4.96163 15.1248 4.81245V2.03468C15.1259 1.9594 15.1119 1.88466 15.0835 1.8149C15.0552 1.74514 15.0132 1.68178 14.9599 1.62858C14.9066 1.57537 14.8432 1.5334 14.7734 1.50515C14.7036 1.4769 14.6288 1.46296 14.5536 1.46413C14.4045 1.46646 14.2625 1.52786 14.1586 1.63483C14.0548 1.74181 13.9977 1.88562 13.9999 2.03468V3.35156C12.6307 1.52812 10.4508 0.5 7.99992 0.5ZM7.69987 3.84591C7.35302 3.8497 6.99812 4.07327 6.99812 4.43746V8.93741C6.99818 9.50074 7.25055 9.74597 7.81242 9.74603H10.8124C11.1953 9.74603 11.3829 9.34375 11.3829 9.04688C11.3829 8.61719 11.1203 8.37056 10.8124 8.37492H8.37492V4.43746C8.37938 4.12618 8.05132 3.84208 7.69987 3.84591Z"
            fill="#00B7BD"
          />
        </svg>
      </div>
      <div class="card-item__status-text">
        {{ $t("card.item.status.pending") }}
      </div>
      <div class="card-item__status-hint">
        <v-popover placement="top" class="hint">
          <span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.1724 6.25063C10.828 6.25063 11.3793 5.68712 11.3793 5.01404C11.3793 4.30965 10.8131 3.7931 10.1724 3.7931C9.53172 3.7931 8.96552 4.30965 8.96552 5.01404C8.96552 5.70278 9.51682 6.25063 10.1724 6.25063Z"
                fill="#7A939C"
              />
              <path
                d="M9.11452 14.8129C9.11452 15.2355 9.38272 15.5172 9.78502 15.5172H10.5747C10.977 15.5172 11.2452 15.2355 11.2452 14.8129V8.03508C11.2452 7.61245 10.977 7.33069 10.5747 7.33069H9.78502C9.38272 7.33069 9.11452 7.61245 9.11452 8.03508V14.8129Z"
                fill="#7A939C"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.6207 10C18.6207 14.7611 14.7611 18.6207 10 18.6207C5.23892 18.6207 1.37931 14.7611 1.37931 10C1.37931 5.23892 5.23892 1.37931 10 1.37931C14.7611 1.37931 18.6207 5.23892 18.6207 10Z"
                fill="#7A939C"
              />
            </svg>
          </span>
          <template slot="popover" class="hint">
            <div class="hint__body">
              <div class="hint__text">
                {{ $t("card.item.status.pending-hint", {}) }}
              </div>
            </div>
            <div class="hint__footer">
              <a v-close-popover class="hint__close">{{
                $t("card.item.status.pending-hint-close")
              }}</a>
            </div>
          </template>
        </v-popover>
      </div>
    </div>
    <div class="card-item__header"></div>
    <div class="card-item__body"></div>
    <div class="card-item__footer">
      <div
        class="card-item__data"
        @click="showCardInfoPopup"
        v-if="temporaryActive"
      >
        <div class="card-item__data-title">
          {{ $t("card.item.data-title") }}
        </div>
        <div class="card-item__number">
          {{ hiddenPan }}
        </div>
      </div>
    </div>
    <card-item-data-popup
      v-if="isShowCardInfoPopup"
      :card="card"
      @closed="closeCardInfoPopup"
    ></card-item-data-popup>
    <div class="off"></div>
  </div>
</template>

<script>
import CardItemDataPopup from "@/components/profile/Cards/Popup/CardItemDataPopup";
import PopoverWithCloseButton from "@/components/popover/PopoverWithCloseButton";

export default {
  name: "CardItem",
  components: {
    PopoverWithCloseButton,
    CardItemDataPopup,
  },
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      isShowCardInfoPopup: false,
      isStatusTooltipOpen: false,
    };
  },
  inject: ["showSuccessTip"],
  computed: {
    temporaryActive() {
      return (
        this.card.status === "active" ||
        (this.card.pan && this.card.pan.length > 0)
      );
    },
    hiddenPan: function () {
      //TODO: Revert back to this.card.status check
      return this.temporaryActive ? `• • • • ${this.card.pan.slice(-4)}` : "";
    },
    isStatusPending() {
      return this.card?.status === "pending" || this.card?.status === "new";
    },
    isStatusBlocked() {
      return this.card?.status === "blocked";
    },
    cardBackgroundImage() {
      return (
        this.card?.card_type?.background_image || this.card?.card_type?.svg
      );
    },
  },
  methods: {
    closeStatusTooltipHandler() {
      this.isStatusTooltipOpen = false;
    },
    toggleStatusTooltip() {
      this.isStatusTooltipOpen = !this.isStatusTooltipOpen;
    },
    copyPanToClipboard() {
      if (!navigator.clipboard) {
        return;
      }

      navigator.clipboard.writeText(this.card.pan).then(
        () => {
          this.showSuccessTip(this.$t("card.item.pan-copied"));
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    showCardInfoPopup() {
      this.isShowCardInfoPopup = true;
    },
    closeCardInfoPopup() {
      this.isShowCardInfoPopup = false;
    },
    selectedCard() {
      this.$emit("selectedCard", this.card);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.card-item {
  position: relative;
  width: 100%;
  max-width: 340px;
  height: 213px;
  border-radius: 12px;
  background-size: cover;
  overflow: hidden;
  background-image: url("~@/assets/img/cards/unchain-card-bg.svg");
  padding: 12px 18px 16px;
  display: flex;
  flex-direction: column;

  .status-description,
  .status-description-content {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 0.375rem;
  }

  .status-description {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: $grayLight;
    border-radius: 0 2.5rem 2.5rem 0;
    padding: 0.8125rem 1rem 0.8125rem 1.5rem;

    &-content {
      &__text {
        font-family: "Nunito", sans-serif;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: $mainHot;
      }

      &__icon {
        display: flex;
        align-items: center;
      }
    }
  }

  &--pending,
  &--blocked {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $grayLight;
      opacity: 0.5;
      border-radius: 0.8125rem;
    }
  }

  &__status {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: $grayLight;
    border-radius: 0 40px 40px 0;
    padding: 13px 16px 13px 24px;
    display: flex;
    align-items: center;

    &--pending & {
      &-text {
        color: $mainCold;
      }
    }

    &--pending-fop & {
      &-text {
        color: $mainHot;
      }
    }

    &-icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      font-weight: 700;
      font-size: pxToRem(14);
      line-height: pxToRem(18);
    }

    &-hint {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;

      &::v-deep {
        .trigger {
          display: flex;
        }

        margin-left: 10px;
        cursor: pointer;

        .hint {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__header {
    &-line {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__body {
    margin-top: auto;
    margin-bottom: auto;
  }

  &__number {
    font-size: pxToRem(18);
    line-height: pxToRem(22);
    color: #a8abb5;
    font-weight: bold;
    margin-right: 50px;
    cursor: pointer;
  }

  &__iban {
    color: #a8abb5;
    font-size: pxToRem(18);
    line-height: pxToRem(22);
    user-select: text;
  }

  &__expiry {
    color: #a8abb5;
    display: flex;
    align-items: center;
    font-size: pxToRem(16);
    line-height: pxToRem(20);
    margin-left: auto;
    margin-right: 50px;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
  }

  &__vendor {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-type {
      margin-bottom: 12px;
    }
  }

  &__data {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__data-title {
    font-size: pxToRem(14);
    line-height: pxToRem(12);
    color: $mainCold;
    margin-bottom: 8px;
    cursor: pointer;
    display: inline-flex;
  }

  &.notActive {
    .off {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: #fff, $alpha: 0.4);
      cursor: pointer;
    }

    &.disabledCard {
      display: none;
    }
  }
}
</style>

<style lang="scss">
.card-item {
  .v-popover {
    .trigger,
    span {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
