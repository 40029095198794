<template>
  <div class="popup add-exchange-modal">
    <div class="popup__block add-exchange-modal__block">
      <base-loader v-if="isDataLoading" />
      <template v-if="activeStep === 5">
        <div class="success-screen">
          <div class="success-screen-title">
            {{ $t("connect_exchange.success.title") }}
          </div>
          <div class="connect-exchange-list success-screen-list">
            <div class="connect-exchange-item success-screen-item">
              <div class="success-screen-item__top">
                <div
                  class="success-screen-item__icon"
                  v-html="selectedExchange.svg"
                />
                <div class="connect-exchange-item__title">
                  {{
                    `${selectedExchange.name} ${selectedCurrencyData.currency}`
                  }}
                </div>
              </div>
              <div class="success-screen-item-balance">
                <div
                  class="success-screen-item-balance__text connect-exchange-item__text"
                >
                  {{ $t("connect_exchange.success.balance") }}
                </div>
                <div class="connect-exchange-item__title">
                  {{ selectedCurrencyData.balance }}
                  {{ selectedCurrencyData.currency }}
                </div>
              </div>
            </div>
            <div class="connect-exchange-item">
              <div
                class="connect-exchange-item__text"
                v-html="
                  $t('connect_exchange.success.info', { page: '/wallets' })
                "
              />
            </div>
          </div>
          <div class="success-screen-icon">
            <img src="@/assets/img/connectExchange/sphere.png" alt="sphere" />
          </div>
          <div class="success-screen-action">
            <primary-button
              :label="$t('referral_terms_modal.action').toString()"
              @click="closePopup"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="add-exchange">
          <div class="add-exchange-modal__header modal-header">
            <div class="popup__title modal-header__title">
              {{ modalTitle }}
            </div>
            <popup-close-button
              class="modal-header__close"
              @click="closePopup"
            />
          </div>
          <div class="add-exchange__content">
            <modal-stepper
              :active-step="activeStep"
              :steps-count="stepsCount"
            />
            <div class="add-exchange__title">
              {{ stepTitle }}
              <div v-if="isStepSubtitleVisible" class="add-exchange__subtitle">
                {{ $t("connect_exchange.whitelist.for_your_safety") }}
              </div>
            </div>

            <div class="add-exchange__fields">
              <component
                :is="currentStepComponent"
                :exchangesForSelect="exchangesForSelect"
                :selectedExchangeData="selectedExchange"
                :selectedExchangeId="selectedExchangeId"
                :selectedExchangeSlug="selectedExchangeSlug"
                :selectedDevice="selectedDevice"
                :activeStep="activeStep"
                :fieldsForCurrentStep="fieldsForCurrentStep"
                :exchangeDataErrors="v$.exchangeData"
                v-model="exchangeData"
                @selectExchange="selectExchangeHandler"
                @selectDevice="selectDevice"
              />
            </div>
            <div
              class="add-exchange__actions"
              :class="{
                'add-exchange__actions--back-button': isBackButtonVisible,
              }"
            >
              <popup-back-button
                v-if="isBackButtonVisible"
                :text="secondaryActionLabel"
                primary
                @click="processSecondaryAction"
              />
              <primary-button
                :disabled="isContinueButtonDisabled"
                :label="primaryActionLabel"
                @click="processPrimaryAction"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
import { ExchangeApi } from "@/office/exchange-api";
import PopupCloseButton from "@/components/button/PopupCloseButton";
import responsiveMixin from "@/mixins/responsive-mixin";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import BaseLoader from "@/components/base/BaseLoader";
import { mapState } from "vuex";
import PopupBackButton from "@/components/button/PopupBackButton";
import ModalStepper from "@/components/profile/Exchanges/Popup/AddExchangeModal/Stepper/ModalStepper";
import ConnectExchangeStep from "@/components/profile/Exchanges/Popup/AddExchangeModal/ConnectExchangeStep";
import RecommendationsStep from "@/components/profile/Exchanges/Popup/AddExchangeModal/RecommendationsStep";
import ExchangeConnection from "@/components/profile/Exchanges/Popup/AddExchangeModal/ExchangeConnection";
import { WalletApi } from "@/office/wallet-api";
import { capitalizeFirstLetter } from "@/utils";

export default {
  name: "AddExchangePopup",
  components: {
    ModalStepper,
    PopupBackButton,
    BaseLoader,
    PopupCloseButton,
    PrimaryButton,
    ConnectExchangeStep,
    RecommendationsStep,
    ExchangeConnection,
  },
  mixins: [responsiveMixin],
  inject: ["showErrorTip"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      selectedExchangeId: null,
      defaultTipHTML: `You need to go to the exchange website in<br />
            <b>> Your account > Profile settings > API section</b> in order to
            get the API key and&nbsp;Secret&nbsp;Key.`,
      exchanges: [],
      apiKey: "",
      secretKey: "",
      exchangeData: {},
      stepsCount: 4,
      activeStep: 1,
      isDataLoading: false,
      isShowTipVideoFrame: false,
      selectedDevice: null,
    };
  },
  validations() {
    if (this.activeStep === 1) {
      return {
        selectedExchangeId: {
          required: required,
        },
      };
    } else if (this.activeStep === 3 || this.activeStep === 4) {
      let validationsObj = {
        exchangeData: {},
      };

      this.fieldsForCurrentStep.forEach((_field) => {
        if (_field.name !== "title") {
          validationsObj.exchangeData[_field.name] = {
            required: required,
          };
        }
      });

      return validationsObj;
    }
  },
  created() {
    document.body.classList.add("popup-opened");
    this.loadAllExchanges();
  },
  beforeDestroy() {
    document.body.classList.remove("popup-opened");
  },
  computed: {
    ...mapState(["selectedLocale"]),
    selectedExchange() {
      return this.selectedExchangeId
        ? this.exchanges.find(
            (e) => parseInt(e.id) === parseInt(this.selectedExchangeId)
          )
        : null;
    },
    isExmoSelected() {
      return this.selectedExchangeSlug === "exmo";
    },
    selectedCurrencyData() {
      if (!this.exchangeData?.currency || !this.selectedExchange) return null;
      const selectedCurrency = this.selectedExchange?.fields
        .find((field) => field.name === "currency")
        ?.values.find(
          (value) =>
            value.id.toLowerCase() === this.exchangeData.currency.toLowerCase()
        );
      if (!selectedCurrency) return null;
      return {
        currency: this.exchangeData?.currency ?? "",
        balance: selectedCurrency?.balance ?? "",
      };
    },
    exchangesForSelect: function () {
      let exchanges = [];

      this.exchanges.forEach((exchange) => {
        exchanges.push({
          id: exchange.id,
          title: exchange.name,
          icon: exchange.svg || null,
        });
      });

      return exchanges;
    },
    fieldsForCurrentStep: function () {
      if (!this.selectedExchange) {
        return [];
      }

      return this.selectedExchange.fields.filter(
        (field) => field.step === this.activeStep - 1
      );
    },
    isBackButtonVisible() {
      return this.activeStep !== 1;
    },
    isContinueButtonDisabled() {
      if (this.activeStep === 3) {
        if (this.isExmoSelected) {
          return (
            !this.exchangeData.isAddressCopied ||
            !this.exchangeData.access_key ||
            !this.exchangeData.secret_key
          );
        }
        return !this.exchangeData.access_key || !this.exchangeData.secret_key;
      }

      if (this.activeStep === 4) {
        return (
          !this.exchangeData.isAddressCopied || !this.exchangeData.currency
        );
      }
      return this.selectedExchangeId === null;
    },
    secondaryActionLabel() {
      return this.activeStep === 1 ? this.$t("cancel") : this.$t("back");
    },
    primaryActionLabel() {
      return this.$t("continue");
    },
    currentStepComponent() {
      switch (this.activeStep) {
        case 1: {
          return "ConnectExchangeStep";
        }
        case 2: {
          return "RecommendationsStep";
        }
        case 3:
        case 4: {
          return "ExchangeConnection";
        }
        default: {
          return "ConnectExchangeStep";
        }
      }
    },
    selectedExchangeSlug() {
      return this.selectedExchange?.slug ?? null;
    },

    modalTitle() {
      if (this.activeStep === 1) {
        return this.$t("new_wallet");
      }
      if (this.activeStep === 4 && this.isExmoSelected) {
        return this.$t("cryptocurrency_connect");
      }
      return `${this.$t("new_wallet")} ${
        capitalizeFirstLetter(this.selectedExchangeSlug) || ""
      }`;
    },
    stepTitle() {
      if (this.activeStep === 4 && this.isExmoSelected) {
        return this.$t("exchange_stepper_exmo_title");
      }
      return this.$t(`exchange_stepper[${this.activeStep - 1}]`);
    },
    isStepSubtitleVisible() {
      return this.activeStep === 4 && !this.isExmoSelected;
    },
  },
  watch: {
    async activeStep() {
      if (this.activeStep > 1 && this.selectedExchangeId === null) {
        this.activeStep = 1;
      }
      if (this.activeStep === 4) {
        this.isDataLoading = true;
        let currencies = null;

        try {
          currencies = await ExchangeApi.getExchangeCurrencies(
            this.selectedExchangeId,
            this.exchangeData
          );
        } catch (e) {
          this.showErrorTip(this.$t("add-wallet.credentials.error"));
          this.isDataLoading = false;
          this.backStep();
          return;
        }

        currencies = currencies.data.currencies.map((_c) => ({
          ..._c,
          title: `${_c.title}`,
        }));

        let currencyFieldIndex = this.selectedExchange.fields.findIndex(
          (_field) => _field.name === "currency"
        );

        if (currencyFieldIndex > -1) {
          this.selectedExchange.fields[currencyFieldIndex].values = currencies;
        }

        this.isDataLoading = false;
      }
    },
  },
  methods: {
    selectExchangeHandler(exchangeId) {
      if (this.selectedExchangeId === exchangeId) {
        this.selectedExchangeId = null;
        return;
      }
      this.selectedExchangeId = exchangeId;
    },
    async loadAllExchanges() {
      this.isDataLoading = true;
      this.exchanges = (await ExchangeApi.getAllExchanges()).data?.exchanges;
      this.isDataLoading = false;
    },
    closePopup() {
      this.$emit("closed");
    },
    async processSave() {
      const validationResult = await this.v$.$validate();
      if (!validationResult || !this.exchangeData.isAddressCopied) {
        return;
      }
      delete this.exchangeData.isAddressCopied;
      try {
        this.isDataLoading = true;
        await WalletApi.createWallet(this.selectedExchangeId, {
          ...this.exchangeData,
          title: `${this.selectedExchange.name} ${this.selectedCurrencyData.currency}`,
        });
        this.isDataLoading = false;
        this.activeStep = 5;
        this.$emit("success", {
          exchangeId: this.selectedExchange.id,
          data: this.exchangeData,
        });
      } catch (error) {
        console.error(error);
        this.showErrorTip(this.$t("tip.wallet_add_error"));
        this.isDataLoading = false;
      }
      this.exchangeData.isAddressCopied = false;
    },
    processPrimaryAction() {
      if (this.activeStep !== this.stepsCount) {
        this.nextStep();
      } else {
        this.processSave();
      }
    },
    processSecondaryAction() {
      if (this.activeStep === 1) {
        this.closePopup();
      } else {
        this.backStep();
      }
    },
    async nextStep() {
      const validationResult = await this.v$.$validate();

      if (!validationResult) {
        return;
      }

      if (this.activeStep < this.stepsCount) {
        this.activeStep++;
      }
    },
    backStep() {
      if (this.activeStep > 1) {
        this.activeStep--;
      }
    },
    getFieldComponent(type) {
      switch (type) {
        case "text":
          return "TextInput";
        case "select":
          return "CustomSelect";
        case "password":
          return "PasswordInput";
        default:
          return "TextInput";
      }
    },
    getFieldProps(field) {
      if (field.type === "select") {
        return {
          emptyPlaceholder: field.title,
          values: field.values,
          showIcon: false,
          showSelectedTitle: true,
          showSearch: true,
        };
      }
    },
    toggleVideoFrame() {
      this.isShowTipVideoFrame = !this.isShowTipVideoFrame;

      // A hack for the safari browser
      setTimeout(() => {
        const element = document.querySelector(".add-exchange__tip");
        if (!element) return;
        element.style.display = "none";
        element.offsetHeight;
        element.style.display = "";
      }, 0);
    },
    copyWallet(data) {
      navigator.clipboard.writeText(data);
    },
    exchange_stepper_fild_name(data) {
      switch (data.toLowerCase()) {
        case "access_key":
          return this.$t("exchange_stepper_fild_name.accessKey");
        case "secret_key":
          return this.$t("exchange_stepper_fild_name.secretKey");
        case "title":
        case "заголовок":
          return this.$t("exchange_stepper_fild_name.title");
        case "currency":
        case "Currency":
        case "Валюта":
        case "валюта":
          return this.$t("exchange_stepper_fild_name.currency");
        default:
          return data;
      }
    },
    selectDevice(device) {
      this.selectedDevice = device;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

.add-exchange-modal {
  --modal-height: 770px;
  --modal-width: 550px;
  --exchange-item-width: 8.9375rem;

  &__block {
    position: relative;
    padding: 2.5rem;
    max-width: unset;
    width: var(--modal-width);
    height: var(--modal-height);
  }

  &__header {
    margin-bottom: 2rem;
  }
}

.modal-header {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-family: "Rubik", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.add-exchange,
.add-exchange__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.add-exchange {
  &__title,
  &__subtitle {
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__title {
    margin-top: 1rem;
    font-weight: 600;
    color: $mainBlack;
  }

  &__subtitle {
    margin-top: 0.125rem;
    font-weight: 400;
    color: $gray1;
  }

  &__content {
    overflow: hidden;

    b {
      color: #333f48;
    }
  }

  &__fields {
    height: 100%;
    margin: 2.5rem 0;
    overflow: auto;
  }

  &__field {
    margin-bottom: 62px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    margin-top: auto;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    column-gap: 3rem;

    &--back-button {
      grid-template-columns: auto 1fr;
    }
  }
}

.exchange-list {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, var(--exchange-item-width));
  column-gap: 1.25rem;
  row-gap: 2rem;
}

.success-screen {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-title {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: $mainBlack;
    margin-bottom: 3rem;
  }

  &-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 8.125rem;
      height: 9.75rem;
    }
  }

  &-action {
    margin-top: auto;
  }

  &-item {
    display: grid;
    grid-auto-flow: row;
    row-gap: 1rem;

    &-balance {
      justify-content: space-between;

      &__text {
        font-family: "Rubik", sans-serif;
      }
    }

    &-balance,
    &__icon,
    &__top {
      display: flex;
      align-items: center;
    }

    &__top {
      column-gap: 1.1875rem;
    }

    &__icon {
      justify-content: center;
      width: 2rem;
      height: 2rem;
      background-color: #fff;
      border: 1px solid $gray3;
      border-radius: 100%;
      padding: 0.25rem;

      &::v-deep svg {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .add-exchange-modal {
    --modal-height: 100%;
    --modal-width: 100%;

    &__block {
      padding: 1rem;
    }

    &__header {
      margin-bottom: 1.5rem;
    }
  }
  .modal {
    &-header {
      &__title {
        width: unset;
        margin: 0;
      }

      &__close {
        position: unset;
      }
    }
  }
  .exchange-list {
    grid-template-columns: repeat(2, var(--exchange-item-width));
  }

  .add-exchange {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    &__content {
      width: 100%;
    }

    &__fields {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      overflow: auto;
    }
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";

.add-exchange {
  &__tip {
    &-text {
      width: 100%;
      color: $gray1;

      ul {
        padding: 0 0 0 20px;
        margin: 10px 0 0 0;

        li {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-link {
      font-weight: 700;
      color: $mainCold;
      margin-top: 28px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    &-frame {
      min-height: 200px;
      margin-top: 24px;
      width: 100%;
      height: 200px;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    &-content {
      font-family: "Quicksand", sans-serif;
      color: $gray1;

      b {
        color: $mainBlack;
      }

      .dark {
        color: $mainBlack;
      }

      ul {
        list-style: none; /* Remove default bullets */
        padding-left: 26px;

        li {
          font-size: pxToRem(16);
          line-height: pxToRem(24);
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: $mainBlack; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }
        }
      }

      a {
        color: $mainCold;
      }
    }
  }
}

.add-exchange {
  &__img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ip_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.copy-wallet {
  &-title {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: $gray1;
    margin-right: 16px;
  }

  &-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
    gap: 6px;

    width: 195px;
    height: 30px;

    background: #ffffff;
    border-radius: 8px;
    position: relative;

    &-half {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 9px;
      gap: 6px;

      height: 30px;

      background: #ffffff;
      border-radius: 8px;
      position: relative;
      width: 103px;
    }

    @media screen and (max-width: 486px) {
      &-half {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 16px;
        gap: 6px;

        width: 195px;
        height: 30px;

        background: #ffffff;
        border-radius: 8px;
        position: relative;
        width: 143px;
      }
    }

    &-full {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 16px;
      gap: 6px;

      width: 195px;
      height: 30px;

      background: #ffffff;
      border-radius: 8px;
      position: relative;
      width: 100%;
    }
  }

  &-link {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: $gray1;
    overflow: hidden;
  }

  &-button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    width: 16px;
    height: 16px;
    background: #ffffff;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.yellow-block {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  gap: 8px;

  background: linear-gradient(0deg, #fbf7d8, #fbf7d8), #fbe122;
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.add-exchange__tip::-webkit-scrollbar {
  width: 6px;
  height: 88px;
  background: inherit;
}

.add-exchange__tip::-webkit-scrollbar-track {
  background: none;
}

.add-exchange__tip::-webkit-scrollbar-track-piece {
  background: none;
}

.add-exchange__tip::-webkit-scrollbar-thumb {
  background: #dde5ed;
  border-radius: 4px;
}

.add-exchange__tip::-webkit-scrollbar-thumb:window-inactive {
  background: none;
}

.tooltip.popover.vue-popover-theme.open {
  z-index: 99;
}
</style>
