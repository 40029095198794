import axiosInstance from "@/services/http/axios-instance";

class _SumsubApi {
  getAccessToken(cardProductId) {
    return axiosInstance.post("/sumsub/token", {
      card_type_id: cardProductId,
    });
  }
}

export const SumsubApi = new _SumsubApi();
