import axiosInstance from "@/services/http/axios-instance";

class AuthApi {
  login(username, password) {
    return axiosInstance.post("/user/login", {
      username,
      password,
    });
  }

  resendOtp(username, otpType, isForgot = false, newUser = "false") {
    return axiosInstance.post("/user/otp/resend", {
      username,
      otp_type: otpType,
      is_forgot: isForgot,
      new_user: newUser,
    });
  }

  authorizeOtp(username, otpCodes, isForgot = false, recaptchaToken = null) {
    return axiosInstance.post("/user/otp/authorize", {
      username,
      otp_codes: otpCodes,
      is_forgot: isForgot,
      recaptcha_token: recaptchaToken,
    });
  }

  register(
    phone,
    originalPhone,
    email,
    password,
    countryCode = null,
    country = null,
    termsAndCondVersion = "1.0",
    parentReferralUUID = null,
    utm = null
  ) {
    return axiosInstance.post("/user/registration", {
      phone,
      original_phone: originalPhone,
      email,
      password,
      c_password: password,
      country_code: countryCode,
      country: country,
      terms_and_cond_version: termsAndCondVersion,
      parent_referral_uuid: parentReferralUUID,
      utm,
    });
  }

  refreshToken(refreshToken) {
    return axiosInstance.post("/user/refresh.token", {
      refresh_token: refreshToken,
    });
  }
}

export default new AuthApi();
