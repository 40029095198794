import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/auth/Login.vue";
import Settings from "@/views/Settings";
import Exchanges from "@/views/Exchanges";
import Registration from "@/views/auth/Registration";

import store from "@/store";

import RegistrationOtp from "@/views/auth/RegistrationOtp";
import OnboardingDiiaQR from "@/views/auth/Onboarding/OnboardingDiiaQR";
import OnboardingAddress from "@/views/auth/Onboarding/OnboardingAddress";
import OnboardingSelfie from "@/views/auth/Onboarding/OnboardingSelfie";
import RegistrationPassword from "@/views/auth/RegistrationPassword";
import ComingSoonTitled from "@/views/ComingSoonTitled";

import ForgotPassword from "@/views/auth/ForgotPassword/ForgotPassword";
import ForgotPasswordOtp from "@/views/auth/ForgotPassword/ForgotPasswordOtp";
import ForgotPasswordPassword from "@/views/auth/ForgotPassword/ForgotPasswordPassword";

import FAQ from "@/views/FAQ";
import WelcomePage from "@/views/WelcomePage";
import CardsPage from "@/views/CardsPage";

import UnexOnboardingBase from "@/views/Unex/Onboarding/UnexOnboardingBase";
import UnexOnboardingDiiaMain from "@/views/Unex/Onboarding/Steps/UnexOnboardingDiiaMain";
import UnexOnboardingSumSub from "@/views/Unex/Onboarding/Steps/UnexOnboardingSumSub";
import UnexOnboardingOTP from "@/views/Unex/Onboarding/Steps/UnexOnboardingOTP";
import UnexOnboardingDiiaRedirectPage from "@/views/Unex/Onboarding/Steps/UnexOnboardingDiiaRedirectPage";
import UnexOnboardingQuestionnaire from "@/views/Unex/Onboarding/Steps/UnexOnboardingQuestionnaire";
import UnexOnboardingDiiaChildren from "@/views/Unex/Onboarding/Steps/UnexOnboardingDiiaChildren";
import UnexOnboardingBusinessRules from "@/views/Unex/Onboarding/Steps/UnexOnboardingBusinessRules";
import UnexOnboardingBankRequest from "@/views/Unex/Onboarding/Steps/UnexOnboardingBankRequest";
import UnexOnboardingBusinessRulesChildren from "@/views/Unex/Onboarding/Steps/UnexOnboardingBusinessRulesChildren";
import UnexOnboardingQuestionnaireVerify from "@/views/Unex/Onboarding/Steps/UnexOnboardingQuestionnaireVerify";
import UnexOnboardingDiiaSign from "@/views/Unex/Onboarding/Steps/UnexOnboardingDiiaSign";
import UnexOnboardingDiiaSignRedirectPage from "@/views/Unex/Onboarding/Steps/UnexOnboardingDiiaSignRedirectPage";
import UnexOnboardingCardCreation from "@/views/Unex/Onboarding/Steps/UnexOnboardingCardCreation";
import UnexOnboardingFail from "@/views/Unex/Onboarding/Steps/UnexOnboardingFail";
import UnexOnboardingPKRSign from "@/views/Unex/Onboarding/Steps/UnexOnboardingPKRSign";
import StakingComingSoon from "@/views/StakingComingSoon";

import {
  authMiddleware,
  onlyGuestMiddleware,
  useCanMiddleware,
} from "@/router/middleware";
import { middlewarePipeline } from "@/router/middlewarePipeline";
import MobileRedirect from "@/views/MobileRedirect.vue";

Vue.use(VueRouter);

const Token = () => import("@/views/Token");
const BuyToken = () => import("@/views/Token/BuyToken");
const InviteUnchain = () => import("@/views/auth/InviteUnchain");
const InviteKol = () => import("@/views/auth/InviteKol");
const ReferralPartner = () => import("@/views/Referrals/ReferralPartner");
const Referrals = () => import("@/views/Referrals");

const routes = [
  {
    path: "/",
    redirect: "/settings",
    meta: {
      middleware: [authMiddleware],
      requireAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "auth-layout",
      middleware: [onlyGuestMiddleware],
    },
  },
  {
    path: "/sign-up",
    name: "Registration",
    component: Registration,
    meta: {
      layout: "auth-layout",
      middleware: [onlyGuestMiddleware],
    },
  },
  {
    path: "/sign-up/otp",
    name: "RegistrationOtp",
    component: RegistrationOtp,
    meta: {
      layout: "auth-layout",
      middleware: [onlyGuestMiddleware],
    },
  },
  {
    path: "/sign-up/password",
    name: "RegistrationPassword",
    component: RegistrationPassword,
    meta: {
      layout: "auth-layout",
      middleware: [authMiddleware],
    },
  },
  {
    path: "/invite/unchain/:token",
    name: "InviteUnchain",
    component: InviteUnchain,
    props: true,
    meta: {
      layout: "auth-layout",
    },
  },
  {
    path: "/invite/kol/:token",
    name: "InviteKol",
    component: InviteKol,
    props: true,
    meta: {
      layout: "auth-layout",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      layout: "auth-layout",
      middleware: [onlyGuestMiddleware],
    },
  },
  {
    path: "/forgot-password/otp",
    name: "ForgotPasswordOtp",
    component: ForgotPasswordOtp,
    meta: {
      layout: "auth-layout",
      middleware: [onlyGuestMiddleware],
    },
  },
  {
    path: "/forgot-password/new-password",
    name: "ForgotPasswordPassword",
    component: ForgotPasswordPassword,
    meta: {
      layout: "auth-layout",
      middleware: [authMiddleware],
    },
  },
  {
    path: "/onboarding/diia",
    name: "OnboardingDiiaQR",
    component: OnboardingDiiaQR,
    meta: {
      layout: "onboarding-layout",
    },
  },
  {
    path: "/onboarding/address",
    name: "OnboardingAddress",
    component: OnboardingAddress,
    meta: {
      layout: "onboarding-layout",
    },
  },
  {
    path: "/onboarding/selfie",
    name: "OnboardingSelfie",
    component: OnboardingSelfie,
    meta: {
      layout: "onboarding-layout",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      pageTitle: "settings",
      middleware: [authMiddleware],
      requireAuth: true,
    },
  },
  {
    path: "/wallets",
    name: "Wallets",
    component: Exchanges,
    meta: {
      pageTitle: "wallets",
      middleware: [authMiddleware, useCanMiddleware("wallets")],
      component: "wallets",
    },
  },
  {
    path: "/cards",
    name: "Cards",
    component: CardsPage,
    meta: {
      pageTitle: "cards_and_accounts",
      middleware: [authMiddleware],
      layoutBg: "#F4F8FC",
    },
  },
  {
    path: "/referrals",
    name: "Referrals",
    component: Referrals,
    meta: {
      pageTitle: "referrals_nav",
      middleware: [authMiddleware],
      layoutBg: "#f4f8fc",
    },
  },
  {
    path: "/referral-partner",
    name: "ReferralPartner",
    component: ReferralPartner,
    meta: {
      pageTitle: "referrals_nav",
      middleware: [authMiddleware],
      layoutBg: "#F4F8FC",
    },
  },
  {
    path: "/staking",
    name: "Referrals",
    component: StakingComingSoon,
    meta: {
      pageTitle: "staking_nav",
      middleware: [authMiddleware],
      layoutBg: "#FFFFFF",
    },
  },
  {
    path: "/balance",
    name: "Balances",
    component: ComingSoonTitled,
    meta: {
      pageTitle: "balance",
      layoutBg: "#FFFFFF",
      middleware: [authMiddleware],
    },
  },
  {
    path: "/token",
    redirect: "/token/staking",
    meta: {
      middleware: [authMiddleware],
    },
  },
  {
    path: "/token/staking",
    name: "TokenStaking",
    component: Token,
    meta: {
      pageTitle: "staking",
      middleware: [authMiddleware],
    },
  },
  {
    path: "/token/buy",
    name: "TokenBuy",
    component: BuyToken,
    meta: {
      pageTitle: "buy",
      middleware: [authMiddleware],
    },
  },
  {
    path: "/token/portfolio",
    name: "TokenPortfolio",
    component: ComingSoonTitled,
    meta: {
      pageTitle: "portfolio",
      layoutBg: "#FFFFFF",
      middleware: [authMiddleware],
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    meta: {
      pageTitle: "faq_title",
      layoutBg: "#FFFFFF",
      middleware: [authMiddleware],
    },
  },
  {
    path: "/welcome",
    name: "WelcomePage",
    component: WelcomePage,
    meta: {
      pageTitle: "",
      layoutBg: "#FFFFFF",
      middleware: [authMiddleware],
    },
  },
  {
    path: "/unex/onboarding/diia/success",
    name: "UnexOnboardingDiiaRedirectPage",
    component: UnexOnboardingDiiaRedirectPage,
    meta: {
      layout: "onboarding-layout",
    },
  },
  {
    path: "/unex/onboarding/fail",
    name: "UnexOnboardingFail",
    component: UnexOnboardingFail,
    meta: {
      layout: "onboarding-layout",
      middleware: [authMiddleware],
    },
  },
  {
    path: "/unex/onboarding/diia/sign/success",
    name: "UnexOnboardingDiiaSignRedirectPage",
    component: UnexOnboardingDiiaSignRedirectPage,
    meta: {
      layout: "onboarding-layout",
    },
  },
  {
    path: "/unex/onboarding",
    name: "UnexOnboarding",
    component: UnexOnboardingBase,
    meta: {
      layout: "onboarding-layout",
      middleware: [authMiddleware],
    },
    children: [
      {
        path: "birth-certificates",
        name: "UnexOnboardingDiiaChildren",
        component: UnexOnboardingDiiaChildren,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "personal-documents",
        name: "UnexOnboardingDiiaMain",
        component: UnexOnboardingDiiaMain,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "business-rules/main",
        name: "UnexOnboardingBusinessRules",
        component: UnexOnboardingBusinessRules,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "business-rules/children",
        name: "UnexOnboardingBusinessRulesChildren",
        component: UnexOnboardingBusinessRulesChildren,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "bank-request",
        name: "UnexOnboardingBankRequest",
        component: UnexOnboardingBankRequest,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "photo",
        name: "UnexOnboardingPhoto",
        component: UnexOnboardingSumSub,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "otp",
        name: "UnexOnboardingOtp",
        component: UnexOnboardingOTP,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "questionnaire",
        name: "UnexOnboardingQuestionnaire",
        component: UnexOnboardingQuestionnaire,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "questionnaire/verify",
        name: "UnexOnboardingQuestionnaireVerify",
        component: UnexOnboardingQuestionnaireVerify,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "pkr/agreement",
        name: "UnexOnboardingPKRSign",
        component: UnexOnboardingPKRSign,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "diia-sign",
        name: "UnexOnboardingDiiaSign",
        component: UnexOnboardingDiiaSign,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
      {
        path: "card-creation",
        name: "UnexOnboardingCardCreation",
        component: UnexOnboardingCardCreation,
        meta: {
          layout: "onboarding-layout",
          middleware: [authMiddleware],
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found-routes",
    component: MobileRedirect,
    meta: {
      layout: "mobile-app-launch-layout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta?.middleware;
  if (!to.meta.middleware) return next();
  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
