<template>
  <router-link :to="toLink" class="logo" :class="`logo--${size}`">
    <div class="logo__icon">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="32" cy="32" r="32" fill="#00B7BD" />
        <path
          d="M46.3167 23C42.3102 23 39.0798 25.5806 39.0022 30.4216H34.9084L35.3256 24.9888C35.3935 24.0575 34.8405 23.4463 33.9189 23.4463H32.0661C31.1348 23.4463 30.5527 23.9314 30.4848 24.853L29.7281 34.8843C29.5923 36.4947 28.9036 37.6299 27.5648 37.6299C26.2552 37.6299 25.5373 36.3881 25.5373 34.8456V26.153C25.5373 25.2216 24.9165 24.6105 23.9949 24.6105H22.3069C21.3756 24.6105 20.7645 25.2313 20.7645 26.153V34.8456C20.7645 36.3881 20.0466 37.6299 18.737 37.6299C17.3983 37.6299 16.7095 36.4947 16.6028 34.8843L15.8849 24.9888C15.817 24.0575 15.235 23.4463 14.3037 23.4463H12.4508C11.5195 23.4463 10.9375 24.0672 11.0054 24.9888L11.762 35.2239C12.0725 39.3083 14.7887 41.85 18.6303 41.85C20.6869 41.85 22.2002 40.9574 23.1315 39.4828C24.0628 40.9574 25.5664 41.85 27.6327 41.85C31.5131 41.85 34.2294 39.415 34.5398 35.2239L34.6465 33.8463H39.0022V34.0888C39.0022 39.1433 42.2326 41.85 47.0443 41.85C49.2755 41.85 50.5172 41.5396 51.4776 41.2679C52.341 40.9963 52.7872 40.2395 52.5447 39.2402L52.3022 38.2411C52.0984 37.3777 51.4097 37.009 50.5172 37.2418C49.8284 37.4164 48.5964 37.688 47.0443 37.688C44.9489 37.688 43.8818 36.5239 43.8818 34.0791V33.8366H52.1275C53.0588 33.8366 53.67 33.2157 53.67 32.2941V29.9269C53.67 25.8522 50.3717 23 46.3167 23ZM48.7516 30.4216H43.8721V29.9366C43.8721 28.3552 44.8713 27.1523 46.307 27.1523C47.8494 27.1523 48.7419 28.394 48.7419 29.9366V30.4216H48.7516Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="logo__text">
      <svg
        width="55"
        height="22"
        viewBox="0 0 55 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.3455 2.4313C36.3455 1.70169 36.7507 1.16162 37.5063 1.05315L38.8293 0.863615C39.6397 0.755145 40.207 1.21529 40.207 2.02482V19.5833C40.207 20.313 39.7207 20.7994 38.9914 20.7994H37.56C36.8306 20.7994 36.3444 20.313 36.3444 19.5833V2.4313H36.3455Z"
          fill="#00B7BD"
        />
        <path
          d="M43.1711 12.2644C43.1711 8.26583 45.8992 6.32136 48.7608 6.32136C49.6248 6.32136 50.57 6.64563 51.1373 6.9699V2.24287C51.1373 1.51326 51.6235 1.02686 52.3529 1.02686H53.7843C54.5136 1.02686 54.9999 1.51326 54.9999 2.24287V15.2365C54.9999 18.9644 52.2718 21.1521 49.0861 21.1521C45.8992 21.1521 43.1723 18.9644 43.1723 15.2365V12.2644H43.1711ZM49.0861 17.8832C50.2743 17.8832 51.1384 16.9652 51.1384 15.1006V10.0756C50.4901 9.80497 49.7881 9.58917 49.1135 9.58917C47.9252 9.58917 47.0075 10.3462 47.0075 12.3991V15.1006C47.0064 16.9652 47.8978 17.8832 49.0861 17.8832Z"
          fill="#00B7BD"
        />
        <path
          d="M27.7608 6.32129C24.6115 6.32129 22.0707 8.35368 22.0113 12.1559H18.7948L19.1235 7.88783C19.1771 7.15822 18.7457 6.67182 18.0163 6.67182H16.5575C15.8282 6.67182 15.3693 7.04975 15.3156 7.77936L14.721 15.6669C14.6125 16.9366 14.0726 17.8283 13.0202 17.8283C11.9941 17.8283 11.4268 16.8555 11.4268 15.6406V8.80583C11.4268 8.07623 10.9405 7.58982 10.2111 7.58982H8.88934C8.15996 7.58982 7.67371 8.07623 7.67371 8.80583V15.6406C7.67371 16.8566 7.10641 17.8283 6.08025 17.8283C5.0267 17.8283 4.4868 16.9366 4.40576 15.6669L3.83847 7.88669C3.78482 7.15708 3.32596 6.67068 2.59658 6.67068H1.13896C0.409581 6.67068 -0.0492785 7.15708 0.00436918 7.88669L0.59906 15.9363C0.842187 19.1505 2.97554 21.1498 6.00035 21.1498C7.6212 21.1498 8.80944 20.4476 9.53768 19.2864C10.2671 20.4476 11.4553 21.1498 13.075 21.1498C16.1261 21.1498 18.2606 19.2316 18.5025 15.9363L18.5859 14.8562H22.0079V15.0457C22.0079 19.0169 24.5465 21.1509 28.3269 21.1509C30.0825 21.1509 31.055 20.9077 31.8106 20.6919C32.4863 20.4761 32.8368 19.8812 32.6473 19.098L32.4578 18.3147C32.2957 17.6399 31.7558 17.3419 31.0538 17.5314C30.5139 17.6662 29.5414 17.8831 28.3258 17.8831C26.6787 17.8831 25.8409 16.9651 25.8409 15.0469V14.8573H32.322C33.0513 14.8573 33.5376 14.3709 33.5376 13.6413V11.7768C33.5399 8.56377 30.9477 6.32129 27.7608 6.32129ZM29.6784 12.157H25.8443V11.7791C25.8443 10.5368 26.6273 9.59139 27.7619 9.59139C28.9775 9.59139 29.6795 10.5642 29.6795 11.7791V12.157H29.6784Z"
          fill="#00B7BD"
        />
      </svg>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "small",
    },
    toLink: {
      type: String,
      default: "/",
    },
  },
  name: "Logo",
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;

  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &__icon {
    width: 64px;
    height: 64px;

    margin-right: 28px;

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    height: 22px;

    svg,
    img {
      height: 100%;
    }
  }

  &--small & {
    &__icon {
      width: 44px;
      height: 44px;
      margin-right: 22px;
    }

    &__text {
      height: 20px;
    }
  }
}
</style>
