<template>
  <div class="unex-otp__wrapper">
    <onboarding-loader
      :lottie-json="waitingLottie"
      :title="$t('unex.onboarding.steps.business-rules-main.title')"
      :sub-title="$t('unex.onboarding.steps.business-rules-main.sub-title')"
    ></onboarding-loader>
  </div>
</template>

<script>
import responsiveMixin from "@/mixins/responsive-mixin";
import OnboardingLoader from "@/components/profile/Onboarding/OnboardingLoader";
import { mapGetters, mapState } from "vuex";
import waitingLottie from "@/assets/lottie/waiting-loader.json";

export default {
  name: "UnexOnboardingDiiaMain",
  components: { OnboardingLoader },
  mixins: [responsiveMixin],
  inject: ["toNextStep"],
  data() {
    return {
      waitingLottie: waitingLottie,
    };
  },
  computed: {
    ...mapState(["wss"]),
    ...mapGetters(["userData"]),
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-otp {
  position: relative;
  width: 100%;
  max-width: 530px;
  background: #ffffff;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  border-radius: 24px;
  padding: 64px;
}
</style>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-otp {
  &__hints {
    background: $grayLight;
    border-radius: 8px;
    padding: 24px;
  }

  &__hint {
    display: flex;
    align-items: flex-start;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    b {
      color: $mainBlack;
      font-weight: 700;
    }

    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $gray1;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
</style>
