var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"unex-onboarding"},[_c('div',{staticClass:"unex-onboarding__steps"},_vm._l((_vm.visibleSteps),function(step,index){return _c('div',{key:`step-${step.slug}`,staticClass:"unex-onboarding__step",class:{
        active: _vm.currentStepIndex === index,
        finished: _vm.currentStepIndex > index,
      },style:({
        width: `${100 / _vm.visibleSteps.length}%`,
      })},[_c('div',{staticClass:"unex-onboarding__step-body"},[_c('div',{staticClass:"unex-onboarding__step-mark-wrapper"},[(_vm.currentStepIndex > index)?_c('svg',{staticClass:"unex-onboarding__step-mark finished",attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM6.95645 9.57306L8.92143 11.6739L12.7227 6.98158C12.94 6.71337 13.3335 6.67208 13.6018 6.88936C13.87 7.10664 13.9113 7.50021 13.694 7.76842L9.71452 12.6807C9.33113 13.1539 8.61796 13.1793 8.20191 12.7345L6.04355 10.4269C5.80776 10.1748 5.82097 9.77934 6.07306 9.54355C6.32515 9.30776 6.72066 9.32097 6.95645 9.57306Z","fill":"#00B7BD","fill-opacity":"0.9"}})]):_c('div',{staticClass:"unex-onboarding__step-mark",class:{
              active: _vm.currentStepIndex === index,
            }})])]),_c('div',{staticClass:"unex-onboarding__step-delimiter"})])}),0),_c('div',{staticClass:"unex-onboarding__body"},[(_vm.isStepsAnalyzing)?_c('base-loader'):_vm._e(),(!_vm.isStepsAnalyzing)?_c('router-view'):_vm._e()],1),(0)?_c('div',{staticClass:"unex-onboarding__footer"},[_c('no-bg-button',{attrs:{"label":_vm.$t('back')}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }