import store from "@/store";

class _RbacService {
  userCan(component) {
    if (
      !store.getters.userData.roles_components ||
      store.getters.userData.roles_components.length === 0
    ) {
      return false;
    }

    return store.getters.userData.roles_components.includes(component);
  }
}

export const RbacService = new _RbacService();
