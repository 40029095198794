<template>
  <div class="login-form__wrapper">
    <div class="login-form">
      <div class="login-form__heading">{{ $t("sign_in") }}</div>
      <div class="login-form__title">{{ $t("enter_credentials") }}</div>
      <div class="login-form__modes">
        <div class="login-form__modes-list">
          <div
            class="login-form__mode"
            @click="changeLoginMode(availableLoginModes.phone)"
            :class="{ active: loginMode === availableLoginModes.phone }"
          >
            {{ $t("phone") }}
          </div>
          <div
            class="login-form__mode"
            @click="changeLoginMode(availableLoginModes.email)"
            :class="{ active: loginMode === availableLoginModes.email }"
          >
            {{ $t("email") }}
          </div>
        </div>
      </div>
      <div class="login-form__field">
        <phone-input
          v-model="phone"
          :v-errors="v$.phone ? v$.phone.$errors : []"
          initial-country="UA"
          initial-country-code="+380"
          v-if="loginMode === availableLoginModes.phone"
          @canFocus="focusPhoneInput"
          @enter="handleLogin"
          ref="phoneInput"
        ></phone-input>
        <email-input
          v-else-if="loginMode === availableLoginModes.email"
          v-model="email"
          :v-errors="v$.email ? v$.email.$errors : []"
          @canFocus="focusEmailInput"
          @enter="handleLogin"
          ref="emailInput"
        ></email-input>
      </div>
      <div class="login-form__field">
        <password-input
          :title="$t('password')"
          :show-forgot-password-link="true"
          v-model="password"
          :v-errors="v$.password.$errors"
          @enter="handleLogin"
        ></password-input>
      </div>
      <div class="login-form__code" v-if="isCodeSent">
        <w-otp-input
          v-model="otpCodes[tfaType.name]"
          :v-errors="v$.otpCodes[tfaType.name].$errors"
          :username="phone"
          :otp-type="tfaType.name"
          @enter="handleLogin"
          :label="getLabelForTFA(tfaType)"
          v-for="tfaType in activeTFA"
          :key="`otp-${tfaType.name}`"
          :num-count="getNumCountForTFA(tfaType)"
          mode="small"
        ></w-otp-input>
      </div>
      <div class="login-form__button">
        <primary-button
          :label="loginBtnLabel"
          :disabled="isContinueButtonDisabled || requestInProgress"
          @click="handleLogin"
        ></primary-button>
      </div>
      <div class="login-form__sign-up">
        <div class="login-form__sign-up-text">
          {{ $t("dont_have_account_yet") }}
        </div>
        <router-link to="/sign-up" class="login-form__sign-up-button">
          {{ $t("sign_up") }}
        </router-link>
      </div>
    </div>
    <terms-popup
      v-if="showTermsAndConditionsPopup"
      :should-agreed="true"
      @agreed="storeUserAgreed"
      @closed="closeTermsCondPopup"
      :can-cancel="false"
    ></terms-popup>
  </div>
</template>

<script>
import PhoneInput from "@/components/input/Phone/PhoneInput";
import PasswordInput from "@/components/input/PasswordInput";
import WOtpInput from "@/components/input/WOtpInput";
import PrimaryButton from "@/components/button/PrimaryButton";
import AuthApi from "@/office/auth-api";
import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_SUPPORT_LINK,
} from "@/store/const/actions-types";

import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  requiredIf,
  maxLength,
  email as emailValidator,
} from "@vuelidate/validators";
import { LOGIN_MODE_EMAIL, LOGIN_MODE_PHONE } from "@/const/login-modes";
import EmailInput from "@/components/input/EmailInput";
import { GA_EVENTS } from "@/const/ga.events";
import { GtagServiceHelper } from "@/services/gtag/gtag.service";
import { OTP_LENGTH } from "@/const/auth/otp-types";
import { TermsApiHelper } from "@/office/terms-api";
import { mapGetters } from "vuex";
import { withI18nMessage } from "@/utils";
import store from "@/store";
import { QueryParserService } from "@/services/http/query-parser.service";

export default {
  name: "Login",
  components: {
    TermsPopup: () => import("@/components/sign-up/Popup/TermsPopup"),
    EmailInput,
    PrimaryButton,
    WOtpInput,
    PasswordInput,
    PhoneInput,
  },
  inject: ["showErrorTip"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      phone: "",
      email: "",
      password: "",
      isCodeSent: false,
      canResendCode: false,
      otpCodes: {},
      activeTFA: [{ name: "sms" }, { name: "email" }],
      availableLoginModes: {
        phone: LOGIN_MODE_PHONE,
        email: LOGIN_MODE_EMAIL,
      },
      loginMode: LOGIN_MODE_PHONE,
      vuelidateExternalResults: {
        password: [],
        phone: [],
        email: [],
      },
      requestInProgress: false,
      activeTermId: null,
      showTermsAndConditionsPopup: false,
      loginResponse: null,
      shouldAgreedTerms: false,
      utm: {},
    };
  },
  validations() {
    const requiredValidatorWithMessage = withI18nMessage(required, {
      messagePath: () => "registration_wm.required",
    });
    const lengthValidatorWithMessage = (validator, count, messagePath) =>
      withI18nMessage(validator(count), {
        messagePath: () => messagePath,
      });

    let _validations = {
      password: {
        required: requiredValidatorWithMessage,
        minLength: lengthValidatorWithMessage(
          minLength,
          6,
          "registration_wm.phoneMin"
        ),
      },
    };

    if (this.activeTFA.length > 0) {
      let _otpCodesValidations = {};

      this.activeTFA.forEach((_aTFA) => {
        _otpCodesValidations[_aTFA.name] = {
          requiredIf: requiredIf(this.isCodeSent),
          minValue: minLength(4),
          maxLength: maxLength(6),
        };
      });

      _validations = {
        ..._validations,
        otpCodes: _otpCodesValidations,
      };
    }

    if (this.loginMode === LOGIN_MODE_PHONE) {
      return {
        ..._validations,
        phone: {
          required: requiredValidatorWithMessage,
          minLength: lengthValidatorWithMessage(
            minLength,
            15,
            "registration_wm.phoneMin"
          ),
          maxLength: lengthValidatorWithMessage(
            maxLength,
            20,
            "registration_wm.phoneMax"
          ),
        },
      };
    } else if (this.loginMode === LOGIN_MODE_EMAIL) {
      return {
        ..._validations,
        email: {
          required: requiredValidatorWithMessage,
          email: withI18nMessage(emailValidator, {
            messagePath: () => "registration_wm.required",
          }),
        },
      };
    }

    return {
      phone: {
        required: requiredValidatorWithMessage,
        minLength: lengthValidatorWithMessage(
          minLength,
          16,
          "registration_wm.phoneMin"
        ),
        maxLength: lengthValidatorWithMessage(
          maxLength,
          16,
          "registration_wm.phoneMin"
        ),
      },
    };
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, "login_page");
    window.addEventListener("beforeunload", this.logoutUser);
    this.utm = { ...QueryParserService.getUTMTagsFromQuery(this.$route.query) };
  },
  async beforeDestroy() {
    if (this.shouldAgreedTerms) {
      await this.$store.dispatch(LOGOUT_USER);
    }
  },
  watch: {
    loginMode() {
      this.loginMode === LOGIN_MODE_EMAIL
        ? this.$refs?.emailInput?.focus()
        : this.$refs?.phoneInput?.focus();
    },
  },
  computed: {
    isContinueButtonDisabled() {
      return (
        (this.loginMode === this.availableLoginModes.phone &&
          this.phone.length < 15) ||
        (this.loginMode === this.availableLoginModes.email && !this.email) ||
        !this.password
      );
    },
    loginBtnLabel() {
      return this.isCodeSent ? this.$t("login") : this.$t("continue");
    },
    username: function () {
      switch (this.loginMode) {
        case LOGIN_MODE_PHONE:
          return this.phone;
        case LOGIN_MODE_EMAIL:
          return this.email;
        default:
          return "";
      }
    },
    afterLoginLink() {
      let _utmFiltered = Object.fromEntries(
        Object.entries(this.utm).filter(([, v]) => v != null)
      );
      if (Object.keys(_utmFiltered).length > 0) {
        let _queryParams = new URLSearchParams(_utmFiltered);
        return "/cards?" + _queryParams.toString();
      } else {
        return "/cards";
      }
    },
    ...mapGetters(["userData"]),
  },
  methods: {
    focusPhoneInput(_phoneInputInstance) {
      _phoneInputInstance?.focus();
    },
    focusEmailInput(__emailInputInstance) {
      __emailInputInstance?.focus();
    },
    async handleLogin() {
      const result = await this.v$.$validate();

      if (!result) {
        return;
      }

      this.requestInProgress = true;

      if (!this.isCodeSent) {
        this.loginUser();
      } else {
        this.verifyOTP();
      }
    },
    async loginUser() {
      GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_IN_NO_2FA_START);
      try {
        store.dispatch(SET_SUPPORT_LINK, "2fa_login_veref");

        const response = await AuthApi.login(this.username, this.password);
        if (response.data.is_two_factor) {
          this.activeTFA = response.data.is_two_factor_types;
          this.requestInProgress = false;
          this.isCodeSent = true;
        } else {
          GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_IN_NO_2FA);
          await this.processLogin(response);
        }
      } catch (error) {
        console.error(error?.data?.error ?? error);
        this.requestInProgress = false;
        const _error = [
          `${
            this.loginMode === this.availableLoginModes.phone
              ? this.$t("registration_wm.login_phone")
              : this.$t("registration_wm.login_email")
          }`,
        ];

        if (error.status >= 400) {
          const errors = {
            phone: _error,
            email: _error,
            password: _error,
          };
          Object.assign(this.vuelidateExternalResults, errors);

          setTimeout(() => {
            this.v$.$clearExternalResults();
          }, 3000);
        }
      }
    },
    async verifyOTP() {
      GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_IN_2FA_START);

      await this.$recaptchaLoaded();
      const reCaptchaToken = await this.$recaptcha("login");

      AuthApi.authorizeOtp(this.username, this.otpCodes, false, reCaptchaToken)
        .then(async (r) => {
          GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_IN_2FA);
          await this.processLogin(r);
          this.requestInProgress = false;
        })
        .catch(() => {
          this.requestInProgress = false;
          this.showErrorTip(this.$t("otp_error"));
        });
    },
    changeLoginMode(selectedLoginMode) {
      this.loginMode = selectedLoginMode;
    },
    getLabelForTFA(tfaItem) {
      return this.$t("tfa_otp_label", {
        digitCount: OTP_LENGTH[tfaItem.name] || "",
        //tfaName: this.$t(tfaItem.name || ""),
        tfaName:
          tfaItem.name === "email"
            ? this.$t("tfa_otp_label_email")
            : this.$t(tfaItem.name || ""),
      });
    },
    getNumCountForTFA(tfaItem) {
      return OTP_LENGTH[tfaItem.name] || 0;
    },
    async processLogin(r) {
      this.loginResponse = r;

      await this.$store.dispatch(LOGIN_USER, {
        phone: this.phone,
        accessToken: r.data.access_token,
        refreshToken: r.data.refresh_token,
      });

      let termResponse = await TermsApiHelper.getActiveTerm();
      if (
        parseInt(this.userData.term_cond_id) !==
        parseInt(termResponse.data.term.term_cond.id)
      ) {
        this.showTermsAndConditionsPopup = true;
        this.shouldAgreedTerms = true;
      } else {
        if (this.userData.type === "mother") {
          await this.$router.push(this.afterLoginLink);
        } else {
          await this.$router.push(this.afterLoginLink);
        }
      }
    },
    closeTermsCondPopup() {
      this.showTermsAndConditionsPopup = false;
    },
    async storeUserAgreed(agreedData) {
      await TermsApiHelper.saveAgreement(
        agreedData.termId,
        agreedData.paragraphs
      );

      this.shouldAgreedTerms = false;
      this.showTermsAndConditionsPopup = false;

      if (this.userData.type === "mother") {
        await this.$router.push(this.afterLoginLink);
      } else {
        await this.$router.push(this.afterLoginLink);
      }
    },
    async logoutUser() {
      if (this.shouldAgreedTerms) {
        await this.$store.dispatch(LOGOUT_USER);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.login-form {
  width: 472px;
  max-width: 100%;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  background-color: #ffffff;
  border-radius: 24px;
  padding: 48px;

  &__modes {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      font-size: pxToRem(14);
      line-height: pxToRem(20);
      font-weight: bold;
      margin-bottom: 10px;
    }

    &-list {
      display: grid;
      grid-template-columns: 8.4375rem 1fr;
      column-gap: 0.5rem;
      justify-content: space-between;
    }
  }

  &__mode {
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
    transition: opacity 0.2s linear;
    padding: 12px 32px;
    border-radius: 1.5rem;
    background-color: $grayLight;
    color: $gray1;
    display: block;

    font-weight: 700;
    font-family: "Nunito", sans-serif;

    &.active {
      cursor: default;
      color: #ffffff;
      background-color: $mainCold;
      font-weight: 600;
      font-family: "Rubik", sans-serif;
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__title {
    font-size: pxToRem(20);
    line-height: pxToRem(28);
    font-weight: 600;
    margin-bottom: 2.5rem;
  }

  &__heading {
    line-height: pxToRem(32);
    font-weight: 700;
  }

  &__field {
    margin-bottom: 64px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__code {
    margin-top: -12px;
    display: flex;
    flex-direction: column;

    &-button {
      margin-left: auto;
      margin-right: auto;
      color: $mainCold;
      font-weight: 700;
      cursor: pointer;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__button {
    margin-top: 40px;
  }

  &__sign-up {
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-text {
      margin-right: 8px;
    }

    &-button {
      color: #00b7bd;
      text-decoration: none;
      cursor: pointer;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .otp-input {
    margin-top: -18px;

    & + .otp-input {
      margin-top: 20px !important;
    }
  }
}

@media screen and (max-width: 486px) {
  .login-form {
    box-shadow: none;
    width: 100%;
    padding: 0;

    &__wrapper {
      margin-top: 0;
    }
  }
}
</style>
