<template>
  <div
    class="input input--password"
    :class="{ error: vErrors.length > 0 || isError }"
  >
    <div class="input__el-wrapper">
      <div class="input__title" :class="{ 'to-top': isLabelToTop }">
        {{ title }}
      </div>
      <input
        :type="inputType"
        class="input__el"
        :title="title"
        v-model="text"
        @focus="processFocus"
        @blur="processBlur"
        @keypress.enter="handleEnterKey"
      />
      <div class="password-toggle" @click="togglePasswordVisibility">
        <svg
          v-if="isPasswordVisible"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0014 3.33203C4.28645 3.33203 1.57286 8.2309 0.914531 9.62923C0.804531 9.86257 0.804531 10.1332 0.914531 10.3665C1.57286 11.7657 4.28645 16.6654 10.0014 16.6654C15.6964 16.6654 18.4118 11.8011 19.0819 10.3828C19.1968 10.1386 19.1968 9.85875 19.0819 9.61458C18.4118 8.19625 15.6964 3.33203 10.0014 3.33203ZM10.0014 5.83203C12.3023 5.83203 14.1681 7.69786 14.1681 9.9987C14.1681 12.2995 12.3023 14.1654 10.0014 14.1654C7.70061 14.1654 5.83478 12.2995 5.83478 9.9987C5.83478 7.69786 7.70061 5.83203 10.0014 5.83203ZM10.0014 7.4987C9.3384 7.4987 8.70252 7.76209 8.23368 8.23093C7.76484 8.69977 7.50145 9.33566 7.50145 9.9987C7.50145 10.6617 7.76484 11.2976 8.23368 11.7665C8.70252 12.2353 9.3384 12.4987 10.0014 12.4987C10.6645 12.4987 11.3004 12.2353 11.7692 11.7665C12.2381 11.2976 12.5014 10.6617 12.5014 9.9987C12.5014 9.33566 12.2381 8.69977 11.7692 8.23093C11.3004 7.76209 10.6645 7.4987 10.0014 7.4987Z"
            fill="currentColor"
          />
        </svg>

        <svg
          v-else
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.2455 1.84922C17.3588 1.73286 17.5129 1.66515 17.6752 1.66042C17.801 1.65679 17.925 1.69123 18.0309 1.75923C18.1368 1.82723 18.2565 1.96239 18.3056 2.0783C18.3547 2.1942 18.3676 2.32222 18.3427 2.44559C18.3178 2.56897 18.2563 2.68196 18.1661 2.76978L2.74943 18.1864C2.69184 18.2464 2.62286 18.2943 2.54653 18.3273C2.4702 18.3603 2.38806 18.3777 2.30491 18.3786C2.22176 18.3794 2.13927 18.3637 2.06229 18.3322C1.9853 18.3008 1.87859 18.2175 1.8198 18.1588C1.761 18.1 1.71452 18.03 1.68309 17.953C1.65166 17.876 1.63591 17.7936 1.63675 17.7104C1.6376 17.6273 1.65502 17.5451 1.68801 17.4688C1.721 17.3925 1.76889 17.3235 1.82887 17.2659L17.2455 1.84922Z"
            fill="currentColor"
          />
          <path
            d="M15.5506 5.1991C14.1552 4.14293 12.3267 3.33203 10.0014 3.33203C4.28645 3.33203 1.57286 8.2309 0.914531 9.62923C0.804531 9.86257 0.804531 10.1332 0.914531 10.3665C1.35896 11.3111 2.74007 13.851 5.34894 15.4018L7.45431 13.2962C6.46918 12.534 5.83478 11.3403 5.83478 9.9987C5.83478 7.69786 7.70061 5.83203 10.0014 5.83203C11.3429 5.83203 12.5365 6.4663 13.2987 7.45126L15.5506 5.1991Z"
            fill="currentColor"
          />
          <path
            d="M12.104 8.64611C12.0082 8.4973 11.8963 8.35798 11.7692 8.23093C11.3004 7.76209 10.6645 7.4987 10.0014 7.4987C9.3384 7.4987 8.70252 7.76209 8.23368 8.23093C7.76484 8.69977 7.50145 9.33566 7.50145 9.9987C7.50145 10.6617 7.76484 11.2976 8.23368 11.7665C8.36079 11.8936 8.50017 12.0056 8.64905 12.1013L12.104 8.64611Z"
            fill="currentColor"
          />
          <path
            d="M10.3375 12.476L12.4788 10.3345C12.4057 10.8735 12.1582 11.3775 11.7692 11.7665C11.3803 12.1554 10.8764 12.4029 10.3375 12.476Z"
            fill="currentColor"
          />
          <path
            d="M8.81861 13.995C9.19359 14.1059 9.59058 14.1654 10.0014 14.1654C12.3023 14.1654 14.1681 12.2995 14.1681 9.9987C14.1681 9.5877 14.1086 9.19057 13.9977 8.81549L16.6519 6.16101C17.9875 7.49646 18.7643 8.94245 19.0819 9.61458C19.1968 9.85875 19.1968 10.1386 19.0819 10.3828C18.4118 11.8011 15.6964 16.6654 10.0014 16.6654C8.7796 16.6654 7.69495 16.4414 6.73824 16.0756L8.81861 13.995Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    <div class="input__footer">
      <div class="input__errors" v-if="vErrors.length > 0">
        <div class="input__error">{{ vErrors[0].$message }}</div>
      </div>
      <router-link
        to="/forgot-password"
        v-if="showForgotPasswordLink"
        class="password-forgot"
      >
        {{ $t("forgot_password") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    showForgotPasswordLink: {
      type: Boolean,
      default: false,
    },
    value: String,
    vErrors: {
      type: Array,
      default() {
        return [];
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: "",
      isFocused: false,
      isPasswordVisible: false,
    };
  },
  created() {
    this.text = this.value;
  },
  computed: {
    isLabelToTop: function () {
      return this.text.length > 0 || this.isFocused;
    },
    inputType: function () {
      return this.isPasswordVisible ? "text" : "password";
    },
  },
  methods: {
    processFocus() {
      this.isFocused = true;
    },
    processBlur() {
      this.isFocused = false;
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    handleEnterKey() {
      this.$emit("enter");
    },
  },
  watch: {
    text: function () {
      this.$emit("update", this.text);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.input {
  &--password & {
    &__el {
      padding-right: 28px;

      &-wrapper {
        position: relative;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.password-toggle {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  transition: opacity 0.2s linear;
  color: $gray2;

  &:hover {
    opacity: 0.6;
  }
}

.password-forgot {
  position: absolute;
  bottom: -20px;
  right: 0;
  margin-top: 3px;
  margin-left: auto;
  text-decoration: none;
  color: $mainCold;
  font-size: pxToRem(12);
  line-height: pxToRem(18);
  margin-left: auto;
}
</style>
