import pixelStandardEvents from "@/const/pixelEvents";
import axiosInstance from "@/services/http/axios-instance";
import { getIpAddress } from "@/utils";
import CookieService from "@/services/cookieService";

class PixelEventsService {
  async saveUserData() {
    try {
      const userIp = await getIpAddress();
      const fbp = CookieService.getCookieValue("fbp")?.split("=")[1];
      const payload = {
        user_data: {
          client_ip_address: userIp,
          client_user_agent: navigator.userAgent,
          fbp,
        },
      };
      await axiosInstance.post("/user/pixel/info", { ...payload });
    } catch (error) {
      console.error(
        "Something went wrong with saveUserData method: -> \n",
        error
      );
    }
  }

  completeRegistration() {
    this.#fireEvent(pixelStandardEvents.CompleteRegistration);
  }

  connectExchange() {
    this.#fireEvent(pixelStandardEvents.SubmitApplication);
  }

  openCard() {
    this.#fireEvent(pixelStandardEvents.Purchase, {
      currency: "UAH",
      value: "UNEX",
    });
  }

  #fireEvent(eventName, options) {
    if (!window.fbq) {
      console.error("No fbq object in global");
      return;
    }
    window.fbq("track", eventName, options);
  }
}

export default new PixelEventsService();
