<template>
  <div class="settings-referrals">
    <base-loader v-if="isDataLoading"></base-loader>
    <div class="settings-referrals__link" v-if="hintComponent === null">
      <div class="settings-referrals__link-text">
        {{ referralURLShort }}
      </div>
      <div class="settings-referrals__link-copy" @click="copyReferralLink">
        Copy referral link
      </div>
    </div>
    <referral-indicators
      v-if="hintComponent === null || hintComponent === 'ReferralEmptyList'"
      class="settings-referrals__indicators"
    ></referral-indicators>
    <component
      :is="hintComponent"
      v-if="hintComponent"
      @metamaskSaved="updateUserData"
    ></component>
    <referral-table
      :referrals-data="referralsFiltered"
      @filtersUpdated="updateReferralsList"
      v-else
    ></referral-table>
  </div>
</template>

<script>
import ReferralIndicators from "@/components/profile/Settings/Referrals/ReferralIndicators";
import { mapGetters } from "vuex";
import ReferralConnectMetamask from "@/components/profile/Settings/Referrals/ReferralConnectMetamask";
import ReferralStakeWeld from "@/components/profile/Settings/Referrals/ReferralStakeWeld";
import ReferralEmptyList from "@/components/profile/Settings/Referrals/ReferralEmptyList";
import { LOAD_USER_DATA } from "@/store/const/actions-types";
import ReferralTable from "@/components/profile/Settings/Referrals/ReferralTable";
import BaseLoader from "@/components/base/BaseLoader";
import { ReferralsApi } from "@/office/referrals-api";
import moment from "moment";
import { REFERRAL_URL } from "@/const/config";
export default {
  name: "SettingsReferrals",
  components: {
    BaseLoader,
    ReferralTable,
    ReferralConnectMetamask,
    ReferralStakeWeld,
    ReferralEmptyList,
    ReferralIndicators,
  },
  inject: ["showSuccessTip"],
  metaInfo: {
    title: "Referrals",
  },
  data() {
    return {
      isDataLoading: true,
      referralsList: {
        users: [],
      },
      referralsFiltered: {
        users: [],
      },
      filter: {},
      sort: null,
      page: 1,
      limit: 4,
      dateFrom: moment().subtract(1, "year").format("YYYY-MM-DD HH:mm:ss"),
      dateTo: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
  },
  async created() {
    await this.$store.dispatch(LOAD_USER_DATA);
    this.loadReferrals();
  },
  computed: {
    ...mapGetters(["userData", "web3Instance"]),
    hintComponent: function () {
      let referralTypeId = parseInt(this.userData.referral_type_id || 4);
      let metamaskAcc = this.userData.metamask_acc;

      if (referralTypeId === 1 || referralTypeId === 2) {
        if (!metamaskAcc || metamaskAcc.trim().length === 0) {
          return "ReferralConnectMetamask";
        }

        if (
          this.referralsList.length === 0 ||
          this.referralsList.users === null ||
          this.referralsList.users.length === 0
        ) {
          return "ReferralEmptyList";
        }

        return null;
      }

      if (referralTypeId >= 3) {
        return "ReferralStakeWeld";
      }

      return "ReferralStakeWeld";
    },
    referralURL: function () {
      return `${REFERRAL_URL}${this.userData.referral_uuid}`;
    },
    referralURLShort: function () {
      return `${this.referralURL.slice(0, 15)}...${this.referralURL.slice(
        this.referralURL.length - 3
      )}`;
    },
  },
  methods: {
    async updateUserData() {
      this.isDataLoading = true;

      await this.$store.dispatch(LOAD_USER_DATA);
      this.isDataLoading = false;
    },
    async loadReferrals(update = false) {
      try {
        let referralsResponse = await ReferralsApi.getReferrals(
          this.page,
          this.limit,
          this.filter,
          this.sort,
          this.dateFrom,
          this.dateTo
        );

        if (update) {
          this.referralsFiltered = referralsResponse.data;
        } else {
          this.referralsList = referralsResponse.data;
          this.referralsFiltered = { ...this.referralsList };
        }
      } catch (e) {
        console.error(e);
      }

      this.isDataLoading = false;
    },
    updateReferralsList(newFilters) {
      this.filter = newFilters.filter;
      this.sort = newFilters.sort;
      this.limit = newFilters.limit;
      this.page = newFilters.page;
      this.dateFrom = newFilters.dateFrom;
      this.dateTo = newFilters.dateTo;

      this.isDataLoading = true;
      this.loadReferrals(true);
    },
    copyReferralLink() {
      if (!navigator.clipboard) {
        return;
      }

      navigator.clipboard.writeText(this.referralURL).then(
        () => {
          this.showSuccessTip(this.$t("tip.referral_url_copied"));
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.settings-referrals {
  position: relative;
  &__indicators {
    margin-bottom: 36px;
  }

  &__link {
    margin-bottom: 24px;

    display: flex;
    align-items: center;

    &-text {
      font-size: pxToRem(16);
      line-height: pxToRem(24);
    }

    &-copy {
      cursor: pointer;
      color: $mainCold;
      font-weight: 700;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      margin-left: 16px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.referral-hint {
  padding: 24px;
  border: 1px solid $gray3;
  border-radius: 24px;
  position: relative;

  &__body {
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-family: "Mic 32 New Rounded", sans-serif;
    font-weight: 700;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    text-align: center;
    margin-bottom: 32px;
  }

  &__text {
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;
    text-align: center;
  }

  &__actions {
    margin-top: 32px;
  }
}
</style>
