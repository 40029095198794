<template>
  <div class="referral-table">
    <div class="referral-table__inner">
      <div class="referral-table__filters">
        <div class="referral-table__period">
          <div class="referral-table__period-title">
            Your referrals <span class="accent">per</span>
          </div>
          <custom-select
            class="referral-table__period-select"
            :show-selected-title="true"
            :title="'Select period'"
            :empty-placeholder="''"
            :values="periodOptions"
            v-model="period"
          ></custom-select>
        </div>
        <text-input
          class="referral-table__keyword"
          :title="'Enter keyword'"
          v-model="keyword"
          icon='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.1449 15.1695C11.9037 16.0953 10.3642 16.6434 8.69672 16.6434C4.58401 16.6434 1.25 13.3094 1.25 9.19672C1.25 5.08401 4.58401 1.75 8.69672 1.75C12.8094 1.75 16.1434 5.08401 16.1434 9.19672C16.1434 10.8642 15.5953 12.4037 14.6695 13.6449L18.4342 17.4096C18.8553 17.8306 18.8553 18.5132 18.4342 18.9342C18.0132 19.3553 17.3306 19.3553 16.9096 18.9342L13.1449 15.1695ZM14.2818 9.19672C14.2818 12.2812 11.7812 14.7818 8.69672 14.7818C5.61219 14.7818 3.11168 12.2812 3.11168 9.19672C3.11168 6.11219 5.61219 3.61168 8.69672 3.61168C11.7812 3.61168 14.2818 6.11219 14.2818 9.19672Z" fill="#7A939C"/></svg>'
        ></text-input>
        <div class="referral-table__sort-by">
          <div class="referral-table__sort-by-title">Sort by:</div>
          <custom-select
            class="referral-table__sort-by-select"
            :show-selected-title="true"
            :empty-placeholder="''"
            :values="taskOptions"
            v-model="task"
          ></custom-select>
        </div>
      </div>
      <div class="referral-table__header">
        <div
          class="referral-table__col referral-table__header-col referral-table__header-col--with-sort"
          @click="setSort('user_id')"
        >
          Referral#
          <div class="referral-table__header-col-caret">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.0117 11.5606C8.46038 12.1465 7.53963 12.1465 6.9883 11.5606L1.23298 5.44412C0.912312 5.10333 0.924127 4.56281 1.25937 4.23684C1.59462 3.91086 2.12635 3.92287 2.44702 4.26366L8 10.1651L13.553 4.26366C13.8737 3.92287 14.4054 3.91086 14.7406 4.23684C15.0759 4.56281 15.0877 5.10333 14.767 5.44412L9.0117 11.5606Z"
                fill="#7A939C"
              />
            </svg>
          </div>
        </div>
        <div
          class="referral-table__col referral-table__header-col referral-table__header-col--with-sort"
          @click="setSort('full_name')"
        >
          Users
          <div class="referral-table__header-col-caret">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.0117 11.5606C8.46038 12.1465 7.53963 12.1465 6.9883 11.5606L1.23298 5.44412C0.912312 5.10333 0.924127 4.56281 1.25937 4.23684C1.59462 3.91086 2.12635 3.92287 2.44702 4.26366L8 10.1651L13.553 4.26366C13.8737 3.92287 14.4054 3.91086 14.7406 4.23684C15.0759 4.56281 15.0877 5.10333 14.767 5.44412L9.0117 11.5606Z"
                fill="#7A939C"
              />
            </svg>
          </div>
        </div>
        <div class="referral-table__col referral-table__header-col">Status</div>
      </div>
      <div class="referral-table__body">
        <div
          class="referral-table__item"
          v-for="referral in referralsUsers || []"
          :key="`referral-${referral.user_id}`"
        >
          <div class="referral-table__col referral-table__col-id">
            {{ referral.user_id }}
          </div>
          <div class="referral-table__col referral-table__col-user">
            <div class="referral-table__col-user-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <mask
                  id="mask0_7032_38903"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="32"
                  height="33"
                >
                  <circle cx="16" cy="16.0002" r="16" fill="white" />
                </mask>
                <g mask="url(#mask0_7032_38903)">
                  <circle
                    cx="16"
                    cy="16.0002"
                    r="15.5"
                    fill="#7A939C"
                    stroke="#7A939C"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0499 6.45471C14.2298 4.24882 17.7642 4.24882 19.9441 6.45471C22.1241 8.6606 22.1241 12.2371 19.9441 14.4429C17.7642 16.6488 14.2298 16.6488 12.0499 14.4429C9.86992 12.2371 9.86992 8.6606 12.0499 6.45471Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.52946 18.9456C10.601 17.8608 13.3047 17.276 15.9969 17.276C18.6891 17.276 21.3928 17.8611 23.4644 18.946C25.499 20.0116 27.1969 21.7268 27.1969 24.067V25.2003C27.1969 26.4518 26.1937 32.0003 24.9569 32.0003H7.03688C5.80008 32.0003 4.79688 26.4518 4.79688 25.2003V24.067C4.79688 21.7263 6.49465 20.0111 8.52946 18.9456Z"
                    fill="white"
                  />
                  <circle cx="16" cy="16.0002" r="15.5" stroke="#7A939C" />
                </g>
              </svg>
            </div>
            {{ referral.full_name }}
          </div>
          <div class="referral-table__col referral-table__col-tasks">
            <div class="referral-table__col-tasks-list">
              <div class="referral-table__col-tasks-active">
                {{
                  $t(
                    `referrals.task.${getLastCompleteReferralTask(
                      referral.tasks
                    )}`
                  )
                }}
              </div>
              <div
                class="referral-table__col-tasks-item"
                :class="{
                  'referral-table__col-tasks-item--done': task.is_done,
                }"
                v-for="task in referral.tasks"
                :key="`referral-${referral.user_id}-task-${task.slug}`"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_7032_38913)">
                    <path
                      d="M12.735 2.03133C12.5013 1.32322 11.4988 1.32321 11.265 2.03133L9.01367 8.85013L1.89337 8.91517C1.15356 8.92192 0.843989 9.86255 1.43539 10.3067L7.16802 14.6121L5.03089 21.4943C4.81046 22.2042 5.62133 22.7858 6.22391 22.35L12 18.1732L17.7761 22.35C18.3787 22.7858 19.1895 22.2042 18.9691 21.4943L16.832 14.6121L22.5646 10.3067C23.156 9.86255 22.8464 8.92192 22.1066 8.91517L14.9863 8.85013L12.735 2.03133Z"
                      fill="#B7C9D3"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7032_38913">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.000244141)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="referral-table__pagination">
      <div class="referral-table__pagination-limit">
        <div class="referral-table__pagination-limit-text">View</div>
        <custom-select
          class="referral-table__pagination-limit-select"
          v-model="limit"
          :values="limitOptions"
          :empty-placeholder="''"
          :show-selected-title="true"
          :list-position="'top'"
        ></custom-select>
        <div class="referral-table__pagination-limit-text">row per page</div>
      </div>
      <div class="referral-table__pagination-pages">
        <div class="referral-table__pagination-pages-hint">
          Showing {{ (referralsData.page - 1) * this.limit + 1 }} to
          {{ referralsData.page * this.limit }} of
          {{ referralsData.count_users }} rows
        </div>
        <div class="referral-table__pagination-pages-list">
          <div
            class="referral-table__pagination-pages-arrow"
            @click="toPrevPage"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="11"
              viewBox="0 0 8 11"
              fill="none"
            >
              <path
                d="M6.95711 1.70808C7.34763 1.31756 7.34763 0.684394 6.95711 0.29387C6.56658 -0.0966545 5.93342 -0.0966545 5.54289 0.29387L6.95711 1.70808ZM1.75 5.50098L1.04289 4.79387L0.335786 5.50098L1.04289 6.20808L1.75 5.50098ZM5.54289 10.7081C5.93342 11.0986 6.56658 11.0986 6.95711 10.7081C7.34763 10.3176 7.34763 9.68439 6.95711 9.29387L5.54289 10.7081ZM5.54289 0.29387L1.04289 4.79387L2.45711 6.20808L6.95711 1.70808L5.54289 0.29387ZM1.04289 6.20808L5.54289 10.7081L6.95711 9.29387L2.45711 4.79387L1.04289 6.20808Z"
                fill="#B7C9D3"
              />
            </svg>
          </div>
          <div
            class="referral-table__pagination-pages-item"
            :class="{ active: page.text === referralsData.page }"
            v-for="page in paginationPages"
            @click="navigateToPage(page)"
            :key="`page-${page.text}`"
          >
            {{ page.text }}
          </div>
          <div
            class="referral-table__pagination-pages-arrow"
            @click="toNextPage"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="11"
              viewBox="0 0 8 11"
              fill="none"
            >
              <path
                d="M1.04289 1.70808C0.652369 1.31756 0.652369 0.684394 1.04289 0.29387C1.43342 -0.0966545 2.06658 -0.0966545 2.45711 0.29387L1.04289 1.70808ZM6.25 5.50098L6.95711 4.79387L7.66421 5.50098L6.95711 6.20808L6.25 5.50098ZM2.45711 10.7081C2.06658 11.0986 1.43342 11.0986 1.04289 10.7081C0.652369 10.3176 0.652369 9.68439 1.04289 9.29387L2.45711 10.7081ZM2.45711 0.29387L6.95711 4.79387L5.54289 6.20808L1.04289 1.70808L2.45711 0.29387ZM6.95711 6.20808L2.45711 10.7081L1.04289 9.29387L5.54289 4.79387L6.95711 6.20808Z"
                fill="#B7C9D3"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/input/CustomSelect";
import TextInput from "@/components/input/TextInput";
import { EventsService } from "@/services/events/events.service";
import moment from "moment";
export default {
  name: "ReferralTable",
  components: { TextInput, CustomSelect },
  props: {
    referralsData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      period: "year",
      keyword: "",
      periodOptions: [
        {
          id: "year",
          title: "Year",
        },
        {
          id: "month",
          title: "Month",
        },
        {
          id: "week",
          title: "Week",
        },
        {
          id: "day",
          title: "Day",
        },
      ],
      taskOptions: [
        {
          id: "all",
          title: "All",
        },
        {
          id: EventsService.EVENT_TRANSACTION,
          title: "Completed-Transaction",
        },
        {
          id: EventsService.EVENT_REGISTRATION,
          title: "Registered",
        },
        {
          id: EventsService.EVENT_STAKING,
          title: "Staked",
        },
      ],
      task: "all",
      limit: 4,
      page: 1,
      limitOptions: [
        {
          id: 4,
          title: "4",
        },
        {
          id: 10,
          title: "10",
        },
        {
          id: 15,
          title: "15",
        },
        {
          id: 25,
          title: "25",
        },
      ],
      sort: {
        field: null,
        direction: "",
      },
    };
  },
  computed: {
    referralsUsers: function () {
      if (!this.referralsData.users) {
        return [];
      }

      return this.referralsData.users;
    },
    paginationPages: function () {
      let pagesList = [];

      if (this.referralsData.count_pages <= 6) {
        for (let i = 1; i <= this.referralsData.count_pages; i++) {
          pagesList.push({
            type: "button",
            text: i,
          });
        }
      } else {
        pagesList.push({
          type: "button",
          text: 1,
        });

        // Print "..." only if currentPage is > 3
        if (this.referralsData.page > 3) {
          pagesList.push({
            type: "skip",
            text: "...",
          });
        }

        // special case where last page is selected...
        if (this.referralsData.page === this.referralsData.count_pages) {
          pagesList.push({
            type: "button",
            text: this.referralsData.page - 2,
          });
        }

        // Print previous number button if currentPage > 2
        if (this.referralsData.page > 2) {
          pagesList.push({
            type: "button",
            text: this.referralsData.page - 1,
          });
        }

        //Print current page number button as long as it not the first or last page
        if (
          this.referralsData.page !== 1 &&
          this.referralsData.page !== this.referralsData.count_pages
        ) {
          pagesList.push({
            type: "button",
            text: this.referralsData.page,
          });
        }

        //print next number button if currentPage < lastPage - 1
        if (this.referralsData.page < this.referralsData.count_pages - 1) {
          pagesList.push({
            type: "button",
            text: this.referralsData.page + 1,
          });
        }

        // special case where first page is selected...
        if (this.referralsData.page === 1) {
          pagesList.push({
            type: "button",
            text: this.referralsData.page + 2,
          });
        }

        //print "..." if currentPage is < lastPage -2
        if (this.referralsData.page < this.referralsData.count_pages - 2) {
          pagesList.push({
            type: "skip",
            text: "...",
          });
        }

        //Always print last page button if there is more than 1 page
        pagesList.push({
          type: "button",
          text: this.referralsData.count_pages,
        });
      }

      return pagesList;
    },
  },
  watch: {
    period: function () {
      this.updateFilters(true);
    },
    task: function () {
      this.updateFilters(true);
    },
    sort: function () {
      this.updateFilters(true);
    },
    limit: function () {
      this.updateFilters(true);
    },
    keyword: function () {
      this.updateFilters(true);
    },
    page: function () {
      this.updateFilters();
    },
  },
  methods: {
    getLastCompleteReferralTask(tasks) {
      let tasksReversed = [...tasks].reverse();

      let finishedTask = tasksReversed.find((t) => t.is_done === true);

      if (!finishedTask) {
        finishedTask = tasks[0];
      }

      return finishedTask.slug;
    },
    updateFilters(resetPage = false) {
      let filters = {};

      if (this.keyword.trim().length > 0) {
        filters["name"] = this.keyword;
      }

      if (this.task !== "all") {
        filters["task"] = this.task;
      }

      if (resetPage) {
        this.page = 1;
      }

      let dateFrom = moment()
        .subtract(1, this.period)
        .format("YYYY-MM-DD HH:mm:ss");
      let dateTo = moment().format("YYYY-MM-DD HH:mm:ss");

      filters["dt_from"] = dateFrom;
      filters["dt_to"] = dateTo;

      this.$emit("filtersUpdated", {
        filter: filters,
        sort: this.sort.field === null ? null : this.sort,
        limit: this.limit,
        page: this.page,
        dateFrom,
        dateTo,
      });
    },
    navigateToPage(page) {
      if (page.type === "skip") {
        return;
      }

      this.page = page.text;
    },
    toPrevPage() {
      if (this.page === 1) {
        return;
      }

      this.page--;
    },
    toNextPage() {
      if (this.page === this.referralsData.count_pages) {
        return;
      }
      this.page++;
    },
    setSort(fieldName) {
      if (this.sort.field === fieldName) {
        this.sort.direction = this.sort.direction === "asc" ? "desc" : "asc";
      } else {
        this.sort.field = fieldName;
        this.sort.direction = "desc";
      }

      this.sort = { ...this.sort };
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
.referral-table {
  &__inner {
    background: #ffffff;
    box-shadow: 0px 0px 62px rgba(183, 201, 211, 0.5);
    border-radius: 24px;
  }

  &__filters {
    display: flex;
    align-items: center;
    padding: 45px 32px 21px;
  }

  &__period {
    display: flex;
    align-items: center;

    &-title {
      font-weight: 700;
      font-size: pxToRem(24);
      line-height: pxToRem(30);

      .accent {
        color: $mainCold;
      }
    }

    .custom-select {
      margin-left: 5px;

      &__selected {
        padding-bottom: 0;
        padding-right: 0;
        border-bottom: none;
        width: auto;

        &-item {
          &-label {
            font-weight: 700;
            font-size: pxToRem(24);
            line-height: pxToRem(30);
            color: $mainCold;
            text-transform: lowercase;
          }
        }
      }

      &__caret {
        margin-left: 10px;
        svg {
          path {
            fill: $mainCold;
          }
        }
      }

      &__block {
        width: 194px;
      }
    }
  }

  &__keyword {
    width: 320px !important;
    margin-left: auto;
  }

  &__sort-by {
    display: flex;
    align-items: center;
    margin-left: 36px;

    &-title {
      font-size: pxToRem(16);
      line-height: pxToRem(24);
      color: $gray1;
    }

    .custom-select {
      margin-left: 5px;

      &__selected {
        padding-bottom: 0;
        padding-right: 0;
        border-bottom: none;
        width: auto;

        &-item {
          &-label {
            font-size: pxToRem(16);
            line-height: pxToRem(24);
            color: $gray1;
          }
        }
      }

      &__caret {
        margin-left: 8px;
        svg {
          path {
            fill: $gray1;
          }
        }
      }

      &__block {
        width: 250px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;

    &-col {
      display: flex;
      align-items: center;
      padding: 24px 32px;
      font-size: pxToRem(20);
      line-height: pxToRem(24);
      color: $gray1;
      cursor: default;

      &--with-sort {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }

      &-caret {
        margin-left: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray3;

    &:last-child {
      border-bottom: none;
    }
  }

  &__col {
    padding: 24px 32px;
    &:nth-child(1) {
      width: 20%;
    }

    &:nth-child(2) {
      width: 40%;
    }

    &:nth-child(3) {
      width: 40%;
    }

    &-user {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: pxToRem(16);
      line-height: pxToRem(20);

      &-icon {
        width: 32px;
        height: 32px;
        margin-right: 16px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-tasks {
      display: flex;
      align-items: center;

      &-active {
        width: 104px;
        margin-right: 24px;
        color: $gray1;
        font-size: pxToRem(16);
        line-height: pxToRem(24);
      }

      &-list {
        display: flex;
        align-items: center;
      }

      &-item {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }

        svg {
          width: 100%;
          height: 100%;
        }

        &--done {
          svg {
            path {
              fill: $mainCold;
            }
          }
        }
      }
    }
  }

  &__pagination {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-limit {
      display: flex;
      align-items: center;
      &-text {
        font-size: pxToRem(14);
        line-height: pxToRem(18);
        color: $gray1;
      }

      .custom-select {
        width: 51px;
        margin: 0 20px;

        &__block {
          width: 51px;
        }
      }
    }

    &-pages {
      display: flex;
      align-items: center;

      &-hint {
        font-size: pxToRem(14);
        line-height: pxToRem(18);
        color: $gray1;
        margin-right: 40px;
      }

      &-list {
        display: flex;
        align-items: center;
      }

      &-item {
        width: 22px;
        height: 26px;
        border-radius: 8px;
        font-size: pxToRem(14);
        line-height: pxToRem(18);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray1;
        cursor: pointer;

        margin-right: 8px;

        &.active {
          background: $grayLight;
          color: $mainBlack;
          cursor: default;
        }
      }

      &-arrow {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:first-child {
          margin-right: 8px;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
