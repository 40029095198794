<template>
  <div
    ref="wrap"
    :class="modernBrowser ? 'justify-space' : 'justify-center'"
    class="otp-input"
  >
    <template v-if="modernBrowser">
      <template v-for="(char, index) in valueParts">
        <input
          :key="index"
          :autofocus="0 === index"
          autocomplete="one-time-code"
          v-bind="$attrs"
          v-on="listeners"
          v-model="valueParts[index]"
          @keydown="
            handleEnterKey($event);
            handleKeyDown($event, index);
          "
          @input="handleInput($event, index)"
          @paste="onPaste"
          @focus="processFocus(index)"
          type="tel"
          onkeypress="if(this.value.length===1) return false;"
          class="otp-input__num"
          size="lg"
          :maxlength="numCount"
        />
      </template>
    </template>
    <input
      v-else
      @keydown="handleEnterKey($event)"
      v-bind="$attrs"
      :value="value"
      v-on="listeners"
      type="tel"
      :maxlength="this.numCount"
      :onkeypress="
        'if(this.value.length===' + this.numCount + ') return false;'
      "
      class="otp-input__num"
      size="lg"
    />
  </div>
</template>

<script>
const modernBrowser = true; // removed detection dependenncy

function _fill(arr, val, l) {
  arr.length = l;
  arr.fill(val, 0, l);
  return arr;
}

function _sanitizeEventKey(key) {
  return key === "Unidentified" ? undefined : key;
}

export default {
  name: "MyOtpInput",
  inheritAttrs: modernBrowser,
  props: {
    value: {
      type: String,
      default: "",
    },
    numCount: Number,
  },
  data() {
    return {
      modernBrowser,
      valueParts: _fill([], "", this.numCount),
      delimiter: this.numCount / 2 - 1,
      protect: false, // multipress protection
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
  watch: {
    valueParts() {
      this.emitInput();
    },
  },
  mounted() {
    if (this.$el.attributes.autofocus) {
      this.focus(0);
    }
  },
  methods: {
    clearInput() {
      this.valueParts = _fill([], "", this.numCount);
    },
    processFocus(index) {
      for (let i = 0; i < index; i++) {
        if (this.valueParts[i].trim().length === 0) {
          this.focus(i);
          return;
        }
      }
    },
    focus(index) {
      const inp = this.$refs.wrap.children[index];
      if (inp) {
        inp.focus();
      }
    },
    handleEnterKey(event) {
      if (event.key === "Enter") {
        this.$emit("enter");
        event.stopPropagation();
      }
    },
    handleKeyDown(event, index) {
      const key = _sanitizeEventKey(event.key);
      if (!key) {
        return;
      } else if (key === "Backspace") {
        if (this.valueParts[index]) {
          return (this.valueParts[index] = "");
        }
        this.focus(index - 1);
      } else if (!event.shiftKey && (key === "ArrowRight" || key === "Right")) {
        this.focus(index + 1);
      } else if (!event.shiftKey && (key === "ArrowLeft" || key === "Left")) {
        this.focus(index - 1);
      } else if (key.length === 1 && this.valueParts[index]) {
        this.valueParts[index] = key;
        this.$forceUpdate();
        this.focus(index + 1);
        this.emitInput();
      }
    },
    handleInput(event, index) {
      if (event.target.value.length >= this.numCount) {
        this.fillCode(event.target.value);
      } else {
        const value = this.valueParts[index];

        if (value) {
          if (value.length > 1) {
            this.valueParts[index] = value[value.length - 1];
          }
          this.focus(index + 1);
        }
      }

      this.emitInput();
    },
    onPaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      if (!clipboardData) {
        return;
      }
      // IE fix
      event.preventDefault();
      const code =
        clipboardData.getData("Text") || clipboardData.getData("text/plain");
      this.fillCode(code);
    },
    fillCode(code) {
      code = code.trim();
      code = code.slice(0, this.numCount);
      const parts = code.split("");
      parts.length = this.numCount;
      this.valueParts = parts;

      const last = code.length - 1;
      setTimeout(() => {
        // cut out extra chars from last input
        this.valueParts[last] =
          this.valueParts[last] && this.valueParts[last].slice(0, 1); // apply just first char
        this.$forceUpdate();
        this.focus(last);
      }, 0);
    },
    emitInput() {
      const result = this.valueParts.join("").slice(0, this.numCount);
      this.$emit("input", result);
    },
  },
};
</script>

<style scoped>
.otp-input__num:nth-child(2n) {
  margin-right: 25px;
}

.otp-input__num:last-child {
  margin-right: 0px;
}
</style>
