<template>
  <div class="main-layout">
    <div
      class="main-layout__sidebar"
      :class="{ opened: isMobile && isSidebarOpened }"
    >
      <div
        class="main-layout__sidebar-mobile-backdrop"
        @click="hideSidebar"
      ></div>
      <div class="main-layout__sidebar-inner">
        <div class="toggle-sidebar">
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.70711 12.2929C9.09763 12.6834 9.09763 13.3166 8.70711 13.7071C8.31658 14.0976 7.68342 14.0976 7.29289 13.7071L8.70711 12.2929ZM2 7L1.29289 7.70711L0.585787 7L1.29289 6.29289L2 7ZM7.29289 0.292894C7.68342 -0.097631 8.31658 -0.0976309 8.70711 0.292894C9.09763 0.683418 9.09763 1.31658 8.70711 1.70711L7.29289 0.292894ZM7.29289 13.7071L1.29289 7.70711L2.70711 6.29289L8.70711 12.2929L7.29289 13.7071ZM1.29289 6.29289L7.29289 0.292894L8.70711 1.70711L2.70711 7.70711L1.29289 6.29289Z"
              fill="#7A939C"
            />
          </svg>
        </div>
        <logo to-link="/"></logo>
        <sidebar></sidebar>
      </div>
    </div>
    <div class="main-layout__inner" :style="styleObj">
      <div class="main-layout__mobile-header">
        <div class="main-layout__mobile-open-mmenu" @click="openSidebar">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.6149 5.38477C35.4646 5.38477 36.1534 6.07356 36.1534 6.92323C36.1534 7.7729 35.4646 8.46169 34.6149 8.46169L5.38417 8.46169C4.5345 8.46169 3.8457 7.7729 3.8457 6.92323C3.8457 6.07356 4.5345 5.38477 5.38417 5.38477L34.6149 5.38477ZM34.6149 31.5386C35.4646 31.5386 36.1534 32.2274 36.1534 33.0771C36.1534 33.9267 35.4646 34.6155 34.6149 34.6155H5.38417C4.5345 34.6155 3.8457 33.9267 3.8457 33.0771C3.8457 32.2274 4.5345 31.5386 5.38417 31.5386L34.6149 31.5386ZM36.1534 20.0002C36.1534 19.1505 35.4646 18.4617 34.6149 18.4617L5.38417 18.4617C4.5345 18.4617 3.8457 19.1505 3.8457 20.0002C3.8457 20.8498 4.5345 21.5386 5.38417 21.5386L34.6149 21.5386C35.4646 21.5386 36.1534 20.8498 36.1534 20.0002Z"
              fill="#00B9BF"
            />
          </svg>
        </div>
        <div class="main-layout__mobile-user">
          <div class="main-layout__mobile-profile">
            <img src="~@/assets/img/no-avatar.svg" alt="" />
          </div>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import Logo from "@/components/base/Logo";
import Sidebar from "@/components/profile/Sidebar";
import responsiveMixin from "@/mixins/responsive-mixin";
import vClickOutside from "v-click-outside";
import { TasksApi } from "@/office/tasks-api";
import { SET_ACTIVE_STORY } from "@/store/const/actions-types";

export default {
  name: "MainLayout",
  components: { Sidebar, Logo },
  directives: { clickOutside: vClickOutside.directive },
  mixins: [responsiveMixin],
  data() {
    return {
      tipText: "",
      isShowSuccessTip: false,
      isShowErrorTip: false,
      isSidebarOpened: false,
    };
  },
  async created() {
    this.currentLocale = this.selectedLocale;
    await this.loadActiveTaskStory();
  },
  computed: {
    layoutBg: function () {
      return this.$route.meta.layoutBg || false;
    },
    styleObj: function () {
      if (!this.layoutBg) {
        return {};
      } else {
        return {
          background: this.layoutBg,
        };
      }
    },
  },
  watch: {
    $route() {
      this.isSidebarOpened = false;
    },
    selectedLocale: function () {
      this.currentLocale = this.selectedLocale;
    },
  },
  methods: {
    async loadActiveTaskStory() {
      let story = await TasksApi.getActiveStory();

      await this.$store.dispatch(SET_ACTIVE_STORY, story.data.story);
    },
    closeErrorTip() {
      this.isShowErrorTip = false;
      this.tipText = "";
    },

    openSidebar() {
      this.isSidebarOpened = true;
    },
    hideSidebar() {
      this.$nextTick(() => {
        if (this.isMobile && this.isSidebarOpened) {
          this.isSidebarOpened = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.main-layout {
  display: flex;
  align-items: stretch;
  //height: 100%;
  min-height: 100%;

  &__sidebar {
    &-mobile-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(51, 63, 72, 0.2);
      width: 100vw;
      height: 100vh;
      z-index: 2;
      opacity: 0;
      display: none;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      padding: 40px 16px;
      background-color: $grayLight;
      z-index: 3;
    }

    width: 296px;
    flex-shrink: 0;
    height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__inner {
    width: 100%;
    min-height: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: #ffffff;
    //padding: 40px 32px 40px 36px;
    overflow: hidden;
  }

  &__body {
    flex-grow: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-wrapper {
      padding: 40px 32px 0;
    }
  }

  &__mobile {
    &-header {
      display: none;
      align-items: center;
      margin-bottom: 44px;
    }

    &-open-mmenu {
      margin-right: 24px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      transition: opacity 0.2s linear;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    &-logo {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-notifications {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-profile {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      margin-left: 24px;

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-user {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }

  &__page {
    &-title {
      font-family: "Rubik", sans-serif;
      font-weight: 700;
      font-size: pxToRem(32);
      line-height: pxToRem(38);
    }
  }
}

.page {
  padding: 0 32px 40px;
}

.header-profile {
  display: flex;
  align-items: center;

  &__notifications {
    width: 36px;
    height: 36px;
    margin-right: 32px;
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 0.6;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__user {
    width: 56px;
    height: 56px;
    cursor: pointer;
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 0.6;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}

.toggle-sidebar {
  display: none;
  position: absolute;
  top: 46px;
  right: -14px;
  width: 36px;
  height: 36px;
  box-shadow: 0 0 16px rgba(183, 201, 211, 0.5);
  background-color: #ffffff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 486px) {
  .toggle-sidebar {
    display: none;
  }

  .main-layout {
    flex-direction: column;
    padding-bottom: 80px;

    &__sidebar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      transform: translateX(-100%);
      transition: transform 0.2s linear;
      z-index: 999;

      &-inner {
        width: 296px;
        height: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      &-mobile-backdrop {
        display: block;
      }

      &.opened {
        transform: translateX(0);
      }

      &.opened & {
        &-mobile-backdrop {
          opacity: 1;
          transition: opacity 0.2s linear;
          transition-delay: 0.2s;
        }
      }
    }

    &__inner {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 24px 16px 0 16px;
      flex-grow: 1;
    }

    &__mobile {
      &-header {
        display: flex;
      }
    }
    &__header-wrapper {
      padding: 0;
    }
  }
}
@media screen and (max-width: 486px) {
  .header-profile {
    display: none;
  }

  .header-actions {
    position: fixed;
    left: 16px;
    bottom: 30px;
    width: calc(100% - 32px);
    z-index: 9;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .main-layout {
    &__page-title,
    &__page-title > * {
      font-size: pxToRem(24);
      line-height: pxToRem(28);
    }
  }
}
</style>
