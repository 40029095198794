<template>
  <div class="faq-item" :class="{ active }">
    <div class="faq-item__header" @click="active = !active">
      <div class="faq-item__question">
        {{ faqItem.question[selectedLocale] }}
      </div>
      <div class="faq-item__icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.916 2.25C11.2196 2.25 10.6552 2.81447 10.6552 3.51078V10.8233H3.51078C2.81447 10.8233 2.25 11.3877 2.25 12.084C2.25 12.7804 2.81447 13.3448 3.51078 13.3448H10.6552V20.4892C10.6552 21.1855 11.2196 21.75 11.916 21.75C12.6123 21.75 13.1767 21.1855 13.1767 20.4892V13.3448H20.4892C21.1855 13.3448 21.75 12.7804 21.75 12.084C21.75 11.3877 21.1855 10.8233 20.4892 10.8233H13.1767V3.51078C13.1767 2.81447 12.6123 2.25 11.916 2.25Z"
            fill="#00B7BD"
          />
        </svg>
      </div>
    </div>
    <div class="faq-item__body">
      {{ faqItem.answer[selectedLocale] }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FaqItem",
  props: {
    faqItem: {
      type: Object,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    ...mapState(["selectedLocale"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.faq-item {
  border-bottom: 1px solid #c4c4c4;

  &:last-child {
    border-bottom: none;
  }

  &:first-child & {
    &__header {
      padding-top: 24px;
    }
  }

  &.active & {
    &__icon {
      transform: rotate(135deg);
    }

    &__body {
      height: auto;
      padding-bottom: 36px;
    }
  }

  &__header {
    padding: 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__title {
    margin-right: 32px;
    flex-shrink: 1;
    flex-grow: 1;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    color: $mainBlack;
  }

  &__icon {
    flex-shrink: 0;
    flex-grow: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s linear, opacity 0.2s linear;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__body {
    overflow: hidden;
    padding-bottom: 0;
    height: 0;
    transition: height 0.3s linear, padding 0.2s linear;
    //padding-bottom: 36px;
  }
}
</style>
