import axiosInstance from "@/services/http/axios-instance";

//BASE URL: host/api

class _UserApi {
  /**
   *
   * @param {string} password
   * @param {string} cPassword
   * @param {string | undefined} oldPassword
   * @returns {Promise<boolean>}
   */
  async setPassword(password, cPassword, oldPassword) {
    const payload = {
      password,
      c_password: cPassword,
    };
    let url = "/user/password/new";
    if (oldPassword) {
      payload.old_password = oldPassword;
      url = "/user/password/change";
    }
    return !!(await axiosInstance.post(url, { ...payload })).data?.success;
  }

  async resetPassword(password, cPassword) {
    const payload = {
      password,
      c_password: cPassword,
    };
    let url = "/user/password/reset";

    return !!(await axiosInstance.post(url, { ...payload })).data?.success;
  }

  getProfileData() {
    return axiosInstance.get("/user/me");
  }

  updateProfileData(data) {
    return axiosInstance.post("/user/me", { ...data });
  }

  getActiveTFA() {
    return axiosInstance.get("/user/tfa/active");
  }

  getAvailableTFA() {
    return axiosInstance.get("/user/tfa/available");
  }

  setActiveTFA(activeTFATypes) {
    return axiosInstance.post("/user/tfa/update", {
      active_tfa_types: activeTFATypes,
    });
  }

  async getGoogleAuthUrl() {
    const r = await axiosInstance.get("/user/tfa/google-auth/url");
    return r.data.url;
  }

  verifyGoogleAuthCode(code, username) {
    return axiosInstance.post("/user/tfa/google-auth/code", { code, username });
  }

  getDocuments() {
    return axiosInstance.get("/user/documents");
  }
}
export const UserApi = new _UserApi();
