<template>
  <label class="checkbox">
    <input type="checkbox" v-model="isChecked" />
    <span class="checkbox__mark">
      <svg
        width="13"
        height="10"
        viewBox="0 0 13 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.00604 7.72097L1.92094 4.39367C1.57868 4.02454 1.00199 4.00276 0.632859 4.34502C0.263731 4.68728 0.24195 5.26397 0.584208 5.6331L4.02826 9.34753C4.5957 9.95951 5.57425 9.92473 6.09681 9.27403L12.3539 1.48253C12.6691 1.09004 12.6064 0.51635 12.2139 0.201157C11.8214 -0.114039 11.2477 -0.0513765 10.9325 0.341114L5.00604 7.72097Z"
          fill="white"
        />
      </svg>
    </span>
    <span class="checkbox__text" v-if="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "CheckboxInputNew",
  model: {
    prop: "checked",
    event: "update",
  },
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    label: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      isChecked: false,
      initialDataLoaded: false,
    };
  },
  created() {
    this.isChecked = this.checked;

    this.$nextTick(() => {
      this.initialDataLoaded = true;
    });
  },
  watch: {
    checked() {
      this.isChecked = this.checked;
    },
    isChecked() {
      if (this.initialDataLoaded) {
        this.$emit("update", this.isChecked);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  input:checked + &__mark {
    background-color: $mainCold;
    border-color: $mainCold;

    svg {
      display: block;
    }
  }

  &__mark {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    padding: 1.25px;
    border: 1px solid $gray2;
    box-sizing: border-box;
    border-radius: 0.375rem;
    margin-right: 0.5rem;
    transition: border-color 0.2s linear;

    svg {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__text {
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray1;
  }
}
</style>
