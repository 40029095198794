<template>
  <div class="login-form__wrapper">
    <div class="login-form">
      <div class="login-form__title">{{ $t("forgot_password_title") }}</div>
      <div class="login-form__sub-title">
        {{ $t("enter_email_for_password_reset") }}
      </div>
      <div class="login-form__field">
        <email-input
          v-model="email"
          :v-errors="v$.email.$errors"
          @enter="requestForgot"
        ></email-input>
      </div>
      <div class="login-form__button">
        <primary-button
          :disabled="isContinueButtonDisabled"
          :label="loginBtnLabel"
          @click="requestForgot"
        ></primary-button>
      </div>
      <div class="login-form__back">
        <button @click="goBack" type="button">{{ $t("back") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
import AuthApi from "@/office/auth-api";

import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import EmailInput from "@/components/input/EmailInput";
import { OTP_TYPES } from "@/const/auth/otp-types";
import {SET_SUPPORT_LINK, SET_USER_EMAIL} from "@/store/const/actions-types";
import store from "@/store";

export default {
  name: "ForgotPassword",
  components: {
    EmailInput,
    PrimaryButton,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  metaInfo: {
    title: "Forgot password",
  },
  inject: ["showErrorTip"],
  data() {
    return {
      email: "",
    };
  },
  validations() {
    return {
      email: { required, email },
    };
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, 'forgot_pass');
  },
  computed: {
    loginBtnLabel() {
      return this.$t("continue");
    },
    isContinueButtonDisabled() {
      return !this.email;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    focusPhoneInput(_phoneInputInstance) {
      _phoneInputInstance.focus();
    },
    requestForgot() {
      AuthApi.resendOtp(this.email, OTP_TYPES.EMAIL, true)
        .then(async () => {
          await this.$store.dispatch(SET_USER_EMAIL, this.email);
          this.$router.push("/forgot-password/otp");
        })
        .catch(() => {
          this.showErrorTip(this.$t("errors.not-found-email"));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.password-info {
  margin-top: -28px;

  &__title {
    font-size: pxToRem(12);
    line-height: pxToRem(18);
    color: $mainBlack;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__item {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active & {
      &-icon {
        svg {
          path {
            fill: $mainCold;
          }
        }
      }

      &-text {
        color: $mainCold;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;

      svg,
      img {
        width: 100%;
        height: 100%;
      }

      svg {
        path {
          fill: $gray2;
        }
      }
    }

    &-text {
      font-size: pxToRem(12);
      line-height: pxToRem(18);
      color: $gray2;
    }
  }
}

.terms-agreed {
  margin-top: 40px;
  color: $gray1;
  text-align: center;

  &__info {
    display: inline;
    color: $mainCold;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.login-form__back {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.25rem;

  button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $mainCold;
    cursor: pointer;
  }
}

@media screen and (max-width: 486px) {
  .login-form {
    width: 100%;
    padding: 0;
    box-shadow: none;

    &-wrapper {
      width: 100%;
    }
  }
}
</style>
