<!--suppress ALL -->
<template>
  <div class="registration-details">
    <button
      @click="toggleDetailsDropdown"
      type="button"
      class="registration-details__title details-title"
      :class="{ 'details-title--active': isDetailsDropdownOpen }"
    >
      <span class="details-title__text">
        {{ $t("referral_registration.detailed_conditions.title") }}
      </span>
      <svg
        class="details-title__icon"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7929 15.7071C18.1834 16.0976 18.8166 16.0976 19.2071 15.7071C19.5976 15.3166 19.5976 14.6834 19.2071 14.2929L17.7929 15.7071ZM12.5 9L13.2071 8.29289L12.5 7.58579L11.7929 8.29289L12.5 9ZM5.79289 14.2929C5.40237 14.6834 5.40237 15.3166 5.79289 15.7071C6.18342 16.0976 6.81658 16.0976 7.20711 15.7071L5.79289 14.2929ZM19.2071 14.2929L13.2071 8.29289L11.7929 9.70711L17.7929 15.7071L19.2071 14.2929ZM11.7929 8.29289L5.79289 14.2929L7.20711 15.7071L13.2071 9.70711L11.7929 8.29289Z"
          fill="currentColor"
        />
      </svg>
    </button>

    <div
      v-if="isDetailsDropdownOpen"
      class="registration-details__content details-content"
    >
      <div class="details-content__title">
        {{ $t("referral_registration.detailed_conditions.list.title") }}
      </div>

      <ul class="details-content__steps steps-list">
        <template v-for="(_, index) of Array.from({ length: stepsCount })">
          <li :key="index" class="steps-list__item list-item">
            <div class="list-item__icon">
              {{ index + 1 }}
            </div>
            <span class="list-item__text">
              {{
                $t(
                  `referral_registration.detailed_conditions.list.step_${
                    index + 1
                  }`
                )
              }}
            </span>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReferralRegistrationDetails",
  data() {
    return {
      stepsCount: 4,
      isDetailsDropdownOpen: false,
    };
  },
  methods: {
    toggleDetailsDropdown() {
      this.isDetailsDropdownOpen = !this.isDetailsDropdownOpen;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/variables";

.registration-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 76px;

  &__title {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  &__content {
    padding-top: 42px;
  }
}

.details-title {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: pxToRem(4);

  color: #00b7bd;

  &--active {
    svg {
      transform: rotate(0);
    }
  }

  &__icon {
    transform: rotate(180deg);
  }

  &__text {
    font-weight: 600;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    font-family: "Rubik", sans-serif;
  }
}

.details-content {
  &__title {
    font-weight: 700;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    text-align: center;

    margin-bottom: 40px;
  }

  &__steps {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.steps-list {
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;
}

.list-item {
  --icon-width: 2.5rem;

  padding: 1rem;
  border-radius: 1rem;
  background-color: $grayLight;
  display: grid;
  grid-template-columns: var(--icon-width) 1fr;
  align-items: center;
  width: 100%;
  column-gap: 1rem;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--icon-width);
    height: var(--icon-width);

    border-radius: 100%;

    background: $mainColdLight;
    color: $mainCold;

    font-weight: 400;
    font-size: 1.625rem;
    line-height: normal;
    font-family: "Rubik", sans-serif;

    svg {
      color: $mainCold;
    }
  }

  &__text {
    font-family: "Nunito", "Rubik", sans-serif;
    color: $mainBlack;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    &--final {
      font-weight: 700;
    }
  }
}
</style>
