class _ClipboardService {
  /**
   *
   * @param {string} value
   */
  async copyToClipboard(value) {
    //here can be fallback function call
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(value);
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}

export default new _ClipboardService();
