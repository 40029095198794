<template>
  <div class="card-transaction card-transaction__placeholder">
    <div class="card-transaction__placeholder-info">
      <div class="card-transaction__placeholder-icon"></div>
      <div class="card-transaction__placeholder-info-content">
        <div
          class="card-transaction__placeholder-dash card-transaction__placeholder-title"
        ></div>
        <div
          class="card-transaction__placeholder-dash card-transaction__placeholder-descr"
        ></div>
      </div>
    </div>
    <div
      class="card-transaction__placeholder-dash card-transaction__placeholder-amount"
    ></div>
    <div
      class="card-transaction__placeholder-dash card-transaction__placeholder-settings"
    ></div>
  </div>
</template>

<script>
export default {
  name: "CardTransactionPlaceholder",
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.card-transaction {
  &__placeholder {
    width: 100%;
    height: 72px;
    padding: 14px 88px 14px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-info {
      display: flex;
      align-items: flex-start;
    }

    &-dash {
      height: 18px;
      border-radius: 8px;
    }

    &-info {
      margin-right: 133px;
    }

    &-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: $gray2;
      margin-right: 24px;
    }

    &-title {
      background: $gray2;
      width: 144px;
      margin-bottom: 8px;
    }

    &-descr {
      width: 284px;
      background: $gray3;
    }

    &-amount {
      width: 148px;
      background: $gray3;
      margin-left: auto;
      flex-shrink: 0;
    }

    &-settings {
      width: 120px;
      background: $gray2;
      flex-shrink: 0;
      margin-left: auto;
    }
  }
}
</style>
