<template>
  <div class="unex-diia-success">
    <onboarding-loader
      :lottie-json="documentsLottie"
      :title="$t('unex.onboarding.diia.sign.approved.title')"
      :sub-title="$t('unex.onboarding.diia.sign.approved.sub_title')"
    ></onboarding-loader>
  </div>
</template>

<script>
import OnboardingLoader from "@/components/profile/Onboarding/OnboardingLoader";
import router from "@/router";
import { UnexOnboardingApi } from "@/office/unex-onboarding-api";
import documentsLottie from "@/assets/lottie/documents-loader.json";

export default {
  name: "UnexOnboardingDiiaSignRedirectPage",
  components: { OnboardingLoader },
  data() {
    return {
      documentsLottie: documentsLottie,
    };
  },
  created() {
    const requestId = this.$route.query.requestId;

    if (!requestId || requestId.trim().length === 0) {
      router.push("/");
    }

    this.$router.replace({});

    this.sendDiiaApprove(requestId);
  },
  methods: {
    sendDiiaApprove(requestId) {
      UnexOnboardingApi.sendDiiaSignApprove(requestId);
    },
  },
};
</script>

<style scoped></style>
