import { TasksApi } from "@/office/tasks-api";

class _TasksService {
  static TASK = "task";
  static STORY = "global_task";

  static TASK_REGISTRATION = "registration";
  static TASK_ADD_EXCHANGE_WALLET = "add_first_exchange";
  static TASK_STAKING = "staking";
  static TASK_ADD_WELD_CARD = "add_weld_card";

  get TASK_REGISTRATION() {
    return _TasksService.TASK_REGISTRATION;
  }

  get TASK_ADD_EXCHANGE_WALLET() {
    return _TasksService.TASK_ADD_EXCHANGE_WALLET;
  }

  get TASK_STAKING() {
    return _TasksService.TASK_STAKING;
  }

  get TASK_TYPE() {
    return _TasksService.TASK;
  }

  get STORY_TYPE() {
    return _TasksService.STORY;
  }

  async completeTask(taskSlug) {
    return await TasksApi.markTaskComplete(_TasksService.TASK, taskSlug);
  }

  get TASK_ADD_WELD_CARD() {
    return _TasksService.TASK_ADD_WELD_CARD;
  }
}

export const TasksService = new _TasksService();
