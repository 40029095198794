export const CURRENCIES_LIST = [
  {
    symbol: "$",
    code: 36,
  },
  {
    symbol: "₼",
    code: 944,
  },
  {
    symbol: "L",
    code: 8,
  },
  {
    symbol: ".د.ج • DA",
    code: 12,
  },
  {
    symbol: "$",
    code: 840,
  },
  {
    symbol: "$",
    code: 951,
  },

  {
    symbol: "Kz",
    code: 973,
  },

  {
    symbol: "$",
    code: 32,
  },
  {
    symbol: "ƒ",
    code: 533,
  },
  {
    symbol: "؋ • Af",
    code: 971,
  },
  {
    symbol: "$",
    code: 44,
  },
  {
    symbol: "৳",
    code: 50,
  },
  {
    symbol: "$",
    code: 52,
  },
  {
    symbol: ".د.ب • BD",
    code: 48,
  },
  {
    symbol: "$",
    code: 84,
  },
  {
    symbol: "₣",
    code: 952,
  },

  {
    symbol: "$",
    code: 60,
  },
  {
    symbol: "Br",
    code: 933,
  },
  {
    symbol: "лв",
    code: 975,
  },
  {
    symbol: "$",
    code: 68,
  },
  {
    symbol: "KM",
    code: 977,
  },
  {
    symbol: "P",
    code: 72,
  },
  {
    symbol: "$",
    code: 986,
  },
  {
    symbol: "$",
    code: 96,
  },

  {
    symbol: "₣",
    code: 108,
  },
  {
    symbol: "Nu",
    code: 64,
  },
  {
    symbol: "Vt",
    code: 548,
  },

  {
    symbol: "£",
    code: 826,
  },
  {
    symbol: "Bs. S.",
    code: 928,
  },
  {
    symbol: "₫",
    code: 704,
  },
  {
    symbol: "֏",
    code: 51,
  },
  {
    symbol: "₣",
    code: 953,
  },
  {
    symbol: "₣",
    code: 950,
  },
  {
    symbol: "G",
    code: 332,
  },
  {
    symbol: "D",
    code: 270,
  },
  {
    symbol: "₵",
    code: 936,
  },
  {
    symbol: "$",
    code: 328,
  },

  {
    symbol: "Q",
    code: 320,
  },
  {
    symbol: "₣",
    code: 324,
  },

  {
    symbol: "£",
    code: 292,
  },
  {
    symbol: "L",
    code: 340,
  },
  {
    symbol: "$",
    code: 344,
  },

  {
    symbol: "kr",
    code: 208,
  },

  {
    symbol: "₾",
    code: 981,
  },
  {
    symbol: "kr",
    code: 208,
  },
  {
    symbol: "₣",
    code: 976,
  },
  {
    symbol: "£",
    code: 826,
  },
  {
    symbol: "₣",
    code: 262,
  },

  {
    symbol: "$",
    code: 214,
  },

  {
    symbol: "Nfk",
    code: 232,
  },
  {
    symbol: "L",
    code: 748,
  },

  {
    symbol: "Br",
    code: 230,
  },
  {
    symbol: ".ج.م • LE",
    code: 818,
  },
  {
    symbol: "﷼ • YR",
    code: 886,
  },
  {
    symbol: "K",
    code: 967,
  },
  {
    symbol: ".د.م • Dh",
    code: 504,
  },
  {
    symbol: "Z$",
    code: 932,
  },
  {
    symbol: "₪",
    code: 376,
  },
  {
    symbol: "₹",
    code: 356,
  },
  {
    symbol: "Rp",
    code: 360,
  },
  {
    symbol: ".د.ع • ID",
    code: 368,
  },
  {
    symbol: "﷼ • IR",
    code: 364,
  },

  {
    symbol: "kr",
    code: 352,
  },

  {
    symbol: ".د.إ • JD",
    code: 400,
  },
  {
    symbol: "$",
    code: 132,
  },
  {
    symbol: "₸",
    code: 398,
  },
  {
    symbol: "$",
    code: 136,
  },
  {
    symbol: "៛",
    code: 116,
  },

  {
    symbol: "$",
    code: 124,
  },
  {
    symbol: "﷼ • QR",
    code: 634,
  },
  {
    symbol: "KSh",
    code: 404,
  },
  {
    symbol: "с",
    code: 417,
  },
  {
    symbol: "¥",
    code: 156,
  },

  {
    symbol: "원",
    code: 408,
  },

  {
    symbol: "$",
    code: 170,
  },
  {
    symbol: "₣",
    code: 174,
  },
  {
    symbol: "₩",
    code: 410,
  },
  {
    symbol: "₡",
    code: 188,
  },

  {
    symbol: "$",
    code: 192,
  },
  {
    symbol: ".د.ك • KD",
    code: 414,
  },
  {
    symbol: "₭",
    code: 418,
  },

  {
    symbol: "L",
    code: 426,
  },

  {
    symbol: "$",
    code: 430,
  },
  {
    symbol: ".ل.ل",
    code: 422,
  },
  {
    symbol: ".د.ل • LD",
    code: 434,
  },
  {
    symbol: "₣",
    code: 756,
  },

  {
    symbol: "K",
    code: 104,
  },
  {
    symbol: "Re",
    code: 480,
  },
  {
    symbol: "UM",
    code: 929,
  },
  {
    symbol: "Ar.",
    code: 969,
  },

  {
    symbol: "$",
    code: 446,
  },
  {
    symbol: "ден.",
    code: 807,
  },
  {
    symbol: "K",
    code: 454,
  },
  {
    symbol: "RM",
    code: 458,
  },

  {
    symbol: ".ރ • Rf",
    code: 462,
  },

  {
    symbol: ".د.م • Dh",
    code: 504,
  },

  {
    symbol: "$",
    code: 484,
  },

  {
    symbol: "MT",
    code: 943,
  },
  {
    symbol: "L",
    code: 498,
  },

  {
    symbol: "₮",
    code: 496,
  },

  {
    symbol: "N$",
    code: 516,
  },

  {
    symbol: "Re",
    code: 524,
  },

  {
    symbol: "₦",
    code: 566,
  },

  {
    symbol: "$",
    code: 558,
  },

  {
    symbol: "$",
    code: 554,
  },
  {
    symbol: "$",
    code: 554,
  },
  {
    symbol: "₣",
    code: 953,
  },
  {
    symbol: "kr",
    code: 578,
  },
  {
    symbol: ".د.إ • Dh",
    code: 784,
  },
  {
    symbol: "﷼ • RO",
    code: 512,
  },
  {
    symbol: "£",
    code: 826,
  },

  {
    symbol: "$",
    code: 554,
  },

  {
    symbol: "Re",
    code: 586,
  },

  {
    symbol: "B/.",
    code: 590,
  },
  {
    symbol: "K",
    code: 598,
  },
  {
    symbol: "₲",
    code: 600,
  },
  {
    symbol: "S/",
    code: 604,
  },
  {
    symbol: "R",
    code: 710,
  },
  {
    symbol: "SSP",
    code: 728,
  },

  {
    symbol: "zł",
    code: 985,
  },

  {
    symbol: "₣",
    code: 646,
  },
  {
    symbol: "L",
    code: 946,
  },
  {
    symbol: "₡",
    code: 222,
  },
  {
    symbol: "$",
    code: 882,
  },

  {
    symbol: "Db",
    code: 930,
  },
  {
    symbol: "﷼ • SR",
    code: 682,
  },
  {
    symbol: "Re",
    code: 690,
  },

  {
    symbol: "din. • дин.",
    code: 941,
  },
  {
    symbol: "$",
    code: 702,
  },
  {
    symbol: ".ل.س • S£",
    code: 760,
  },

  {
    symbol: "ƒ",
    code: 532,
  },

  {
    symbol: "$",
    code: 90,
  },
  {
    symbol: "S",
    code: 706,
  },
  {
    symbol: "£",
    code: 938,
  },
  {
    symbol: "$",
    code: 968,
  },

  {
    symbol: "Le",
    code: 694,
  },
  {
    symbol: "с.",
    code: 972,
  },
  {
    symbol: "฿",
    code: 764,
  },
  {
    symbol: "NT$",
    code: 901,
  },
  {
    symbol: "TSh",
    code: 834,
  },

  {
    symbol: "$",
    code: 554,
  },
  {
    symbol: "$",
    code: 776,
  },
  {
    symbol: "$",
    code: 780,
  },

  {
    symbol: ".د.ت • TD",
    code: 788,
  },
  {
    symbol: "₺",
    code: 949,
  },
  {
    symbol: "m",
    code: 934,
  },
  {
    symbol: "USh",
    code: 800,
  },
  {
    symbol: "Ft",
    code: 348,
  },
  {
    symbol: "so’m • сўм",
    code: 860,
  },
  {
    symbol: "₴",
    code: 980,
  },
  {
    symbol: "$",
    code: 858,
  },
  {
    symbol: "kr",
    code: 208,
  },
  {
    symbol: "$",
    code: 242,
  },
  {
    symbol: "₱",
    code: 608,
  },

  {
    symbol: "€",
    code: 953,
  },
  {
    symbol: "Kn",
    code: 191,
  },

  {
    symbol: "Kč",
    code: 203,
  },
  {
    symbol: "$",
    code: 152,
  },
  {
    symbol: "€",
    code: 978,
  },

  {
    symbol: "₣",
    code: 756,
  },
  {
    symbol: "kr",
    code: 752,
  },
  {
    symbol: "Re",
    code: 144,
  },
  {
    symbol: "$",
    code: 388,
  },
  {
    symbol: "¥",
    code: 392,
  },
];
