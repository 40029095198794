import store from "@/store";
import {
  LOAD_USER_DATA,
  LOGIN_USER,
  SET_PARENT_REFERRAL_UUID,
  SET_USER_COUNTRY_CODE,
  SET_USER_PHONE,
} from "@/store/const/actions-types";

export const collectInitialData = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const phone = localStorage.getItem("phone");
  const refreshToken = localStorage.getItem("refreshToken");
  const parentReferralUUID = localStorage.getItem("parentReferralUUID");
  const countryCode = localStorage.getItem("countryCode");

  if (accessToken) {
    await store.dispatch(LOGIN_USER, { accessToken, phone, refreshToken });
  } else if (phone) {
    await store.dispatch(SET_USER_PHONE, phone);
  }

  if (parentReferralUUID) {
    await store.dispatch(SET_PARENT_REFERRAL_UUID, parentReferralUUID);
  }

  if (countryCode) {
    store.commit(SET_USER_COUNTRY_CODE, countryCode);
  }
};

export const loadUserData = async () => {
  await store.dispatch(LOAD_USER_DATA);
};
