<template>
  <button
    type="button"
    class="back-button"
    :class="{
      'back-button--with-text': !!text,
      'back-button--primary': primary,
    }"
    @click="$emit('click')"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7071 4.70711C17.0976 4.31658 17.0976 3.68342 16.7071 3.29289C16.3166 2.90237 15.6834 2.90237 15.2929 3.29289L16.7071 4.70711ZM8 12L7.29289 11.2929L6.58579 12L7.29289 12.7071L8 12ZM15.2929 20.7071C15.6834 21.0976 16.3166 21.0976 16.7071 20.7071C17.0976 20.3166 17.0976 19.6834 16.7071 19.2929L15.2929 20.7071ZM15.2929 3.29289L7.29289 11.2929L8.70711 12.7071L16.7071 4.70711L15.2929 3.29289ZM7.29289 12.7071L15.2929 20.7071L16.7071 19.2929L8.70711 11.2929L7.29289 12.7071Z"
        fill="currentColor"
      />
    </svg>
    <span v-if="text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: "PopupBackButton",
  props: {
    text: {
      type: String,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

button.back-button {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.5rem;
  height: 1.5rem;

  opacity: 1;
  transition: opacity 0.2s linear;

  color: $gray1;

  &--with-text {
    width: 100%;
  }

  &--primary {
    display: grid;
    grid-auto-flow: column;
    column-gap: 0.5rem;
    color: $mainCold;

    span {
      font-family: "Rubik", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $mainCold;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
