class CookieService {
  /**
   *
   * @param {string} key
   * @returns {string | undefined}
   */
  getCookieValue(key) {
    const cookies = document.cookie.split(";");
    if (!cookies.length) return;
    return cookies.find((value) => value.includes(key));
  }
}

export default new CookieService();
