class _UnexOnboardingService {
  getStepErrorMessageKey(step = "default") {
    return {
      title: `unex.onboarding.error.${step}.title`,
      subTitle: `unex.onboarding.error.${step}.sub-title`,
    };
  }

  getFeedbackLink(cardProduct, phone, fullName, locale) {
    if (!cardProduct) {
      if (locale === "en") {
        return "https://docs.google.com/forms/d/1JGD5HPSU0w9wYBxOmG72v-9dOw1sOfWOvjb-qy07L4k/viewform";
      } else {
        return "https://docs.google.com/forms/d/1ufy8xoJx2V_T4a7NNdfawyV3P3AZvajaPfEmVBMF0kM/viewform";
      }
    }

    if (cardProduct.slug === "weld-card") {
      if (locale === "en") {
        return "https://docs.google.com/forms/d/1JGD5HPSU0w9wYBxOmG72v-9dOw1sOfWOvjb-qy07L4k/viewform";
      } else {
        return "https://docs.google.com/forms/d/1ufy8xoJx2V_T4a7NNdfawyV3P3AZvajaPfEmVBMF0kM/viewform";
      }
    }

    if (locale === "en") {
      return `https://docs.google.com/forms/d/e/1FAIpQLSftIgXoNKpZekAalCJcG5hN4df46c7Eb2dVcA1WUQpp7AzEmA/viewform?usp=pp_url&entry.1802219202=${fullName}&entry.397384712=${phone}`;
    }

    return `https://docs.google.com/forms/d/e/1FAIpQLScnnECATdrY6u794R7_J9Qg2oOX9ibpC1io8TVJtV7XstD_4A/viewform?usp=pp_url&entry.170948363=${fullName}&entry.764128475=${phone}`;
  }
}

export const UnexOnboardingService = new _UnexOnboardingService();
