<template>
  <div class="stepper">
    <div
      class="stepper__item"
      :class="{
        finished: index < activeStep,
        active: index === activeStep,
      }"
      v-for="index in stepsCount"
      :key="`step-${index}`"
    >
      <div class="stepper__item-identifier" v-if="activeStep <= index"></div>
      <div class="stepper__item-identifier" v-else>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 17.75C13.8325 17.75 17.75 13.8325 17.75 9C17.75 4.16751 13.8325 0.25 9 0.25C4.16751 0.25 0.25 4.16751 0.25 9C0.25 13.8325 4.16751 17.75 9 17.75ZM5.95645 8.57306L7.92143 10.6739L11.7227 5.98158C11.94 5.71337 12.3335 5.67208 12.6018 5.88936C12.87 6.10664 12.9113 6.50021 12.694 6.76842L8.71452 11.6807C8.33113 12.1539 7.61796 12.1793 7.20191 11.7345L5.04355 9.42694C4.80776 9.17485 4.82097 8.77934 5.07306 8.54355C5.32515 8.30776 5.72066 8.32097 5.95645 8.57306Z"
            fill="#00B7BD"
          />
        </svg>
      </div>
      <div class="stepper__item-delimiter" v-if="index < stepsCount"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnboardingStepper",
  props: {
    activeStep: {
      type: Number,
    },
    stepsCount: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

.stepper {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }

    &-identifier {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17px;
      height: 17px;
      border: 1px solid transparent;
      border-radius: 50%;
      background-color: $gray2;

      margin-right: 4px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-delimiter {
      width: 136px;
      height: 1px;
      background-color: $gray2;
    }

    &.active & {
      &-identifier {
        width: 28px;
        height: 28px;
        border-color: $mainCold;
        background-color: transparent;
      }
    }

    &.finished & {
      &-identifier {
        width: 20px;
        height: 20px;
        background-color: transparent;
      }

      &-delimiter {
        background-color: $mainCold;
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .stepper {
    &__item {
      &-delimiter {
        width: 78px;
      }
    }
  }
}
</style>
