<template>
  <div
    ref="stepperItemRef"
    class="stepper-item"
    :class="{
      active: stepIndex === activeStep,
      finished: activeStep > stepIndex,
    }"
  >
    <div class="stepper-item__mark">
      <svg
        v-if="activeStep > stepIndex"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75C6.61479 21.75 2.25 17.3852 2.25 12ZM16.5303 10.5303C16.8232 10.2374 16.8232 9.76256 16.5303 9.46967C16.2374 9.17678 15.7626 9.17678 15.4697 9.46967L11 13.9393L8.53033 11.4697C8.23744 11.1768 7.76256 11.1768 7.46967 11.4697C7.17678 11.7626 7.17678 12.2374 7.46967 12.5303L10.4697 15.5303C10.7626 15.8232 11.2374 15.8232 11.5303 15.5303L16.5303 10.5303Z"
          fill="#00B7BD"
        />
      </svg>
      <svg
        v-else
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25ZM12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75Z"
          :fill="activeStep === stepIndex ? '#00B7BD' : '#DDE5ED'"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalStepperItem",
  props: {
    activeStep: {
      type: Number,
      required: true,
    },
    stepIndex: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.stepper-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;

  &.active & {
    &__mark {
      background-color: transparent;
      border-color: $mainCold;
    }
  }

  &.finished & {
    &__mark {
      border: none;
      background-color: transparent;
    }
  }

  &__mark {
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      background: #ffffff;
      padding: 0 0.140625rem;
    }
  }
}
</style>
