<template>
  <button
    :disabled="disabled"
    class="button-primary"
    @click="clickHandler"
    :type="buttonType"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "PrimaryColoredButton",
  props: {
    buttonType: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.button-primary {
  width: 100%;
  margin: 0;
  border: none;
  background-color: $mainCold;
  padding: 0.75rem 0;
  border-radius: 1.5rem;
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: all 100ms ease-in;

  &:disabled {
    background-color: $grayLight;
    color: $gray2;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background-color: $hoverMain;
    }

    &:active {
      background-color: $pressed;
    }
  }
}
</style>
