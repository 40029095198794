<template>
  <div class="popup">
    <div
      class="popup__block popup__block--card-data"
      :class="{
        'popup__block--card-data_desktop': !isMobile,
      }"
    >
      <div class="popup__header">
        <div class="popup__title">
          {{ $t("card.popup.card-data.title") }}
        </div>
        <popup-close-button @click="closePopup"></popup-close-button>
      </div>
      <div class="card-data">
        <div class="card-data__items">
          <base-loader v-if="isDataLoading"></base-loader>
          <div class="card-data__item">
            <div class="card-data__item-title">
              {{ $t("card.popup.card-data.pan") }}
            </div>
            <div class="card-data__item-value">
              {{ formattedPan }}
            </div>
          </div>
          <div class="card-data__item">
            <div class="card-data__item-title">
              {{ $t("card.popup.card-data.exp") }}
            </div>
            <div class="card-data__item-value">
              {{ `${card.exp_m}/${card.exp_y}` }}
            </div>
          </div>
          <div class="card-data__item card-data__item--iban">
            <div class="card-data__item-title">
              {{ $t("card.popup.card-data.iban") }}
            </div>
            <div class="card-data__item-value">
              {{ card.iban }}
            </div>
          </div>
          <div class="card-data__item">
            <div class="card-data__item-title">
              {{ $t("card.popup.card-data.cvv") }}
            </div>
            <div class="card-data__item-value">
              <div
                class="card-data__item-cvv-get"
                @click="sendCvvOtp"
                v-if="!isCvvOtpSent && !cvv"
              >
                {{ $t("card.popup.card-data.cvv-get") }}
              </div>
              <div
                class="card-data__item-cvv-placeholder"
                v-if="isCvvOtpSent && !cvv"
              >
                —
              </div>
              <div class="card-data__item-cvv" v-if="cvv">{{ cvv }}</div>
            </div>
          </div>
          <div class="card-data__item-otp" v-if="isCvvOtpSent && !cvv">
            <w-otp-input
              class="card-data__item-otp"
              :num-count="6"
              v-model="cvvOtpCode"
              v-if="isCvvOtpSent"
              :resendMethod="sendCvvOtp"
              :label="$t('card.popup.card-data.cvv-hint')"
              @enter="getCvv"
              mode="small"
            ></w-otp-input>
            <no-bg-button
              class="card-data__item-cvv-otp-verify"
              :label="$t('card.popup.card-data.cvv-verify')"
              @click="getCvv"
            ></no-bg-button>
          </div>
        </div>

        <div class="card-data__actions">
          <bordered-button
            class="card-data__action"
            :label="$t('card.popup.card-data.copy-pan')"
            @click="copyCardPan"
          ></bordered-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopupCloseButton from "@/components/button/PopupCloseButton";
import WOtpInput from "@/components/input/WOtpInput";
import { CardApi } from "@/office/card-api";
import NoBgButton from "@/components/button/NoBgButton";
import BorderedButton from "@/components/button/BorderedButton";
import BaseLoader from "@/components/base/BaseLoader";
import responsiveMixin from "@/mixins/responsive-mixin";

export default {
  name: "CardItemDataPopup",
  mixins: [responsiveMixin],
  components: {
    BaseLoader,
    BorderedButton,
    NoBgButton,
    WOtpInput,
    PopupCloseButton,
  },
  inject: ["showErrorTip", "showSuccessTip"],
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      cvvOtpCode: "",
      cvv: null,
      isCvvOtpSent: false,
      verificationId: null,
      isDataLoading: false,
    };
  },
  computed: {
    formattedPan: function () {
      return (this.card.pan.match(/.{1,4}/g) || []).join(" ");
    },
  },
  methods: {
    closePopup() {
      this.$emit("closed");
    },
    copyCardPan() {
      if (!navigator.clipboard) {
        return;
      }

      navigator.clipboard.writeText(this.card.pan).then(
        () => {
          this.showSuccessTip(this.$t("card.item.pan-copied"), 3000);
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    async sendCvvOtp() {
      try {
        this.isDataLoading = true;
        let sendCvvResponse = await CardApi.sendCvvOtp(this.card.card_uuid);
        this.verificationId = sendCvvResponse.data.verification_id;
        this.isCvvOtpSent = true;
      } catch (e) {
        console.error(e);
      }

      this.isDataLoading = false;
    },
    async getCvv() {
      try {
        if (!this.cvvOtpCode || this.cvvOtpCode.length < 6) return false;

        this.isDataLoading = true;
        let getCvvResponse = await CardApi.getCvv(
          this.verificationId,
          this.cvvOtpCode
        );
        this.cvv = getCvvResponse.data.cvv;
        this.isCvvOtpSent = false;
      } catch (e) {
        console.error(e);
      }

      this.isDataLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  &__block {
    &--card-data {
      width: 100%;
      max-width: 470px;
      &_desktop {
        max-width: 510px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-right: 30px;
  }
}
.card-data-popup {
  position: relative;
}

.card-data {
  margin-top: 36px;

  &__items {
    position: relative;
    background-color: $grayLight;
    padding: 24px;
    border-radius: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &--iban & {
      &-title {
        margin-right: 10px;
      }
    }

    &-title {
      margin-right: 44px;
      font-size: pxToRem(16);
      line-height: pxToRem(24);
      color: $gray1;
    }

    &-value {
      font-weight: 700;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      text-align: right;
      color: $mainBlack;
      user-select: all;
      overflow-wrap: anywhere;
    }

    &-cvv {
      &-get {
        color: $mainCold;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      &-otp {
        &-verify {
          margin-top: 24px;
        }
      }
    }
  }

  &__actions {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__action {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.card-data {
  &__item {
    &-otp {
      margin-top: 24px !important;

      .otp-input__code {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .otp-input__code-label {
        margin-right: 0;
        width: 100%;
        margin-bottom: 24px;
      }

      .otp-input__code-el {
        margin-right: auto;
      }
    }
  }
}
</style>
