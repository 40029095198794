<template>
  <div class="popup">
    <div class="popup__block">
      <div class="p-details">
        <div class="p-details__full">
          <div class="p-details__section">
            <div class="p-details__section-header">
              <div class="popup__title">
                {{ $t("google-authenticator.google-authenticator") }}
              </div>
              <popup-close-button @click="closePopup"></popup-close-button>
            </div>
            <div class="p-details__section-body">
              <div class="google-auth__qr">
                <a :href="url" class="google-auth__qr-image">
                  <canvas ref="qrCanvas"></canvas>
                </a>
              </div>
              <div class="p-details__field">
                <text-input
                  :title="$t('google-authenticator.otp-code')"
                  v-model="code"
                  :v-errors="v$.code.$errors"
                ></text-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup__actions">
        <no-bg-button
          class="popup__action"
          :label="$t('google-authenticator.cancel')"
          @click="closePopup"
        ></no-bg-button>
        <primary-button
          class="popup__action"
          :label="$t('google-authenticator.verify-integration')"
          @click="verifyGoogleAuthCode"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import NoBgButton from "@/components/button/NoBgButton";
import PrimaryButton from "@/components/button/PrimaryButton";
import PopupCloseButton from "@/components/button/PopupCloseButton";
import TextInput from "@/components/input/TextInput";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { UserApi } from "@/office/user-api";
import QRCode from "qrcode";
export default {
  name: "GoogleAuthConfigPopup",
  components: {
    PopupCloseButton,
    PrimaryButton,
    NoBgButton,
    TextInput,
  },
  props: {
    user: Object,
  },
  async created() {
    this.url = await UserApi.getGoogleAuthUrl();
    this.drawQR(this.url);
  },
  data() {
    return {
      code: "",
      url: "",
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      code: { required },
    };
  },
  methods: {
    closePopup() {
      this.$emit("closed");
    },
    verifyGoogleAuthCode() {
      this.$emit("success", this.code);
    },
    drawQR(url) {
      QRCode.toCanvas(this.$refs.qrCanvas, url, {
        errorCorrectionLevel: "H",
        width: 278,
        height: 278,
        color: {
          dark: "#66798F",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.google-auth {
  &__qr {
    max-width: 576px;
    width: 100%;
    padding: 32px 52px;
    background-color: #ffffff;
    box-shadow: 0px 0px 62px rgba(183, 201, 211, 0.5);
    border-radius: 24px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    &-image {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      width: 278px;
      height: 278px;

      canvas {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(51, 63, 72, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  &__close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s linear;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .p-details__full {
    padding-bottom: 20px;
    padding-left: 0;
    border-left: 0;

    .p-details__field {
      margin-top: 60px;
    }
  }

  &__actions {
    margin-top: 84px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
