export const mccTransactionCodes = [
  {
    mcc_code: 5309,
    uk: "Duty Free",
    en: "Duty Free",
  },
  {
    mcc_code: 3002,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3003,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3004,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3014,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3019,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3021,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3023,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3024,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3027,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3030,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3031,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3036,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3038,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3040,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3041,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3044,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3045,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3046,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3049,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3053,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3054,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3055,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3059,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3060,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3061,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3062,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3065,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3067,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3068,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3069,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3071,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3072,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3073,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3078,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3081,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3083,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3084,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3085,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3086,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3087,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3090,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3092,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3093,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3094,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3095,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3096,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3097,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3098,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3101,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3104,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3105,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3106,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3107,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3109,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3110,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3111,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3112,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3113,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3114,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3115,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3116,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3117,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3118,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3119,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3120,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3121,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3122,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3123,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3124,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3125,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3126,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3128,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3129,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3130,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3131,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3133,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3134,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3135,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3137,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3138,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3139,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3140,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3141,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3142,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3143,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3145,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3146,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3147,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3148,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3150,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3151,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3152,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3153,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3154,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3155,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3156,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3157,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3158,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3159,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3160,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3161,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3162,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3163,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3164,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3165,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3166,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3167,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3168,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3169,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3170,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3171,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3172,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3173,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3175,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3176,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3177,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3178,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3179,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3185,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3186,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3187,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3188,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3189,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3190,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3191,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3192,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3193,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3194,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3195,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3197,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3198,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3199,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3200,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3201,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3202,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3203,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3204,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3205,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3207,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3208,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3209,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3210,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3211,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3212,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3213,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3214,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3215,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3216,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3218,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3219,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3220,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3221,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3222,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3223,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3224,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3225,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3226,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3227,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3228,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3229,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3230,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3231,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3232,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3233,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3234,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3235,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3237,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3238,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3239,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3240,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3241,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3242,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3243,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3244,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3249,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3250,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3251,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3252,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3253,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3254,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3257,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3258,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3259,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3262,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3263,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3264,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3265,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3267,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3268,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3270,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3274,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3275,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3276,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3277,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3278,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3279,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3280,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3281,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3282,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3283,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3284,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3285,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3286,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3287,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3288,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3289,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3290,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3291,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3292,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3293,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3294,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3296,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3298,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3301,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3000,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3001,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3005,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3006,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3007,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3008,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3009,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3010,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3011,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3012,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3013,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3015,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3016,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3017,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3018,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3020,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3022,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3025,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3026,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3028,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3029,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3032,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3033,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3034,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3035,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3037,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3039,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3042,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3043,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3047,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3048,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3050,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3051,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3052,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3056,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3057,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3058,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3063,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3064,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3066,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3075,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3076,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3077,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3079,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3082,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3088,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3089,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3099,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3100,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3102,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3103,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3127,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3132,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3136,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3144,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3174,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3180,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3181,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3182,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3183,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3184,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3196,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3206,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3217,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3236,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3245,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3246,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3247,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3248,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3256,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3260,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3261,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3266,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3295,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3297,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 3299,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 4511,
    uk: "Авіаквитки",
    en: "Airline tickets",
  },
  {
    mcc_code: 5511,
    uk: "Автосервіс",
    en: "Autoservice",
  },
  {
    mcc_code: 5531,
    uk: "Автосервіс",
    en: "Autoservice",
  },
  {
    mcc_code: 5532,
    uk: "Автосервіс",
    en: "Autoservice",
  },
  {
    mcc_code: 5533,
    uk: "Автосервіс",
    en: "Autoservice",
  },
  {
    mcc_code: 7531,
    uk: "Автосервіс",
    en: "Autoservice",
  },
  {
    mcc_code: 7534,
    uk: "Автосервіс",
    en: "Autoservice",
  },
  {
    mcc_code: 7535,
    uk: "Автосервіс",
    en: "Autoservice",
  },
  {
    mcc_code: 7538,
    uk: "Автосервіс",
    en: "Autoservice",
  },
  {
    mcc_code: 7542,
    uk: "Автосервіс",
    en: "Autoservice",
  },
  {
    mcc_code: 5172,
    uk: "АЗС",
    en: "Gas station",
  },
  {
    mcc_code: 5541,
    uk: "АЗС",
    en: "Gas station",
  },
  {
    mcc_code: 5542,
    uk: "АЗС",
    en: "Gas station",
  },
  {
    mcc_code: 5983,
    uk: "АЗС",
    en: "Gas station",
  },
  {
    mcc_code: 5122,
    uk: "Аптеки",
    en: "Pharmacies",
  },
  {
    mcc_code: 5292,
    uk: "Аптеки",
    en: "Pharmacies",
  },
  {
    mcc_code: 5295,
    uk: "Аптеки",
    en: "Pharmacies",
  },
  {
    mcc_code: 5912,
    uk: "Аптеки",
    en: "Pharmacies",
  },
  {
    mcc_code: 5139,
    uk: "Взуття",
    en: "Shoes",
  },
  {
    mcc_code: 5661,
    uk: "Взуття",
    en: "Shoes",
  },
  {
    mcc_code: 5921,
    uk: "Виномаркети",
  },
  {
    mcc_code: 5131,
    uk: "Все для дому",
    en: "Everything for home",
  },
  {
    mcc_code: 5200,
    uk: "Все для дому",
    en: "Everything for home",
  },
  {
    mcc_code: 5211,
    uk: "Все для дому",
    en: "Everything for home",
  },
  {
    mcc_code: 5231,
    uk: "Все для дому",
    en: "Everything for home",
  },
  {
    mcc_code: 5712,
    uk: "Все для дому",
    en: "Everything for home",
  },
  {
    mcc_code: 5713,
    uk: "Все для дому",
    en: "Everything for home",
  },
  {
    mcc_code: 5719,
    uk: "Все для дому",
    en: "Everything for home",
  },
  {
    mcc_code: 3507,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3511,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3514,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3517,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3521,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3522,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3523,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3524,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3525,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3526,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3527,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3529,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3531,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3532,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3534,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3537,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3538,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3539,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3540,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3545,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3546,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3547,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3549,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3550,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3551,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3554,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3556,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3557,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3558,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3559,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3560,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3561,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3563,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3564,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3565,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3566,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3567,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3568,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3570,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3571,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3572,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3573,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3574,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3575,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3576,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3578,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3580,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3582,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3587,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3588,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3589,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3593,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3594,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3595,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3597,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3598,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3599,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3600,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3601,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3602,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3603,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3604,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3605,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3606,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3607,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3608,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3609,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3610,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3611,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3613,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3614,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3616,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3617,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3618,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3619,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3620,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3621,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3622,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3624,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3626,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3627,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3630,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3631,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3632,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3633,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3636,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3639,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3645,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3646,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3647,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3648,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3656,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3658,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3660,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3663,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3664,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3666,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3669,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3670,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3671,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3673,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3674,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3675,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3676,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3677,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3679,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3680,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3681,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3682,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3683,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3685,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3686,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3688,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3689,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3691,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3694,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3696,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3697,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3699,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3701,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3702,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3704,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3705,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3706,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3707,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3708,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3711,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3712,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3713,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3714,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3717,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3718,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3719,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3720,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3724,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3725,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3726,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3727,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3728,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3729,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3731,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3732,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3733,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3734,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3735,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3736,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3738,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3739,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3742,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3743,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3745,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3746,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3747,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3748,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3749,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3751,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3752,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3753,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3755,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3756,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3758,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3759,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3760,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3761,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3762,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3763,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3764,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3766,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3767,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3768,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3772,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3774,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3775,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3776,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3780,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3781,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3782,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3783,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3784,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3785,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3786,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3787,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3788,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3789,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3791,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3792,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3794,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3796,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3797,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3798,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3799,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3816,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3828,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3830,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3831,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3501,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3502,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3503,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3504,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3505,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3506,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3508,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3509,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3510,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3512,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3513,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3515,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3516,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3518,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3519,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3520,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3528,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3530,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3533,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3535,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3536,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3541,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3542,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3543,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3544,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3548,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3552,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3553,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3555,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3562,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3569,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3577,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3579,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3581,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3583,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3584,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3585,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3586,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3590,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3591,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3592,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3596,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3612,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3615,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3623,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3625,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3628,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3629,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3634,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3635,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3637,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3638,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3640,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3641,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3642,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3643,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3644,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3649,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3650,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3651,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3652,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3653,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3654,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3655,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3657,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3659,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3661,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3662,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3665,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3667,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3668,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3672,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3678,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3684,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3687,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3690,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3692,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3693,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3695,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3698,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3700,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3703,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3709,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3710,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3715,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3716,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3721,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3722,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3723,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3730,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3737,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3740,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3741,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3744,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3750,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3754,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3757,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3765,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3769,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3770,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3771,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3773,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3777,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3778,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3779,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3790,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3793,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 3795,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 7011,
    uk: "Готелі",
    en: "Hotels",
  },
  {
    mcc_code: 5641,
    uk: "Дитячі товари",
    en: "Baby products",
  },
  {
    mcc_code: 5945,
    uk: "Дитячі товари",
    en: "Baby products",
  },
  {
    mcc_code: "0742",
    uk: "Зоомагазини",
    en: "Pet Stores",
  },
  {
    mcc_code: 5995,
    uk: "Зоомагазини",
    en: "Pet Stores",
  },
  {
    mcc_code: 5816,
    uk: "Ігри та додатки",
  },
  {
    mcc_code: 5815,
    uk: "Ігри та додатки",
  },
  {
    mcc_code: 5817,
    uk: "Ігри та додатки",
  },
  {
    mcc_code: 5811,
    uk: "Кафе та ресторани",
  },
  {
    mcc_code: 5812,
    uk: "Кафе та ресторани",
  },
  {
    mcc_code: 5813,
    uk: "Кафе та ресторани",
  },
  {
    mcc_code: 5814,
    uk: "Кафе та ресторани",
  },
  {
    mcc_code: 5992,
    uk: "Квіти",
    en: "Flowers",
  },
  {
    mcc_code: 5193,
    uk: "Квіти",
    en: "Flowers",
  },
  {
    mcc_code: 5261,
    uk: "Квіти",
    en: "Flowers",
  },
  {
    mcc_code: "0780",
    uk: "Квіти",
    en: "Flowers",
  },
  {
    mcc_code: 7829,
    uk: "Кіно та театри",
    en: "Cinema and theaters",
  },
  {
    mcc_code: 7832,
    uk: "Кіно та театри",
    en: "Cinema and theaters",
  },
  {
    mcc_code: 7922,
    uk: "Кіно та театри",
    en: "Cinema and theaters",
  },
  {
    mcc_code: 5192,
    uk: "Книги",
    en: "Books",
  },
  {
    mcc_code: 5942,
    uk: "Книги",
    en: "Books",
  },
  {
    mcc_code: 5943,
    uk: "Книги",
    en: "Books",
  },
  {
    mcc_code: 5949,
    uk: "Книги",
    en: "Books",
  },
  {
    mcc_code: 5970,
    uk: "Книги",
    en: "Books",
  },
  {
    mcc_code: 5441,
    uk: "Кондитерські",
    en: "Bakery",
  },
  {
    mcc_code: 5462,
    uk: "Кондитерські",
    en: "Bakery",
  },
  {
    mcc_code: 7230,
    uk: "Краса та догляд",
    en: "Beauty and care",
  },
  {
    mcc_code: 7297,
    uk: "Краса та догляд",
    en: "Beauty and care",
  },
  {
    mcc_code: 7298,
    uk: "Краса та догляд",
    en: "Beauty and care",
  },
  {
    mcc_code: 5331,
    uk: "Краса та догляд",
    en: "Beauty and care",
  },
  {
    mcc_code: 5977,
    uk: "Краса та догляд",
    en: "Beauty and care",
  },
  {
    mcc_code: 5300,
    uk: "Маркетплейси",
  },
  {
    mcc_code: 5964,
    uk: "Маркетплейси",
  },
  {
    mcc_code: 5310,
    uk: "Маркетплейси",
  },
  {
    mcc_code: 5311,
    uk: "Маркетплейси",
  },
  {
    mcc_code: 5399,
    uk: "Маркетплейси",
  },
  {
    mcc_code: 5999,
    uk: "Маркетплейси",
  },
  {
    mcc_code: 4119,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8011,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8021,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8031,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8041,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8042,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8043,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8049,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8050,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8062,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8071,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 8099,
    uk: "Медзаклади",
    en: "Health facilities",
  },
  {
    mcc_code: 5137,
    uk: "Одяг",
    en: "Clothes",
  },
  {
    mcc_code: 5611,
    uk: "Одяг",
    en: "Clothes",
  },
  {
    mcc_code: 5621,
    uk: "Одяг",
    en: "Clothes",
  },
  {
    mcc_code: 5651,
    uk: "Одяг",
    en: "Clothes",
  },
  {
    mcc_code: 5681,
    uk: "Одяг",
    en: "Clothes",
  },
  {
    mcc_code: 5691,
    uk: "Одяг",
    en: "Clothes",
  },
  {
    mcc_code: 5699,
    uk: "Одяг",
    en: "Clothes",
  },
  {
    mcc_code: 5931,
    uk: "Одяг",
    en: "Clothes",
  },
  {
    mcc_code: 5411,
    uk: "Продукти",
    en: "Foods",
  },
  {
    mcc_code: 5412,
    uk: "Продукти",
    en: "Foods",
  },
  {
    mcc_code: 5422,
    uk: "Продукти",
    en: "Foods",
  },
  {
    mcc_code: 5451,
    uk: "Продукти",
    en: "Foods",
  },
  {
    mcc_code: 5499,
    uk: "Продукти",
    en: "Foods",
  },
  {
    mcc_code: 7932,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 7933,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 7996,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 7998,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 7999,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 5733,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 5818,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 7841,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 7993,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 7221,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 7395,
    uk: "Розваги",
    en: "Entertainment",
  },
  {
    mcc_code: 5655,
    uk: "Спорт та фітнес",
    en: "Sports and fitness",
  },
  {
    mcc_code: 5940,
    uk: "Спорт та фітнес",
    en: "Sports and fitness",
  },
  {
    mcc_code: 5941,
    uk: "Спорт та фітнес",
    en: "Sports and fitness",
  },
  {
    mcc_code: 7911,
    uk: "Спорт та фітнес",
    en: "Sports and fitness",
  },
  {
    mcc_code: 7941,
    uk: "Спорт та фітнес",
    en: "Sports and fitness",
  },
  {
    mcc_code: 7992,
    uk: "Спорт та фітнес",
    en: "Sports and fitness",
  },
  {
    mcc_code: 7997,
    uk: "Спорт та фітнес",
    en: "Sports and fitness",
  },
  {
    mcc_code: 4121,
    uk: "Таксі",
    en: "Taxi",
  },
  {
    mcc_code: 4812,
    uk: "Техніка",
    en: "Home appliances",
  },
  {
    mcc_code: 5722,
    uk: "Техніка",
    en: "Home appliances",
  },
  {
    mcc_code: 5732,
    uk: "Техніка",
    en: "Home appliances",
  },
  {
    mcc_code: 5946,
    uk: "Техніка",
    en: "Home appliances",
  },
  {
    mcc_code: 4131,
    uk: "Транспорт",
    en: "Transport",
  },
  {
    mcc_code: 4111,
    uk: "Транспорт",
    en: "Transport",
  },
  {
    mcc_code: 4112,
    uk: "Транспорт",
    en: "Transport",
  },
  {
    mcc_code: 7216,
    uk: "Хімчистка",
    en: "Dry cleaning",
  },
  {
    mcc_code: 7210,
    uk: "Хімчистка",
    en: "Dry cleaning",
  },
  {
    mcc_code: 7211,
    uk: "Хімчистка",
    en: "Dry cleaning",
  },
  {
    mcc_code: 7251,
    uk: "Хімчистка",
    en: "Dry cleaning",
  },
  {
    mcc_code: 7217,
    uk: "Хімчистка",
    en: "Dry cleaning",
  },
];
