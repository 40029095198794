<template>
  <div class="recommendations">
    <div class="recommendations-steps connect-exchange-list">
      <div class="connect-exchange-item recommendation-step device-choosing">
        <span class="connect-exchange-item__title">
          {{ $t("connect_exchange.recommendations.device_detection.text") }}
        </span>
        <device-type-select
          class="device-choosing__value"
          v-model="selectedDeviceType"
          :types-list="deviceTypes"
        />
      </div>
      <div
        class="connect-exchange-item recommendation-step recommendation-hint"
        :class="recommendationHintClassNames"
      >
        <div
          class="connect-exchange-item__text"
          v-html="recommendationsHintText"
        />
        <div class="recommendation-hint__tooltip">
          <v-popover placement="top" offset="3" class="hint-tooltip">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1379 9.00051C12.6624 9.00051 13.1034 8.5497 13.1034 8.01123C13.1034 7.44772 12.6505 7.03448 12.1379 7.03448C11.6254 7.03448 11.1724 7.44772 11.1724 8.01123C11.1724 8.56222 11.6135 9.00051 12.1379 9.00051Z"
                fill="currentColor"
              />
              <path
                d="M11.2916 15.8503C11.2916 16.1884 11.5062 16.4138 11.828 16.4138H12.4598C12.7816 16.4138 12.9962 16.1884 12.9962 15.8503V10.4281C12.9962 10.09 12.7816 9.86455 12.4598 9.86455H11.828C11.5062 9.86455 11.2916 10.09 11.2916 10.4281V15.8503Z"
                fill="currentColor"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM18.8966 12C18.8966 15.8089 15.8089 18.8966 12 18.8966C8.19114 18.8966 5.10345 15.8089 5.10345 12C5.10345 8.19114 8.19114 5.10345 12 5.10345C15.8089 5.10345 18.8966 8.19114 18.8966 12Z"
                fill="currentColor"
              />
            </svg>

            <template v-slot:popover>
              <span
                class="connect-exchange-item__text connect-exchange-item__text--popover"
              >
                {{ recommendationsHintTooltip }}
              </span>
            </template>
          </v-popover>
        </div>
      </div>
      <div
        v-if="isAuthSectionVisible"
        class="connect-exchange-item authentication"
      >
        <div class="connect-exchange-item__title authentication__title">
          {{
            this.$t("connect_exchange.recommendations.2fa.title", {
              exchange: selectedExchangeSlugUppercase,
            })
          }}
        </div>
        <div
          class="connect-exchange-item__text"
          v-html="
            $t(
              `connect_exchange.recommendations.2fa.${this.selectedExchangeSlug}.text`
            )
          "
        />
        <div
          v-if="isStepsSectionExist"
          class="authentication-steps"
          :class="{ 'authentication-steps--open': isStepsVisible }"
        >
          <button
            @click="toggleStepsVisibility"
            type="button"
            class="authentication-steps__button"
          >
            {{ $t("how_to_do") }}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.2929 15.7071C17.6834 16.0976 18.3166 16.0976 18.7071 15.7071C19.0976 15.3166 19.0976 14.6834 18.7071 14.2929L17.2929 15.7071ZM12 9L12.7071 8.29289L12 7.58579L11.2929 8.29289L12 9ZM5.29289 14.2929C4.90237 14.6834 4.90237 15.3166 5.29289 15.7071C5.68342 16.0976 6.31658 16.0976 6.70711 15.7071L5.29289 14.2929ZM18.7071 14.2929L12.7071 8.29289L11.2929 9.70711L17.2929 15.7071L18.7071 14.2929ZM11.2929 8.29289L5.29289 14.2929L6.70711 15.7071L12.7071 9.70711L11.2929 8.29289Z"
                fill="#00B7BD"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        v-if="isStepsSectionExist && isStepsVisible"
        class="connect-exchange-list steps-list"
      >
        <template v-for="(step, index) of steps">
          <div
            :key="index"
            class="connect-exchange-item steps-list__item connect-exchange-item--indexed"
          >
            <div class="connect-exchange-item__index">
              {{ index + 1 }}
            </div>
            <div class="connect-exchange-item__text" v-html="step" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ResponsiveMixin from "@/mixins/responsive-mixin";
import DeviceTypeSelect from "@/components/profile/Exchanges/Popup/AddExchangeModal/DeviceTypeSelect";
import { MobileDetectService } from "@/services/mobile/mobile-detect";
import { capitalizeFirstLetter } from "@/utils";

export default {
  name: "RecommendationsStep",
  components: { DeviceTypeSelect },
  props: {
    selectedExchangeSlug: {
      type: String,
      required: true,
    },
  },
  mixins: [ResponsiveMixin],
  data() {
    return {
      isStepsVisible: false,
      selectedDeviceType: null,
      deviceTypes: ["web", "mob"],
    };
  },
  watch: {
    selectedDeviceType(value) {
      this.$emit("selectDevice", value);
    },
    isMobile: {
      immediate: true,
      handler(value) {
        this.selectedDeviceType = value ? "mob" : "web";
      },
    },
  },
  computed: {
    isAuthSectionVisible() {
      return !this.isExmo;
    },
    selectedExchangeSlugUppercase() {
      if (!this.selectedExchangeSlug) return "";
      return capitalizeFirstLetter(this.selectedExchangeSlug);
    },
    isWhitebit() {
      return this.selectedExchangeSlug === "whitebit";
    },
    isHuobi() {
      return this.selectedExchangeSlug === "huobi";
    },
    isExmo() {
      return this.selectedExchangeSlug === "exmo";
    },
    isMobileSelected() {
      return this.selectedDeviceType === "mob";
    },
    recommendationsHintText() {
      return this.$t(
        `connect_exchange.recommendations.${this.selectedExchangeSlug}.hint.${this.selectedDeviceType}.text`
      );
    },
    recommendationsHintTooltip() {
      return this.$t(
        `connect_exchange.recommendations.${this.selectedExchangeSlug}.hint.${this.selectedDeviceType}.tooltip`
      );
    },
    recommendationHintClassNames() {
      return {
        "recommendation-hint--warning":
          this.isMobileSelected && (this.isWhitebit || this.isExmo),
      };
    },
    isStepsSectionExist() {
      return this.isMobileSelected && this.isHuobi;
    },
    steps() {
      return this.$t(
        `connect_exchange.recommendations.2fa.${this.selectedExchangeSlug}.steps`
      );
    },
  },
  methods: {
    toggleStepsVisibility() {
      this.isStepsVisible = !this.isStepsVisible;
    },
  },
  mounted() {
    this.selectedDeviceType = MobileDetectService.isPhone() ? "mob" : "web";
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.recommendation-step {
  &--no-bg {
    background-color: transparent;
  }

  &.device-choosing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 486px) {
      display: grid;
      grid-template-columns: 1fr 0.9fr;
      column-gap: 0.625rem;
      overflow: hidden;
      padding: 1rem 1rem 1rem 1.5rem;
      .connect-exchange-item__title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.recommendation-hint {
  padding: 1.25rem 1.25rem 1.25rem 1.5rem;
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.25rem;
  background-color: $mainColdLight;

  &--warning {
    background-color: $mainHotLight;
  }

  &__tooltip {
    color: $gray1;
  }

  &__text {
    &::v-deep {
      color: $gray1;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      font-family: "Nunito", sans-serif;

      b {
        font-weight: 700;
        font-family: "Nunito", sans-serif;
      }
    }
  }
}

.authentication {
  padding: 2rem 1.5rem;

  &__title {
    margin-bottom: 1rem;
  }
}

.authentication-steps {
  margin-top: 1.5rem;

  &--open {
    .authentication-steps__button svg {
      transform: rotate(0deg);
    }
  }

  &__button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: $mainCold;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;

    display: grid;
    grid-auto-flow: column;
    column-gap: 0.5rem;

    svg {
      transform: rotate(180deg);
      max-width: 100%;
    }
  }
}

.steps-list {
  padding-bottom: 2.5rem;
}
</style>
