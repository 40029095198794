<template>
  <div class="button-primary" :class="{ disabled }" @click="processClick">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    processClick() {
      if (this.disabled) {
        return;
      }

      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.button-primary {
  width: 100%;
  padding: 12px 36px;
  background-color: $mainCold;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  transition: opacity 0.2s linear;

  &:hover {
    opacity: 0.6;
  }

  &.disabled {
    background-color: $grayLight;
    color: $gray2;
    font-weight: 600;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
