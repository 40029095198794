<template>
  <div class="link-field" :class="{ 'link-field--disabled': disabled }">
    <span class="link-field__text">{{ link }}</span>
    <svg
      class="link-field__icon"
      v-if="disabled"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99999 16.9521C10.5021 16.9521 10.9091 16.5525 10.9091 16.0594C10.9091 15.5664 10.5021 15.1667 9.99999 15.1667C9.49792 15.1667 9.0909 15.5664 9.0909 16.0594C9.0909 16.5525 9.49792 16.9521 9.99999 16.9521Z"
        fill="#EB6FBD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 5.38889V9.0124C2.1681 9.43996 0 11.8335 0 14.7222C0 17.9132 2.64559 20.5 5.90909 20.5H14.0909C17.3544 20.5 20 17.9132 20 14.7222C20 11.8335 17.8319 9.43996 15 9.0124V5.38889C15 2.68883 12.7614 0.5 10 0.5C7.23858 0.5 5 2.68883 5 5.38889ZM13.1818 8.58081V5.38889C13.1818 3.67067 11.7573 2.27778 10 2.27778C8.24273 2.27778 6.81818 3.67067 6.81818 5.38889V8.58081C6.81818 8.78164 6.984 8.94444 7.18855 8.94444H12.8114C13.016 8.94444 13.1818 8.78164 13.1818 8.58081ZM1.81818 14.7222C1.81818 12.5131 3.64974 10.7222 5.90909 10.7222H14.0909C16.3503 10.7222 18.1818 12.5131 18.1818 14.7222C18.1818 16.9314 16.3503 18.7222 14.0909 18.7222H5.90909C3.64974 18.7222 1.81818 16.9314 1.81818 14.7222Z"
        fill="#EB6FBD"
      />
    </svg>

    <template v-else>
      <v-popover
        placement="top"
        trigger="hover"
        offset="3"
        class="link-field-popover"
        :disabled="!tooltipContent"
      >
        <svg
          @click="copyLink"
          class="link-field__icon link-field__icon--active"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_9763_82676)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.36846 17C6.0604 17 5 15.6386 5 14.3595V4.06603C5 2.78692 6.0604 1.75 7.36846 1.75H15.1318C16.4399 1.75 17.6318 2.78692 17.6318 4.06603V14.3595C17.6318 15.6386 16.5714 17 15.2633 17H11.25H7.36846ZM6.38184 14.3595V4.45569C6.38184 3.74507 6.82701 3.19531 7.55371 3.19531H11.3818H14.9756C15.7023 3.19531 16.2451 3.74507 16.2451 4.45569V9.25V14.3595C16.2451 15.0701 15.7023 15.6471 14.9756 15.6471H7.55371C6.82701 15.6471 6.38184 15.0701 6.38184 14.3595Z"
              fill="#00B7BD"
            />
            <path
              d="M3.92575 16.5386C3.92575 17.2968 4.58703 18.2534 5.37106 18.2534H9.99997H13.75C13.75 18.2534 14.248 18.2534 14.375 18.6284V19.2534C14.375 19.2534 14.2763 19.6935 13.75 19.6935H9.37497H5.07809C3.66684 19.6935 2.50001 18.3331 2.50001 16.9683V5.50342C2.49938 5.50342 2.53704 4.65625 3.06336 4.65625C3.24091 4.65625 3.33413 4.65627 3.37972 4.65629C3.38348 4.65624 3.38721 4.65624 3.39093 4.65629C3.38756 4.65629 3.38383 4.65629 3.37972 4.65629C3.37814 4.65631 3.37655 4.65633 3.37497 4.65637C3.37497 4.65637 3.46078 4.65633 3.39093 4.65629C3.38721 4.65624 3.38348 4.65624 3.37972 4.65629C3.38383 4.65629 3.38756 4.65629 3.39093 4.65629C3.79088 4.66184 3.92617 5.24307 3.92575 5.50342V10.9435V16.5386Z"
              fill="#00B7BD"
            />
          </g>
          <defs>
            <clipPath id="clip0_9763_82676">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
        <template v-slot:popover>
          <span class="link-field__tooltip" id="">
            {{ tooltipContent }}
          </span>
        </template>
      </v-popover>
    </template>
  </div>
</template>

<script>
export default {
  name: "LinkField",
  props: {
    link: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipContent: {
      type: String,
    },
  },
  methods: {
    copyLink() {
      this.$emit("copyLink", this.link);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.link-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: $grayLight;
  border-radius: 0.5rem;
  padding: 0 1rem;
  &-popover,
  &-popover::v-deep .trigger {
    height: 100%;
    overflow: hidden;
  }
  &-popover {
    &::v-deep .trigger {
      display: flex !important;
      align-items: center;
    }
  }

  &__text {
    color: $gray1;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 0.3125rem;
  }

  &__icon {
    max-width: 100%;
    max-height: 100%;
    &--active {
      cursor: pointer;
    }
  }

  &__tooltip {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $gray1;
  }
}
</style>
