<template>
  <div class="video-guide" :class="{ 'video-guide--play': !isPaused }">
    <div v-show="isPaused" class="video-guide__control">
      <button @click="togglePlay" type="button">
        <svg
          width="71"
          height="71"
          viewBox="0 0 71 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.7918 5.5293C18.8976 5.5293 5.19922 19.1743 5.19922 36.0026C5.19922 52.831 18.8976 66.476 35.7918 66.476C52.686 66.476 66.3844 52.831 66.3844 36.0026C66.3844 19.1743 52.686 5.5293 35.7918 5.5293ZM45.632 36.472L30.7181 47.2805C30.6363 47.339 30.5399 47.374 30.4395 47.3815C30.3391 47.389 30.2386 47.3688 30.149 47.3231C30.0594 47.2774 29.9841 47.208 29.9315 47.1225C29.8789 47.037 29.851 46.9386 29.8508 46.8384V25.2349C29.8505 25.1344 29.8781 25.0358 29.9306 24.95C29.9831 24.8642 30.0584 24.7945 30.1482 24.7487C30.238 24.703 30.3388 24.6828 30.4394 24.6906C30.54 24.6984 30.6364 24.7338 30.7181 24.7928L45.632 35.5945C45.7024 35.6441 45.7599 35.7099 45.7996 35.7862C45.8392 35.8626 45.86 35.9473 45.86 36.0332C45.86 36.1192 45.8392 36.2039 45.7996 36.2803C45.7599 36.3566 45.7024 36.4223 45.632 36.472Z"
            fill="#00B7BD"
          />
        </svg>
      </button>
    </div>
    <template v-if="videoId">
      <youtube
        ref="youtube"
        :video-id="videoId"
        :playerVars="playerVars"
        height="290"
        @ready="playerReadyHandler"
        @paused="setPause"
      />
    </template>
  </div>
</template>

<script>
import { delay } from "@/utils";
import { getIdFromUrl } from "vue-youtube";

export default {
  name: "VideoGuide",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPaused: true,
      playerVars: {
        showinfo: 0,
        autohide: 1,
        origin: location.hostname,
      },
      autoPlayPaused: false,
    };
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
    videoId() {
      return getIdFromUrl(this.url);
    },
  },
  methods: {
    togglePlay() {
      this.isPaused = !this.isPaused;
    },
    setPause() {
      this.isPaused = true;
    },
    async preparePlayer() {
      await this.player.playVideo();
      await this.player.mute();
      await delay(500);
      await this.player.pauseVideo();
      await this.player.unMute();
    },
    async playerReadyHandler() {
      await this.preparePlayer();
    },
  },
  watch: {
    async url() {
      this.setPause();
      await this.preparePlayer();
    },
    isPaused(value) {
      !value ? this.player.playVideo() : this.player.pauseVideo();
    },
  },
};
</script>

<style scoped lang="scss">
.video-guide {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
  height: 18.125rem;

  &::v-deep iframe {
    width: 100%;
    height: 100%;
    border-radius: 1.1875rem;
  }

  &__control {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(183, 201, 211, 0.3);
    border-radius: 1.1875rem;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  iframe {
    width: 100%;
    border-radius: 1.1875rem;
    border: none;
  }
}
</style>
