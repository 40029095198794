<template>
  <div class="floating-back-button" @click="$emit('click')">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.65916 10.4825C5.78028 11.3094 5.78028 12.6906 6.65916 13.5175L15.8338 22.1505C16.345 22.6315 17.1558 22.6138 17.6447 22.1109C18.1337 21.6081 18.1157 20.8105 17.6045 20.3295L8.7524 12L17.6045 3.67053C18.1157 3.18952 18.1337 2.39193 17.6447 1.88906C17.1558 1.38619 16.345 1.36847 15.8338 1.84947L6.65916 10.4825Z"
        fill="#00B7BD"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "FloatingBackButton",
};
</script>

<style lang="scss" scoped>
.floating-back-button {
  position: fixed;
  top: 18px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
