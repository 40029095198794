<template>
  <div class="oops-card-count-modal">
    <div class="oops-card-count-modal__text-block">
      {{ $t("popup_end_referral.oops_text") }}
    </div>
    <img :src="icon" class="oops-card-count-modal__icon" />

    <div class="oops-card-count-modal__close-btn">
      <primary-colored-button @click="closeModal">
        {{ $t("referral_terms_modal.action") }}
      </primary-colored-button>
    </div>
  </div>
</template>

<script>
import CopyReferralLink from "@/mixins/copy-referral-link";
import PrimaryColoredButton from "@/components/button/PrimaryColoredButton";
import IconHeart from "@/assets/img/heart.png";

export default {
  name: "ReferralPartnerShareModal",
  components: { PrimaryColoredButton },
  mixins: [CopyReferralLink],
  props: {},
  data() {
    return {
      icon: IconHeart,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
.oops-card-count-modal {
  text-align: center;

  &__text-block {
    width: 396px;
    height: 144px;
    background: #f4f8fc;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    color: #7a939c;
    padding: 24px;
    margin-top: 27px;
    font-weight: 400;
    text-align: left;
  }

  &__icon {
    margin: 50px auto;
  }

  &__close-btn {
  }
}

@media screen and (max-width: 486px) {
}
</style>
