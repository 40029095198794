<template>
  <div class="unex-otp__wrapper">
    <div class="unex-onboarding__title">
      {{ $t("unex.onboarding.otp.step.title") }}
    </div>
    <div class="unex-onboarding__sub-title">
      {{ $t("unex.onboarding.otp.step.sub_title") }}
    </div>
    <div class="unex-otp">
      <base-loader v-if="isDataLoading"></base-loader>
      <unex-o-t-p-phone-step
        v-if="!isOtpCodeSent"
        @codeSent="handleCodeSent"
      ></unex-o-t-p-phone-step>
      <unex-o-t-p-code-step
        :phone="otpPhone"
        :operation-id="operationId"
        @otpChecked="handleOtpChecked"
        v-else
      ></unex-o-t-p-code-step>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/components/base/BaseLoader";

import responsiveMixin from "@/mixins/responsive-mixin";
import UnexOTPPhoneStep from "@/components/profile/Unex/Onboarding/Steps/OTP/UnexOTPPhoneStep";
import UnexOTPCodeStep from "@/components/profile/Unex/Onboarding/Steps/OTP/UnexOTPCodeStep";
import store from "@/store";
import {SET_SUPPORT_LINK} from "@/store/const/actions-types";

export default {
  name: "UnexOnboardingDiiaMain",
  components: { UnexOTPCodeStep, UnexOTPPhoneStep, BaseLoader },
  mixins: [responsiveMixin],
  inject: ["toNextStep"],
  data() {
    return {
      isDataLoading: false,
      isOtpCodeSent: false,
      otpPhone: null,
      operationId: null,
    };
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, 'onbord_phone_veref');
  },
  methods: {
    handleCodeSent(phone, operationId) {
      this.analytics('Number_success');
      this.otpPhone = phone;
      this.operationId = operationId;
      this.isOtpCodeSent = true;
    },
    handleOtpChecked() {
      this.analytics('Number_otp_success');
      this.toNextStep();
    },
    analytics(event) {
      console.log(event);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push ({'event': event,});
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-otp {
  position: relative;
  width: 100%;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  border-radius: 24px;
  padding: 48px;
}

@media screen and (max-width: 786px) {
  .unex-otp {
    box-shadow: unset;
    padding: 20px 0;
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-otp {
  &__hints {
    background: $grayLight;
    border-radius: 8px;
    padding: 24px;
  }

  &__hint {
    display: flex;
    align-items: flex-start;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    b {
      color: $mainBlack;
      font-weight: 700;
    }

    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $gray1;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
</style>
