class _CardsProductService {
  static get cardProductsOnboardingRoutes() {
    return {
      help: "/unex/onboarding/birth-certificates",
      "help-f": "/unex/onboarding/birth-certificates",
      "help-kharkiv": "/unex/onboarding/birth-certificates",
      "help-zaporizhya": "/unex/onboarding/birth-certificates",
      "weld-card": "/unex/onboarding/personal-documents",
      "weld-card-pkr": "/unex/onboarding/personal-documents",
      "weld-card-nbu": "/unex/onboarding/personal-documents",
    };
  }

  getCardProductOnboardingRoute(cardProductSlug) {
    return (
      _CardsProductService.cardProductsOnboardingRoutes[cardProductSlug] ||
      false
    );
  }

  getLocalStorageSlugKey() {
    return "onboardingCardProductSlug";
  }
}

export const CardsProductService = new _CardsProductService();
