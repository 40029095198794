import axiosInstance from "@/services/http/axios-instance";

class _ReferralsApi {
  // getReferrals(page = 1, limit = 4, { sort = null, filter = null } = {}) {
  //   //TODO: ADD LOAD REFERRALS TABLE METHOD
  //   return;
  // }

  getIndicators() {
    return axiosInstance.get("/referrals/indicators");
  }

  saveMetamaskAcc(metamaskAcc) {
    return axiosInstance.post("/referrals/metamask/save", {
      metamask_acc: metamaskAcc,
    });
  }

  getReferrals(page, limit) {
    return axiosInstance.post("/referrals/list", {
      page,
      limit,
      // filter,
      // sort,
      // date_from: dateFrom,
      // date_to: dateTo,
    });
  }

  getReferralProgramTasks() {
    return axiosInstance.get("/referrals/tasks");
  }

  approvePartner() {
    return axiosInstance.post("/referrals/partner/approve");
  }

  async submitWithdrawal() {
    return (await axiosInstance.post("/referrals/withdraw-bonuses")).data;
  }
}

export const ReferralsApi = new _ReferralsApi();
