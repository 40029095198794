<template>
  <div class="referral-hint referral-connect-metamask">
    <div class="referral-hint__body">
      <div class="referral-hint__title">
        {{ $t("referrals.hints.empty-list.title") }}
      </div>
      <div class="referral-hint__text">
        {{ $t("referrals.hints.empty-list.text") }}
      </div>
      <div class="referral-hint__actions">
        <primary-button
          :label="$t('referrals.hints.empty-list.action')"
          @click="copyReferralLink"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
import { mapGetters } from "vuex";
import { REFERRAL_URL } from "@/const/config";

export default {
  name: "ReferralEmptyList",
  inject: ["showSuccessTip"],
  components: {
    PrimaryButton,
  },
  computed: {
    ...mapGetters(["userData"]),
    referralURL: function () {
      return `${REFERRAL_URL}${this.userData.referral_uuid}`;
    },
  },
  methods: {
    copyReferralLink() {
      if (!navigator.clipboard) {
        return;
      }

      navigator.clipboard.writeText(this.referralURL).then(
        () => {
          this.showSuccessTip(this.$t("tip.referral_url_copied"));
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>

<style scoped></style>
