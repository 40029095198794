<template>
  <div class="referral-hint referral-connect-metamask">
    <div class="referral-hint__body">
      <div class="referral-hint__title">
        {{ $t("referrals.hints.stake-weld.title") }}
      </div>
      <div class="referral-hint__text">
        {{ $t("referrals.hints.stake-weld.text") }}
      </div>
      <div class="referral-hint__actions">
        <primary-button
          :label="$t('referrals.hints.stake-weld.action')"
          @click="toStakeWeld"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";

export default {
  name: "ReferralStakeWeld",
  components: {
    PrimaryButton,
  },
  methods: {
    toStakeWeld() {
      this.$router.push({
        path: "/token/staking",
        query: {
          start_staking: true,
        },
      });
    },
  },
};
</script>

<style scoped></style>
