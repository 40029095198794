<template>
  <div class="coming-soon">
    <div class="coming-soon__text" v-html="$t('coming_soon')"></div>
    <div class="coming-soon__image">
      <img src="~@/assets/img/coming-soon.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.coming-soon {
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__text {
    font-family: "Quicksand", sans-serif;
    font-size: pxToRem(70);
    line-height: pxToRem(74);
    display: flex;
    font-weight: 700;

    &-black {
      color: $mainBlack;
    }

    &-accent {
      color: $mainCold;
    }
  }

  &__image {
    max-width: calc(100% - 20px);
    width: 600px;
    margin-top: 60px;

    img {
      width: 100%;
    }
  }
}
</style>
