<template>
  <div class="login-form__wrapper">
    <div class="login-form forgot-password">
      <div class="login-form__title forgot-password__title">
        {{ $t("set_new_password") }}
      </div>
      <div class="login-form__field">
        <password-input
          :title="$t('new_password')"
          v-model="password"
          :v-errors="v$.password.$errors"
        ></password-input>
      </div>
      <div class="login-form__field">
        <password-input
          :title="$t('confirm_password')"
          v-model="confirmPassword"
          :v-errors="v$.confirmPassword.$errors"
          @enter="handleButton"
        ></password-input>
      </div>
      <div class="password-info">
        <div class="password-info__title">{{ $t("password_must_be") }}:</div>
        <div class="password-info__list">
          <div
            class="password-info__item"
            :class="{
              active:
                v$.checkPassword.required.$invalid === false &&
                v$.checkPassword.minLength.$invalid === false,
            }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("at_least_chars", { numChars: 8 }) }}
            </div>
          </div>
          <div
            class="password-info__item"
            :class="{
              active: v$.checkPassword.existLowerCase.$invalid === false,
            }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("contain_lower") }}
            </div>
          </div>
          <div
            class="password-info__item"
            :class="{
              active: v$.checkPassword.existUpperCase.$invalid === false,
            }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("contain_upper") }}
            </div>
          </div>
          <div
            class="password-info__item"
            :class="{ active: v$.checkPassword.existDigit.$invalid === false }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("contain_digit") }}
            </div>
          </div>
          <div
            class="password-info__item"
            :class="{ active: v$.checkPassword.existSymbol.$invalid === false }"
          >
            <div class="password-info__item-icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                  fill="#B7C9D3"
                />
              </svg>
            </div>
            <div class="password-info__item-text">
              {{ $t("contain_symbol") }}
            </div>
          </div>
        </div>
      </div>
      <div class="login-form__button">
        <primary-button
          :disabled="isContinueButtonDisabled"
          :label="$t('confirm')"
          @click="handleButton"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordInput from "@/components/input/PasswordInput";
import PrimaryButton from "@/components/button/PrimaryButton";
import { UserApi } from "@/office/user-api";

import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import {LOGOUT_USER, SET_SUPPORT_LINK} from "@/store/const/actions-types";
import store from "@/store";

const existUpperCase = (value) => /[A-Z]/.test(value);
const existLowerCase = (value) => /[a-z]/.test(value);
const existDigit = (value) => /[0-9]/.test(value);
const existSymbol = (value) => /[!@#$%^&*)(+=._-]/.test(value);

export default {
  name: "ForgotPassword",
  components: { PrimaryButton, PasswordInput },
  inject: ["showSuccessTip"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  metaInfo() {
    return {
      title: this.$t("create_new_password"),
    };
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      otpCode: "",
      checkPassword: "",
    };
  },
  validations() {
    return {
      password: {
        required,
        existDigit,
        existLowerCase,
        existSymbol,
        existUpperCase,
        minLength: minLength(8),
      },
      checkPassword: {
        required,
        existDigit,
        existLowerCase,
        existSymbol,
        existUpperCase,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAs: sameAs(this.password),
      },
    };
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, 'forgot_pass');
  },
  computed: {
    isContinueButtonDisabled() {
      return !this.password || !this.checkPassword;
    },
  },
  watch: {
    password: function () {
      this.checkPassword = this.password;
      this.v$.checkPassword.$touch();
    },
  },
  methods: {
    async handleButton() {
      const result = await this.v$.$validate();

      if (!result) {
        return;
      }

      await this.setPassword();
      await this.$store.dispatch(LOGOUT_USER);
      this.showSuccessTip(this.$t("password_changed"));
      this.$router.push("/login");
    },
    setPassword() {
      return UserApi.resetPassword(this.password, this.confirmPassword);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.forgot-password {
  &__title {
    margin-bottom: 3.75rem;
  }
  .login-form__button {
    margin-top: 3rem;
  }
}

.password-info {
  margin-top: -0.25rem;

  &__title {
    font-size: pxToRem(12);
    line-height: pxToRem(16);
    color: $mainBlack;
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-family: "Nunito", "Rubik", sans-serif;
  }

  &__item {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active & {
      &-icon {
        svg {
          path {
            fill: $mainCold;
          }
        }
      }

      &-text {
        color: $mainCold;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;

      svg,
      img {
        width: 100%;
        height: 100%;
      }

      svg {
        path {
          fill: $gray1;
        }
      }
    }

    &-text {
      font-size: pxToRem(14);
      line-height: pxToRem(20);
      color: $gray1;
      font-family: "Nunito", "Rubik", sans-serif;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.terms-agreed {
  margin-top: 40px;
  color: $gray1;
  text-align: center;

  a {
    color: $mainCold;
    text-decoration: none;
    font-weight: 700;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 486px) {
  .login-form {
    width: 100%;
    padding: 0;
    box-shadow: none;

    &-wrapper {
      width: 100%;
    }
  }
}
</style>
