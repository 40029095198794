<template>
  <div class="popup">
    <div class="popup__block">
      <div class="popup__header" v-if="isMobile">
        <div class="popup__title">{{ $t("edit_profile") }}</div>
        <popup-close-button @click="closePopup"></popup-close-button>
      </div>
      <div class="p-details">
        <input
          ref="avatar"
          type="file"
          name="avatar"
          accept="image/jpeg, image/jpg, image/png, image/webp, image/bmp"
          v-show="false"
          @change="onUploadFiles"
        />

        <div class="p-details__short">
          <div class="p-details__avatar">
            <img :src="newAvatar ? newAvatar : user.avatar" :alt="user.name" />
            <div class="p-details__avatar-edit" @click="downloadAvatar">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.3937 2.39399L13.1008 3.1011C13.6866 3.68688 13.6779 4.64528 13.0814 5.24174L6.31086 12.0123C6.17347 12.1497 6.01158 12.2601 5.8339 12.3376L3.99842 13.1377C2.94777 13.5957 1.89909 12.547 2.35709 11.4963L3.1572 9.66086C3.23465 9.48318 3.34504 9.3213 3.48243 9.1839L10.253 2.41332C10.8495 1.81686 11.8079 1.8082 12.3937 2.39399ZM4.08113 10.05C4.10695 9.99077 4.14374 9.93681 4.18954 9.89101L10.9601 3.12042C11.1589 2.9216 11.4784 2.91872 11.6737 3.11398L12.3808 3.82109C12.576 4.01635 12.5732 4.33582 12.3743 4.53464L5.60375 11.3052C5.55795 11.351 5.50399 11.3878 5.44477 11.4136L3.60928 12.2137C3.39915 12.3053 3.18942 12.0956 3.28102 11.8855L4.08113 10.05Z"
                  fill="white"
                />
              </svg>
            </div>
            <div
              v-if="isSetAvatar"
              class="p-details__avatar-edit p-details__avatar-delete"
              @click="deleteAvatar"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.33621 6.33615C5.88793 6.78442 5.88793 7.51122 6.33621 7.95949L10.3769 12.0002L6.33648 16.0406C5.8882 16.4888 5.8882 17.2156 6.33648 17.6639C6.78475 18.1122 7.51155 18.1122 7.95982 17.6639L12.0002 13.6235L16.0398 17.6631C16.488 18.1113 17.2148 18.1113 17.6631 17.6631C18.1114 17.2148 18.1114 16.488 17.6631 16.0397L13.6236 12.0002L17.6641 7.95967C18.1123 7.51139 18.1123 6.78459 17.6641 6.33632C17.2158 5.88804 16.489 5.88804 16.0407 6.33632L12.0002 10.3768L7.95956 6.33615C7.51128 5.88787 6.78448 5.88787 6.33621 6.33615Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="p-details__full">
          <div class="p-details__section">
            <div class="p-details__section-header" v-if="!isMobile">
              <div class="p-details__section-title">
                {{ $t("edit_profile") }}
              </div>
              <div class="popup__close" @click="closePopup">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.33621 6.33615C5.88793 6.78442 5.88793 7.51122 6.33621 7.95949L10.3769 12.0002L6.33648 16.0406C5.8882 16.4888 5.8882 17.2156 6.33648 17.6639C6.78475 18.1122 7.51155 18.1122 7.95982 17.6639L12.0002 13.6235L16.0398 17.6631C16.488 18.1113 17.2148 18.1113 17.6631 17.6631C18.1114 17.2148 18.1114 16.488 17.6631 16.0397L13.6236 12.0002L17.6641 7.95967C18.1123 7.51139 18.1123 6.78459 17.6641 6.33632C17.2158 5.88804 16.489 5.88804 16.0407 6.33632L12.0002 10.3768L7.95956 6.33615C7.51128 5.88787 6.78448 5.88787 6.33621 6.33615Z"
                    fill="#7A939C"
                  />
                </svg>
              </div>
            </div>
            <div class="p-details__section-body">
              <div class="p-details__field">
                <custom-select
                  v-model="user.country"
                  :values="countries"
                  :empty-placeholder="$t('country.select')"
                  :show-icon="true"
                  :show-selected-title="true"
                  :show-search="true"
                ></custom-select>
              </div>
              <!--              <div class="p-details__field">-->
              <!--                <phone-input-->
              <!--                  v-model="user.phone"-->
              <!--                  :initial-phone="user.phoneNumber"-->
              <!--                ></phone-input>-->
              <!--              </div>-->
              <!--              <div class="p-details__field">-->
              <!--                <text-input-->
              <!--                  title="Email"-->
              <!--                  v-model="user.email"-->
              <!--                  :v-errors="v$.user.email.$errors"-->
              <!--                ></text-input>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="popup__actions">
        <no-bg-button
          class="popup__action"
          :label="$t('cancel')"
          @click="closePopup"
        ></no-bg-button>
        <primary-button
          class="popup__action"
          :label="$t('save')"
          @click="saveProfile"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import NoBgButton from "@/components/button/NoBgButton";
import PrimaryButton from "@/components/button/PrimaryButton";
import { UserApi } from "@/office/user-api";

import useVuelidate from "@vuelidate/core";
import PopupCloseButton from "@/components/button/PopupCloseButton";
import responsiveMixin from "@/mixins/responsive-mixin";
import CustomSelect from "@/components/input/CustomSelect";
import { COUNTRIES } from "@/const/countries";
import { SET_USER_AVATAR } from "@/store/const/actions-types";
import DefaultAvatar from "@/assets/img/logo-circle-small.svg";

export default {
  name: "EditPersonalDataPopup",
  components: {
    CustomSelect,
    PopupCloseButton,
    PrimaryButton,
    NoBgButton,
  },
  mixins: [responsiveMixin],
  props: ["user", "newAvatar"],
  inject: ["showErrorTip"],
  data() {
    return {
      fileSizeLimit: 10 * 1024 * 1024,
      availableTypes: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/webp",
        "image/bmp",
      ],
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  computed: {
    countries: function () {
      return COUNTRIES.map((_country) => {
        return {
          id: _country.isoCode,
          title: _country.name,
          image: _country.flag,
        };
      });
    },
    isSetAvatar: function () {
      return this.newAvatar || this.user.avatar != DefaultAvatar;
    },
  },
  methods: {
    closePopup() {
      this.$emit("closed");
    },
    async saveProfile() {
      const result = await this.v$.$validate();

      if (!result) {
        return;
      }
      let updatedUser = { ...this.user };

      if (updatedUser.avatar && updatedUser.avatar !== DefaultAvatar) {
        updatedUser.old_avatar = updatedUser.avatar;
      }
      delete updatedUser.avatar;

      if (this.newAvatar) {
        updatedUser.avatar =
          this.newAvatar == DefaultAvatar ? null : this.newAvatar;
        updatedUser.old_avatar = null;
      }

      try {
        const response = await UserApi.updateProfileData(updatedUser);
        await this.$store.dispatch(SET_USER_AVATAR, response.data.avatar);
      } catch (error) {
        console.error("Something went wrong with the update profile \n", error);
      }

      if (!updatedUser.avatar) {
        updatedUser.avatar = updatedUser.old_avatar
          ? updatedUser.old_avatar
          : DefaultAvatar;
        this.$store.dispatch(SET_USER_AVATAR, updatedUser.avatar);
      }

      this.$emit("success", updatedUser);
    },
    onUploadFiles(e) {
      const avatar = e.target.files;
      if (!avatar.length) return;

      if (!this.availableTypes.includes(avatar[0].type)) {
        this.showErrorTip(this.$t("errors.available_types"));
        return;
      }

      if (avatar[0].size > this.fileSizeLimit) {
        this.showErrorTip(this.$t("errors.big_file"));
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(avatar[0]);
      reader.onload = () => {
        this.$emit("loadAvatar", reader.result);
      };
    },
    downloadAvatar() {
      this.$refs.avatar.click();
    },

    deleteAvatar() {
      this.newAvatar = DefaultAvatar;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

.popup {
  .p-details__avatar {
    position: relative;

    &-edit {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $mainCold;
      position: absolute;
      bottom: 0;
      right: 15px;

      cursor: pointer;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.8;
      }
    }

    &-delete {
      bottom: 20px;
      right: -5px;
    }
  }

  &__actions {
    margin-top: 56px;
  }

  &__action {
    margin-left: 8px;
  }

  .p-details__short {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 486px) {
  .p-details {
    margin-top: 44px;

    &__short {
      margin-bottom: 52px;
    }
  }
  .p-details__short {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
