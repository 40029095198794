import { EventsApi } from "@/office/events-api";

class _EventsService {
  static EVENT_REGISTRATION = "registration";
  static EVENT_STAKING = "staking";
  static EVENT_TRANSACTION = "transaction";

  get EVENT_REGISTRATION() {
    return _EventsService.EVENT_REGISTRATION;
  }

  get EVENT_STAKING() {
    return _EventsService.EVENT_STAKING;
  }

  get EVENT_TRANSACTION() {
    return _EventsService.EVENT_TRANSACTION;
  }

  async fireEvent(eventName) {
    return await EventsApi.fireEvent(eventName);
  }
}

export const EventsService = new _EventsService();
