import { RbacService } from "@/services/user/rbac.service";

export default {
  methods: {
    userCan(component) {
      console.log("user can", RbacService.userCan(component));
      return RbacService.userCan(component);
    },
  },
};
