<template>
  <div class="popup">
    <div class="popup__block terms-conditions">
      <div class="terms-conditions__full">
        <div id="TermsCond" class="terms-conditions__section">
          <div class="terms-conditions__section-header">
            <div class="popup__title">{{ $t("terms_and_cond") }}</div>
            <popup-close-button
              @click="closePopup"
              v-if="!shouldAgreed"
            ></popup-close-button>
          </div>
          <div
            class="terms-conditions__select-all"
            v-if="shouldAgreed || canSelectAgreement"
            @click="toggleAll(allChecked)"
          >
            <div class="terms-conditions__select-all-checkbox">
              <div class="active" v-if="allChecked"></div>
            </div>
            <div class="terms-conditions__select-all-text">
              {{ allChecked ? $t("unselect_all") : $t("select_all") }}
            </div>
          </div>
          <div class="terms-conditions__section-body">
            <base-loader v-if="isDataLoading"></base-loader>
            <ul>
              <li
                v-for="paragraph in paragraphs"
                :key="`paragraph-${paragraph.id}`"
                class="terms-conditions__paragraph"
              >
                <div class="terms-conditions__paragraph-check">
                  <checkbox-input
                    v-if="shouldAgreed || canSelectAgreement"
                    v-model="selectedParagraphs[paragraph.id]"
                    @update="refreshSelectedParagraphs"
                  ></checkbox-input>
                </div>
                <div
                  class="terms-conditions__paragraph-text"
                  v-html="paragraph.content[selectedLocale]"
                ></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="popup__actions">
        <no-bg-button
          class="popup__action"
          :label="closeLabel ? closeLabel : $t('cancel')"
          v-if="canCancel"
          @click="closePopup"
        ></no-bg-button>
        <primary-button
          v-if="canSelectAgreement || shouldAgreed"
          class="popup__action"
          :label="$t('agree_all')"
          @click="saveAgreement"
          :disabled="
            (shouldAgreed || canSelectAgreement) &&
            onlySelectedParagraphs.length === 0
          "
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import NoBgButton from "@/components/button/NoBgButton";
import PrimaryButton from "@/components/button/PrimaryButton";
import PopupCloseButton from "@/components/button/PopupCloseButton";
import { mapGetters, mapState } from "vuex";
import CheckboxInput from "@/components/input/CheckboxInput";
import { TermsGlobalApi } from "@/office/terms-global-api.js";
import BaseLoader from "@/components/base/BaseLoader";

export default {
  name: "TermsPopup",
  components: {
    BaseLoader,
    CheckboxInput,
    PopupCloseButton,
    PrimaryButton,
    NoBgButton,
  },
  props: {
    canSelectAgreement: {
      type: Boolean,
      default: false,
    },
    shouldAgreed: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Boolean,
      default: true,
    },
    closeLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      termsCondItem: {},
      paragraphs: [],
      isDataLoading: false,
      selectedParagraphs: {},
    };
  },
  created() {
    this.loadActiveTermsAndConditions();
  },
  computed: {
    ...mapState(["selectedLocale"]),
    ...mapGetters(["userData"]),
    allChecked: function () {
      if (this.paragraphs.length === 0) {
        return false;
      }

      let selectedParagraphsCount = 0;
      for (let key in this.selectedParagraphs) {
        if (this.selectedParagraphs[key]) {
          selectedParagraphsCount++;
        }
      }

      return selectedParagraphsCount === this.paragraphs.length;
    },
    onlySelectedParagraphs: function () {
      let _selectedParagraphs = [];

      for (let key in this.selectedParagraphs) {
        if (this.selectedParagraphs[key]) {
          _selectedParagraphs.push(key);
        }
      }

      return _selectedParagraphs;
    },
  },
  methods: {
    async loadActiveTermsAndConditions() {
      this.isDataLoading = true;

      this.selectedParagraphs = {};

      let termsResponse = await TermsGlobalApi.getActiveTerm();

      this.termsCondItem = termsResponse.data.term.term_cond;
      this.paragraphs = termsResponse.data.term.term_cond_paragraph;
      this.paragraphs.forEach((paragraph) => {
        this.selectedParagraphs[paragraph.id] = false;
      });

      this.isDataLoading = false;
    },
    closePopup() {
      this.$emit("closed");
    },
    toggleAll(isChecked) {
      let _selectedParagraphs = {};
      this.paragraphs.forEach((paragraph) => {
        _selectedParagraphs[paragraph.id] = !isChecked;
      });

      this.selectedParagraphs = _selectedParagraphs;
    },
    saveAgreement() {
      let _selectedParagraphs = [];

      for (let key in this.selectedParagraphs) {
        if (this.selectedParagraphs[key]) {
          _selectedParagraphs.push(parseInt(key));
        }
      }

      this.isDataLoading = true;
      this.$emit("agreed", {
        termId: this.termsCondItem.id,
        paragraphs: _selectedParagraphs,
      });
      this.isDataLoading = false;
      this.closePopup();
    },
    refreshSelectedParagraphs() {
      this.selectedParagraphs = { ...this.selectedParagraphs };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: rgba(51, 63, 72, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  &__close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s linear;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .terms-conditions__full {
  }

  &__actions {
    margin-top: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: 486px) {
      margin-top: 10px;
    }
  }
}

.terms-conditions {
  width: 100%;
  max-width: 680px;
  max-height: calc(100% - 80px);

  display: flex;
  flex-direction: column;

  &__section {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 36px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &-body {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 24px;
      background-color: $grayLight;
      border-radius: 8px;
      overflow: auto;

      ul {
        margin: 0;
        padding-left: 0px;
        list-style-type: none;

        li {
          font-size: pxToRem(16);
          line-height: pxToRem(24);
          color: $gray1;
          margin-bottom: 25px;
        }
      }
    }
  }

  &__paragraph {
    display: flex;
    align-items: flex-start;

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: pxToRem(16);
      line-height: pxToRem(24);
      align-items: center;
      color: $gray1;
      word-break: break-word;
    }

    &-check {
      margin-right: 15px;
    }
  }

  &__full {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__actions {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__select-all {
    display: flex;
    justify-content: left;
    align-items: center;

    margin-bottom: 15px;

    color: $gray1;
    font-size: pxToRem(14);
    cursor: pointer;

    &-checkbox {
      width: 18px;
      height: 18px;
      position: relative;
      border: 1px solid $gray1;
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 9px;
      transition: border-color 0.2s linear;

      .active {
        position: absolute;
        width: 14px;
        height: 14px;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: $mainCold;
        border-radius: 2px;
        transition: opacity 0.2s linear;
      }
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: pxToRem(14);
      line-height: pxToRem(18);

      color: $mainBlack;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
