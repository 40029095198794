<template>
  <div class="switcher">
    <div class="switcher__label">
      {{ switcherLabel }}
    </div>
    <label class="toggle">
      <input type="checkbox" v-model="isChecked" />
      <span class="toggle-slider round" @click="emitClick"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Switcher",
  model: {
    prop: "checked",
    event: "update",
  },
  props: {
    label: {
      type: [String, Array],
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: false,
      initialDataLoaded: false,
    };
  },
  created() {
    this.isChecked = this.checked;

    this.$nextTick(() => {
      this.initialDataLoaded = true;
    });
  },
  watch: {
    checked: function () {
      this.isChecked = this.checked;
    },
    isChecked: function () {
      if (this.initialDataLoaded) {
        this.$emit("update", this.isChecked);
      }
    },
  },
  computed: {
    switcherLabel: function () {
      if (Array.isArray(this.label)) {
        return this.isChecked ? this.label[0] : this.label[1];
      } else {
        return this.label;
      }
    },
  },
  methods: {
    emitClick: function () {
      console.log("switcher was clicked");
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

.switcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

/* The toggle-slider */
.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid $mainBlack;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1px;
  bottom: 1px;
  background-color: $mainBlack;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .toggle-slider {
  background-color: $mainCold;
  border-color: $mainCold;
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px $mainCold;
}

input:checked + .toggle-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  background-color: white;
}

/* Rounded sliders */
.toggle-slider.round {
  border-radius: 34px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}
</style>
