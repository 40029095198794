<template>
  <div
    class="button-no-bg"
    :class="{ accent: color === 'accent', dark: color === 'dark' }"
    @click="processClick"
  >
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "NoBgButton",
  props: {
    label: String,
    color: {
      type: String,
      default: "accent",
    },
  },
  methods: {
    processClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.button-no-bg {
  width: 100%;
  padding: 12px 36px;
  background-color: transparent;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: $mainCold;
  cursor: pointer;
  transition: opacity 0.2s linear;

  &:hover {
    opacity: 0.6;
  }

  &.dark {
    color: $gray2;
  }
}
</style>
