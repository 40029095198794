const cloudFlareEndpoint = "https://www.cloudflare.com/cdn-cgi/trace";
const ipV4RegExp = /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}/g;

export const getIpAddress = async () => {
  return new Promise((resolve, reject) => {
    return fetch(cloudFlareEndpoint)
      .then((data) => data.text())
      .then((response) => {
        const ip = response.match(ipV4RegExp)[0];
        if (!ip) throw new Error("Can't find ip address");
        resolve(ip);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
