<template>
  <div class="unex-questionnaire__wrapper">
    <div class="unex-onboarding__title">
      {{ $t("unex.onboarding.questionnaire.step.title") }}
    </div>
    <!--<div class="unex-onboarding__sub-title">
      {{
        $t("unex.onboarding.questionnaire.step.sub_title", {
          cardProductName: getCardProduct().name,
        })
      }}
    </div>-->
    <component :is="questionnaireComponent"></component>
  </div>
</template>

<script>
import responsiveMixin from "@/mixins/responsive-mixin";
import { mapGetters, mapState } from "vuex";
import QuestionnaireWeldCard from "@/views/Unex/Onboarding/Steps/Questionnaire/QuestionnaireWeldCard";
import QuestionnaireHelpCard from "@/views/Unex/Onboarding/Steps/Questionnaire/QuestionnaireHelpCard";

export default {
  name: "UnexOnboardingQuestionnaire",
  components: {
    QuestionnaireWeldCard,
    QuestionnaireHelpCard,
  },
  mixins: [responsiveMixin],
  inject: [
    "toNextStep",
    "errorWSMessageHandler",
    "getCardProductId",
    "getCardProductSlug",
    "getCardProduct",
  ],
  data() {
    return {};
  },
  created() {
    // this.loadDicts();
    // this.initWS();
  },
  computed: {
    ...mapState(["wss"]),
    ...mapGetters(["userData"]),
    questionnaireComponent: function () {
      if (
        this.getCardProductSlug() === "weld-card" ||
        this.getCardProductSlug() === "weld-card-pkr" ||
        this.getCardProductSlug() === "weld-card-nbu"
      ) {
        return "QuestionnaireWeldCard";
      }

      return "QuestionnaireHelpCard";
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-questionnaire {
  position: relative;
  width: 100%;
  max-width: 530px;
  background: #ffffff;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  border-radius: 24px;
  padding: 48px;
}

@media screen and (max-width: 786px) {
  .unex-questionnaire {
    box-shadow: unset;
    padding: 20px 0;
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.unex-questionnaire {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  &__hints {
    background: $grayLight;
    border-radius: 8px;
    padding: 24px;
  }

  &__hint {
    display: flex;
    align-items: flex-start;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;

    b {
      color: $mainBlack;
      font-weight: 700;
    }

    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $gray1;
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  &__code-word {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  &__radio {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__radio-groups {
    margin-bottom: 54px;
  }

  &__additional-address {
    margin-bottom: 80px;

    &-line {
      display: flex;
      align-items: flex-end;
      margin-bottom: 54px;

      &:last-child {
        margin-bottom: 0;
      }

      &-small {
        width: 136px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 16px;
      }

      &-big {
        flex-grow: 1;
        flex-shrink: 1;
      }

      &-half {
        width: calc(50% - 8px);

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  &__job {
    &-info {
      margin-bottom: 84px;
    }

    &-field {
      margin-bottom: 67px;

      &:last-child {
        margin-bottom: 0;
      }

      &-line {
        margin-bottom: 67px;
        display: flex;
        align-items: flex-end;

        &-small {
          width: 112px;
          margin-left: 16px;
          flex-shrink: 0;
          flex-grow: 0;
        }

        &-big {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
    }
  }

  &__agreement-field {
    margin-bottom: 25px;
  }
}
</style>
