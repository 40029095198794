<template>
  <div class="popup">
    <div class="popup__block popup__block--card-data">
      <div class="popup__header">
        <div class="popup__title">
          {{ $t("card.transaction.popup.details-title") }}
        </div>
        <popup-close-button @click="closePopup"></popup-close-button>
      </div>
      <div class="transaction-data">
        <div class="transaction-data__items">
          <base-loader v-if="isDataLoading"></base-loader>
          <div class="transaction-data__item">
            <div class="transaction__icon">
              <svg
                width="65"
                height="64"
                viewBox="0 0 65 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 32C0.5 14.3269 14.8269 0 32.5 0C50.1731 0 64.5 14.3269 64.5 32C64.5 49.6731 50.1731 64 32.5 64C14.8269 64 0.5 49.6731 0.5 32Z"
                  fill="#EB6FBD"
                />
                <circle
                  cx="32.5"
                  cy="32"
                  r="31.5"
                  fill="#EB6FBD"
                  stroke="#EB6FBD"
                />
                <path
                  d="M19.5846 17.9141C16.598 17.9141 14.168 20.3441 14.168 23.3307V37.4973C14.168 40.484 16.598 42.914 19.5846 42.914H27.8756L29.8033 40.3792L19.5846 40.414C17.9763 40.414 16.668 39.1057 16.668 37.4973V27.914H48.3345V33.8723L50.8345 37.914V23.3307C50.8345 20.3441 48.4045 17.9141 45.4179 17.9141H19.5846ZM19.5846 20.4141H45.4179C47.0262 20.4141 48.3345 21.7224 48.3345 23.3307V24.5807H16.668V23.3307C16.668 21.7224 17.9763 20.4141 19.5846 20.4141ZM21.2513 34.5807C21.0856 34.5783 20.9212 34.6089 20.7675 34.6707C20.6138 34.7325 20.4739 34.8242 20.3559 34.9405C20.2379 35.0568 20.1443 35.1954 20.0803 35.3482C20.0164 35.501 19.9835 35.665 19.9835 35.8307C19.9835 35.9963 20.0164 36.1603 20.0803 36.3132C20.1443 36.466 20.2379 36.6046 20.3559 36.7209C20.4739 36.8372 20.6138 36.9289 20.7675 36.9906C20.9212 37.0524 21.0856 37.083 21.2513 37.0807H26.2513C26.4169 37.083 26.5814 37.0524 26.7351 36.9906C26.8888 36.9289 27.0287 36.8372 27.1466 36.7209C27.2646 36.6046 27.3583 36.466 27.4222 36.3132C27.4861 36.1603 27.5191 35.9963 27.5191 35.8307C27.5191 35.665 27.4861 35.501 27.4222 35.3482C27.3583 35.1954 27.2646 35.0568 27.1466 34.9405C27.0287 34.8242 26.8888 34.7325 26.7351 34.6707C26.5814 34.6089 26.4169 34.5783 26.2513 34.5807H21.2513Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M40.8281 31.2461C39.9498 31.2461 39.1363 31.707 38.6846 32.4603L31.1846 44.9602C30.7213 45.7327 30.7087 46.6948 31.1521 47.4781C31.5962 48.2615 32.4273 48.746 33.3282 48.746H48.3281C49.2289 48.746 50.06 48.2615 50.5042 47.4781C50.9475 46.6948 50.935 45.7319 50.4717 44.9602L42.9717 32.4603C42.52 31.707 41.7065 31.2461 40.8281 31.2461ZM40.8281 34.5794C41.2881 34.5794 41.6614 34.9527 41.6614 35.4127V42.0794C41.6614 42.5394 41.2881 42.9127 40.8281 42.9127C40.3681 42.9127 39.9948 42.5394 39.9948 42.0794V35.4127C39.9948 34.9527 40.3681 34.5794 40.8281 34.5794ZM40.8281 44.3711C41.4031 44.3711 41.8698 44.8377 41.8698 45.4127C41.8698 45.9877 41.4031 46.4544 40.8281 46.4544C40.2531 46.4544 39.7864 45.9877 39.7864 45.4127C39.7864 44.8377 40.2531 44.3711 40.8281 44.3711Z"
                  fill="white"
                />
              </svg>
            </div>
            <div class="transaction__summ">
              {{
                `${item.is_unex_direct_outcom ? "-" : ""}${
                  (item.origin_transaction_amount || 0) / 100
                }`
              }}
              {{ getCurrencySymbol(transaction.origin_transaction_currency) }}
            </div>
            <div class="transaction__summsalesman">
              {{ transaction.merchant_name }}
            </div>
            <div class="transaction__type">{{$t('card.transaction.not-many')}}</div>
            <div class="transaction__date">
              <span class="transaction__date-day">{{ transactionDate }}</span>
              <span class="transaction__date-time">{{ transactionTime }}</span>
            </div>
            <div class="transaction__unsuccessfully-wrap">
              <div class="transaction__unsuccessfully-icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5C10.7348 5 10.4804 5.10536 10.2929 5.29289C10.1054 5.48043 10 5.73478 10 6V12C10 12.2652 10.1054 12.5196 10.2929 12.7071C10.4804 12.8946 10.7348 13 11 13C11.2652 13 11.5196 12.8946 11.7071 12.7071C11.8946 12.5196 12 12.2652 12 12V6ZM12 15.5C12 15.2348 11.8946 14.9804 11.7071 14.7929C11.5196 14.6054 11.2652 14.5 11 14.5C10.7348 14.5 10.4804 14.6054 10.2929 14.7929C10.1054 14.9804 10 15.2348 10 15.5V16C10 16.2652 10.1054 16.5196 10.2929 16.7071C10.4804 16.8946 10.7348 17 11 17C11.2652 17 11.5196 16.8946 11.7071 16.7071C11.8946 16.5196 12 16.2652 12 16V15.5Z"
                    fill="#EB6FBD"
                  />
                </svg>
              </div>
              <div class="transaction__unsuccessfully-text">
                {{ /*transaction.unex_status_comment*/ }}
                {{ $t(`card.transaction.errorCods.${transaction.unex_status}`) }}
              </div>
            </div>

            <div class="transaction__exchange">
              <div class="transaction__exchange-second">
                <div class="transaction__exchange-subtitle">
                  {{ $t("card.transaction.exchange-rate") }}
                </div>
                <div class="transaction__exchange-subsumm">
                  1 {{ transaction.currency }} = {{ transaction.rate || 0 }} ₴
                </div>
              </div>
            </div>

            <div class="transaction__exchange">
              <div class="transaction__exchange-first">
                <div class="transaction__exchange-title">
                  {{ $t("card.transaction.weld-wallet-balance") }}
                </div>
                <div class="transaction__exchange-summ pink">
                  0 {{ transaction.currency }}
                </div>
              </div>
            </div>
            <div class="button-close" @click="closePopup">
              {{ $t("card.popup.invite.button") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopupCloseButton from "@/components/button/PopupCloseButton";
import BaseLoader from "@/components/base/BaseLoader";
import moment from "moment";

export default {
  name: "CardItemTransactionUnsuccessfullyPopup",
  components: {
    BaseLoader,
    PopupCloseButton,
  },
  inject: ["showErrorTip", "showSuccessTip", "getCurrencySymbol"],
  props: {
    transaction: {
      type: Object,
    },
  },
  data() {
    return {
      isDataLoading: false,
    };
  },
  computed: {
    transactionDate: function () {
      return moment(this.transaction.created_at).format("YYYY-MM-DD");
    },
    transactionTime: function () {
      return moment(this.transaction.created_at).format("HH:mm");
    },
  },
  methods: {
    closePopup() {
      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  &__block {
    &--card-data {
      width: 100%;
      max-width: 470px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-right: 30px;
  }
}
.transaction-data-popup {
  position: relative;
}

.transaction-data {
  margin-top: 36px;

  &__items {
    position: relative;
    padding: 24px;
    border-radius: 8px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    .transaction {
      &__icon {
        width: 64px;
        height: 64px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      &__summ {
        margin-top: 16px;
        font-style: normal;
        font-weight: 700;
        font-size: pxToRem(24);
        line-height: pxToRem(28);
        text-decoration: line-through;

        text-align: center;

        color: #eb6fbd;
      }
      &__summsalesman {
        margin-top: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: pxToRem(16);
        line-height: pxToRem(20);

        text-align: center;

        color: #333f48;
      }
      &__salesman {
        margin-top: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: pxToRem(16);
        line-height: pxToRem(20);

        text-align: center;

        color: $mainBlack;
      }
      &__type {
        margin-top: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: pxToRem(14);
        line-height: pxToRem(18);

        text-align: center;

        color: #eb6fbd;
      }
      &__date {
        margin-top: 16px;
        &-day {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);
          color: $gray1;
        }
        &-time {
          margin-left: 6px;
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);

          color: $gray1;
        }
      }
      &__valet {
        width: 100%;
        margin-top: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &-icon {
          width: 24px;
          height: 24px;
          margin-right: 6px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        &-name {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);

          color: $gray1;
        }
      }
      &__exchange {
        width: 100%;
        margin-bottom: 20px;
        &-first {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &-title {
          display: flex;
          justify-content: left;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);

          color: $mainBlack;
          span {
            margin-left: 8px;
            margin-right: 8px;
            font-weight: 700;
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
        &-summ {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);

          color: $mainBlack;

          &.green {
            color: rgba(0, 183, 189, 1);
          }
          &.pink {
            color: #eb6fbd;
          }
        }
        &-second {
          width: 100%;
          margin-top: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &-subtitle {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);
          color: $gray1;
        }
        &-subsumm {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);
          color: $gray1;
        }
      }
      &__unsuccessfully {
        &-wrap {
          display: flex;
          width: 100%;
          margin-bottom: 32px;
          margin-top: 32px;
        }
        &-icon {
          width: 22px;
          height: 22px;
          margin-right: 9px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
        &-text {
          width: 310px;
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(16);
          line-height: pxToRem(24);
          color: #eb6fbd;
        }
      }
    }

    .button-close {
      position: fixed;
      bottom: 58px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 12px 36px;
      gap: 10px;

      width: 343px;
      height: 44px;

      background: $mainCold;
      border-radius: 24px;

      font-style: normal;
      font-weight: 700;
      font-size: pxToRem(16);
      line-height: pxToRem(20);

      text-align: center;

      color: #ffffff;
    }
  }
}
</style>
