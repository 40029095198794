import axiosInstance from "@/services/http/axios-instance";

class _UnexOnboardingApi {
  getDiiaMainDeeplinkUrl(cardProductId) {
    return axiosInstance.post("/unex/onboarding/diia/main/deeplink", {
      card_type_id: cardProductId,
    });
  }

  getDiiaChildrenDeeplinkUrl(cardProductId) {
    return axiosInstance.post("/unex/onboarding/diia/children/deeplink", {
      card_type_id: cardProductId,
    });
  }

  getDiiaSignDeeplinkUrl(cardProductId) {
    return axiosInstance.post("/unex/onboarding/diia/sign/deeplink", {
      card_type_id: cardProductId,
    });
  }

  sendDiiaApprove(requestId) {
    return axiosInstance.post("/webhook/unex/diia/approved", {
      request_id: requestId,
    });
  }

  sendDiiaSignApprove(requestId) {
    return axiosInstance.post("/webhook/unex/diia/sign/approved", {
      request_id: requestId,
    });
  }

  sendOtp(phone) {
    return axiosInstance.post("/unex/onboarding/otp/send", {
      phone,
    });
  }

  checkOtp(operationId, phone, otpCode, cardProductId) {
    return axiosInstance.post("/unex/onboarding/otp/check", {
      operation_id: operationId,
      phone,
      otp_code: otpCode,
      card_type_id: cardProductId,
    });
  }

  generatePKRAgreement(cardProductId) {
    return axiosInstance.post("/unex/onboarding/pkr/agreement/generate", {
      card_product_id: cardProductId,
    });
  }

  sendPRKOtp(cardProductId) {
    return axiosInstance.post("/unex/onboarding/pkr/otp/send", {
      card_product_id: cardProductId,
    });
  }

  checkPKROtp(otpCode, cardProductId) {
    return axiosInstance.post("/unex/onboarding/pkr/otp/check", {
      otp_code: otpCode,
      card_product_id: cardProductId,
    });
  }

  downloadUserPKRAgreement(cardProductId) {
    return axiosInstance.get("/unex/onboarding/pkr/agreement/download", {
      responseType: "blob",
      params: {
        card_product_id: cardProductId,
      },
    });
  }

  getQuestionnaireDicts() {
    return axiosInstance.get("/unex/onboarding/questionnaire/dicts");
  }

  sendQuestionnaire(questionnaireData, cardProductId) {
    return axiosInstance.post("/unex/onboarding/questionnaire/submit", {
      ...questionnaireData,
      card_type_id: cardProductId,
    });
  }

  getUserAgreement() {
    return axiosInstance.get("/unex/onboarding/agreement");
  }

  downloadUserAgreement() {
    return axiosInstance.get("/unex/onboarding/agreement/download", {
      responseType: "blob",
    });
  }

  findInCityDict(adr_city_kind_id, name) {
    return axiosInstance.post(
      "/unex/onboarding/questionnaire/dicts/city/search",
      {
        adr_city_kind_id,
        name,
      }
    );
  }

  findInStreetDict(city_kind_id, city_name, street_kind_id, street_name) {
    return axiosInstance.post(
      "/unex/onboarding/questionnaire/dicts/street/search",
      {
        city_kind_id,
        city_name,
        street_kind_id,
        street_name,
      }
    );
  }
}
export const UnexOnboardingApi = new _UnexOnboardingApi();
