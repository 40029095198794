<template>
  <div class="wrapper">
    <main-layout-header :page-title="pageTitle"></main-layout-header>
    <div class="main-layout__body">
      <div class="page page--welcome">
        <base-loader v-if="isDataLoading"></base-loader>
        <story class="welcome-story"></story>
        <div class="welcome-message">
          <div class="welcome-message__title">
            {{ $t("welcome_message.title") }}
          </div>
          <div class="welcome-message__description">
            {{ $t("welcome_message.description") }}
          </div>
          <!--          <div class="welcome-message__button">-->
          <!--            <primary-button-->
          <!--              :label="$t('add_wallet')"-->
          <!--              @click="goToAddWallet"-->
          <!--            ></primary-button>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLayoutHeader from "@/components/layouts/MainLayout/MainLayoutHeader";
import { mapState } from "vuex";
import BaseLoader from "@/components/base/BaseLoader";
// import PrimaryButton from "@/components/button/PrimaryButton";
import Story from "@/components/profile/Tasks/Story";
import store from "@/store";
import {SET_SUPPORT_LINK} from "@/store/const/actions-types";
export default {
  name: "WelcomePage",
  components: { Story, BaseLoader, MainLayoutHeader },
  data() {
    return {
      isDataLoading: false,
      story: null,
    };
  },
  computed: {
    pageTitle: function () {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
    ...mapState(["selectedLocale"]),
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, 'welcome');
  },
  methods: {
    async goToAddWallet() {
      await this.$router.push({
        path: "/exchanges/wallets",
        query: {
          add_wallet: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.page {
  &--welcome {
    margin-top: 20px;
    padding: 67px 32px;
  }
}

.welcome-story {
  margin-bottom: 64px;
}

.welcome-message {
  box-sizing: border-box;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: "Mic 32 New Rounded", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    color: $mainBlack;
    margin-bottom: 32px;
  }

  &__description {
    font-weight: 500;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;
    margin-bottom: 32px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
  }
}

@media screen and (max-width: 486px) {
  .page {
    &--welcome {
      padding: 0;
    }
  }
  .welcome-message {
    &__description {
      padding: 0;
    }
  }
}
</style>
