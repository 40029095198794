<template>
  <div class="exchange-list">
    <template v-for="{ title, icon, id } of exchangesForSelect">
      <exchange-item
        @selectExchange="selectExchangeHandler"
        :title="title"
        :icon="icon"
        :id="id"
        :selected="id === selectedExchangeId"
        :key="id"
      />
    </template>
  </div>
</template>

<script>
import ExchangeItem from "./ExchangeItem";

export default {
  name: "ConnectExchangeStep",
  components: { ExchangeItem },
  props: {
    exchangesForSelect: {
      type: Array,
      required: true,
    },
    selectedExchangeId: {
      type: [Number, String, null],
      default: null,
    },
  },
  methods: {
    selectExchangeHandler(exchangeId) {
      this.$emit("selectExchange", exchangeId);
    },
  },
};
</script>

<style scoped></style>
