<template>
  <div
    class="device-select-container"
    :class="{ 'device-select-container--open': isDropdownOpen }"
  >
    <div class="device-select-overlay" />
    <button
      @click="toggleDropdownVisibility"
      type="button"
      class="device-select"
    >
      <span class="connect-exchange-item__title">
        {{
          $t(
            `connect_exchange.recommendations.device_detection.type.${selectedDeviceType}`
          )
        }}
      </span>
      <svg
        class="device-select__arrow"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L17.2929 8.29289ZM12 15L12.7071 15.7071L12 16.4142L11.2929 15.7071L12 15ZM5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L5.29289 9.70711ZM18.7071 9.70711L12.7071 15.7071L11.2929 14.2929L17.2929 8.29289L18.7071 9.70711ZM11.2929 15.7071L5.29289 9.70711L6.70711 8.29289L12.7071 14.2929L11.2929 15.7071Z"
          fill="#333F48"
        />
      </svg>
    </button>
    <template v-if="isDropdownOpen">
      <div class="device-select-dropdown">
        <div class="dropdown-top">
          <div class="dropdown-top__title">
            {{
              $t(
                `connect_exchange.recommendations.device_detection.dropdown_title`
              )
            }}
          </div>
          <button
            @click="closeDropdown"
            type="button"
            class="dropdown-top__close"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4499 2.55012C13.133 2.23329 12.6194 2.23329 12.3025 2.55012L8 6.85267L3.69746 2.55012C3.38063 2.23329 2.86695 2.23329 2.55012 2.55012C2.23329 2.86695 2.23329 3.38064 2.55012 3.69747L6.85266 8.00001L2.55015 12.3025C2.23332 12.6194 2.23332 13.133 2.55015 13.4499C2.86698 13.7667 3.38066 13.7667 3.69749 13.4499L8 9.14735L12.3025 13.4499C12.6193 13.7667 13.133 13.7667 13.4499 13.4499C13.7667 13.133 13.7667 12.6194 13.4499 12.3025L9.14734 8.00001L13.4499 3.69747C13.7667 3.38064 13.7667 2.86695 13.4499 2.55012Z"
                fill="#7A939C"
              />
            </svg>
          </button>
        </div>
        <ul class="device-select-dropdown__list dropdown-list">
          <template v-for="(type, index) of typesList">
            <li
              @click="selectDeviceType(type)"
              :key="index"
              class="dropdown-list-item"
              :class="{
                'dropdown-list-item--selected': type === selectedDeviceType,
              }"
            >
              <span class="dropdown-list-item__text">{{
                $t(
                  `connect_exchange.recommendations.device_detection.type.${type}`
                )
              }}</span>
            </li>
          </template>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "select",
  },
  name: "DeviceTypeSelect",
  props: {
    value: {
      type: String,
      required: true,
    },
    typesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    selectedDeviceType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("select", value);
      },
    },
  },
  watch: {
    selectedDeviceType() {
      this.closeDropdown();
    },
  },
  methods: {
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    toggleDropdownVisibility() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectDeviceType(value) {
      this.selectedDeviceType = value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/variables";

.device-select {
  margin: 0;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 0.5rem;
  border-radius: 1.5rem;
  background: $gray3;
  width: fit-content;
  border: none;
  color: $mainBlack;

  cursor: pointer;

  &-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 63, 72, 0.2);
    z-index: 1;
  }

  &-dropdown {
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(100% + 0.75rem);
    background: #ffffff;
    box-shadow: 0 4px 32px rgba(121, 150, 160, 0.2);
    border-radius: 0.5rem;
    padding: 0.5rem;

    .dropdown-top {
      display: none;
      padding: 0.75rem 1rem;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      &__title {
        font-family: "Rubik", sans-serif;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      &__close {
        border: none;
        padding: 0;
        margin: 0;
        background-color: transparent;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  &-container {
    position: relative;
  }
}

.dropdown-list {
  display: grid;
  grid-auto-flow: row;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;

  &-item {
    padding: 1.25rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;

    &--selected,
    &:hover {
      background-color: $grayLight;
    }

    &__text {
      font-family: "Nunito", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $mainBlack;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 486px) {
  .device-select {
    width: 100%;
    overflow: hidden;
    padding: 12px 14px;
    column-gap: 0.125rem;

    .connect-exchange-item__title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-dropdown {
      z-index: 2;
      position: fixed;
      bottom: 0;
      top: unset;
      box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
      border-radius: 1.5rem 1.5rem 0 0;
      padding: 1rem 0.5rem;

      .dropdown-top {
        display: flex;
      }
    }

    &-container {
      max-width: 8.6875rem;
      overflow: hidden;
      &--open {
        .device-select {
          &-overlay {
            display: block;
          }
        }
      }
    }
  }
}
</style>
