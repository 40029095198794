<template>
  <div class="main-layout__header-wrapper">
    <div class="main-layout__header">
      <div class="main-layout__page-title" @click="setSelectedCards">
        <slot name="page-title">{{ pageTitle }}</slot>
      </div>
      <rates-indicator
        class="header-rates"
        v-if="isRatesViewVisible && !isMobile"
      />
      <div v-else class="header-rates"></div>
      <div class="header-actions" v-if="actions.length > 0">
        <primary-button
          :label="action.label"
          @click="action.method"
          v-for="(action, index) in actions"
          :key="`action-${index}`"
          class="header-action"
        ></primary-button>
      </div>
    </div>
    <!--    <tasks-story class="tasks-story-block"></tasks-story>-->
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
import { mapState } from "vuex";
import { SET_CURRENT_LOCALE } from "@/store/const/actions-types";
// import TasksStory from "@/components/layouts/MainLayout/TasksStory";
import responsiveMixin from "@/mixins/responsive-mixin";
import rbacMixin from "@/mixins/rbac-mixin";
import RatesIndicator from "@/components/profile/Rates/RatesIndicator";

export default {
  name: "MainLayoutHeader",
  components: {
    RatesIndicator,
    // TasksStory,
    PrimaryButton,
  },
  props: {
    pageTitle: String,
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mixins: [responsiveMixin, rbacMixin],
  data() {
    return {
      isRatesViewVisible: false,
    };
  },
  computed: {
    ...mapState(["selectedLocale"]),
  },
  methods: {
    changeLocale(locale) {
      this.$store.dispatch(SET_CURRENT_LOCALE, locale);
    },
    setSelectedCards() {
      this.$emit("setSelectedCards", true);
    },
  },
  mounted() {
    this.isRatesViewVisible = this.userCan("rates-view");
  },
};
</script>

<style lang="scss" scoped>
.header-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 32px;
}

.header-rates {
  margin-left: auto;
}

.button-primary.header-action {
  width: auto;
}

.header-action {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.2s linear;

  &:hover {
    opacity: 0.7;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}

.header-rates + .header-actions {
  margin-left: 0;
}
</style>

<style lang="scss">
.tasks-story-block {
  margin-top: 48px;
}
</style>
