import {
  SET_AVAILABLE_CRYPTO_ACCOUNT,
  SET_WEB3_INSTANCE,
} from "@/store/const/actions-types";
import erc20TokenABI from "@/const/crypto/erc20-abi";
import tokenSaleContractABI from "@/const/crypto/token-sale-contract-abi";
import stakingContractABI from "@/const/crypto/staking-contract-abi";
import store from "@/store";
import web3 from "web3";
import {
  CRYPTO_API_STATUSES,
  CRYPTO_API_TRANSACTION_TYPES,
  CryptoApiHelper,
} from "@/office/crypto-api";
import Big from "big.js";
import moment from "moment";

export class Web3Service {
  constructor() {
    this._web3 = store.getters.web3Instance || null;
  }

  static get WELD_ADDRESS() {
    return {
      [Web3Service.CHAINS.BSC_TESTNET]:
        "0x04E53234FE8F4570b8676046092aC0b4d7A8074e",
      [Web3Service.CHAINS.BSC_MAIN]:
        "0x5b6ebb33eea2d12eefd4a9b2aeaf733231169684",
    };
  }

  static get WELD_COIN() {
    return "WELD";
  }

  static get CONTRACTS() {
    return {
      tokenSaleContract: {
        [Web3Service.CHAINS.BSC_TESTNET]: {
          address: "0xF1a953E74fE43A75F87016a99E884D901160Bc43",
          abi: tokenSaleContractABI,
        },
      },
      stakingContract: {
        [Web3Service.CHAINS.BSC_TESTNET]: {
          address: "0xAF4A7A0fB3fCaa5087c45C21be5E950f29e34264",
          abi: stakingContractABI,
        },
      },
    };
  }

  static get AVAILABLE_COINS() {
    return {
      USDT: "USDT",
      USDC: "USDC",
      BUSD: "BUSD",
      BNB: "BNB",
      DAI: "DAI",
    };
  }

  static get COINS() {
    return {
      [this.CHAINS.BSC_TESTNET]: {
        [this.AVAILABLE_COINS.USDT]: {
          address: "0xa0ba7c9a6808d22388b875b98bd79452f13f1d5a",
          decimals: 18,
        },
        [this.AVAILABLE_COINS.USDC]: {
          address: "0xb27bd95ecb2dcbbfd6899bafaccb49501b65ea76",
          decimals: 18,
        },
        [this.AVAILABLE_COINS.DAI]: {
          address: "0xa827fb3fe388e88e8dc7b79467a889f2f9311df3",
          decimals: 18,
        },
        [this.AVAILABLE_COINS.BUSD]: {
          address: "0xdc67831bad46227d0c3e1224ed15a2c43763ce07",
          decimals: 18,
        },
        WELD: {
          address: Web3Service.WELD_ADDRESS[Web3Service.CHAINS.BSC_TESTNET],
          decimals: 18,
        },
        main: {
          address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
        },
      },
      [this.CHAINS.BSC_MAIN]: {
        [this.AVAILABLE_COINS.USDT]: {
          address: "0x55d398326f99059ff775485246999027b3197955",
          decimals: 18,
        },
        [this.AVAILABLE_COINS.BUSD]: {
          address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
          decimals: 18,
        },
        [this.AVAILABLE_COINS.USDC]: {
          address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
          decimals: 18,
        },
        [this.AVAILABLE_COINS.DAI]: {
          address: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
          decimals: 18,
        },
        [this.AVAILABLE_COINS.BNB]: {
          address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
          decimal: 18,
        },
        WELD: {
          address: Web3Service.WELD_ADDRESS[Web3Service.CHAINS.BSC_MAIN],
          decimals: 18,
        },
        main: {
          address: "0x0000000000000000000000000000000000000000",
          decimals: 18,
        },
      },
    };
  }

  static get CHAINS() {
    return {
      ETHEREUM_MAIN: 1,
      ETHEREUM_TESTNET: 42,
      BSC_MAIN: 56,
      BSC_TESTNET: 97,
    };
  }

  async loadWeb3ToStore() {
    if (window.ethereum) {
      await store.dispatch(SET_WEB3_INSTANCE, window.ethereum);
    }
  }

  async requestAccounts() {
    let accounts = await this._web3.eth
      .requestAccounts()
      .catch((err) => console.log(err));

    store.commit(SET_AVAILABLE_CRYPTO_ACCOUNT, accounts);

    return accounts || [];
  }

  async validateNetworkAndChangeIfNotValid(currentNetwork) {
    if (
      AVAILABLE_CHAINS.findIndex(
        (_aChain) => parseInt(_aChain) === parseInt(currentNetwork)
      ) > -1
    ) {
      return true;
    }

    try {
      await this._web3.givenProvider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: this._web3.utils.toHex(AVAILABLE_CHAINS[0]),
          },
        ],
      });
      return true;
    } catch (e) {
      if (e.code && e.code === 4902) {
        try {
          await this._web3.givenProvider.request({
            method: "wallet_addEthereumChain",
            params: [CHAIN_PARAMS[AVAILABLE_CHAINS[0]]],
          });
          return true;
        } catch (addChainError) {
          console.error(addChainError);
          return false;
        }
      }
    }
  }

  async allowCurrency(address, chain, coinName, spender = null) {
    if (!spender) {
      spender = address;
    }

    const coinContract = new this._web3.eth.Contract(
      erc20TokenABI,
      Web3Service.COINS[chain][coinName.toUpperCase()].address
    );

    let allowance = await coinContract.methods
      .allowance(address, spender)
      .call();

    if (this._web3.utils.toBN(allowance).cmpn(0) !== 0) {
      return true;
    }

    let totalSupply = await coinContract.methods.totalSupply().call();
    let totalSupplyBN = this._web3.utils.toBN(totalSupply);

    return await coinContract.methods
      .approve(spender, totalSupplyBN)
      .send({ from: address });
  }

  async buyWeldWithToken(amount, address, chain, coinName, additionalData) {
    let allowance = await this.allowCurrency(
      address,
      chain,
      coinName,
      Web3Service.CONTRACTS.tokenSaleContract[chain].address
    );

    if (!allowance) {
      return false;
    }

    let tokenSaleContract = this.getTokenSaleContract(chain);

    let _calcPriceResult = await tokenSaleContract.methods
      .calcPrice(
        Date.now(),
        address,
        Web3Service.COINS[chain][coinName.toUpperCase()].address,
        this._web3.utils.toWei(amount.toString(), "ether")
      )
      .call();

    let availableForSell = await tokenSaleContract.methods
      .availableForSellAmounts(Date.now(), address)
      .call();

    if (
      1 ===
      this._web3.utils
        .toBN(_calcPriceResult.toActualAmount)
        .cmp(this._web3.utils.toBN(availableForSell.maxAmount))
    ) {
      return false;
    }

    let estimatedGas = await tokenSaleContract.methods
      .swapFromToken(
        Web3Service.COINS[chain][coinName.toUpperCase()].address,
        this._web3.utils.toBN(_calcPriceResult.fromActualAmount),
        this._web3.utils.toBN(_calcPriceResult.toActualAmount)
      )
      .estimateGas({ from: address });

    return await tokenSaleContract.methods
      .swapFromToken(
        Web3Service.COINS[chain][coinName.toUpperCase()].address,
        this._web3.utils.toBN(_calcPriceResult.fromActualAmount),
        this._web3.utils.toBN(_calcPriceResult.toActualAmount)
      )
      .send({ from: address, gas: estimatedGas })
      .on("transactionHash", (txHash) => {
        CryptoApiHelper.saveBuyTransaction(
          txHash,
          address,
          this._web3.utils.fromWei(_calcPriceResult.toActualAmount),
          additionalData.amountUSDT || 0,
          this._web3.utils.fromWei(_calcPriceResult.fromActualAmount),
          coinName.toUpperCase(),
          additionalData.rateUSDT || 0
        );
      })
      .on("receipt", (txReceipt) => {
        CryptoApiHelper.updateBuyTransaction(
          txReceipt.transactionHash,
          CRYPTO_API_STATUSES.SUCCESS
        );
      })
      .on("error", (txError, receipt) => {
        if (receipt) {
          CryptoApiHelper.updateBuyTransaction(
            receipt.transactionHash || "",
            CRYPTO_API_STATUSES.ERROR
          );
        }
      });
  }

  async buyWeldWithCoin(amount, address, chain, additionalData) {
    const tokenSaleContract = new this._web3.eth.Contract(
      Web3Service.CONTRACTS.tokenSaleContract[chain].abi,
      Web3Service.CONTRACTS.tokenSaleContract[chain].address
    );

    let _calcPriceResult = await tokenSaleContract.methods
      .calcPrice(
        Date.now(),
        address,
        Web3Service.COINS[chain].main.address,
        this._web3.utils.toWei(amount.toString(), "ether")
      )
      .call();

    let estimatedGas = await tokenSaleContract.methods
      .swapFromCoin(_calcPriceResult.toActualAmount)
      .estimateGas({ from: address, value: _calcPriceResult.fromActualAmount });

    await tokenSaleContract.methods
      .swapFromCoin(_calcPriceResult.toActualAmount)
      .send({
        from: address,
        gas: estimatedGas,
        value: _calcPriceResult.fromActualAmount,
      })
      .on("transactionHash", (txHash) => {
        CryptoApiHelper.saveBuyTransaction(
          txHash,
          address,
          this._web3.utils.fromWei(_calcPriceResult.toActualAmount),
          additionalData.amountUSDT || 0,
          this._web3.utils.fromWei(_calcPriceResult.fromActualAmount),
          Web3Service.AVAILABLE_COINS.BNB,
          additionalData.rateUSDT || 0
        );
      })
      .on("receipt", (txReceipt) => {
        CryptoApiHelper.updateBuyTransaction(
          txReceipt.transactionHash,
          CRYPTO_API_STATUSES.SUCCESS
        );
      })
      .on("error", (txError, receipt) => {
        if (receipt) {
          CryptoApiHelper.updateBuyTransaction(
            receipt.transactionHash || "",
            CRYPTO_API_STATUSES.ERROR
          );
        }
      });
  }

  async getWeldBalance(address, chain) {
    if (!this._web3) {
      return 0;
    }

    if (!Web3Service.WELD_ADDRESS[chain]) {
      return 0;
    }

    const weldCoinContract = new this._web3.eth.Contract(
      erc20TokenABI,
      Web3Service.WELD_ADDRESS[chain]
    );

    let weldBalance = this._web3.utils.fromWei(
      await weldCoinContract.methods.balanceOf(address).call(),
      "ether"
    );

    return new Big(weldBalance).toPrecision();
  }

  async getCoinBalance(address, coin, chain) {
    if (!this._web3) {
      return 0;
    }

    if (coin.toUpperCase() === Web3Service.AVAILABLE_COINS.BNB) {
      let bnbBalance = await this._web3.eth.getBalance(address);
      return Big(this._web3.utils.fromWei(bnbBalance)).toPrecision();
    }

    if (!Web3Service.COINS[chain][coin.toUpperCase()]) {
      return 0;
    }

    const weldCoinContract = new this._web3.eth.Contract(
      erc20TokenABI,
      Web3Service.COINS[chain][coin.toUpperCase()].address
    );

    let coinBalance = this._web3.utils.fromWei(
      await weldCoinContract.methods.balanceOf(address).call(),
      "ether"
    );

    return new Big(coinBalance).toPrecision();
  }

  async getWeldCoinRate(_coin, chain) {
    if (_coin === Web3Service.AVAILABLE_COINS.BNB) {
      _coin = "main";
    }

    if (!Web3Service.COINS[chain][_coin]) {
      throw Error(`Coin ${_coin} unsupported`);
    }

    let tokenSaleContract = this.getTokenSaleContract(chain);
    let weldCoinRate = await tokenSaleContract.methods
      .prices_(Web3Service.COINS[chain][_coin].address)
      .call();

    return this._web3.utils.fromWei(weldCoinRate);
  }

  async canUserStake(address, chain) {
    if (!this._web3) {
      return false;
    }

    const stakingContract = this.getStakingContract(chain);
    return await stakingContract.methods.canDeposit(address).call();
  }

  async getAvailableWithdrawAmount(address, chain) {
    if (!this._web3) {
      return 0;
    }

    const stakingContract = this.getStakingContract(chain);
    let _availableWithdraw = await stakingContract.methods
      .availableWithdrawAmount(address)
      .call();

    return this._web3.utils.fromWei(_availableWithdraw);
  }

  async canStakeAmount(weldAmount, chain) {
    if (!this._web3) {
      return false;
    }

    const stakingContract = this.getStakingContract(chain);

    let availableDepositAmount = await stakingContract.methods
      .availableDepositAmount()
      .call();

    availableDepositAmount = parseFloat(
      this._web3.utils.fromWei(availableDepositAmount)
    );
    weldAmount = parseFloat(weldAmount);

    if (weldAmount <= availableDepositAmount) {
      return true;
    }

    return availableDepositAmount;
  }

  async depositWeld(address, weldAmount, chain, additionalData) {
    if (!this._web3) {
      return false;
    }

    const stakingContract = this.getStakingContract(chain);

    const weldAllowance = await this.allowCurrency(
      address,
      chain,
      Web3Service.WELD_COIN,
      stakingContract.options.address
    );

    if (!weldAllowance) {
      return false;
    }

    let canStakeAmount = await this.canStakeAmount(weldAmount, chain);

    if (canStakeAmount !== true) {
      return false;
    }

    let _weldAmountWei = this._web3.utils.toWei(weldAmount);

    let estimatedGas = await stakingContract.methods
      .depositToken(_weldAmountWei)
      .estimateGas({ from: address });

    return await stakingContract.methods
      .depositToken(_weldAmountWei)
      .send({ from: address, gas: estimatedGas })
      .on("transactionHash", (txHash) => {
        CryptoApiHelper.saveStakingTransaction(
          txHash,
          address,
          weldAmount,
          additionalData.amountUSDT || 0,
          additionalData.rateUSDT || 0
        );
      })
      .on("receipt", (txReceipt) => {
        CryptoApiHelper.updateStakingTransaction(
          txReceipt.transactionHash,
          CRYPTO_API_STATUSES.SUCCESS
        );
      })
      .on("error", (txError, receipt) => {
        if (receipt) {
          CryptoApiHelper.updateStakingTransaction(
            receipt.transactionHash || "",
            CRYPTO_API_STATUSES.ERROR
          );
        }
      });
  }

  async getAvailableStakeWithdrawAmount(address, chain) {
    if (!this._web3) {
      return 0;
    }

    const stakingContract = this.getStakingContract(chain);

    return await stakingContract.methods
      .availableWithdrawAmount(address)
      .call();
  }

  async withdrawStakes(address, chain) {
    if (!this._web3) {
      return 0;
    }

    const stakingContract = this.getStakingContract(chain);

    let availableToWithdraw = await this.getAvailableStakeWithdrawAmount(
      address,
      chain
    );

    if (Big(this._web3.utils.fromWei(availableToWithdraw)).eq(Big(0))) {
      throw new Error(`No available amount to withdraw`);
    }

    let estimatedGas = await stakingContract.methods
      .withdrawToken()
      .estimateGas({ from: address });

    return await stakingContract.methods
      .withdrawToken()
      .send({ from: address, gas: estimatedGas })
      .on("transactionHash", (txHash) => {
        console.log("transaction hash", txHash);
      })
      .on("receipt", (txReceipt) => {
        console.log("transaction receipt", txReceipt);
      })
      .on("error", (txError, receipt) => {
        console.log("transaction error", txError, receipt);
      });
  }

  getStakingContract(chain) {
    return new this._web3.eth.Contract(
      Web3Service.CONTRACTS.stakingContract[chain].abi,
      Web3Service.CONTRACTS.stakingContract[chain].address
    );
  }

  getTokenSaleContract(chain) {
    return new this._web3.eth.Contract(
      Web3Service.CONTRACTS.tokenSaleContract[chain].abi,
      Web3Service.CONTRACTS.tokenSaleContract[chain].address
    );
  }

  async getStakes(address, chain) {
    const stakingContract = this.getStakingContract(chain);

    let accountStakes = await stakingContract.methods.balance(address).call();

    let _formattedStakes = accountStakes.map((_stake) => ({
      amount: this._web3.utils.fromWei(_stake.amount),
      date: new Date(_stake.date * 1000),
    }));

    return _formattedStakes.sort((stake1, stake2) => {
      let timestamp1 = moment(stake1.date).format("X");
      let timestamp2 = moment(stake2.date).format("X");

      if (timestamp1 < timestamp2) {
        return -1;
      }

      if (timestamp1 > timestamp2) {
        return 1;
      }

      return 0;
    });
  }

  updateTransactionStatus(txHash, type) {
    return new Promise((resolve) => {
      this._web3.eth.getTransactionReceipt(txHash, (err, transaction) => {
        let status = CRYPTO_API_STATUSES.PENDING;

        if (err) {
          status = CRYPTO_API_STATUSES.ERROR;
        } else if (transaction) {
          if (transaction.status === false) {
            status = CRYPTO_API_STATUSES.ERROR;
          } else {
            status = CRYPTO_API_STATUSES.SUCCESS;
          }
        }

        switch (type) {
          case CRYPTO_API_TRANSACTION_TYPES.BUY: {
            CryptoApiHelper.updateBuyTransaction(txHash, status);
            break;
          }
          case CRYPTO_API_TRANSACTION_TYPES.STAKING: {
            CryptoApiHelper.updateStakingTransaction(txHash, status);
          }
        }

        resolve(status);
      });
    });
  }

  validateChain(chain) {
    return !!Web3Service.CHAINS[chain];
  }
}

export const AVAILABLE_CHAINS =
  process.env.VUE_APP_AVAILABLE_CRYPTO_NETWROKS.split(",");

export const CHAIN_PARAMS = {
  [Web3Service.CHAINS.BSC_TESTNET]: {
    chainId: web3.utils.toHex(Web3Service.CHAINS.BSC_TESTNET),
    chainName: "Binance Smart Chain - Testnet",
    nativeCurrency: {
      name: "Binance",
      symbol: "BNB",
      decimals: 18,
    },
    blockExplorerUrls: ["https://testnet.bscscan.com"],
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
  },
  [Web3Service.CHAINS.BSC_MAIN]: {
    chainId: web3.utils.toHex(Web3Service.CHAINS.BSC_MAIN),
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      name: "Binance",
      symbol: "BNB",
      decimals: 18,
    },
    blockExplorerUrls: ["https://bscscan.com"],
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
  },
};
