<template>
  <div class="onboarding">
    <div class="onboarding__header">
      <onboarding-stepper :active-step="2"></onboarding-stepper>
      <div class="onboarding__step-title">Enter an actual address</div>
    </div>

    <div
      class="onboarding__address"
      @scroll="handleScroll($event)"
      :style="{
        //0px 0px 62px rgba(183, 201, 211, 0.5);
        boxShadow:
          scrollTop > 10
            ? 'inset 0 62px 62px -62px rgba(183, 201, 211, 0.5)'
            : 'none',
      }"
    >
      <div class="onboarding__address-fields">
        <div class="onboarding__address-field">
          <text-input title="Country"></text-input>
        </div>
        <div class="onboarding__address-field">
          <text-input title="ZIP Code"></text-input>
        </div>
        <div class="onboarding__address-field">
          <text-input title="Region"></text-input>
        </div>
        <div class="onboarding__address-field">
          <text-input title="District"></text-input>
        </div>
        <div class="onboarding__address-field">
          <text-input title="Settlement"></text-input>
        </div>
        <div class="onboarding__address-field">
          <text-input title="Street"></text-input>
        </div>
        <div class="onboarding__address-field onboarding__address-field--row">
          <text-input title="Building"></text-input>
          <text-input title="Houseroom"></text-input>
        </div>
      </div>
      <div class="onboarding__address-actions">
        <no-bg-button
          label="Back"
          color="dark"
          class="onboarding__address-action"
          @click="toPrevStep"
          v-if="!isMobile"
        ></no-bg-button>
        <floating-back-button v-else @click="toPrevStep"></floating-back-button>
        <primary-button
          label="Next step"
          @click="toNextStep"
          class="onboarding__address-action onboarding__address-action--next"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingStepper from "@/components/profile/Onboarding/OnboardingStepper";
import PrimaryButton from "@/components/button/PrimaryButton";
import TextInput from "@/components/input/TextInput";
import NoBgButton from "@/components/button/NoBgButton";

import responsiveMixin from "@/mixins/responsive-mixin";
import FloatingBackButton from "@/components/button/FloatingBackButton";

export default {
  name: "OnboardingAddress",
  components: {
    FloatingBackButton,
    NoBgButton,
    TextInput,
    PrimaryButton,
    OnboardingStepper,
  },
  mixins: [responsiveMixin],
  data() {
    return {
      scrollTop: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    toPrevStep() {
      this.$router.push("/onboarding/diia");
    },
    toNextStep() {
      this.$router.push("/onboarding/selfie");
    },
    handleScroll(event) {
      this.scrollTop = event.target.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_mixins.scss";
@import "~@/assets/scss/_variables.scss";

.onboarding {
  &__address {
    max-width: 345px;
    width: 100%;
    padding-bottom: 60px;

    &-fields {
      margin-top: 54px;
      margin-bottom: 60px;
    }

    &-field {
      margin-bottom: 64px;

      &:last-child {
        margin-bottom: 0;
      }

      &--row {
        display: flex;
        justify-content: space-between;

        .input {
          width: calc(50% - 10px);
        }
      }
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
    }

    &-action {
      width: auto;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .onboarding {
    width: 100%;
    &__header {
      padding-bottom: 40px;
      margin-bottom: 4px;
    }

    &__address {
      width: 100%;
      max-width: 100%;
      overflow: auto;
      padding: 0 16px 70px;

      &-fields {
        margin: 0;
      }

      &-action {
        &--next {
          position: fixed;
          width: calc(100% - 32px);
          bottom: 20px;
          left: 16px;
        }
      }
    }
  }
}
</style>
