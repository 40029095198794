<template>
  <div class="wrapper">
    <story class="page__story"></story>
    <main-layout-header :page-title="pageTitle"></main-layout-header>
    <div class="main-layout__body">
      <div class="page page--faq">
        <base-loader v-if="isDataLoading"></base-loader>
        <div
          class="faq-group"
          v-for="(faqGroup, index) in faqGroups"
          :key="`faq-group-${index}`"
        >
          <div class="faq-group__title">
            {{ faqGroup.title[selectedLocale] }}
          </div>
          <div class="faq-group__list">
            <faq-item
              :faq-item="faqItem"
              v-for="(faqItem, index) in faqGroup.faqs"
              :key="`faq-item-${index}`"
            ></faq-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLayoutHeader from "@/components/layouts/MainLayout/MainLayoutHeader";
import { mapGetters, mapState } from "vuex";
import FaqItem from "@/components/profile/FAQ/FAQItem";
import BaseLoader from "@/components/base/BaseLoader";
import { FaqApi } from "@/office/faq-api";
import Story from "@/components/profile/Tasks/Story";
export default {
  name: "FAQ",
  components: { Story, BaseLoader, FaqItem, MainLayoutHeader },
  data() {
    return {
      faqGroups: [
        {
          title: {
            en: "Test",
          },
          questions: [
            {
              question: { en: "Test" },
              answer: { en: "Test" },
            },
            {
              question: { en: "Test" },
              answer: { en: "Test" },
            },
            {
              question: { en: "Test" },
              answer: { en: "Test" },
            },
          ],
        },
        {
          title: {
            en: "Test",
          },
          questions: [
            {
              question: { en: "Test" },
              answer: { en: "Test" },
            },
          ],
        },
      ],
      isDataLoading: false,
    };
  },
  computed: {
    pageTitle: function () {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
    ...mapState(["selectedLocale"]),
    ...mapGetters(["userData"]),
  },
  created() {
    this.loadFAQ();
  },
  methods: {
    loadFAQ() {
      this.isDataLoading = true;
      FaqApi.getFAQ().then((r) => {
        this.faqGroups = r.data.faq_groups;
        this.isDataLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.page {
  &--faq {
    margin-top: 20px;
    padding: 67px 44px 0;
  }
}

.faq-group {
  margin-bottom: 64px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-weight: bold;
    font-size: pxToRem(24);
    line-height: pxToRem(28);
    color: $mainBlack;
    margin-bottom: 32px;
  }

  &__list {
    border: 1px solid $gray3;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 0 16px;
  }
}
</style>
