<template>
  <div class="popup__block popup__block--card-products">
    <div class="popup__header popup__header--wrap">
      <div class="popup__title">
        {{ $t("card.popup.product.sub-products.title") }}
      </div>
    </div>
    <div class="card-products__sub-header">
      {{ $t("card.popup.product.sub-products.sub-title") }}
    </div>
    <div class="card-products">
      <div class="card-products__list">
        <card-product-item
          :class="{
            active:
              selectedCardProduct && selectedCardProduct.id === cardProduct.id,
            'sub-product': true,
          }"
          v-for="cardProduct in formattedCardProducts"
          :card-product="cardProduct"
          :key="`card-product-${cardProduct.id}`"
          @click="setActiveCardProduct(cardProduct)"
        ></card-product-item>
      </div>
      <div class="card-products__actions">
        <no-bg-button
          class="card-products__action button--hide"
          :label="$t('back')"
          @click="goBack"
        ></no-bg-button>
        <primary-button
          class="card-products__action"
          :label="$t('continue')"
          :disabled="!selectedCardProduct"
          @click="goToAddCardProcess"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import CardProductItem from "@/components/profile/Cards/Items/CardProductItem";
import NoBgButton from "@/components/button/NoBgButton";
import PrimaryButton from "@/components/button/PrimaryButton";
import { CardApi } from "@/office/card-api";
export default {
  name: "AddCardSubProducts",
  components: { PrimaryButton, NoBgButton, CardProductItem },
  props: {
    cardProducts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedCardProduct: null,
      rates: null,
    };
  },
  async created() {
    await this.loadRates();
  },
  computed: {
    formattedCardProducts: function () {
      if (!this.rates) {
        return [];
      }

      return this.cardProducts.map((_cP) => ({
        ..._cP,
        name: this.$t(`card.popup.product.sub-product.${_cP.slug}`),
        subTitle: this.getSubTitle(_cP.slug),
      }));
    },
  },
  methods: {
    async loadRates() {
      try {
        let ratesResponse = await CardApi.getCardProductsRates();
        this.rates = ratesResponse.data;
      } catch (e) {
        console.error(e);
      }
    },
    goBack() {
      this.$emit("goBack");
    },
    goToAddCardProcess() {
      this.$emit("goNext", this.selectedCardProduct);
    },
    setActiveCardProduct(cardProduct) {
      this.selectedCardProduct = cardProduct;
    },
    getSubTitle(rateType) {
      if (rateType === "weld-card-pkr") {
        return `${this.rates["manual_rates"].USDT} UAH-USDT`;
      } else if (rateType === "weld-card-nbu") {
        return `${this.rates["nbu_rates"].USD} UAH-USD`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_mixins.scss";
@import "~@/assets/scss/_variables.scss";

.card-products {
  margin-top: 16px;

  &__sub-header {
    font-weight: 500;
    font-size: pxToRem(14);
    line-height: pxToRem(18);
    color: $gray1;
    margin-top: 40px;
  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__actions {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__action {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-product {
  width: calc(50% - 12px);
  margin-right: 24px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  &.active {
    filter: grayscale(0);
  }
}
</style>
