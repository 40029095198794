<template>
  <div class="otp-wrapper">
    <div class="login-form__wrapper">
      <div class="login-form">
        <div class="login-form__title">{{ $t("verify_phone_email") }}</div>

        <div class="login-form__otp-block">
          <div class="login-form__sub-title">
            {{ $t("phone") }}: {{ user.phone }}
          </div>
          <div class="sign-up__form-otp">
            <w-otp-input
              ref="otpCodePhoneRef"
              v-model="otpCodePhone"
              :hidden-label="true"
              :v-errors="v$.otpCodePhone.$errors"
              @enter="verifyOTP"
              :username="user.phone"
              :otp-type="otpTypes.SMS"
              mode="small"
              hide-resend
            ></w-otp-input>
          </div>
        </div>

        <div class="login-form__otp-block">
          <div class="login-form__sub-title">
            {{ $t("email") }}: {{ user.email }}
          </div>
          <div class="sign-up__form-otp">
            <w-otp-input
              ref="otpCodeEmailRef"
              v-model="otpCodeEmail"
              :hidden-label="true"
              :v-errors="v$.otpCodeEmail.$errors"
              @enter="verifyOTP"
              :username="user.phone"
              :otp-type="otpTypes.EMAIL"
              mode="small"
              hide-resend
            ></w-otp-input>
          </div>
        </div>

        <div class="login-form__resend-otp">
          <span>{{ resendOtpText }}</span>
          <button
            @click="resendCodes"
            :disabled="isOtpResent"
            type="button"
            class="login-form__resend-button"
          >
            {{ $t("resend_codes") }}
          </button>
        </div>

        <div class="login-form__button">
          <primary-button
            :disabled="isSubmitButtonDisabled"
            :label="$t('continue')"
            @click="verifyOTP"
          ></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
import WOtpInput from "@/components/input/WOtpInput";

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import AuthApi from "@/office/auth-api";
import { LOGIN_USER, SET_SUPPORT_LINK } from "@/store/const/actions-types";
import { mapState } from "vuex";
import { GA_EVENTS } from "@/const/ga.events";
import { GtagServiceHelper } from "@/services/gtag/gtag.service";
import { OTP_TYPES } from "@/const/auth/otp-types";
import { OTP_RESEND_TIMEOUT } from "@/const/config";
import store from "@/store";

export default {
  name: "RegistrationOtp",
  components: { WOtpInput, PrimaryButton },
  inject: ["showErrorTip"],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  metaInfo: {
    title: "Registration",
  },
  data() {
    return {
      isOtpResent: true,
      otpCodePhone: "",
      otpCodeEmail: "",
      otpTypes: OTP_TYPES,
      baseResendTimeout: OTP_RESEND_TIMEOUT,
      resendTimeout: OTP_RESEND_TIMEOUT,
      resendOTPInterval: null,
    };
  },
  created() {
    store.dispatch(SET_SUPPORT_LINK, "reg_mail_veref");

    this.setTimer();
  },
  beforeDestroy() {
    if (this.resendOTPInterval) {
      clearInterval(this.resendOTPInterval);
    }
  },

  validations() {
    return {
      otpCodePhone: {
        required,
        minValue: minLength(4),
        maxLength: maxLength(6),
      },
      otpCodeEmail: {
        required,
        minValue: minLength(4),
        maxLength: maxLength(6),
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    isSubmitButtonDisabled() {
      return !this.otpCodePhone || !this.otpCodeEmail;
    },
    resendOtpText() {
      return this.isOtpResent
        ? this.resendTimeoutText
        : this.$t("codes_expired");
    },
    resendTimeoutText() {
      let minutes = Math.floor(this.resendTimeout / 60);
      let seconds = this.resendTimeout - minutes * 60;

      return `${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)}`;
    },
  },
  methods: {
    async resendCodes() {
      if (!this.user.phone) {
        return console.error("No user phone");
      }
      this.clearOtpFields();
      try {
        await Promise.all([
          AuthApi.resendOtp(this.user.phone, this.otpTypes.SMS, false, "true"),
          AuthApi.resendOtp(
            this.user.phone,
            this.otpTypes.EMAIL,
            false,
            "true"
          ),
        ]);
        this.setTimer();
        this.isOtpResent = true;
      } catch (error) {
        console.error(error);
        this.isOtpResent = false;
      }
    },
    clearOtpFields() {
      this.$refs.otpCodeEmailRef.clearInput();
      this.$refs.otpCodePhoneRef.clearInput();
    },
    setTimer() {
      if (this.resendOTPInterval) {
        clearInterval(this.resendOTPInterval);
      }

      this.resendOTPInterval = setInterval(() => {
        if (this.resendTimeout <= 0) {
          clearInterval(this.resendOTPInterval);
          this.isOtpResent = false;
          this.resendTimeout = this.baseResendTimeout;
        } else {
          this.resendTimeout -= 1;
        }
      }, 1000);
    },
    async verifyOTP() {
      const result = await this.v$.$validate();
      if (!result) return;
      GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_UP_OTP_START);

      await this.$recaptchaLoaded();
      const reCaptchaToken = await this.$recaptcha("login");

      AuthApi.authorizeOtp(
        this.user.phone,
        {
          sms: this.otpCodePhone,
          email: this.otpCodeEmail,
        },
        false,
        reCaptchaToken
      )
        .then(async (r) => {
          this.analytics();

          GtagServiceHelper.sendEvent(this, GA_EVENTS.SIGN_UP_OTP);
          await this.$store.dispatch(LOGIN_USER, {
            phone: this.user.phone,
            accessToken: r.data.access_token,
            refreshToken: r.data.refresh_token,
            isRegister: true
          });

          // await this.setPassword(this.user.password);

          await this.$router.push("/sign-up/password");
        })
        .catch(() => {
          this.showErrorTip(this.$t("otp_error"));
        });
    },
    analytics() {
      console.log("Step4_VerifTelMail");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "Step4_VerifTelMail" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.otp-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  &__sub-title {
    color: $mainBlack;
    margin-bottom: 20px;
  }

  &__otp-block {
    margin-bottom: 40px;
  }

  &__resend-otp {
    display: grid;
    grid-auto-flow: row;
    row-gap: 0.75rem;
    text-align: center;

    span {
      font-family: "Nunito", "Rubik", sans-serif;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $gray1;
    }
  }

  &__resend-button {
    background: transparent;
    border: none;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $mainCold;
    cursor: pointer;
    &:disabled {
      cursor: default;
      color: $gray2;
    }
  }
}

.sign-up {
  &__form {
    &-otp {
      display: flex;
      justify-content: center;
    }
  }
}

.auth-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 486px) {
  .login-form {
    width: 100%;
    padding: 0;
    box-shadow: unset;
    display: flex;
    flex-direction: column;

    &__wrapper {
      width: 100%;
      flex-grow: 1;
    }
  }

  .sign-up {
    &__form {
      &-otp {
        flex-grow: 1;
      }
    }
  }

  .otp-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss">
.otp-input {
  margin-top: 0 !important;

  &__code {
    justify-content: center;
  }

  &__resend {
    margin-top: 72px;
  }
}

@media screen and (max-width: 486px) {
  .sign-up {
    &__form {
      &-otp {
        & > .otp-input {
          display: flex;
          flex-direction: column;

          &__code {
            width: 100%;
          }
        }
        .otp-input__resend {
          margin-top: auto;
        }
      }
    }
  }
}
</style>
