export default [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_timestamp",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_buyer",
        type: "address",
      },
    ],
    name: "availableForSellAmounts",
    outputs: [
      {
        internalType: "uint256",
        name: "minAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_timestamp",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_buyer",
        type: "address",
      },
      {
        internalType: "address",
        name: "_fromAsset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_fromAmount",
        type: "uint256",
      },
    ],
    name: "calcPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "toActualAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "fromActualAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_asset",
        type: "address",
      },
    ],
    name: "prices_",
    outputs: [
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "confirmNewAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "asset",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        internalType: "struct Price[]",
        name: "_prices",
        type: "tuple[]",
      },
    ],
    name: "setPrices",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_toExpectedAmount",
        type: "uint256",
      },
    ],
    name: "swapFromCoin",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_fromAsset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_fromAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_toExpectedAmount",
        type: "uint256",
      },
    ],
    name: "swapFromToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
