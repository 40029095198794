import axiosInstance from "@/services/http/axios-instance";

class _TasksApi {
  getActiveStory() {
    return axiosInstance.get("/tasks/story/active");
  }

  getStoryById(storyId) {
    return axiosInstance.get(`/tasks/story/${storyId}`);
  }

  markTaskComplete(taskTypeSlug, taskSlug) {
    return axiosInstance.post("/tasks/complete", {
      task_type_slug: taskTypeSlug,
      task_slug: taskSlug,
    });
  }
}

export const TasksApi = new _TasksApi();
