<template>
  <button
    @click="exchangeItemClickHandler"
    type="button"
    class="exchange-item"
    :class="{ 'exchange-item--selected': selected }"
  >
    <div class="exchange-item__main exchange-main">
      <div class="exchange-main__logo main-logo">
        <span v-if="icon" v-html="icon" />
        <div v-if="selected" class="main-logo__selected-icon">
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.5 15C12.366 15 15.5 11.866 15.5 8C15.5 4.13401 12.366 1 8.5 1C4.63401 1 1.5 4.13401 1.5 8C1.5 11.866 4.63401 15 8.5 15ZM6.06516 7.65845L7.63715 9.3391L10.6782 5.58527C10.852 5.3707 11.1668 5.33767 11.3814 5.51149C11.596 5.68531 11.629 6.00017 11.4552 6.21474L8.27161 10.1445C7.9649 10.5232 7.39437 10.5435 7.06152 10.1876L5.33484 8.34155C5.1462 8.13988 5.15678 7.82347 5.35845 7.63484C5.56012 7.44621 5.87653 7.45678 6.06516 7.65845Z"
              fill="#00B7BD"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="exchange-main__title">
      {{ title }}
    </div>
  </button>
</template>

<script>
export default {
  name: "ExchangeItem",
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  methods: {
    exchangeItemClickHandler() {
      this.$emit("selectExchange", this.id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.exchange-item {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  width: 100%;
  align-items: center;
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  row-gap: 0.75rem;
  cursor: pointer;

  &--selected {
    .exchange-main {
      position: relative;

      &__logo {
        border-color: $mainCold;
      }

      &__title {
        font-weight: 600;
      }
    }
  }
}

.exchange-main {
  &__logo,
  &__logo span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: 4rem;
    height: 4rem;

    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: $gray3;
    border-radius: 40px;
  }

  &__title {
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $mainBlack;
  }
}

.main-logo {
  position: relative;

  &__selected-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0.125rem;
    right: 0.1875rem;
    background-color: #ffffff;
    border-radius: 2.5rem;

    padding: 0.0625rem;

    svg {
      width: 0.875rem;
      height: 0.875rem;
    }
  }
}
</style>

<style lang="scss">
.exchange-main__logo svg {
  width: 2.25rem;
  height: 2.25rem;
}
</style>
