<template>
  <div class="otp-wrapper">
    <div class="login-form__wrapper">
      <div class="login-form">
        <div class="login-form__title">{{ $t("verify_email") }}</div>
        <div class="login-form__sub-title">
          {{ $t("verify_email_hint") }}
        </div>

        <div class="login-form__otp-block">
          <div class="sign-up__form-otp">
            <w-otp-input
              v-model="otpCodeEmail"
              :hidden-label="true"
              :v-errors="v$.otpCodeEmail.$errors"
              @enter="verifyOTP"
              :username="user.phone"
              :otp-type="otpTypes.EMAIL"
              :resendMethod="requestForgot"
              mode="small"
            ></w-otp-input>
          </div>
        </div>

        <div class="login-form__button">
          <primary-button
            :label="$t('continue')"
            @click="verifyOTP"
          ></primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
import WOtpInput from "@/components/input/WOtpInput";

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import AuthApi from "@/office/auth-api";
import { LOGIN_USER } from "@/store/const/actions-types";
import { mapState } from "vuex";
import { OTP_TYPES } from "@/const/auth/otp-types";

export default {
  name: "ForgotPasswordOtp",
  components: { WOtpInput, PrimaryButton },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  metaInfo: {
    title: "Verify OTP",
  },
  inject: ["showErrorTip"],
  data() {
    return {
      otpCodePhone: "",
      otpCodeEmail: "",
      otpTypes: OTP_TYPES,
    };
  },
  created() {},
  validations() {
    return {
      otpCodeEmail: {
        required,
        minValue: minLength(4),
        maxLength: maxLength(6),
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async verifyOTP() {
      await this.$recaptchaLoaded();
      const reCaptchaToken = await this.$recaptcha("login");

      AuthApi.authorizeOtp(
        this.user.email,
        {
          email: this.otpCodeEmail,
        },
        true,
        reCaptchaToken
      )
        .then(async (r) => {
          await this.$store.dispatch(LOGIN_USER, {
            phone: this.user.phone || "",
            accessToken: r.data.access_token,
            refreshToken: r.data.refresh_token,
          });

          await this.$router.push("/forgot-password/new-password");
        })
        .catch(() => {
          this.showErrorTip(this.$t("otp_error"));
        });
    },
    requestForgot() {
      AuthApi.resendOtp(this.user.email, OTP_TYPES.EMAIL, true)
          .then(() => {})
          .catch(() => {
            this.showErrorTip(this.$t("errors.not-found-email"));
          });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.otp-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  &__sub-title {
    margin-bottom: 20px;
  }

  &__otp-block {
    margin-bottom: 40px;
  }
}

.sign-up {
  &__form {
    &-otp {
      display: flex;
      justify-content: center;
    }
  }
}

.auth-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 486px) {
  .login-form {
    width: 100%;
    padding: 0;
    box-shadow: unset;
    display: flex;
    flex-direction: column;

    &__wrapper {
      width: 100%;
      flex-grow: 1;
    }
  }

  .sign-up {
    &__form {
      &-otp {
        flex-grow: 1;
      }
    }
  }

  .otp-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss">
.otp-input {
  margin-top: 0 !important;

  &__code {
    justify-content: center;
  }

  &__resend {
    margin-top: 72px;
  }
}

@media screen and (max-width: 486px) {
  .sign-up {
    &__form {
      &-otp {
        & > .otp-input {
          display: flex;
          flex-direction: column;

          &__code {
            width: 100%;
          }
        }
        .otp-input__resend {
          margin-top: auto;
        }
      }
    }
  }
}
</style>
