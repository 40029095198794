var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup"},[_c('div',{staticClass:"popup__block"},[_c('div',{staticClass:"p-details"},[_c('div',{staticClass:"p-details__full"},[_c('div',{staticClass:"p-details__section"},[_c('div',{staticClass:"p-details__section-header"},[_c('div',{staticClass:"popup__title"},[_vm._v(_vm._s(_vm.$t("change_password")))]),_c('popup-close-button',{on:{"click":_vm.closePopup}})],1),_c('div',{staticClass:"p-details__section-body"},[_c('div',{staticClass:"p-details__field"},[_c('password-input',{attrs:{"title":_vm.$t('old_password'),"show-forgot-password-link":false,"v-errors":_vm.v$.oldPassword.$errors},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1),_c('div',{staticClass:"p-details__field"},[_c('password-input',{attrs:{"title":_vm.$t('set_new_password'),"show-forgot-password-link":false,"v-errors":_vm.v$.newPassword.$errors},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('div',{staticClass:"p-details__field"},[_c('password-input',{attrs:{"title":_vm.$t('confirm_new_password'),"show-forgot-password-link":false,"v-errors":_vm.v$.confirmPassword.$errors},on:{"click":_vm.changePassword},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('div',{staticClass:"password-info"},[_c('div',{staticClass:"password-info__title"},[_vm._v(" "+_vm._s(_vm.$t("password_must_be"))+": ")]),_c('div',{staticClass:"password-info__list"},[_c('div',{staticClass:"password-info__item",class:{
                    active:
                      _vm.v$.checkPassword.required.$invalid === false &&
                      _vm.v$.checkPassword.minLength.$invalid === false,
                  }},[_c('div',{staticClass:"password-info__item-icon"},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z","fill":"#B7C9D3"}})])]),_c('div',{staticClass:"password-info__item-text"},[_vm._v(" "+_vm._s(_vm.$t("at_least_chars", { numChars: 8 }))+" ")])]),_c('div',{staticClass:"password-info__item",class:{
                    active:
                      _vm.v$.checkPassword.existLowerCase.$invalid === false,
                  }},[_c('div',{staticClass:"password-info__item-icon"},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z","fill":"#B7C9D3"}})])]),_c('div',{staticClass:"password-info__item-text"},[_vm._v(" "+_vm._s(_vm.$t("contain_lower"))+" ")])]),_c('div',{staticClass:"password-info__item",class:{
                    active:
                      _vm.v$.checkPassword.existUpperCase.$invalid === false,
                  }},[_c('div',{staticClass:"password-info__item-icon"},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z","fill":"#B7C9D3"}})])]),_c('div',{staticClass:"password-info__item-text"},[_vm._v(" "+_vm._s(_vm.$t("contain_upper"))+" ")])]),_c('div',{staticClass:"password-info__item",class:{
                    active: _vm.v$.checkPassword.existDigit.$invalid === false,
                  }},[_c('div',{staticClass:"password-info__item-icon"},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z","fill":"#B7C9D3"}})])]),_c('div',{staticClass:"password-info__item-text"},[_vm._v(" "+_vm._s(_vm.$t("contain_digit"))+" ")])]),_c('div',{staticClass:"password-info__item",class:{
                    active: _vm.v$.checkPassword.existSymbol.$invalid === false,
                  }},[_c('div',{staticClass:"password-info__item-icon"},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z","fill":"#B7C9D3"}})])]),_c('div',{staticClass:"password-info__item-text"},[_vm._v(" "+_vm._s(_vm.$t("contain_symbol"))+" ")])])])])])])])]),_c('div',{staticClass:"popup__actions"},[_c('no-bg-button',{staticClass:"popup__action",attrs:{"label":_vm.$t('cancel')},on:{"click":_vm.closePopup}}),_c('primary-button',{staticClass:"popup__action",attrs:{"label":_vm.$t('save')},on:{"click":_vm.changePassword}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }