<template>
  <div class="onboarding-layout">
    <logo to-link="/cards" :size="logoSize"></logo>
    <div class="onboarding-layout__inner">
      <slot />
      <router-link class="link-toMain" to="/cards">{{
        $t("close_card_setup")
      }}</router-link>
      <div class="support">
        <a href="https://t.me/WeldSupport_Bot" target="_blank">
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 0C8.5234 0 0 8.5234 0 19V26.8717C0 28.8173 1.7043 30.4 3.8 30.4H5.7C6.20391 30.4 6.5 30 6.5 30C6.5 29.5 6.5 29.5039 6.5 29V24V19C6.5 18.4961 6.5 18.7071 6.5 18C6.5 17.5 5.91016 16.8283 5.40625 16.8283H2.5C3.05559 12.9611 5.21737 9.19844 8.5 6.5C11.4589 4.06764 15.3773 2.5 19 2.5C22.7294 2.5 26.997 3.94419 30 6.5C33.1503 9.18115 34.9592 13.0638 35.5 16.8283H33.5C32.9961 16.8283 32.3563 17.0285 32 17.3848C31.6437 17.7411 31.5 18.4961 31.5 19V24.5V30.5C31.5 32.5957 29.0957 35.5 27 35.5H22.8V33.5H19H15.2V38H26.6C30.7914 38 34.2 34.5914 34.2 30.4C36.2957 30.4 38 28.8173 38 26.8717V19C38 8.5234 29.4766 0 19 0Z"
              fill="#7A939C"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/base/Logo";
import responsiveMixin from "@/mixins/responsive-mixin";

export default {
  name: "OnboardingLayout",
  components: { Logo },
  mixins: [responsiveMixin],
  computed: {
    logoSize: function () {
      return this.windowWidth > 992 ? "big" : "small";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
.onboarding-layout {
  padding: 56px 70px;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }

  .logo {
    margin-bottom: 61px;
    position: absolute;
    top: 56px;
    left: 70px;
    z-index: 9;
  }
}
.link-toMain {
  position: absolute;
  bottom: 0;
  left: 106px;

  font-style: normal;
  font-weight: 700;
  font-size: pxToRem(16);
  line-height: pxToRem(20);
  text-decoration: none;

  color: $gray2;
}

.support {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 992px) {
  .onboarding-layout {
    padding: 60px 30px 30px;

    .logo {
      position: relative;
      margin-bottom: 30px;
      top: unset;
      left: unset;
    }
  }

  .link-toMain {
    display: none;
  }
  .support a svg {
    width: 22px;
    height: 22px;
    position: absolute;
    top: -70px;
    right: 50px;
  }
}

@media screen and (max-width: 786px) {
  .onboarding-layout {
    background-color: #ffffff;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    padding-bottom: 90px !important;

    &__inner {
      width: 100%;
      min-height: 100%;

      align-items: flex-start;
    }
  }

  .link-toMain {
    display: none;
  }
  .support a svg {
    width: 22px;
    height: 22px;
    position: absolute;
    top: -70px;
    right: 50px;
  }
}
</style>
