<template>
  <div class="onboarding-loader">
    <div class="onboarding-loader__icon" v-html="icon" v-if="icon"></div>
    <div
      ref="lottieLoader"
      class="onboarding-loader__lottie"
      v-html="icon"
      v-else-if="lottieJson"
    ></div>
    <div class="onboarding-loader__title" v-html="title"></div>
    <div
      class="onboarding-loader__sub-title"
      :class="{ 'onboarding-loader__sub-title--link': closeOnSubTitle }"
      @click="processSubTitleClick"
      v-html="subTitle"
    ></div>
  </div>
</template>

<script>
import lottie from "lottie-web";

export default {
  name: "OnboardingLoader",
  props: {
    icon: String,
    lottieJson: {
      type: Object,
      default: null,
    },
    title: String,
    subTitle: String,
    closeOnSubTitle: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.lottieJson) {
      lottie.loadAnimation({
        container: this.$refs.lottieLoader,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: this.lottieJson,
      });
    }
  },
  methods: {
    processSubTitleClick() {
      if (this.closeOnSubTitle) {
        window.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_mixins.scss";
@import "~@/assets/scss/_variables.scss";

.onboarding-loader {
  background-color: $grayLight;

  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;

  padding: 16px;

  &__icon {
    height: 178px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;

    svg {
      max-height: 100%;
    }
  }

  &__lottie {
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    svg {
      max-height: 100%;
    }
  }

  &__title {
    font-weight: bold;
    font-size: pxToRem(30);
    line-height: pxToRem(36);
    text-align: center;
    color: $mainBlack;
    margin-bottom: 36px;
  }

  &__sub-title {
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: $gray1;
    text-align: center;

    &--link {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
