<template>
  <div class="card" v-if="card.status === 'active'">
    <div class="card__wrap" :class="{ selected: sCard.id === card.id }">
      <div
        class="card__item-img"
        :class="{ 'card-item--pending': card.status === 'pending' }"
        :style="{
          'background-image': `url(/img/cards/${card.card_type.background_image})`,
        }"
        @click="selectedCard"
      >
        <div class="off" v-if="sCard.id !== card.id"></div>
      </div>
      <div>
        <div class="card__item-bank">
          {{ hiddenPan }} {{ card.card_type.name }}
        </div>
        <div class="card__item-balance">{{ card.unex_balance / 100 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardItemMobile",
  props: {
    sCard: {
      type: Object,
      default() {
        return {};
      },
    },
    card: {
      type: Object,
    },
  },
  data() {
    return {
      isShowCardInfoPopup: false,
    };
  },
  inject: ["showSuccessTip"],
  computed: {
    hiddenPan: function () {
      return this.card.status === "active"
        ? `• • • • ${this.card.pan.slice(-4)}`
        : "";
    },
  },
  methods: {
    copyPanToClipboard() {
      if (!navigator.clipboard) {
        return;
      }

      navigator.clipboard.writeText(this.card.pan).then(
        () => {
          this.showSuccessTip(this.$t("card.item.pan-copied"));
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    showCardInfoPopup() {
      this.isShowCardInfoPopup = true;
    },
    closeCardInfoPopup() {
      this.isShowCardInfoPopup = false;
    },
    selectedCard() {
      this.$emit("selectedCard", this.card);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
.card {
  position: relative;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 1px solid rgba(183, 201, 211, 1);

  &__wrap {
    display: flex;
    justify-content: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &__item {
    &-img {
      width: 62px;
      height: 40px;
      margin-right: 16px;
      background: red;
      background-size: cover;
    }
    &-bank {
      font-style: normal;
      font-weight: 500;
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $mainBlack;
    }
    &-balance {
      font-style: normal;
      font-weight: 700;
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $mainBlack;
    }
  }
}

div.card:last-child {
  border-bottom: 1px solid rgba(183, 201, 211, 1);
}

.off {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #fff, $alpha: 0.4);
  cursor: pointer;
}

.selected {
  background-color: rgba(0, 183, 189, 0.05);
}
</style>
