<template>
  <div class="card-transaction-date card">
    <div
      class="card-transaction-first"
      @click="
        openSecondLine();
        showCardInfoPopup();
      "
    >
      <div class="card-transaction-first--info">
        <div class="card-transaction-first--arrow">
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.2929 0.292892C12.6834 -0.0976319 13.3166 -0.0976319 13.7071 0.292892C14.0976 0.683416 14.0976 1.31658 13.7071 1.70711L12.2929 0.292892ZM7 7L7.70711 7.70711L7 8.41421L6.29289 7.70711L7 7ZM0.292893 1.70711C-0.0976317 1.31658 -0.0976317 0.683417 0.292893 0.292893C0.683417 -0.0976314 1.31658 -0.0976314 1.70711 0.292893L0.292893 1.70711ZM13.7071 1.70711L7.70711 7.70711L6.29289 6.29289L12.2929 0.292892L13.7071 1.70711ZM6.29289 7.70711L0.292893 1.70711L1.70711 0.292893L7.70711 6.29289L6.29289 7.70711Z"
              fill="#7A939C"
            />
          </svg>
        </div>
        <div class="card-transaction-first--icon">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
              fill="#EB6FBD"
            />
            <circle cx="16" cy="16" r="15.5" fill="#EB6FBD" stroke="#EB6FBD" />
            <path
              d="M9.54036 8.95312C8.04703 8.95312 6.83203 10.1681 6.83203 11.6615V18.7448C6.83203 20.2381 8.04703 21.4531 9.54036 21.4531H13.6859L14.6497 20.1857L9.54036 20.2031C8.73619 20.2031 8.08203 19.5489 8.08203 18.7448V13.9531H23.9153V16.9322L25.1653 18.9531V11.6615C25.1653 10.1681 23.9503 8.95312 22.457 8.95312H9.54036ZM9.54036 10.2031H22.457C23.2611 10.2031 23.9153 10.8573 23.9153 11.6615V12.2864H8.08203V11.6615C8.08203 10.8573 8.73619 10.2031 9.54036 10.2031ZM10.3737 17.2864C10.2909 17.2853 10.2086 17.3006 10.1318 17.3314C10.0549 17.3623 9.98498 17.4082 9.926 17.4663C9.86702 17.5245 9.82018 17.5938 9.78822 17.6702C9.75625 17.7466 9.73979 17.8286 9.73979 17.9114C9.73979 17.9943 9.75625 18.0763 9.78822 18.1527C9.82018 18.2291 9.86702 18.2984 9.926 18.3565C9.98498 18.4147 10.0549 18.4605 10.1318 18.4914C10.2086 18.5223 10.2909 18.5376 10.3737 18.5364H12.8737C12.9565 18.5376 13.0387 18.5223 13.1156 18.4914C13.1924 18.4605 13.2624 18.4147 13.3214 18.3565C13.3803 18.2984 13.4272 18.2291 13.4591 18.1527C13.4911 18.0763 13.5076 17.9943 13.5076 17.9114C13.5076 17.8286 13.4911 17.7466 13.4591 17.6702C13.4272 17.5938 13.3803 17.5245 13.3214 17.4663C13.2624 17.4082 13.1924 17.3623 13.1156 17.3314C13.0387 17.3006 12.9565 17.2853 12.8737 17.2864H10.3737Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.1641 15.6211C19.7249 15.6211 19.3181 15.8515 19.0923 16.2282L15.3423 22.4782C15.1106 22.8644 15.1044 23.3454 15.326 23.7371C15.5481 24.1288 15.9637 24.3711 16.4141 24.3711H23.9141C24.3645 24.3711 24.78 24.1288 25.0021 23.7371C25.2238 23.3454 25.2175 22.864 24.9858 22.4782L21.2358 16.2282C21.01 15.8515 20.6032 15.6211 20.1641 15.6211ZM20.164 17.2878C20.394 17.2878 20.5807 17.4744 20.5807 17.7044V21.0377C20.5807 21.2677 20.394 21.4544 20.164 21.4544C19.934 21.4544 19.7474 21.2677 19.7474 21.0377V17.7044C19.7474 17.4744 19.934 17.2878 20.164 17.2878ZM20.164 22.1836C20.4515 22.1836 20.6849 22.4169 20.6849 22.7044C20.6849 22.9919 20.4515 23.2252 20.164 23.2252C19.8765 23.2252 19.6432 22.9919 19.6432 22.7044C19.6432 22.4169 19.8765 22.1836 20.164 22.1836Z"
              fill="white"
            />
          </svg>
        </div>
        <div>
          <div class="card-transaction-first--title">
            {{ item.merchant_name }}
          </div>
          <div class="card-transaction-first--type">
            {{ $t("card.transaction.not-many") }}
          </div>
        </div>
      </div>
      <div class="card-transaction-first--value">
        {{
          `${item.is_unex_direct_outcom ? "-" : ""}${
            (item.origin_transaction_amount || 0) / 100
          }`
        }}
        {{ getCurrencySymbol(item.origin_transaction_currency) }}
      </div>
    </div>
    <div class="card-transaction-second" v-if="openLine && !isMobile">
      <div class="card-transaction-second-svg">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5C10.7348 5 10.4804 5.10536 10.2929 5.29289C10.1054 5.48043 10 5.73478 10 6V12C10 12.2652 10.1054 12.5196 10.2929 12.7071C10.4804 12.8946 10.7348 13 11 13C11.2652 13 11.5196 12.8946 11.7071 12.7071C11.8946 12.5196 12 12.2652 12 12V6ZM12 15.5C12 15.2348 11.8946 14.9804 11.7071 14.7929C11.5196 14.6054 11.2652 14.5 11 14.5C10.7348 14.5 10.4804 14.6054 10.2929 14.7929C10.1054 14.9804 10 15.2348 10 15.5V16C10 16.2652 10.1054 16.5196 10.2929 16.7071C10.4804 16.8946 10.7348 17 11 17C11.2652 17 11.5196 16.8946 11.7071 16.7071C11.8946 16.5196 12 16.2652 12 16V15.5Z"
            fill="#EB6FBD"
          />
        </svg>
      </div>
      <div class="card-transaction-second-text">
        {{ /*item.unex_status_comment*/ }}
        {{ $t(`card.transaction.errorCods.${item.unex_status}`) }}
      </div>
    </div>
    <CardItemTransactionUnsuccessfullyPopup
      v-if="isShowCardInfoPopup && isMobile"
      :transaction="item"
      @closed="closeCardInfoPopup"
    ></CardItemTransactionUnsuccessfullyPopup>
  </div>
</template>

<script>
import moment from "moment";
import responsiveMixin from "@/mixins/responsive-mixin";
import CardItemTransactionUnsuccessfullyPopup from "@/components/profile/Cards/Popup/CardItemTransactionUnsuccessfullyPopup";

export default {
  name: "CardTransactionItemUnsuccessfully",
  mixins: [responsiveMixin],
  inject: ["getCurrencySymbol"],
  components: {
    CardItemTransactionUnsuccessfullyPopup,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      openLine: false,
      isShowCardInfoPopup: false,
    };
  },
  computed: {
    transactionDate: function () {
      return moment(this.item.created_at).format("YYYY-MM-DD");
    },
    transactionTime: function () {
      return moment(this.item.created_at).format("HH:mm");
    },
  },
  methods: {
    openSecondLine() {
      this.openLine = !this.openLine;
    },

    showCardInfoPopup() {
      this.isShowCardInfoPopup = true;
    },
    closeCardInfoPopup() {
      console.log("444");
      this.isShowCardInfoPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.card-transaction {
  background: #fff;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  &-date {
    padding: 10px 0;
    border-top: 1px solid #e7eef5;
    margin-bottom: 0;

    &.card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    &.card:hover {
      background-color: rgba(0, 183, 189, 0.05);
    }

    &.card:hover .card-transaction-first--arrow svg {
      display: block;
    }
  }
  &-first {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 88px 12px 32px;

    &--info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 36px;
    }

    &--arrow {
      width: 50px;

      svg {
        display: none;
      }
    }
    &--icon {
      margin-right: 24px;
      width: 24px;
      height: 24px;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &--title {
      font-style: normal;
      font-weight: 700;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      color: $mainBlack;
      margin-bottom: 4px;
    }

    &--type {
      font-style: normal;
      font-weight: 500;
      font-size: pxToRem(16);
      line-height: pxToRem(24);
      color: #eb6fbd;
    }

    &--value {
      padding-right: 36px;

      font-style: normal;
      font-weight: 700;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      text-align: right;
      color: #eb6fbd;
      text-decoration: line-through;
    }
  }
  &-second {
    width: 100%;
    padding: 34px 32px 34px 170px;
    display: flex;
    background-color: #fff;

    &-svg {
      width: 22px;
      height: 22px;
      margin-right: 9px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: pxToRem(16);
      line-height: pxToRem(24);
      color: #eb6fbd;
    }
  }
  .second__line {
    display: flex;
    justify-content: space-between;

    &-info {
      width: 465px;
    }

    &-wrap {
      margin-bottom: 18px;
    }

    &-title {
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: pxToRem(16);
      line-height: pxToRem(20);
      color: $mainBlack;
      margin-bottom: 6px;

      span {
        font-weight: 700;
        margin-left: 8px;

        svg {
          cursor: pointer;
        }
      }

      &.green {
        color: $mainCold;
      }
    }

    &-subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $gray1;
    }
  }
}

@media screen and (max-width: 486px) {
  .card-transaction {
    &-date {
      &.card {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    &-first {
      padding: 12px 16px 16px;

      &--info {
        padding-left: 0;
      }

      &--value {
        padding-right: 0;
      }

      &--arrow {
        display: none !important;
      }
    }
  }
}
</style>
