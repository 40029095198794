export const videoGuides = {
  whitebit: {
    api_keys: {
      uk: "https://youtu.be/ESRpqif-jY0",
      en: "https://www.youtube.com/watch?v=wEOthNJv6HE",
    },
    whitelist: {
      uk: "https://youtu.be/3OsZDcawMzc",
      en: "https://www.youtube.com/watch?v=-u8YZFzNG5A",
    },
  },
  huobi: {
    web: {
      api_keys: {
        uk: "https://youtu.be/1ZPpgYxzYoI",
        en: "https://www.youtube.com/watch?v=ek_Ex4RbE44",
      },
      whitelist: {
        uk: "https://youtu.be/UmZFq3zJj2U",
        en: "https://www.youtube.com/watch?v=N2wy8bER6pk",
      },
    },
    mob: {
      api_keys: {
        uk: "https://youtu.be/Xr6dfy8nbCE",
        en: "https://www.youtube.com/watch?v=P2ac37x3f5g",
      },
      whitelist: {
        uk: "https://youtu.be/PHSyGmWioLA",
        en: "https://www.youtube.com/watch?v=cHgymZ9-yx4",
      },
    },
  },
  exmo: {
    api_keys: {
      uk: "https://www.youtube.com/watch?v=gQXbjEi4C1g",
      en: "https://www.youtube.com/watch?v=tl0n1e7ij4w",
    },
  },
};
