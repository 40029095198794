<template>
  <div class="card-product" @click="emitClick">
    <div class="card-product--active">
      <div class="card-product__icon" v-html="cardProduct.svg"></div>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM4.56516 6.65845L6.13715 8.3391L9.17815 4.58527C9.35198 4.3707 9.66683 4.33767 9.8814 4.51149C10.096 4.68531 10.129 5.00017 9.95518 5.21474L6.77161 9.14454C6.4649 9.52315 5.89437 9.54345 5.56152 9.1876L3.83484 7.34155C3.6462 7.13988 3.65678 6.82347 3.85845 6.63484C4.06012 6.44621 4.37653 6.45678 4.56516 6.65845Z"
          fill="#00B7BD"
        />
      </svg>
    </div>
    <div class="card-product__name">
      {{ cardProduct.name }}
    </div>
    <div class="card-product__sub-title" v-if="cardProduct.subTitle">
      {{ cardProduct.subTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CardProductItem",
  props: {
    cardProduct: Object,
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.card-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: grayscale(1);
  transition: filter 0.2s linear;
  cursor: pointer;

  &:hover {
    filter: grayscale(0);
  }

  &__icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    //border-radius: 20px;

    &::v-deep {
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__name {
    color: $mainBlack;
    font-size: pxToRem(14);
    line-height: pxToRem(18);
  }

  div.card-product--active {
    position: relative;
    width: 68px;
    height: 68px;
    border-radius: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*overflow: hidden;*/
    border: 2px solid transparent;
    margin-bottom: 8px;

    svg {
      display: none;
    }
  }

  &.active div.card-product--active {
    border: 2px solid $mainCold;

    svg {
      position: absolute;
      display: block;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border: 2px solid #fff;
      border-radius: 9px;
      bottom: 0;
      right: 0;
    }
  }

  &__sub-title {
    color: $gray1;
    font-size: pxToRem(14);
    line-height: pxToRem(18);
  }
}
</style>
