<template>
  <div class="unex-otp__phone">
    <base-loader v-if="isDataLoading"></base-loader>
    <div class="unex-otp__hints">
      <div class="unex-otp__hint">
        <span v-html="$t('unex.onboarding.otp.hint.enter_phone')"></span>
      </div>
      <div class="unex-otp__hint">
        <span v-html="$t('unex.onboarding.otp.hint.click_get_code')"></span>
      </div>
    </div>
    <phone-input
      class="unex-otp__phone-el"
      v-model="userPhone"
      :initial-original-phone="userData.original_phone"
      :initial-country="userData.country"
      :initial-country-code="userData.country_code"
      :v-errors="v$.userPhone.$errors"
    ></phone-input>
    <primary-button
      :label="$t('unex.onboarding.otp.button.send_otp')"
      :disabled="isDataLoading"
      @click="sendOtp"
    ></primary-button>
  </div>
</template>

<script>
import PhoneInput from "@/components/input/Phone/PhoneInput";
import PrimaryButton from "@/components/button/PrimaryButton";
import { mapGetters } from "vuex";
import BaseLoader from "@/components/base/BaseLoader";
import { UnexOnboardingApi } from "@/office/unex-onboarding-api";
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength, required } from "@vuelidate/validators";
export default {
  name: "UnexOTPPhoneStep",
  components: { BaseLoader, PrimaryButton, PhoneInput },
  data() {
    return {
      userPhone: null,
      isDataLoading: false,
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      userPhone: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(20),
      },
    };
  },
  computed: {
    ...mapGetters(["userData"]),
  },
  created() {
    this.userPhone = this.userData.cell_phone;

    console.log(this.userData.cell_phone);
  },
  methods: {
    async sendOtp() {
      let validationResult = await this.v$.$validate();

      if (!validationResult) {
        return false;
      }

      try {
        this.isDataLoading = true;
        let otpResponse = await UnexOnboardingApi.sendOtp(this.userPhone);
        console.log("otpResponse", otpResponse);
        this.$emit("codeSent", this.userPhone, otpResponse.data.operationID);
      } catch (e) {
        console.log("otp send error", e);
      } finally {
        this.isDataLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unex-otp {
  &__phone & {
    &__hints {
      margin-bottom: 90px;
    }
  }

  &__phone {
    &-el {
      margin-bottom: 85px;
    }
  }
}
</style>
