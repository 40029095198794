<template>
  <div class="unex-otp__code">
    <div class="unex-otp__hints">
      <div class="unex-otp__hint">
        <span
          v-html="
            $t('unex.onboarding.otp.hint.enter_otp', {
              phoneNumber: this.phone,
            })
          "
        ></span>
      </div>
    </div>
    <w-otp-input
      class="unex-otp__code-el"
      hidden-label
      v-model="otpCode"
      :v-errors="v$.otpCode.$errors"
      :resend-method="sendOtp"
    ></w-otp-input>
    <primary-button
      :label="$t('unex.onboarding.otp.button.check_otp')"
      @click="checkOtp"
    ></primary-button>
  </div>
</template>

<script>
import PrimaryButton from "@/components/button/PrimaryButton";
import WOtpInput from "@/components/input/WOtpInput";
import { UnexOnboardingApi } from "@/office/unex-onboarding-api";
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength, required } from "@vuelidate/validators";

export default {
  name: "UnexOTPCodeStep",
  components: { WOtpInput, PrimaryButton },
  inject: ["showErrorTip", "getCardProductId"],
  props: {
    phone: {
      type: String,
      default: null,
    },
    operationId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      otpCode: {
        required,
        minValue: minLength(4),
        maxLength: maxLength(6),
      },
    };
  },
  data() {
    return {
      otpCode: null,
      isDataLoading: false,
      otpOperationIdValue: null,
    };
  },
  computed: {},
  created() {
    this.otpOperationIdValue = this.operationId;
  },
  methods: {
    async checkOtp() {
      let validationResult = await this.v$.$validate();

      if (!validationResult) {
        return false;
      }

      this.isDataLoading = true;

      try {
        await UnexOnboardingApi.checkOtp(
          this.otpOperationIdValue,
          this.phone,
          this.otpCode,
          this.getCardProductId()
        );

        this.$emit("otpChecked");
      } catch (e) {
        this.showErrorTip(this.$t("unex.onboarding.otp.errors.otp-invalid"));
        console.log("opt check error", e);
      } finally {
        this.isDataLoading = false;
      }
    },
    async sendOtp() {
      try {
        this.isDataLoading = true;
        let otpResponse = await UnexOnboardingApi.sendOtp(this.phone);
        console.log("otpResponse", otpResponse);
        this.otpOperationIdValue = otpResponse.data.operationID;
      } catch (e) {
        console.log("otp send error", e);
      } finally {
        this.isDataLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unex-otp {
  &__code & {
    &__hints {
      margin-bottom: 75px;
    }
  }

  &__code {
    &-el {
      margin-bottom: 30px;
    }
  }
}
</style>
