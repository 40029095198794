<template>
  <div class="auth-layout">
    <logo :size="logoSize"></logo>
    <div class="auth-layout__inner">
      <slot />
      <div class="locale-switcher">
        <div
          class="locale-switcher__item"
          v-for="locale in availableLocales"
          :key="`locale-${locale.key}`"
          @click="changeLocale(locale.key)"
          :class="{ active: selectedLocale === locale.key }"
        >
          {{ locale.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/base/Logo";
import responsiveMixin from "@/mixins/responsive-mixin";
import { mapState } from "vuex";
import { SET_CURRENT_LOCALE } from "@/store/const/actions-types";

export default {
  name: "AuthLayout",
  components: { Logo },
  mixins: [responsiveMixin],
  data() {
    return {
      tipText: "",
      isShowSuccessTip: false,
      isShowErrorTip: false,
      availableLocales: [
        {
          key: "uk",
          label: "UA",
        },
        // { key: "ru", label: "RU" },
        { key: "en", label: "EN" },
      ],
    };
  },
  computed: {
    logoSize: function () {
      return this.windowWidth > 486 ? "big" : "small";
    },
    ...mapState(["selectedLocale"]),
  },
  methods: {
    changeLocale(locale) {
      this.$store.dispatch(SET_CURRENT_LOCALE, locale);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
.auth-layout {
  padding: 56px 70px;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__inner {
    flex-grow: 1;
    height: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    margin-bottom: 61px;
    position: absolute;
    top: 56px;
    left: 70px;
  }

  .success-tip,
  .error-tip {
    transform: translateX(-50%);
  }
}

.locale-switcher {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 30px;
  left: 30px;

  &__item {
    margin: 0 10px;
    color: $mainCold;
    transition: opacity 0.2s linear;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.active {
      font-weight: 700;

      &:hover {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .auth-layout {
    padding: 30px;
    padding-bottom: 100px !important;

    .logo {
      top: 30px;
      left: 30px;
    }
  }
}

@media screen and (max-width: 486px) {
  .auth-layout {
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .logo {
      position: relative;
      margin-bottom: 40px;
      top: unset;
      left: unset;
    }

    &__inner {
      width: 100%;
      align-items: flex-start;
      flex-direction: column;
    }

    .success-tip {
      &--mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--mobile & {
        &__backdrop {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(51, 63, 72, 0.2);
          z-index: 98;
        }

        &__block {
          width: 270px;
          background-color: #ffffff;
          z-index: 99;
          border-radius: 8px;
        }

        &__content {
          width: 100%;
          padding: 16px;
          border-bottom: 1px solid $gray2;
          font-size: pxToRem(16);
          line-height: pxToRem(20);
          font-weight: 700;
          text-align: center;
        }

        &__action {
          width: 100%;
          text-align: center;
          color: $mainCold;
          font-size: pxToRem(16);
          line-height: pxToRem(20);
          font-weight: 700;
          padding: 10px;
        }
      }
    }

    .error-tip {
      &--mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--mobile & {
        &__backdrop {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(51, 63, 72, 0.2);
          z-index: 98;
        }

        &__block {
          width: 270px;
          background-color: #ffffff;
          z-index: 99;
          border-radius: 8px;
        }

        &__content {
          width: 100%;
          padding: 16px;
          border-bottom: 1px solid $gray2;
          font-size: pxToRem(16);
          line-height: pxToRem(20);
          font-weight: 700;
          text-align: center;
        }

        &__action {
          width: 100%;
          text-align: center;
          color: $mainHot;
          font-size: pxToRem(16);
          line-height: pxToRem(20);
          font-weight: 700;
          padding: 10px;
        }
      }
    }
  }

  .locale-switcher {
    position: relative;
    left: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
