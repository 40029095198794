<template>
  <div class="button-popover">
    <v-popover
      placement="top"
      offset="3"
      trigger="manual"
      :open="isStatusTooltipOpen"
      @auto-hide="closeStatusTooltipHandler"
    >
      <img
        class="button-popover-trigger"
        @click="toggleStatusTooltip"
        src="@/assets/img/tooltipInfo.svg"
        :alt="tooltipContent"
      />

      <template v-slot:popover>
        <tooltip-content-with-button @closeTooltip="closeStatusTooltipHandler">
          <span>{{ tooltipContent }}</span>
        </tooltip-content-with-button>
      </template>
    </v-popover>
  </div>
</template>

<script>
import TooltipContentWithButton from "@/components/base/tooltip/TooltipContentWithButton";

export default {
  name: "PopoverWithCloseButton",
  components: { TooltipContentWithButton },
  props: {
    tooltipContent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isStatusTooltipOpen: false,
    };
  },
  methods: {
    toggleStatusTooltip() {
      this.isStatusTooltipOpen = !this.isStatusTooltipOpen;
    },
    closeStatusTooltipHandler() {
      this.isStatusTooltipOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
.button-popover {
  &-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
</style>
