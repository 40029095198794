import axiosInstance from "@/services/http/axios-instance";

class _ExchangeApi {
  getAllExchanges() {
    return axiosInstance.get("/exchanges");
  }

  getMyExchanges() {
    return axiosInstance.get("/user/exchanges");
  }

  addExchange(exchangeId, exchangeData) {
    return axiosInstance.post("/user/exchanges/create", {
      exchange_id: exchangeId,
      exchange_data: exchangeData,
    });
  }

  getExchangeCurrencies(exchangeId, exchangeData) {
    return axiosInstance.post("/exchanges/currencies", {
      exchange_id: exchangeId,
      exchange_data: exchangeData,
    });
  }
}

export const ExchangeApi = new _ExchangeApi();
