import Vue from "vue";
import Vuex from "vuex";
import axiosInstance from "@/services/http/axios-instance";
import * as at from "@/store/const/actions-types";
import { UserApi } from "@/office/user-api";
import Web3 from "web3";
import i18n from "@/lang/i18n";
import { SUPPORT_URL } from "@/const/config";

Vue.use(Vuex);

const selectedLocale = localStorage.getItem("selectedLocale") || "uk";
window.zE("webWidget", "setLocale", i18n.locale);

export default new Vuex.Store({
  state: {
    user: {
      accessToken: "",
      refreshToken: "",
      phone: "",
      email: "",
      password: "",
      countryCode: "",
      isLoggedIn: false,
      parentReferralUUID: null,
      data: {},
      activeStory: null,
      avatar_url: null,
    },
    appState: null,
    wss: null,
    crypto: {
      web3: null,
      accounts: [],
      currentChainId: null,
    },
    selectedLocale,
    agreement: {
      termId: null,
      paragraphs: [],
    },
    activeCardProduct: null,
    unexOnboardingError: {
      title: null,
      subTitle: null,
    },
    supportLink: SUPPORT_URL
  },
  getters: {
    isUserLoggedIn: (state) => state.user.isLoggedIn,
    userData: (state) => state.user.data,
    avatarUrl: (state) => state.user.avatar_url,
    web3Instance: (state) => state.crypto.web3,
    parentReferralUUID: (state) => state.user.parentReferralUUID,
    activeStory: (state) => state.user.activeStory,
    supportLink: (state) => state.supportLink
  },
  mutations: {
    [at.LOGIN_USER](state, payload) {
      state.user.accessToken = payload.accessToken;
      state.user.refreshToken = payload.refreshToken;
      state.user.phone = payload.phone;
      state.user.isLoggedIn = true;
    },
    [at.UPDATE_TOKENS](state, tokens) {
      state.user.accessToken = tokens.accessToken;
      state.user.refreshToken = tokens.refreshToken;
    },
    [at.LOGOUT_USER](state) {
      state.user.accessToken = null;
      state.user.refreshToken = null;
      state.user.isLoggedIn = false;
    },
    [at.SET_CURRENT_LOCALE](state, locale) {
      state.selectedLocale = locale;
      i18n.locale = state.selectedLocale;
      window.zE("webWidget", "setLocale", i18n.locale);
    },
    [at.SET_USER_PHONE](state, phone) {
      state.user.phone = phone;
    },
    [at.SET_USER_ORIGINAL_PHONE](state, phone) {
      6;
      state.user.originalPhone = phone;
    },
    [at.SET_USER_EMAIL](state, email) {
      state.user.email = email;
    },
    [at.SET_USER_PASSWORD](state, password) {
      state.user.password = password;
    },
    [at.SET_USER_COUNTRY_CODE](state, countryCode) {
      state.user.countryCode = countryCode;
    },
    [at.SET_USER_COUNTRY](state, country) {
      state.user.country = country;
    },
    [at.SET_APPLICATION_STATE](state, appState) {
      state.appState = appState;
    },
    [at.LOAD_USER_DATA](state, userData) {
      state.user.data = userData;
    },
    [at.SET_USER_AVATAR](state, avatarUrl) {
      state.user.avatar_url = avatarUrl;
    },
    [at.SET_WSS_CONNECTION](state, wss) {
      state.wss = wss;
    },
    [at.SET_WEB3_INSTANCE](state, web3Instance) {
      state.crypto.web3 = web3Instance;
    },
    [at.SET_PARENT_REFERRAL_UUID](state, parentReferralUUID) {
      state.user.parentReferralUUID = parentReferralUUID;
    },
    [at.SET_AVAILABLE_CRYPTO_ACCOUNT](state, accounts) {
      state.crypto.accounts = accounts;
    },
    [at.SET_CURRENT_CRYPTO_CHAIN_ID](state, chainId) {
      state.crypto.currentChainId = chainId;
    },
    [at.SET_AGREEMENT](state, agreementData) {
      state.agreement.termId = agreementData.termId;
      state.agreement.paragraphs = agreementData.paragraphs;
    },
    [at.SET_ACTIVE_STORY](state, activeStory) {
      state.user.activeStory = activeStory;
    },
    [at.SET_ACTIVE_CARD_PRODUCT](state, cardProduct) {
      state.activeCardProduct = cardProduct;
    },
    [at.SET_UNEX_ONBOARDING_ERROR](state, errorObj) {
      state.unexOnboardingError.title = errorObj.title;
      state.unexOnboardingError.subTitle = errorObj.subTitle;
    },
    [at.CLEAR_UNEX_ONBOARDING_ERROR](state) {
      state.unexOnboardingError.title = null;
      state.unexOnboardingError.subTitle = null;
    },
    [at.SET_SUPPORT_LINK](state, supportLink) {
      state.supportLink = supportLink;
    },
  },
  actions: {
    async [at.LOGIN_USER]({ commit, dispatch }, payload) {
      localStorage.setItem("accessToken", payload.accessToken);
      localStorage.setItem("phone", payload.phone);
      localStorage.setItem("refreshToken", payload.refreshToken);

      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${payload.accessToken}`;

      commit(at.LOGIN_USER, payload);

      if(!payload.isRegister) {
        await dispatch(at.LOAD_USER_DATA);
      }
    },
    [at.UPDATE_TOKENS]({ commit }, tokens) {
      localStorage.setItem("accessToken", tokens.accessToken);
      localStorage.setItem("refreshToken", tokens.refreshToken);
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${tokens.accessToken}`;

      commit(at.UPDATE_TOKENS, tokens);
    },
    [at.LOGOUT_USER]({ commit }) {
      localStorage.setItem("accessToken", "");
      localStorage.setItem("username", "");
      localStorage.setItem("phone", "");

      delete axiosInstance.defaults.headers.common["Authorization"];
      commit(at.LOGOUT_USER);
    },
    [at.SET_CURRENT_LOCALE]({ commit }, locale) {
      localStorage.setItem("selectedLocale", locale);
      commit(at.SET_CURRENT_LOCALE, locale);
    },
    [at.SET_USER_PHONE]({ commit }, phone) {
      localStorage.setItem("phone", phone);
      commit(at.SET_USER_PHONE, phone);
    },
    [at.SET_USER_ORIGINAL_PHONE]({ commit }, phone) {
      localStorage.setItem("originalPhone", phone);
      commit(at.SET_USER_ORIGINAL_PHONE, phone);
    },
    [at.SET_USER_EMAIL]({ commit }, email) {
      localStorage.setItem("email", email);
      commit(at.SET_USER_EMAIL, email);
    },
    [at.SET_USER_PASSWORD]({ commit }, password) {
      commit(at.SET_USER_PASSWORD, password);
    },
    [at.SET_USER_COUNTRY_CODE]({ commit }, countryCode) {
      localStorage.setItem("countryCode", countryCode);
      commit(at.SET_USER_COUNTRY_CODE, countryCode);
    },
    [at.SET_USER_COUNTRY]({ commit }, country) {
      localStorage.setItem("country", country);
      commit(at.SET_USER_COUNTRY, country);
    },
    [at.SET_USER_AVATAR]({ commit }, avatarUrl) {
      localStorage.setItem("avatar_url", avatarUrl);
      commit(at.SET_USER_AVATAR, avatarUrl);
    },
    [at.SET_APPLICATION_STATE]({ commit }, appState) {
      commit(at.SET_APPLICATION_STATE, appState);
    },
    async [at.LOAD_USER_DATA]({ commit }) {
      await UserApi.getProfileData()
        .then((r) => {
          commit(at.LOAD_USER_DATA, r.data.user);
          commit(at.SET_USER_AVATAR, r.data.user.avatar_url);
        })
        .catch((err) => console.log(err));
    },
    [at.SET_WSS_CONNECTION]({ commit }, wss) {
      commit(at.SET_WSS_CONNECTION, wss);
    },
    async [at.SET_WEB3_INSTANCE]({ commit }, web3Provider) {
      const _web3 = new Web3(web3Provider);

      if (_web3.givenProvider.isConnected()) {
        commit(at.SET_AVAILABLE_CRYPTO_ACCOUNT, await _web3.eth.getAccounts());
        commit(at.SET_CURRENT_CRYPTO_CHAIN_ID, await _web3.eth.getChainId());
      }

      _web3.givenProvider.on("connect", async () => {
        commit(at.SET_AVAILABLE_CRYPTO_ACCOUNT, await _web3.eth.getAccounts());
        commit(at.SET_CURRENT_CRYPTO_CHAIN_ID, await _web3.eth.getChainId());
      });

      _web3.givenProvider.on("accountsChanged", (accounts) => {
        commit(at.SET_AVAILABLE_CRYPTO_ACCOUNT, accounts);
      });

      _web3.givenProvider.on("chainChanged", (network) => {
        console.log("network", _web3.utils.hexToNumber(network));
        commit(
          at.SET_CURRENT_CRYPTO_CHAIN_ID,
          _web3.utils.hexToNumber(network)
        );
      });

      _web3.givenProvider.on("message", (msg) => {
        console.log("msg", msg);
      });

      commit(at.SET_WEB3_INSTANCE, _web3);
    },
    [at.SET_PARENT_REFERRAL_UUID]({ commit }, parentReferralUUID) {
      localStorage.setItem("parentReferralUUID", parentReferralUUID);
      commit(at.SET_PARENT_REFERRAL_UUID, parentReferralUUID);
    },
    [at.SET_AGREEMENT]({ commit }, agreementData) {
      localStorage.setItem("agreementData", JSON.stringify(agreementData));
      commit(at.SET_AGREEMENT, agreementData);
    },
    [at.LOAD_AGREEMENT_DATA]({ commit }) {
      let agreementData = localStorage.getItem("agreementData");

      if (agreementData.trim().length > 0) {
        commit(at.SET_AGREEMENT, JSON.parse(agreementData));
      }
    },
    [at.SET_ACTIVE_STORY]({ commit }, activeStory) {
      commit(at.SET_ACTIVE_STORY, activeStory);
    },
    [at.SET_SUPPORT_LINK]({ commit }, page) {
      commit(at.SET_SUPPORT_LINK, `${SUPPORT_URL}?start=${page}`);
    },
  },
  modules: {},
});
