import Big from "big.js";

export default {
  methods: {
    toFixedDecimals(number, decimals = 2) {
      try {
        return Big(Big(number).toFixed(decimals)).toPrecision();
      } catch (e) {
        return 0;
      }
    },
  },
};
