<template>
  <div class="popup">
    <div class="popup__block popup__block--card-data">
      <div class="popup__header">
        <div class="popup__title">
          {{ $t("card.transaction.popup.details-title") }}
        </div>
        <popup-close-button @click="closePopup"></popup-close-button>
      </div>
      <div class="transaction-data">
        <div class="transaction-data__items">
          <base-loader v-if="isDataLoading"></base-loader>
          <div
            class="transaction-data__item"
            :class="{
              'transaction-data__item--pending':
                transaction.status_slug === 'pending',
            }"
          >
            <div
              class="transaction__icon"
              v-if="transaction.mcc_svg"
              v-html="transaction.mcc_svg"
            ></div>
            <div class="transaction__icon" v-else>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="12" fill="#00B7BD" />
                <path
                  d="M7.33333 10.104C6.29131 10.104 5.4375 10.9581 5.4375 11.9998C5.4375 13.0417 6.29152 13.8957 7.33333 13.8957C8.37515 13.8957 9.22917 13.0417 9.22917 11.9998C9.22917 10.958 8.37515 10.104 7.33333 10.104ZM12 10.104C10.958 10.104 10.1042 10.9581 10.1042 11.9998C10.1042 13.0417 10.9582 13.8957 12 13.8957C13.0418 13.8957 13.8958 13.0417 13.8958 11.9998C13.8958 10.958 13.0418 10.104 12 10.104ZM16.6667 10.104C15.6246 10.104 14.7708 10.9581 14.7708 11.9998C14.7708 13.0417 15.6249 13.8957 16.6667 13.8957C17.7087 13.8957 18.5625 13.0415 18.5625 11.9998C18.5625 10.958 17.7085 10.104 16.6667 10.104ZM7.33333 10.979C7.9021 10.979 8.35417 11.4311 8.35417 11.9998C8.35417 12.5686 7.9021 13.0207 7.33333 13.0207C6.76457 13.0207 6.3125 12.5686 6.3125 11.9998C6.3125 11.431 6.76419 10.979 7.33333 10.979ZM12 10.979C12.5688 10.979 13.0208 11.4311 13.0208 11.9998C13.0208 12.5686 12.5688 13.0207 12 13.0207C11.4312 13.0207 10.9792 12.5686 10.9792 11.9998C10.9792 11.431 11.4309 10.979 12 10.979ZM16.6667 10.979C17.2354 10.979 17.6875 11.4311 17.6875 11.9998C17.6875 12.5687 17.2358 13.0207 16.6667 13.0207C16.0979 13.0207 15.6458 12.5686 15.6458 11.9998C15.6458 11.431 16.0975 10.979 16.6667 10.979Z"
                  fill="white"
                />
              </svg>
            </div>
            <div class="transaction__summ">
              {{
                `${transaction.is_unex_direct_outcom ? "-" : ""}${
                  (transaction.origin_transaction_amount || 0) / 100
                }`
              }}
              {{ getCurrencySymbol(transaction.origin_transaction_currency) }}
            </div>
            <div class="transaction__summsalesman">
              {{ transaction.merchant_name }}
            </div>
            <div class="transaction__type">
              {{ displayCategory }}
            </div>
            <div class="transaction__date">
              <span class="transaction__date-day">{{ transactionDate }}</span>
              <span class="transaction__date-time">{{ transactionTime }}</span>
            </div>
            <div class="transaction__valet">
              <div
                class="transaction__valet-icon"
                v-if="transaction.exchange_svg && transaction.full_crypto_sm"
                v-html="transaction.exchange_svg"
              ></div>
              <div class="transaction__valet-name">
                {{ transaction.exchange_name }}
              </div>
            </div>
            <div class="transaction__exchange">
              <div class="transaction__exchange-first">
                <div class="transaction__exchange-title">
                  {{ $t("card.transaction.exchange-from") }}
                  <span>{{ transaction.exchange_name }}</span>
                  <v-popover placement="top" class="hint">
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1724 6.25063C10.828 6.25063 11.3793 5.68712 11.3793 5.01404C11.3793 4.30965 10.8131 3.7931 10.1724 3.7931C9.53172 3.7931 8.96552 4.30965 8.96552 5.01404C8.96552 5.70278 9.51682 6.25063 10.1724 6.25063Z"
                          fill="#7A939C"
                        />
                        <path
                          d="M9.11452 14.8129C9.11452 15.2355 9.38272 15.5172 9.78502 15.5172H10.5747C10.977 15.5172 11.2452 15.2355 11.2452 14.8129V8.03508C11.2452 7.61245 10.977 7.33069 10.5747 7.33069H9.78502C9.38272 7.33069 9.11452 7.61245 9.11452 8.03508V14.8129Z"
                          fill="#7A939C"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.6207 10C18.6207 14.7611 14.7611 18.6207 10 18.6207C5.23892 18.6207 1.37931 14.7611 1.37931 10C1.37931 5.23892 5.23892 1.37931 10 1.37931C14.7611 1.37931 18.6207 5.23892 18.6207 10Z"
                          fill="#7A939C"
                        />
                      </svg>
                    </span>
                    <template slot="popover" class="hint">
                      <div class="hint__body">
                        <div class="hint__text">
                          {{
                            $t("card-transactions.item.mwa-hint", {
                              mwa: transaction.mwa,
                              currency: transaction.currency,
                            })
                          }}
                        </div>
                      </div>
                      <div class="hint__footer">
                        <a v-close-popover class="hint__close">{{
                          $t("card-transactions.item.mwa-hint-close")
                        }}</a>
                      </div>
                    </template>
                  </v-popover>
                </div>
                <div class="transaction__exchange-summ">
                  - {{ toFixedDecimals(transaction.full_crypto_sm / 100, 4) }}
                  {{ transaction.currency }}
                </div>
              </div>
              <div class="transaction__exchange-second">
                <div class="transaction__exchange-subtitle">
                  {{ $t("card.transaction.commission") }}
                </div>
                <div class="transaction__exchange-subsumm">
                  -
                  {{
                    toFixedDecimals(transaction.sm_commission_crypto / 100, 4)
                  }}
                  {{ transaction.currency }}
                </div>
              </div>
              <div
                class="transaction__exchange-second"
                v-if="ownAmount !== false"
              >
                <div class="transaction__exchange-subtitle">
                  {{ $t("card.transaction.own-amount") }}
                </div>
                <div class="transaction__exchange-subsumm">
                  {{ ownAmount }} ₴
                </div>
              </div>
              <div class="transaction__exchange-second">
                <div class="transaction__exchange-subtitle">
                  {{ $t("card.transaction.weld-wallet-credit") }}
                </div>
                <div class="transaction__exchange-subsumm">
                  -
                  {{ toFixedDecimals(transaction.wallet_credit / 100, 4) }}
                  {{ transaction.currency }}
                </div>
              </div>
            </div>
            <div class="transaction__exchange">
              <div class="transaction__exchange-first">
                <div class="transaction__exchange-title">
                  {{ $t("card.transaction.amount") }}
                </div>
                <div class="transaction__exchange-summ">
                  -
                  {{
                    toFixedDecimals(transaction.operation_crypto_sm / 100, 4)
                  }}
                  {{ transaction.currency }}
                </div>
              </div>
              <div class="transaction__exchange-second">
                <div class="transaction__exchange-subtitle">
                  {{ $t("card.transaction.exchange-rate") }}
                </div>
                <div class="transaction__exchange-subsumm">
                  1 {{ transaction.currency }} = {{ transaction.rate || 0 }} ₴
                </div>
              </div>
            </div>
            <div class="transaction__exchange">
              <div class="transaction__exchange-first">
                <div class="transaction__exchange-title">
                  {{ $t("card.transaction.weld-wallet-income") }}
                </div>
                <div class="transaction__exchange-summ green">
                  {{ toFixedDecimals(transaction.wallet_debit / 100, 4) }}
                  {{ transaction.currency }}
                </div>
              </div>
            </div>
            <div class="transaction__exchange">
              <div class="transaction__exchange-first">
                <div class="transaction__exchange-title">
                  {{ $t("card.transaction.weld-wallet-balance") }}
                </div>
                <div class="transaction__exchange-summ green">
                  {{
                    toFixedDecimals(transaction.tail_wallet_balance / 100, 4)
                  }}
                  {{ transaction.currency }}
                </div>
              </div>
            </div>
            <div class="transaction__exchange">
              <div class="transaction__exchange-first">
                <div class="transaction__exchange-title">
                  {{ $t("card.transaction.transaction-status.title") }}
                </div>
                <div
                  class="transaction__exchange-summ"
                  :class="{
                    'transaction__exchange-summ--pending':
                      transaction.status_slug === 'pending',
                    'transaction__exchange-summ--complete':
                      transaction.status_slug === 'complete',
                  }"
                >
                  {{
                    $t(
                      `card.transaction.transaction-status.${transaction.status_slug}`
                    )
                  }}
                </div>
              </div>
            </div>
            <div
              class="button-close transaction__exchange-button-close"
              @click="closePopup"
            >
              {{ $t("card.popup.invite.button") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopupCloseButton from "@/components/button/PopupCloseButton";
import BaseLoader from "@/components/base/BaseLoader";
import moment from "moment";
import mathMixin from "@/mixins/math-mixin";

export default {
  name: "CardItemDataPopup",
  components: {
    BaseLoader,
    PopupCloseButton,
  },
  mixins: [mathMixin],
  inject: ["showErrorTip", "showSuccessTip", "getCurrencySymbol"],
  props: {
    transaction: {
      type: Object,
    },
    displayCategory: {
      type: String,
    },
  },
  data() {
    return {
      isDataLoading: false,
    };
  },
  computed: {
    transactionDate: function () {
      return moment(this.transaction.created_at).format("YYYY-MM-DD");
    },
    transactionTime: function () {
      return moment(this.transaction.created_at).format("HH:mm");
    },
    ownAmount: function () {
      let _ownAmount = this.transaction.full_sm_uah - this.transaction.sm_uah;

      if (_ownAmount === 0) {
        return false;
      }

      return _ownAmount / 100;
    },
  },
  methods: {
    closePopup() {
      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  &__block {
    &--card-data {
      width: 100%;
      max-width: 470px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-right: 30px;
  }
}
.transaction-data-popup {
  position: relative;
}

.transaction-data {
  margin-top: 36px;

  &__items {
    position: relative;
    padding: 24px;
    border-radius: 8px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    &--pending {
      .transaction {
        &__icon {
          width: 64px;
          height: 64px;

          svg {
            width: 100%;
            height: 100%;

            circle {
              fill: $mainYellow;
            }
          }
        }

        &_summ {
          color: $mainYellow;
        }
      }
    }

    .transaction {
      &__icon {
        width: 64px;
        height: 64px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      &__summ {
        margin-top: 16px;
        font-style: normal;
        font-weight: 700;
        font-size: pxToRem(24);
        line-height: pxToRem(28);

        text-align: center;

        color: $mainBlack;
      }
      &__salesman {
        margin-top: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: pxToRem(16);
        line-height: pxToRem(20);

        text-align: center;

        color: $mainBlack;
      }
      &__type {
        margin-top: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: pxToRem(14);
        line-height: pxToRem(18);

        text-align: center;

        color: $gray1;
      }
      &__date {
        margin-top: 16px;
        &-day {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);
          color: $gray1;
        }
        &-time {
          margin-left: 6px;
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);

          color: $gray1;
        }
      }
      &__valet {
        width: 100%;
        margin-top: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &-icon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        &-name {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);

          color: $gray1;
        }
      }
      &__exchange {
        width: 100%;
        margin-bottom: 20px;
        &-first {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &-title {
          display: flex;
          justify-content: left;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);

          color: $mainBlack;
          span {
            margin-left: 8px;
            margin-right: 8px;
            font-weight: 700;
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
        &-summ {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);

          color: $mainBlack;

          &--pending {
            color: $mainYellow;
          }

          &-complete {
            color: $mainCold;
          }

          &.green {
            color: rgba(0, 183, 189, 1);
          }
        }
        &-second {
          width: 100%;
          margin-top: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &-subtitle {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);
          color: $gray1;
        }
        &-subsumm {
          font-style: normal;
          font-weight: 500;
          font-size: pxToRem(14);
          line-height: pxToRem(18);
          color: $gray1;
        }
      }
    }

    .button-close {
      bottom: 58px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 12px 36px;
      gap: 10px;

      width: 100%;
      height: 44px;

      background: $mainCold;
      border-radius: 24px;

      font-style: normal;
      font-weight: 700;
      font-size: pxToRem(16);
      line-height: pxToRem(20);

      text-align: center;

      color: #ffffff;
      margin-bottom: 20px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
.transaction__icon {
  width: 64px;
  height: 64px;
  svg {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 486px) {
  .transaction-data {
    height: 600px;
    overflow-y: auto;
  }
  .transaction__icon {
    width: 64px;
    height: 64px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
@media screen and (max-width: 486px) {
  .transaction__icon {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
