<template>
  <div class="popup">
    <div class="popup__block popup__block--onboarding-feedback">
      <div class="popup__header">
        <div class="popup__title">
          {{ $t("unex.onboarding.popup.feedback.title") }}
        </div>
        <popup-close-button @click="closePopup"></popup-close-button>
      </div>
      <div class="onboarding-feedback">
        <div
          class="onboarding-feedback__text"
          v-html="
            $t('unex.onboarding.popup.feedback.text', {
              link: feedbackFormLink,
            })
          "
        ></div>
        <div class="onboarding-feedback__actions">
          <no-bg-button
            class="onboarding-feedback__action"
            :label="$t('unex.onboarding.popup.feedback.button')"
            @click="closePopup"
          ></no-bg-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopupCloseButton from "@/components/button/PopupCloseButton";
import NoBgButton from "@/components/button/NoBgButton";
import { mapGetters, mapState } from "vuex";
import { UnexOnboardingService } from "@/services/unex/unex-onboarding.service";

export default {
  name: "OnboardingFeedbackPopup",
  components: { NoBgButton, PopupCloseButton },
  inject: ["showErrorTip"],
  data() {
    return {
      cardProducts: [],
      selectedCardProduct: null,
    };
  },
  computed: {
    ...mapState(["selectedLocale"]),
    ...mapGetters(["userData"]),
    feedbackFormLink: function () {
      return UnexOnboardingService.getFeedbackLink(
        this.userData.cell_phone,
        this.userData.full_name,
        this.selectedLocale
      );
    },
  },
  methods: {
    closePopup() {
      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  &__block {
    &--onboarding-feedback {
      width: 100%;
      max-width: 440px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-right: 30px;
  }
}
.onboarding-feedback-popup {
  position: relative;
}

.onboarding-feedback {
  margin-top: 36px;

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__actions {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__action {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    background: $grayLight;
    border-radius: 8px;
    padding: 24px;

    a {
      color: $mainCold;
    }
  }
}
</style>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";

.onboarding-feedback {
  &__text {
    a {
      color: $mainCold;
    }
  }
}
</style>
