<template>
  <div class="popup">
    <div class="popup__block">
      <div class="p-details">
        <div class="p-details__full">
          <div class="p-details__section">
            <div class="p-details__section-header">
              <div class="popup__title">{{ $t("change_password") }}</div>
              <popup-close-button @click="closePopup"></popup-close-button>
            </div>
            <div class="p-details__section-body">
              <div class="p-details__field">
                <password-input
                  :title="$t('old_password')"
                  :show-forgot-password-link="false"
                  v-model="oldPassword"
                  :v-errors="v$.oldPassword.$errors"
                ></password-input>
              </div>
              <div class="p-details__field">
                <password-input
                  :title="$t('set_new_password')"
                  :show-forgot-password-link="false"
                  v-model="newPassword"
                  :v-errors="v$.newPassword.$errors"
                ></password-input>
              </div>
              <div class="p-details__field">
                <password-input
                  :title="$t('confirm_new_password')"
                  :show-forgot-password-link="false"
                  v-model="confirmPassword"
                  :v-errors="v$.confirmPassword.$errors"
                  @click="changePassword"
                ></password-input>
              </div>
              <div class="password-info">
                <div class="password-info__title">
                  {{ $t("password_must_be") }}:
                </div>
                <div class="password-info__list">
                  <div
                    class="password-info__item"
                    :class="{
                      active:
                        v$.checkPassword.required.$invalid === false &&
                        v$.checkPassword.minLength.$invalid === false,
                    }"
                  >
                    <div class="password-info__item-icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                          fill="#B7C9D3"
                        />
                      </svg>
                    </div>
                    <div class="password-info__item-text">
                      {{ $t("at_least_chars", { numChars: 8 }) }}
                    </div>
                  </div>
                  <div
                    class="password-info__item"
                    :class="{
                      active:
                        v$.checkPassword.existLowerCase.$invalid === false,
                    }"
                  >
                    <div class="password-info__item-icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                          fill="#B7C9D3"
                        />
                      </svg>
                    </div>
                    <div class="password-info__item-text">
                      {{ $t("contain_lower") }}
                    </div>
                  </div>
                  <div
                    class="password-info__item"
                    :class="{
                      active:
                        v$.checkPassword.existUpperCase.$invalid === false,
                    }"
                  >
                    <div class="password-info__item-icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                          fill="#B7C9D3"
                        />
                      </svg>
                    </div>
                    <div class="password-info__item-text">
                      {{ $t("contain_upper") }}
                    </div>
                  </div>
                  <div
                    class="password-info__item"
                    :class="{
                      active: v$.checkPassword.existDigit.$invalid === false,
                    }"
                  >
                    <div class="password-info__item-icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                          fill="#B7C9D3"
                        />
                      </svg>
                    </div>
                    <div class="password-info__item-text">
                      {{ $t("contain_digit") }}
                    </div>
                  </div>
                  <div
                    class="password-info__item"
                    :class="{
                      active: v$.checkPassword.existSymbol.$invalid === false,
                    }"
                  >
                    <div class="password-info__item-icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.24798 13.0944L4.72214 9.29179C4.33099 8.86993 3.67191 8.84504 3.25005 9.23619C2.82819 9.62735 2.8033 10.2864 3.19445 10.7083L7.13052 14.9533C7.77901 15.6528 8.89736 15.613 9.49457 14.8693L16.6455 5.96478C17.0057 5.51622 16.9341 4.86057 16.4855 4.50035C16.037 4.14013 15.3813 4.21174 15.0211 4.6603L8.24798 13.0944Z"
                          fill="#B7C9D3"
                        />
                      </svg>
                    </div>
                    <div class="password-info__item-text">
                      {{ $t("contain_symbol") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup__actions">
        <no-bg-button
          class="popup__action"
          :label="$t('cancel')"
          @click="closePopup"
        ></no-bg-button>
        <primary-button
          class="popup__action"
          :label="$t('save')"
          @click="changePassword"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import NoBgButton from "@/components/button/NoBgButton";
import PrimaryButton from "@/components/button/PrimaryButton";
import PasswordInput from "@/components/input/PasswordInput";
import { UserApi } from "@/office/user-api";
import PopupCloseButton from "@/components/button/PopupCloseButton";
import useVuelidate from "@vuelidate/core";
import { minLength, required, sameAs } from "@vuelidate/validators";
import { withI18nMessage } from "@/utils";

const existUpperCase = (value) => /[A-Z]/.test(value);
const existLowerCase = (value) => /[a-z]/.test(value);
const existDigit = (value) => /[0-9]/.test(value);
const existSymbol = (value) => /[!@#$%^&*)(+=._-]/.test(value);
const notSameAsOldPassword = (value, siblings) => siblings.oldPassword!==value;

export default {
  name: "EditPasswordPopup",
  components: {
    PopupCloseButton,
    PasswordInput,
    PrimaryButton,
    NoBgButton,
  },
  inject: ["showErrorTip"],
  props: {
    user: Object,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
      checkPassword: "",
    };
  },
  validations() {
    const passwordValidator = {
      required: withI18nMessage(required, {
        messagePath: () => "registration_wm.required",
      }),
      existDigit,
      existLowerCase,
      existSymbol,
      existUpperCase,
      minLength: minLength(8),
    };
    return {
      oldPassword: { ...passwordValidator },
      newPassword: {
        ...passwordValidator,
        notSameAsOldPassword: withI18nMessage(notSameAsOldPassword, {
          messagePath: () => "password_not_sameas",
        }),
      },
      checkPassword: { ...passwordValidator },
      confirmPassword: {
        sameAs: withI18nMessage(sameAs(this.newPassword), {
          messagePath: () => "password_sameas",
        }),
      },
    };
  },
  mounted() {
    this.checkPassword = this.newPassword;
    this.v$.checkPassword.$touch();
  },
  watch: {
    newPassword: function () {
      this.checkPassword = this.newPassword;
      this.v$.checkPassword.$touch();
    },
  },
  methods: {
    closePopup() {
      this.$emit("closed");
    },
    async changePassword() {
      const isValid = await this.v$.$validate();
      if (!isValid) {
        return;
      }
      UserApi.setPassword(
        this.newPassword,
        this.confirmPassword,
        this.oldPassword
      ).then(() => {
        this.$emit("success");
      }, () => {
        this.showErrorTip(this.$t("invalid_password"));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(51, 63, 72, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  &__close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s linear;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .p-details__full {
    padding-bottom: 20px;
    padding-left: 0;
    border-left: 0;
  }

  &__actions {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.password-info {
  margin-top: -28px;

  &__title {
    font-size: pxToRem(12);
    line-height: pxToRem(18);
    color: $mainBlack;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__item {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active & {
      &-icon {
        svg {
          path {
            fill: $mainCold;
          }
        }
      }

      &-text {
        color: $mainCold;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;

      svg,
      img {
        width: 100%;
        height: 100%;
      }

      svg {
        path {
          fill: $gray2;
        }
      }
    }

    &-text {
      font-size: pxToRem(12);
      line-height: pxToRem(18);
      color: $gray2;
    }
  }
}
</style>
